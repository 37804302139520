import React from 'react';
import styled from 'styled-components';
import { Rate } from 'antd';

const StyledRate = styled(Rate)`
	font-size: 24px;
	${props => ({ ...props.style })};

	// Override Ant Design Rate color
	.ant-rate-star-full {
		.ant-rate-star-second {
			color: #fab400;

		}
	}
	.ant-rate-star-half {
		.ant-rate-star-first {
			color: #fab400;
		}
	}

	.ant-rate-star-half, .ant-rate-star-zero {
		.ant-rate-star-second {
			color:#ddd;
		}
	}

	.ant-rate-star-first, .ant-rate-star-second {
		display: flex;
		align-items: center;
	}
`;

interface IRating {
	allowHalf?: boolean;
	disabled?: boolean;
	value: number | string;
	style?: {
		[x:string]: string | number;
	};
	handleChange?: (number) => void;
}

const Rating = ({ allowHalf = false, disabled = true, value, style, handleChange }: IRating): JSX.Element => (
	<StyledRate
		allowHalf={allowHalf}
		disabled={disabled}
		value={value}
		style={style}
		onChange={handleChange}
	/>
);

export default Rating;
