// Get all purchase records by purchase order id list
export const GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_CALL = 'GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_CALL';
export const GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_DONE = 'GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_DONE';
export const GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_FAIL = 'GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_FAIL';

// Get each purchase course detail by course id
export const GET_EACH_PURCHASE_COURSE_DETAIL_BY_ID_CALL = 'GET_EACH_PURCHASE_COURSE_DETAIL_BY_ID_CALL';
export const GET_EACH_PURCHASE_COURSE_DETAIL_BY_ID_DONE = 'GET_EACH_PURCHASE_COURSE_DETAIL_BY_ID_DONE';
export const GET_EACH_PURCHASE_COURSE_DETAIL_BY_ID_FAIL = 'GET_EACH_PURCHASE_COURSE_DETAIL_BY_ID_FAIL';

// Get each purchase event detail by shortLink
export const GET_EACH_PURCHASE_EVENT_DETAIL_BY_LINK_CALL = 'GET_EACH_PURCHASE_EVENT_DETAIL_BY_LINK_CALL';
export const GET_EACH_PURCHASE_EVENT_DETAIL_BY_LINK_DONE = 'GET_EACH_PURCHASE_EVENT_DETAIL_BY_LINK_DONE';
export const GET_EACH_PURCHASE_EVENT_DETAIL_BY_LINK_FAIL = 'GET_EACH_PURCHASE_EVENT_DETAIL_BY_LINK_FAIL';

// Get each purchase webinar detail by shortLink
export const GET_EACH_PURCHASE_WEBINAR_DETAIL_BY_LINK_CALL = 'GET_EACH_PURCHASE_WEBINAR_DETAIL_BY_LINK_CALL';
export const GET_EACH_PURCHASE_WEBINAR_DETAIL_BY_LINK_DONE = 'GET_EACH_PURCHASE_WEBINAR_DETAIL_BY_LINK_DONE';
export const GET_EACH_PURCHASE_WEBINAR_DETAIL_BY_LINK_FAIL = 'GET_EACH_PURCHASE_WEBINAR_DETAIL_BY_LINK_FAIL';
