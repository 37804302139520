/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

import {
	BUTTON_VARIANT_CONTAINED, BUTTON_VARIANT_OUTLINED,
	BUTTON_VARIANT_CONTAINED_DEFAULT_COLOR, BUTTON_VARIANT_OUTLINED_DEFAULT_COLOR,
	BUTTON_VARIANT_CONTAINED_ERROR_COLOR,
} from '@src/const/common';

const BasicButton = styled.div`
	position: relative;
	height: fit-content;
	padding: 16px;
	border-radius: 4px;
	text-align: center;
  white-space: nowrap;
	cursor: ${props => (props.isDisable ? 'unset' : 'pointer')};
`;

const ContainedButton = styled(BasicButton)`
	border: 1px solid ${props => (props.isDisable ? '#C0C0C0' : props.theme.colors.primary)};
	background: ${props => (props.isDisable ? '#C0C0C0' : props.theme.colors.primary)};
	color: #FFF;
	&:hover {
		border-color: ${props => (props.isDisable ? '#9C9C9C' : props.theme.colors.primaryDark)};
		background: ${props => (props.isDisable ? '#C0C0C0' : props.theme.colors.primaryDark)};
	}
`;

const ContainedDefaultColorButton = styled(BasicButton)`
	border: 1px solid ${props => (props.isDisable ? '#C0C0C0' : '#3091FD')};
	background: ${props => (props.isDisable ? '#C0C0C0' : '#3091FD')};
	color: #FFF;
	&:hover {
		border-color: ${props => (props.isDisable ? '#9C9C9C' : '#005ABE')};
		background: ${props => (props.isDisable ? '#C0C0C0' : '#005ABE')};
	}
`;

const ContainedErrorColorButton = styled(BasicButton)`
	border: 1px solid ${props => (props.isDisable ? '#C0C0C0' : '#FFF')};
	background: ${props => (props.isDisable ? '#C0C0C0' : '#FFF')};
	color: #D0021B;
	&:hover {
		border-color: ${props => (props.isDisable ? '#9C9C9C' : '#FFF')};
		background: ${props => (props.isDisable ? '#C0C0C0' : '#FFF')};
		color: #AE0015;
	}
`;

const OutlinedButton = styled(BasicButton)`
	border: 1px solid ${props => (props.isDisable ? '#9C9C9C' : props.theme.colors.primary)};
	background: #FFF;
	color: ${props => (props.isDisable ? '#9C9C9C' : props.theme.colors.primary)};
	&:hover {
		color: ${props => (props.isDisable ? '#9C9C9C' : props.theme.colors.primaryDark)};
		border-color: ${props => (props.isDisable ? '#9C9C9C' : props.theme.colors.primaryDark)};
	}
`;

const OutlinedDefaultColorButton = styled(BasicButton)`
	border: 1px solid ${props => (props.isDisable ? '#9C9C9C' : '#3091FD')};
	background: #FFF;
	color: ${props => (props.isDisable ? '#9C9C9C' : '#3091FD')};
	&:hover {
		color: ${props => (props.isDisable ? '#9C9C9C' : '#005ABE')};
		border-color: ${props => (props.isDisable ? '#9C9C9C' : '#005ABE')};
	}
`;

const SpinnerWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

interface IButton {
	className?: string;
	children: any;
	handleClick?: () => void;
	variant?:
		typeof BUTTON_VARIANT_CONTAINED |
		typeof BUTTON_VARIANT_OUTLINED |
		typeof BUTTON_VARIANT_CONTAINED_DEFAULT_COLOR |
		typeof BUTTON_VARIANT_OUTLINED_DEFAULT_COLOR;
	isLoading?: boolean;
	isDisable?:boolean;
	props?: {
		[x:string]: string | number;
	};
}

/**
 * TODO: 'ref' will be removed.
 * 'ref' is used for antd Tooltip.
 * After Tooltip be removed, ref can be removed.
 * src/components/course/advancedInfo/discussions/operator.tsx
*/
const Button = ({
	className, children, handleClick, variant = BUTTON_VARIANT_CONTAINED, isLoading, isDisable = false, ...props
}: IButton, ref): JSX.Element => {
	// const StyledButton = variant === BUTTON_VARIANT_OUTLINED ? OutlinedButton : ContainedButton;
	let StyledButton = ContainedButton;
	if (variant === BUTTON_VARIANT_CONTAINED) {
		StyledButton = ContainedButton;
	} else if (variant === BUTTON_VARIANT_OUTLINED) {
		StyledButton = OutlinedButton;
	} else if (variant === BUTTON_VARIANT_CONTAINED_DEFAULT_COLOR) {
		StyledButton = ContainedDefaultColorButton;
	} else if (variant === BUTTON_VARIANT_OUTLINED_DEFAULT_COLOR) {
		StyledButton = OutlinedDefaultColorButton;
	} else if (variant === BUTTON_VARIANT_CONTAINED_ERROR_COLOR) {
		StyledButton = ContainedErrorColorButton;
	}

	return (
		<StyledButton
			className={className}
			onClick={(isDisable || isLoading) ? null : handleClick}
			isDisable={isDisable || isLoading}
			{...props}
			ref={ref}
		>
			<SpinnerWrapper className="wrapper">
				{isLoading && <LoadingOutlined style={{ fontSize: 20, marginRight: 8 }} />}
				{children}
			</SpinnerWrapper>
		</StyledButton>
	);
};

export default forwardRef(Button);
