import React, { useEffect } from 'react';
import _ from 'lodash';
import reduxWrapper from '@src/store';
import * as actions from '@src/store/actions';
import * as selectors from '@src/store/selectors';
import HomePage from '@src/pages/home/HomePage';
import { defaultActionCalls } from '@src/router/ssr';
import { SSR_PAGE_HOME } from '@src/router/hydrate';
import { getApiData, getPageTimeoutData } from '@src/utils/deprecatedCommon';
import { getSearchState } from '@src/store/selectors/category';
import { STATE_SUCCESS } from '@src/const/common';
import { useSelector, useDispatch } from 'react-redux';

const homePagesNeedData = ({ list = [], type }) => {
	const find = _.find(list, { type });
	return find && !find.hide;
};

const getHomeThemeList = (themes) => {
	const pages = themes?.pages || [];
	const homeTheme = _.find(pages, ({ content }) => content.indexOf('"key":"home"') > -1);
	return JSON.parse(homeTheme?.content || '{}')?.list || [];
};

const Home = ssrProps => {
	const dispatch = useDispatch();
	const { themes } = useSelector(selectors.getThemeSelector);
	const homeThemeList = getHomeThemeList(themes);
	useEffect(() => {
		const ssrHeaders = {
			'x-school-hostname': process.env.NEXT_PUBLIC_HOSTNAME,
		};
		if (homePagesNeedData({ list: homeThemeList, type: 'courseProducts' })) {
			dispatch(actions.getCourseViewCall({ ssrHeaders }));
		}
		if (homePagesNeedData({ list: homeThemeList, type: 'offlineEvents' })) {
			dispatch(actions.getEventsCall({ ssrHeaders }));
		}
		if (themes?.enable_learning_map_and_area) {
			if (homePagesNeedData({ list: homeThemeList, type: 'learningPath' })) {
				dispatch(actions.getLearningPathsCall({ ssrHeaders }));
			}
			if (homePagesNeedData({ list: homeThemeList, type: 'learningArea' })) {
				dispatch(actions.getLearningAreasCall({ ssrHeaders }));
			}
		}
		// 相容舊學校
		dispatch(actions.getWebinarsCall({ ssrHeaders }));
	}, []);

	return <HomePage ssrProps={ssrProps} />;
};

export const getServerSideProps = reduxWrapper.getServerSideProps(
	async ctx => {
		const { store, req } = ctx;
		const { ssrHeaders } = getApiData(req);
		const time = 3000;
		const pageTimeout = setTimeout(() => {
			const pageTimeoutData = getPageTimeoutData({ req, time });

			if (pageTimeoutData) {
				store.dispatch(
					actions.sendBugMsgCall({ msg: JSON.stringify(pageTimeoutData) }),
				);
			}
		}, time);

		store.dispatch(actions.setSSRPage(SSR_PAGE_HOME));

		let state = store.getState();
		const themes = state?.theme?.themes;
		const homeThemeList = getHomeThemeList(themes);
		const getCourses = homePagesNeedData({ list: homeThemeList, type: 'courseProducts' });
		const getEvents = homePagesNeedData({ list: homeThemeList, type: 'offlineEvents' });

		const success = await new Promise(resolve => {
			const unsubscribe = store.subscribe(() => {
				state = store.getState();

				const defaultActionDone = defaultActionCalls.actionDone(ctx);
				const eventsState = selectors.getEventsState(state);
				const { coursesViewState } = selectors.selectCourse(state);
				const { webinarsListState } = selectors.selectWebinar(state);
				const searchState = getSearchState(state);
				if (
					defaultActionDone &&
					(getEvents ? eventsState === STATE_SUCCESS : true) &&
					(getCourses ? coursesViewState === STATE_SUCCESS : true) &&
					webinarsListState === STATE_SUCCESS &&
					(getCourses ? searchState === STATE_SUCCESS : true)
				) {
					clearTimeout(pageTimeout);
					resolve(unsubscribe);
				}
			});

			defaultActionCalls.actionCall(ctx);

			if (getEvents) {
				store.dispatch(actions.getEventsCall({ ssrHeaders }));
			}
			if (getCourses) {
				store.dispatch(actions.getCourseViewCall({ ssrHeaders }));
				store.dispatch(actions.getCommoditySearchCall({ ssrHeaders, page_size: 8 }));
			}
			// 相容舊學校
			store.dispatch(actions.getWebinarsCall({ ssrHeaders }));

			if (themes?.enable_learning_map_and_area) {
				if (homePagesNeedData({ list: homeThemeList, type: 'learningPath' })) {
					store.dispatch(actions.getLearningPathsCall({ ssrHeaders }));
				}
				if (homePagesNeedData({ list: homeThemeList, type: 'learningArea' })) {
					store.dispatch(actions.getLearningAreasCall({ ssrHeaders }));
				}
			}
		});

		success();
	},
);

export default Home;
