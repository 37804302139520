import { createAction } from 'redux-actions';
import * as types from '@src/store/types';

export const addCourseDiscussionCall = createAction(
	types.ADD_COURSE_DISCUSSION_CALL,
);
export const addCourseDiscussionDone = createAction(
	types.ADD_COURSE_DISCUSSION_DONE,
);
export const addCourseDiscussionFail = createAction(
	types.ADD_COURSE_DISCUSSION_FAIL,
);
export const addCourseDiscussionReset = createAction(
	types.ADD_COURSE_DISCUSSION_RESET,
);

export const addCourseDiscussionReplyCall = createAction(
	types.ADD_COURSE_DISCUSSION_REPLY_CALL,
);
export const addCourseDiscussionReplyDone = createAction(
	types.ADD_COURSE_DISCUSSION_REPLY_DONE,
);
export const addCourseDiscussionReplyFail = createAction(
	types.ADD_COURSE_DISCUSSION_REPLY_FAIL,
);
export const addCourseDiscussionReplyReset = createAction(
	types.ADD_COURSE_DISCUSSION_REPLY_RESET,
);

export const getCourseDiscussionsBeforeCall = createAction(
	types.GET_COURSE_DISCUSSIONS_BEFORE_CALL,
);
export const getCourseDiscussionsBeforeDone = createAction(
	types.GET_COURSE_DISCUSSIONS_BEFORE_DONE,
);
export const getCourseDiscussionsBeforeFail = createAction(
	types.GET_COURSE_DISCUSSIONS_BEFORE_FAIL,
);

export const addCourseDiscussionsBeforeCall = createAction(
	types.ADD_COURSE_DISCUSSIONS_BEFORE_CALL,
);
export const addCourseDiscussionsBeforeDone = createAction(
	types.ADD_COURSE_DISCUSSIONS_BEFORE_DONE,
);
export const addCourseDiscussionsBeforeFail = createAction(
	types.ADD_COURSE_DISCUSSIONS_BEFORE_FAIL,
);

export const getCourseDiscussionsCall = createAction(
	types.GET_COURSE_DISCUSSIONS_CALL,
);
export const getCourseDiscussionsDone = createAction(
	types.GET_COURSE_DISCUSSIONS_DONE,
);
export const getCourseDiscussionsFail = createAction(
	types.GET_COURSE_DISCUSSIONS_FAIL,
);
