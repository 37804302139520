export const STATE_SUCCESS = 'SUCCESS';
export const STATE_LOADING = 'LOADING';
export const STATE_FAIL = 'FAIL';
export const STATE_IDLE = 'IDLE';
export const STATE_PRIVATE = 'HOMEWORK_IS_PRIVATE';
export const STATE_NOT_FOUND = 'NOT FOUND';
export const STATE_INVALID = 'INVALID';
export const STATE_INSUFFICIENT_CONDITION = 'INSUFFICIENT CONDITION';
export const STATE_UNAUTHORIZED = 'UNAUTHORIZED';

export const MODAL_TYPE_LOGIN = 'login';
export const MODAL_TYPE_REGISTER = 'register';
export const MODAL_TYPE_SELECT_AUTH = 'selectAuth';

export const BUTTON_VARIANT_CONTAINED = 'CONTAINED';
export const BUTTON_VARIANT_OUTLINED = 'OUTLINED';
export const BUTTON_VARIANT_CONTAINED_DEFAULT_COLOR = 'CONTAINED_DEFAULT_COLOR';
export const BUTTON_VARIANT_OUTLINED_DEFAULT_COLOR = 'OUTLINED_DEFAULT_COLOR';
export const BUTTON_VARIANT_CONTAINED_ERROR_COLOR = 'CONTAINED_ERROR_COLOR';

export const TOAST_TYPE_SUCCESS = 'success';
export const TOAST_TYPE_WARN = 'warn';
export const TOAST_TYPE_ERROR = 'error';
export const TOAST_TYPE_INFO = 'info';

export const CURRENCY_SYMBOL = 'NT$';

export const STATUS_IN_PROGRESS = 'in_progress';
export const STATUS_EXPIRED = 'expired';
export const STATUS_COMPLETED = 'completed';
export const STATUS_UNCOMPLETED = 'uncompleted';

export const NEWEST_TO_OLDEST = 'latest';
export const OLDEST_TO_NEWEST = 'oldest';

export const INPUT_ONLY_NUMBER_TYPE = 'onlyNumber';

export const SSO_TYPE_GOOGLE = 'google_oauth';
export const SSO_TYPE_SELF_HOST = 'self_hosted';
export const SSO_TYPE_SANWA = 'sanwa';

export const LEARNING_PATH_CATEGORY = 'category';
export const LEARNING_PATH_CERTIFICATE = 'certificate';
export const LEARNING_PATH_WEBINAR = 'webinar';
export const LEARNING_PATH_OFFLINE_EVENT = 'offline_event';
export const LEARNING_PATH_COURSE = 'course';

export const LOCALE = {
	'zh-CHT': 'zh-tw',
	'zh-CHS': 'zh-cn',
	en: 'en',
};

export const I18N_TO_LANG = {
	'zh-tw': 'zh-CHT',
	'zh-cn': 'zh-CHS',
	en: 'en',
};
