import styled from 'styled-components';
import { RateStarIcon } from '@src/components/common/icon';

export const CardWrapper = styled.div`
	background: #FFFFFF;
	cursor: pointer;
	word-break: break-all;
`;

export const MediaWrapper = styled.div`
	position: relative;
`;

export const Title = styled.div`
	font-weight: 500;
	font-size: 18px;
	color: #363636;
	line-height: 27px;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	flex: 0 0 auto;
	min-height: 54px;

	@media 
		${props => props.theme.device.mobile},
		${props => props.theme.device.smTablet}
		{
			font-size: 16px;
			line-height: 24px;
			min-height: 48px;
		}

`;

export const HostBy = styled.div`
	font-size: 12px;
	color: #797979;
	line-height: 18px;
	min-height: 18px;
`;

export const PriceRange = styled.div`
	color: ${props => props.theme.colors.primary};
	font-size: 22px;
	font-weight: 500;
	align-items: center;

	.price-range__currency {
		font-weight: 400;
		margin-right: 4px;
	}
	.price-range__number {
	}
`;

export const InfoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	border: 1px solid #E9E9E9;
	border-left: none;
	border-right: none;
	background-color: #fff;
	padding: 8px 0px;
	align-items: center;
`;

export const InfoItem = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;
	padding: 0px 8px;
	color: #797979;
`;

export const StyledRateStarIcon = styled(RateStarIcon)`
	margin-bottom: 2px;
	margin-right: 6px;
`;

export const InfoHr = styled.div`
	width: 1px;
	height: 10px;
	background-color: #E9E9E9;
`;
