import React from 'react';
import HtmlParser from '@src/components/common/htmlParser';
import { MyProgressiveImage } from '@src/components';
import classStyle from './TextBackground.module.less';

export default class TextBackground extends React.Component {
	render() {
		const { item } = this.props;
		const { text, image, thumbnailLink } = item;

		return (
			<div className={classStyle.container}>
				<MyProgressiveImage
					className={classStyle.image}
					src={image}
					placeholder={thumbnailLink}
				/>
				<HtmlParser className={classStyle.textDiv} html={text} />
			</div>
		);
	}
}
