import React from 'react';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { IconComponent } from '@src/icons';
import HtmlParser from '@src/components/common/htmlParser';
import classStyle from './FAQComponent.module.less';

export default class FAQComponent extends React.Component {
	constructor(props) {
		super(props);
		const {
			item: { list },
		} = this.props;

		this.state = {
			list,
		};
	}

	handleOpenItem = ({ item }) => {
		const {
			item: { list },
		} = this.props;

		item.isOpen = !item.isOpen;

		this.setState({
			list,
		});
	};

	renderItem = (item, index) => {
		const { isThemeEdit } = this.props;
		const { isOpen, title, text } = item;
		const isOpenItem = isOpen || isThemeEdit;
		const icon = isOpenItem ? 'minus' : 'add';

		return (
			<div key={index}>
				<div
					className={classStyle.item}
					onClick={() => this.handleOpenItem({ item })}
				>
					<div className={classStyle.title}>{title}</div>
					<IconComponent size={16} className={classStyle.addIcon} icon={icon} />
				</div>
				<motion.div
					initial="collapsed"
					animate={isOpenItem ? 'open' : 'collapsed'}
					exit="collapsed"
					variants={{
						open: { opacity: 1, height: 'auto' },
						collapsed: { opacity: 0, height: 0 },
					}}
					transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
				>
					{isOpenItem && <HtmlParser className={classStyle.text} html={text} />}
				</motion.div>
			</div>
		);
	};

	render() {
		const {
			item: { list },
		} = this.props;

		return (
			<div className={classStyle.container}>{_.map(list, this.renderItem)}</div>
		);
	}
}
