import React from 'react';
import { MyIcon } from './MyIcon';

export const IconComponent = (props) => {
  const { iconType } = props;
  let Icon;

  switch (iconType) {
    case 'MyIcon':
      Icon = MyIcon;
      break;
    default:
      Icon = MyIcon;
      break;
  }

  return <Icon {...props} />;
};
