import { createAction } from 'redux-actions';
import * as types from '@src/store/types';

export const getQuizzesQuestionSheetCall = createAction(types.GET_QUIZZES_QUESTION_SHEET_CALL);
export const getQuizzesQuestionSheetDone = createAction(types.GET_QUIZZES_QUESTION_SHEET_DONE);
export const getQuizzesQuestionSheetFail = createAction(types.GET_QUIZZES_QUESTION_SHEET_FAIL);

export const getQuizzesAnswerSheetCall = createAction(types.GET_QUIZZES_ANSWER_SHEET_CALL);
export const getQuizzesAnswerSheetDone = createAction(types.GET_QUIZZES_ANSWER_SHEET_DONE);
export const getQuizzesAllAnswerSheetDone = createAction(types.GET_QUIZZES_ALL_ANSWER_SHEET_DONE);
export const getQuizzesAnswerSheetFail = createAction(types.GET_QUIZZES_ANSWER_SHEET_FAIL);

export const getQuizzesAnswerSheetViewAnswerCall = createAction(types.GET_QUIZZES_ANSWER_SHEET_VIEW_ANSWER_CALL);
export const getQuizzesAnswerSheetViewAnswerDone = createAction(types.GET_QUIZZES_ANSWER_SHEET_VIEW_ANSWER_DONE);
export const getQuizzesAnswerSheetViewAnswerFail = createAction(types.GET_QUIZZES_ANSWER_SHEET_VIEW_ANSWER_FAIL);

export const setQuizzesAnswerSheetCall = createAction(types.SET_QUIZZES_ANSWER_SHEET_CALL);
export const setQuizzesAnswerSheetDone = createAction(types.SET_QUIZZES_ANSWER_SHEET_DONE);
export const setQuizzesAnswerSheetFail = createAction(types.SET_QUIZZES_ANSWER_SHEET_FAIL);
