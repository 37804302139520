import React from 'react';
import styled from 'styled-components';
import { Skeleton } from 'antd';

const SkeletonCardWrapper = styled.div`
	width: 100%;
	@media
		${props => props.theme.device.mobile} {
		display: grid;
		grid-template-columns: 120px 1fr;
	}
`;

const StyledMediaWrapper = styled.div`
	width: 100%;
	@media
		${props => props.theme.device.mobile} {
			display: flex;
			align-items: center;
		}
`;

const StyledMedia = styled(Skeleton.Button)`
	width: 100% !important;
	height: 148px !important;
	@media
		${props => props.theme.device.mobile} {
			height: 67px !important;
		}
`;

const CardContentWrapper = styled.div`
	padding: 4px;
	@media
		${props => props.theme.device.mobile} {
			height: 100px !important;
			margin-left: 10px;

			.ant-skeleton-paragraph {
				margin: 0px !important;
			}
			li {
				margin: 8px 0px !important;
			}
	}
`;

const StyledSkeleton = styled(Skeleton)`
	margin-top: 36px;
	padding: 7px 0px;
	border-top: 1px solid #E9E9E9;
	border-bottom: 1px solid #E9E9E9;
	&.ant-skeleton .ant-skeleton-title {
		width: 80% !important;
		margin: unset !important;
	}
	&.ant-skeleton .ant-skeleton-paragraph {
		display: none;
	}

	@media
		${props => props.theme.device.mobile} {
			display: none;
		}
`;

const StyledSkeletonText = styled(Skeleton)`
	&.ant-skeleton .ant-skeleton-title {
		display: none;
	}
	.ant-skeleton-paragraph {
		margin: 12px 0px 4px 0px !important;
	}
	.ant-skeleton-content .ant-skeleton-paragraph > li {
		height: 20px;
	}
	.ant-skeleton-content .ant-skeleton-paragraph > li + li {
		margin-top: 12px;
	}
`;

const SkeletonCard = ({ className }: { className?: string }): JSX.Element => (
	<SkeletonCardWrapper className={className}>
		<StyledMediaWrapper>
			<StyledMedia active size="default" shape="default" />
		</StyledMediaWrapper>
		<CardContentWrapper>
			<StyledSkeletonText active paragraph={{ rows: 3 }} />
			<StyledSkeleton active paragraph={{ rows: 0 }} />
		</CardContentWrapper>
	</SkeletonCardWrapper>
);

export default SkeletonCard;
