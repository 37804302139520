import Cookies from 'universal-cookie';
import config from '@src/config';

const { isTest, type } = config;

export const range = (start, end) => {
	const length = end - start + 1;
	return Array.from({ length }, (_, i) => start + i);
};

export const checkIsServer = () => typeof window === 'undefined';

export const clearQueryString = (url = '') => url.split('?')[0];

export const getApiData = req => {
	if (!req) {
		if (!checkIsServer()) {
			window.location.reload();
		}
	}
	const cookies = new Cookies(req?.headers?.cookie);
	const internalApiDomain = checkIsServer() ? 'http://course-api:8001' : `https://${window.location.host}/api`;
	let apiDomain = internalApiDomain;
	let host = req?.headers['x-school-hostname'] || req?.headers?.host;
	let ssrHeaders = {
		'x-school-hostname': host,
		Authorization: cookies.get('token') || '',
	};

	if (isTest) {
		// internalApiDomain = 'https://staging.teaches.cc/api';
		// internalApiDomain = 'https://teaches.cc/api';
		// apiDomain = internalApiDomain;
		// ssrHeaders = {
		//   'x-school-hostname': host,
		//   host,
		// };
		apiDomain = `https://${process.env.NEXT_PUBLIC_HOSTNAME}/api`;
		host = process.env.NEXT_PUBLIC_HOSTNAME;
		ssrHeaders = {
			'x-school-hostname': host,
			Authorization: cookies.get('token') || '',
		};
	}

	// Setup Domain
	const domain = `https://${host}`;
	const ssrIsMobile = !!req?.headers['user-agent'].match(
		/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
	);

	return {
		apiDomain,
		ssrHeaders,
		domain,
		ssrIsMobile,
	};
};

export const getPageTimeoutData = ({ req, time }) => {
	if (isTest) {
		return;
	}

	const { url } = req;
	const host = req?.headers['x-school-hostname'] || req?.headers?.host;
	const errorMessage = {
		type,
		msg: 'pageTimeout',
		host,
		url,
		time,
	};

	console.log(errorMessage);

	return errorMessage;
};
