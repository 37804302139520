export const ADD_PRODUCT_TO_CART_CALL = 'ADD_PRODUCT_TO_CART_CALL';
export const ADD_PRODUCT_TO_CART_DONE = 'ADD_PRODUCT_TO_CART_DONE';
export const ADD_PRODUCT_TO_CART_FAIL = 'ADD_PRODUCT_TO_CART_FAIL';
export const ADD_PRODUCT_TO_CART_CANCEL = 'ADD_PRODUCT_TO_CART_CANCEL';

export const GET_ALL_PURCHASE_LIST_CALL = 'GET_ALL_PURCHASE_LIST_CALL';
export const GET_COMPLETED_PURCHASE_LIST_CALL =
  'GET_COMPLETED_PURCHASE_LIST_CALL';
export const GET_CANCELED_PURCHASE_LIST_CALL =
  'GET_CANCELED_PURCHASE_LIST_CALL';
export const GET_PURCHASE_LIST_DONE = 'GET_PURCHASE_LIST_DONE';
export const GET_PURCHASE_LIST_FAIL = 'GET_PURCHASE_LIST_FAIL';

export const GET_PURCHASE_CALL = 'GET_PURCHASE_CALL';
export const GET_PURCHASE_DONE = 'GET_PURCHASE_DONE';
export const GET_PURCHASE_FAIL = 'GET_PURCHASE_FAIL';

export const GET_PURCHASE_COURSE_LIST_CALL = 'GET_PURCHASE_COURSE_LIST_CALL';
export const GET_PURCHASE_COURSE_LIST_DONE = 'GET_PURCHASE_COURSE_LIST_DONE';
export const GET_PURCHASE_COURSE_LIST_FAIL = 'GET_PURCHASE_COURSE_LIST_FAIL';

export const GET_PURCHASE_COURSE_TEACHER_LIST_CALL =
  'GET_PURCHASE_COURSE_TEACHER_LIST_CALL';
export const GET_PURCHASE_COURSE_TEACHER_LIST_DONE =
  'GET_PURCHASE_COURSE_TEACHER_LIST_DONE';
export const GET_PURCHASE_COURSE_TEACHER_LIST_FAIL =
  'GET_PURCHASE_COURSE_TEACHER_LIST_FAIL';

export const CANCEL_PURCHASE_ORDER_CALL = 'CANCEL_PURCHASE_ORDER_CALL';
export const CANCEL_PURCHASE_ORDER_DONE = 'CANCEL_PURCHASE_ORDER_DONE';
export const CANCEL_PURCHASE_ORDER_FAIL = 'CANCEL_PURCHASE_ORDER_FAIL';

export const BUY_THE_FREE_COURSE_CALL = 'BUY_THE_FREE_COURSE_CALL';
export const BUY_THE_FREE_COURSE_DONE = 'BUY_THE_FREE_COURSE_DONE';
export const BUY_THE_FREE_COURSE_FAIL = 'BUY_THE_FREE_COURSE_FAIL';
export const BUY_THE_FREE_COURSE_CANCEL = 'BUY_THE_FREE_COURSE_CANCEL';

export const BUY_THE_FREE_CART_CALL = 'BUY_THE_FREE_CART_CALL';
export const BUY_THE_FREE_CART_DONE = 'BUY_THE_FREE_CART_DONE';
export const BUY_THE_FREE_CART_FAIL = 'BUY_THE_FREE_CART_FAIL';

export const GET_PURCHASE_EVENTS_CALL = 'GET_PURCHASE_EVENTS_CALL';
export const GET_PURCHASE_EVENTS_DONE = 'GET_PURCHASE_EVENTS_DONE';
export const GET_PURCHASE_EVENTS_FAIL = 'GET_PURCHASE_EVENTS_FAIL';

export const GET_PURCHASE_WEBINARS_CALL = 'GET_PURCHASE_WEBINARS_CALL';
export const GET_PURCHASE_WEBINARS_DONE = 'GET_PURCHASE_WEBINARS_DONE';
export const GET_PURCHASE_WEBINARS_FAIL = 'GET_PURCHASE_WEBINARS_FAIL';
