import React from 'react';
import _ from 'lodash';
import HtmlParser from '@src/components/common/htmlParser';
import { Row, Col } from 'antd';
import classStyle from './Texts.module.less';

export default function Texts(props) {
	const { item } = props;
	const { list = [] } = item;
	const md = 24 / list.length;

	const renderItem = (item, index) => {
		const { text } = item;

		return (
			<Col xs={24} md={md} key={index}>
				<HtmlParser html={text} />
			</Col>
		);
	};

	return (
		<Row className={classStyle.wrapper} gutter={[15, 15]}>
			{_.map(list, renderItem)}
		</Row>
	);
}
