export const GET_WEBINAR_CALL = 'GET_WEBINAR_CALL';
export const GET_WEBINAR_DONE = 'GET_WEBINAR_DONE';
export const GET_WEBINAR_FAIL = 'GET_WEBINAR_FAIL';

export const GET_WEBINARS_CALL = 'GET_WEBINARS_CALL';
export const GET_WEBINARS_DONE = 'GET_WEBINARS_DONE';
export const GET_WEBINARS_FAIL = 'GET_WEBINARS_FAIL';

export const SET_CURRENT_WEBINAR_PRICE = 'SET_CURRENT_WEBINAR_PRICE';

export const SET_WEBINAR_COUNTDOWN_FOR_SALE = 'SET_WEBINAR_COUNTDOWN_FOR_SALE';
