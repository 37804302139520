import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import classStyle from './ThemeCarousel.module.less';
import _ from 'lodash';
import Image from 'react-image';
import AliceCarousel from 'react-alice-carousel';
import leftImage from '@src/assets/images/theme/leftImage.svg';
import rightImage from '@src/assets/images/theme/rightImage.svg';

class ThemeCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowPrev: props.infinite,
      isShowNext: props.infinite,
    };
  }

  handleSlideTo = () => {
    this.Carousel.slideTo();
  };

  handlePrev = () => {
    this.Carousel.slidePrev();
  };

  handleNext = () => {
    this.Carousel.slideNext();
  };

  handleSlideChanged = (slideEvent) => {
    const { infinite } = this.props;

    if (infinite) {
      return;
    }

    const { slide } = slideEvent;
    const { item } = this.props;
    const length = item.list.length;

    this.setState({
      isShowPrev: slide === 0 ? false : true,
      isShowNext: slide < length - 1 ? true : false,
    });
  };

  render() {
    const {
      item,
      responsive,
      onRenderItem,
      classNames = {},
      startIndex,
      infinite = false,
    } = this.props;
    const { list } = item;

    return (
      <div className={classStyle.container}>
        <div className={cx(classStyle.carousel, classNames.carousel)}>
          <AliceCarousel
            ref={(ref) => (this.Carousel = ref)}
            key={startIndex}
            mouseTracking
            disableDotsControls
            disableButtonsControls
            responsive={responsive}
            activeIndex={startIndex}
            onInitialized={this.handleSlideChanged}
            onSlideChanged={this.handleSlideChanged}
            infinite={infinite}
          >
            {_.map(list, onRenderItem)}
          </AliceCarousel>
        </div>
        {(infinite || this.state.isShowPrev) && (
          <div
            className={cx(classStyle.prevButton, classNames.prevButton)}
            onClick={this.handlePrev}
          >
            <Image className={classStyle.buttonImage} src={leftImage} />
          </div>
        )}
        {(infinite || this.state.isShowNext) && (
          <div
            className={cx(classStyle.nextButton, classNames.nextButton)}
            onClick={this.handleNext}
          >
            <Image className={classStyle.buttonImage} src={rightImage} />
          </div>
        )}
      </div>
    );
  }
}

ThemeCarousel.defaultProps = {
  infinite: true,
};

ThemeCarousel.propTypes = {
  infinite: PropTypes.bool,
};

export default ThemeCarousel;
