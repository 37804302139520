import { createAction } from 'redux-actions';
import {
	GET_KNOWLEDGE_BASE_SPACES_CALL,
	GET_KNOWLEDGE_BASE_SPACES_DONE,
	GET_KNOWLEDGE_BASE_SPACES_FAIL,
	POST_KNOWLEDGE_BASE_SPACE_CALL,
	POST_KNOWLEDGE_BASE_SPACE_DONE,
	POST_KNOWLEDGE_BASE_SPACE_FAIL,
	DELETE_KNOWLEDGE_BASE_SPACE_CALL,
	DELETE_KNOWLEDGE_BASE_SPACE_DONE,
	DELETE_KNOWLEDGE_BASE_SPACE_FAIL,
	GET_KNOWLEDGE_BASE_SPACE_DETAIL_CALL,
	GET_KNOWLEDGE_BASE_SPACE_DETAIL_DONE,
	GET_KNOWLEDGE_BASE_SPACE_DETAIL_FAIL,
	PATCH_KNOWLEDGE_BASE_SPACE_CALL,
	PATCH_KNOWLEDGE_BASE_SPACE_DONE,
	PATCH_KNOWLEDGE_BASE_SPACE_FAIL,
} from '@src/store/types';

export const getKnowledgeBaseSpacesCall = createAction(GET_KNOWLEDGE_BASE_SPACES_CALL);
export const getKnowledgeBaseSpacesDone = createAction(GET_KNOWLEDGE_BASE_SPACES_DONE);
export const getKnowledgeBaseSpacesFail = createAction(GET_KNOWLEDGE_BASE_SPACES_FAIL);
export const postKnowledgeBaseSpaceCall = createAction(POST_KNOWLEDGE_BASE_SPACE_CALL);
export const postKnowledgeBaseSpaceDone = createAction(POST_KNOWLEDGE_BASE_SPACE_DONE);
export const postKnowledgeBaseSpaceFail = createAction(POST_KNOWLEDGE_BASE_SPACE_FAIL);
export const deleteKnowledgeBaseSpaceCall = createAction(DELETE_KNOWLEDGE_BASE_SPACE_CALL);
export const deleteKnowledgeBaseSpaceDone = createAction(DELETE_KNOWLEDGE_BASE_SPACE_DONE);
export const deleteKnowledgeBaseSpaceFail = createAction(DELETE_KNOWLEDGE_BASE_SPACE_FAIL);
export const getKnowledgeBaseSpaceDetailCall = createAction(GET_KNOWLEDGE_BASE_SPACE_DETAIL_CALL);
export const getKnowledgeBaseSpaceDetailDone = createAction(GET_KNOWLEDGE_BASE_SPACE_DETAIL_DONE);
export const getKnowledgeBaseSpaceDetailFail = createAction(GET_KNOWLEDGE_BASE_SPACE_DETAIL_FAIL);
export const patchKnowledgeBaseSpaceCall = createAction(PATCH_KNOWLEDGE_BASE_SPACE_CALL);
export const patchKnowledgeBaseSpaceDone = createAction(PATCH_KNOWLEDGE_BASE_SPACE_DONE);
export const patchKnowledgeBaseSpaceFail = createAction(PATCH_KNOWLEDGE_BASE_SPACE_FAIL);
