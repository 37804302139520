export const formatPrice = (price) => {
  const formatted = parseInt(price, 10);

  if (isNaN(formatted)) {
    return null;
  }

  return formatted.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const isEqualPrice = (price1, price2) => {
  return price1 === price2;
};

// 計算課程優惠後的價錢
export function getDiscountPrice({
  discount_amount,
  discount_type,
  selling_price,
}) {
  const price = Number(selling_price);
  const discount = Number(discount_amount);
  let finalPrice = 0;
  if (discount_type === 'percentage') {
    finalPrice = (price * discount) / 10;
  } else {
    finalPrice = price - discount;
  }

  return formatPrice(finalPrice);
}
