export const GET_KNOWLEDGE_BASE_PAGES_CALL = 'GET_KNOWLEDGE_BASE_PAGES_CALL';
export const GET_KNOWLEDGE_BASE_PAGES_DONE = 'GET_KNOWLEDGE_BASE_PAGES_DONE';
export const GET_KNOWLEDGE_BASE_PAGES_FAIL = 'GET_KNOWLEDGE_BASE_PAGES_FAIL';

export const POST_KNOWLEDGE_BASE_PAGE_CALL = 'POST_KNOWLEDGE_BASE_PAGE_CALL';
export const POST_KNOWLEDGE_BASE_PAGE_DONE = 'POST_KNOWLEDGE_BASE_PAGE_DONE';
export const POST_KNOWLEDGE_BASE_PAGE_FAIL = 'POST_KNOWLEDGE_BASE_PAGE_FAIL';

export const POST_KNOWLEDGE_BASE_PAGES_ORDER_CALL = 'POST_KNOWLEDGE_BASE_PAGES_ORDER_CALL';
export const POST_KNOWLEDGE_BASE_PAGES_ORDER_DONE = 'POST_KNOWLEDGE_BASE_PAGES_ORDER_DONE';
export const POST_KNOWLEDGE_BASE_PAGES_ORDER_FAIL = 'POST_KNOWLEDGE_BASE_PAGES_ORDER_FAIL';

export const DELETE_KNOWLEDGE_BASE_PAGE_CALL = 'DELETE_KNOWLEDGE_BASE_PAGE_CALL';
export const DELETE_KNOWLEDGE_BASE_PAGE_DONE = 'DELETE_KNOWLEDGE_BASE_PAGE_DONE';
export const DELETE_KNOWLEDGE_BASE_PAGE_FAIL = 'DELETE_KNOWLEDGE_BASE_PAGE_FAIL';

export const GET_KNOWLEDGE_BASE_PAGE_DETAIL_CALL = 'GET_KNOWLEDGE_BASE_PAGE_DETAIL_CALL';
export const GET_KNOWLEDGE_BASE_PAGE_DETAIL_DONE = 'GET_KNOWLEDGE_BASE_PAGE_DETAIL_DONE';
export const GET_KNOWLEDGE_BASE_PAGE_DETAIL_FAIL = 'GET_KNOWLEDGE_BASE_PAGE_DETAIL_FAIL';

export const GET_KNOWLEDGE_BASE_PAGE_SEO_CALL = 'GET_KNOWLEDGE_BASE_PAGE_SEO_CALL';
export const GET_KNOWLEDGE_BASE_PAGE_SEO_DONE = 'GET_KNOWLEDGE_BASE_PAGE_SEO_DONE';
export const GET_KNOWLEDGE_BASE_PAGE_SEO_FAIL = 'GET_KNOWLEDGE_BASE_PAGE_SEO_FAIL';

export const PATCH_KNOWLEDGE_BASE_PAGE_CALL = 'PATCH_KNOWLEDGE_BASE_PAGE_CALL';
export const PATCH_KNOWLEDGE_BASE_PAGE_DONE = 'PATCH_KNOWLEDGE_BASE_PAGE_DONE';
export const PATCH_KNOWLEDGE_BASE_PAGE_FAIL = 'PATCH_KNOWLEDGE_BASE_PAGE_FAIL';

export const POST_KNOWLEDGE_BASE_PAGE_READ_CALL = 'POST_KNOWLEDGE_BASE_PAGE_READ_CALL';
export const POST_KNOWLEDGE_BASE_PAGE_READ_DONE = 'POST_KNOWLEDGE_BASE_PAGE_READ_DONE';
export const POST_KNOWLEDGE_BASE_PAGE_READ_FAIL = 'POST_KNOWLEDGE_BASE_PAGE_READ_FAIL';
