import { createAction } from 'redux-actions';

import {
	GET_ALL_CATEGORIES_CALL,
	GET_ALL_CATEGORIES_DONE,
	GET_ALL_CATEGORIES_FAIL,

	GET_COMMODITY_SEARCH_CALL,
	GET_COMMODITY_SEARCH_SWITCH_CALL,
	GET_COMMODITY_SEARCH_DONE,
	GET_COMMODITY_SEARCH_FAIL,

	GET_COMMODITY_PUBLISHED_TYPES_CALL,
	GET_COMMODITY_PUBLISHED_TYPES_DONE,
	GET_COMMODITY_PUBLISHED_TYPES_FAIL,
} from '@src/store/types/category';

export const getAllCategoriesCall = createAction(GET_ALL_CATEGORIES_CALL);
export const getAllCategoriesDone = createAction(GET_ALL_CATEGORIES_DONE);
export const getAllCategoriesFail = createAction(GET_ALL_CATEGORIES_FAIL);

export const getCommoditySearchCall = createAction(GET_COMMODITY_SEARCH_CALL);
export const getCommoditySearchSwitchCall = createAction(GET_COMMODITY_SEARCH_SWITCH_CALL);
export const getCommoditySearchDone = createAction(GET_COMMODITY_SEARCH_DONE);
export const getCommoditySearchFail = createAction(GET_COMMODITY_SEARCH_FAIL);

export const getCommodityPublishedTypeCall = createAction(GET_COMMODITY_PUBLISHED_TYPES_CALL);
export const getCommodityPublishedTypeDone = createAction(GET_COMMODITY_PUBLISHED_TYPES_DONE);
export const getCommodityPublishedTypeFail = createAction(GET_COMMODITY_PUBLISHED_TYPES_FAIL);
