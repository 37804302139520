import React from 'react';
import styled from 'styled-components';
import useTranslation from '@src/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { TeachesLogo } from '@src/components/common/icon';
import { getThemeSelector } from '@src/store/selectors';

const Footer = styled.div`
	display: block;
	width: 100vw;
`;

const Content = styled.div`
	background: #FFFFFF;
	width: 100vw;
	clear: both;
	height: 64px;
	padding: 23px 20px;
	display: flex;
	justify-content: space-between;
	border-top: 1px solid #DDDDDD;


	@media 
	${props => props.theme.device.mobile},
	${props => props.theme.device.smTablet}
	{
		flex-direction: column;
		padding: 20px;
		height: 90px;
	}
`;

const Wrap = styled.div`
	display: flex;
	flex-direction: row;

	@media ${props => props.theme.device.tablet} {
		display: none;
	}
`;

const StyledText = styled.div`
	font-size: 12px;
	color: #676767;
`;

const StyledTeachesLogo = styled(TeachesLogo)`
	margin-left: 6px;
`;

const BusinessFooter = (): JSX.Element => {
	const { t } = useTranslation();
	const { themes: { name } } = useSelector(getThemeSelector) as { themes: { name: string } };
	return (
		<Footer id="footer">
			<Content>
				<StyledText>
					{t('business:footer:footerLessen', { schoolName: name })}
				</StyledText>
				<Wrap>
					<StyledText>Made with ♡ by</StyledText>
					<StyledTeachesLogo />
				</Wrap>
			</Content>
		</Footer>
	);
};

export default BusinessFooter;
