import { TSideEffectState } from '@src/types/common';
import { IWebinar } from '@src/types/webinar';

interface ICountdown {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
}

interface IWebinarState {
	webinar: IWebinar;
	getWebinarState: TSideEffectState;
	webinarsList: IWebinar[];
	webinarsListState: TSideEffectState;
	currentPrice: number;
	countDownForSale: ICountdown;
}

export const getWebinar = (shortLink: string) => ({ webinar }: { webinar: IWebinarState }): IWebinar => (
	webinar.webinar[shortLink]
);

export const getWebinarsMap = ({ webinar }) => webinar.webinar;

export const getWebinarState = (shortLink: string) => ({ webinar }: { webinar: IWebinarState }): TSideEffectState => (
	webinar.getWebinarState[shortLink]
);

export const getWebinarStateMap = () => ({ webinar }) => (
	webinar.getWebinarState
);

export const getWebinarsList = ({ webinar }: { webinar: IWebinarState }): IWebinar[] => webinar.webinarsList;

export const getWebinarsListState = ({ webinar }: {
	webinar: IWebinarState
}): TSideEffectState => webinar.webinarsListState;

export const getCurrentWebinarPrice = ({ webinar }: {
	webinar: IWebinarState
}): number => webinar.currentPrice;

export const selectCountDownForSale = ({ webinar }: {
	webinar: IWebinarState
}): ICountdown => webinar.countDownForSale;

export const selectWebinar = ({ webinar }: {
	webinar: IWebinarState
}): IWebinarState => webinar;
