/* eslint-disable max-len */
import { createAction } from 'redux-actions';

import {
	GET_OVERTIME_APPLICATIONS_CALL,
	GET_OVERTIME_APPLICATIONS_DONE,
	GET_OVERTIME_APPLICATIONS_FAIL,
	POST_OVERTIME_APPLICATION_CALL,
	POST_OVERTIME_APPLICATION_DONE,
	POST_OVERTIME_APPLICATION_FAIL,
	GET_OVERTIME_APPLICATION_INFO_CALL,
	GET_OVERTIME_APPLICATION_INFO_DONE,
	GET_OVERTIME_APPLICATION_INFO_FAIL,
	GET_OVERTIME_APPLICATION_DETAIL_CALL,
	GET_OVERTIME_APPLICATION_DETAIL_DONE,
	GET_OVERTIME_APPLICATION_DETAIL_FAIL,
	REST_OVERTIME_APPLICATION_DETAIL_CALL,
	POST_OVERTIME_APPLICATION_APPROVE_CALL,
	POST_OVERTIME_APPLICATION_APPROVE_DONE,
	POST_OVERTIME_APPLICATION_APPROVE_FAIL,
	GET_OVERTIME_APPLICATION_CONFIRM_CALL,
	GET_OVERTIME_APPLICATION_CONFIRM_DONE,
	GET_OVERTIME_APPLICATION_CONFIRM_FAIL,
	POST_OVERTIME_APPLICATION_CONFIRM_CALL,
	POST_OVERTIME_APPLICATION_CONFIRM_DONE,
	POST_OVERTIME_APPLICATION_CONFIRM_FAIL,
	POST_OVERTIME_APPLICATION_WITHDRAW_CALL,
	POST_OVERTIME_APPLICATION_WITHDRAW_DONE,
	POST_OVERTIME_APPLICATION_WITHDRAW_FAIL,
	GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_CALL,
	GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_DONE,
	GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_FAIL,
	POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_CALL,
	POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_DONE,
	POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_FAIL,
} from '@src/store/types';

export const getOvertimeApplicationsCall = createAction(GET_OVERTIME_APPLICATIONS_CALL);
export const getOvertimeApplicationsDone = createAction(GET_OVERTIME_APPLICATIONS_DONE);
export const getOvertimeApplicationsFail = createAction(GET_OVERTIME_APPLICATIONS_FAIL);
export const postOvertimeApplicationCall = createAction(POST_OVERTIME_APPLICATION_CALL);
export const postOvertimeApplicationDone = createAction(POST_OVERTIME_APPLICATION_DONE);
export const postOvertimeApplicationFail = createAction(POST_OVERTIME_APPLICATION_FAIL);
export const getOvertimeApplicationInfoCall = createAction(GET_OVERTIME_APPLICATION_INFO_CALL);
export const getOvertimeApplicationInfoDone = createAction(GET_OVERTIME_APPLICATION_INFO_DONE);
export const getOvertimeApplicationInfoFail = createAction(GET_OVERTIME_APPLICATION_INFO_FAIL);
export const getOvertimeApplicationDetailCall = createAction(GET_OVERTIME_APPLICATION_DETAIL_CALL);
export const getOvertimeApplicationDetailDone = createAction(GET_OVERTIME_APPLICATION_DETAIL_DONE);
export const getOvertimeApplicationDetailFail = createAction(GET_OVERTIME_APPLICATION_DETAIL_FAIL);
export const restOvertimeApplicationDetailCall = createAction(REST_OVERTIME_APPLICATION_DETAIL_CALL);
export const postOvertimeApplicationApproveCall = createAction(POST_OVERTIME_APPLICATION_APPROVE_CALL);
export const postOvertimeApplicationApproveDone = createAction(POST_OVERTIME_APPLICATION_APPROVE_DONE);
export const postOvertimeApplicationApproveFail = createAction(POST_OVERTIME_APPLICATION_APPROVE_FAIL);
export const getOvertimeApplicationConfirmCall = createAction(GET_OVERTIME_APPLICATION_CONFIRM_CALL);
export const getOvertimeApplicationConfirmDone = createAction(GET_OVERTIME_APPLICATION_CONFIRM_DONE);
export const getOvertimeApplicationConfirmFail = createAction(GET_OVERTIME_APPLICATION_CONFIRM_FAIL);
export const postOvertimeApplicationConfirmCall = createAction(POST_OVERTIME_APPLICATION_CONFIRM_CALL);
export const postOvertimeApplicationConfirmDone = createAction(POST_OVERTIME_APPLICATION_CONFIRM_DONE);
export const postOvertimeApplicationConfirmFail = createAction(POST_OVERTIME_APPLICATION_CONFIRM_FAIL);
export const postOvertimeApplicationWithdrawCall = createAction(POST_OVERTIME_APPLICATION_WITHDRAW_CALL);
export const postOvertimeApplicationWithdrawDone = createAction(POST_OVERTIME_APPLICATION_WITHDRAW_DONE);
export const postOvertimeApplicationWithdrawFail = createAction(POST_OVERTIME_APPLICATION_WITHDRAW_FAIL);
export const getOvertimeApplicationsApproverViewCall = createAction(GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_CALL);
export const getOvertimeApplicationsApproverViewDone = createAction(GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_DONE);
export const getOvertimeApplicationsApproverViewFail = createAction(GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_FAIL);
export const postOvertimeApplicationWithdrawApprovalCall = createAction(POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_CALL);
export const postOvertimeApplicationWithdrawApprovalDone = createAction(POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_DONE);
export const postOvertimeApplicationWithdrawApprovalFail = createAction(POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_FAIL);
