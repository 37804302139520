import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getDomain } from '@src/store/selectors';
import { replaceText } from '@src/utils/hardcode';

interface IReturn {
	t: (str: string, data?: any) => string;
}

const replaceString = (str, replaceList) => {
	for (let i = 0; i < replaceList.length; i += 1) {
		const [oldStr, newStr] = replaceList[i];
		// eslint-disable-next-line no-param-reassign
		str = str.replaceAll(oldStr, newStr);
	}
	return str;
};

const useCustomTranslation = (): IReturn => {
	const { t: originT } = useTranslation();
	const domain = useSelector(getDomain) as string;
	const replaceList = replaceText(domain.toLocaleLowerCase());

	const t = (str, data) => {
		const translationText = originT(str, data);
		if (!replaceList) return translationText;
		return replaceString(translationText, replaceList);
	};

	return {
		t,
	};
};

export default useCustomTranslation;
