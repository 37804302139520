/* eslint-disable react/prop-types */
import React from 'react';
import { Video } from '@src/components';

import classStyle from './VideoComponent.module.less';

const VideoComponent = props => {
	const { item } = props;
	const {
		link,
		// image, thumbnailLink
	} = item;

	return (
		<div className={classStyle.container}>
			<div className={classStyle.backVideo}>
				<Video
					url={link}
					loop
					isRwdType
					controls
				/>
			</div>
		</div>
	);
};

export default VideoComponent;
