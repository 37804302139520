import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 52px;
  z-index: 999;
  top: 0;
  left: 0;
  background: #fff;
  align-items: center;
  padding: 0 15px;
  box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.1);
  display: none;

  @media (max-width: 767px) {
    display: flex;
  }

  .rightBox {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const SearchBar = styled.div`
  position: absolute;
  left: 0;
  width: 100vw;
  background: #fff;
  transition: all 0.2s ease-in-out;
  opacity: ${({ show }) => (show ? 1 : 0)};
  top: ${({ show }) => (show ? '52px' : '30px')};
  z-index: ${({ show }) => (show ? 1 : -1)};
  height: ${({ show }) => (show ? 'auto' : '0')};
  box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.08);
  padding: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;

  svg {
    color: #222222;
  }

  .search {
    flex: 1;
    margin-left: 15px;
    border: 1px solid #ddd;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 36px;
    line-height: 36px;
    overflow: hidden;

    .search-course-btn {
      margin-right: 10px;
    }
  }
  input {
    flex: 1;
    outline: none;
    border: none;
  }
`;

export const Menu = styled.div`
  position: absolute;
  width: calc(100vw - 60px);
  background: #fff;
  transition: all 0.2s ease-in-out;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  top: 52px;
  left: ${({ show }) => (show ? '0px' : '-30px')};
  z-index: ${({ show }) => (show ? 2 : -1)};
  height: ${({ show }) => (show ? 'auto' : '0')};
  height: calc(100vh - 52px);
  overflow-x: hidden;
  overflow-y: scroll;

  .item {
    cursor: pointer;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    color: #222;

    .title {
      margin-left: 10px;
    }

    &:hover {
      background-color: #ffefe2;
    }
  }

  .optionItem {
    margin-left: 18px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .userProfileItem {
    border-top: 1px solid #ddd;
  }
`;
