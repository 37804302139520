import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ThemeButton } from '@src/components/home';
import { Video } from '@src/components';
import { Row, Col } from 'antd';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { VideoPlayIcon, VideoPauseIcon } from '@src/components/common/icon';

import classStyle from './VideoBanner.module.less';

const PlayerIcon = styled.div`
	height: 40px;
	width: 40px;
	position: absolute;
	right: 40px;
	bottom: 40px;
	@media
	${props => props.theme.device.smTablet},
	${props => props.theme.device.mobile}
	{
		right: 20px;
		bottom: 20px;
	}
`;

export default function VideoBanner(props) {
	const { item, ssrProps } = props;
	const { title, subTitle, link, button } = item;
	const [playerSize, setPlayerSize] = useState({
		width: 0, height: 0,
	});
	let checkIsMobile = isMobile;

	if (ssrProps) {
		checkIsMobile = ssrProps.ssrIsMobile;
	}

	const isAutoPlay = !!link;
	const [isPlay, setPlay] = useState(isAutoPlay);

	const renderTextDiv = () => (
		<div className={classStyle.textDiv}>
			<div className={classStyle.title}>{title}</div>
			<div className={classStyle.subTitle}>{subTitle}</div>
		</div>
	);

	const handleResizeVideo = () => {
		const reactPlayerDOM = document.getElementById('react-player');
		if (reactPlayerDOM) {
			setPlayerSize({
				width: reactPlayerDOM.clientWidth,
				height: (reactPlayerDOM.clientWidth / 16) * 9,
			});
		}
	};

	useEffect(() => {
		handleResizeVideo();
		window.addEventListener('resize', handleResizeVideo);
		return () => {
			window.removeEventListener('resize', handleResizeVideo);
		};
	}, []);

	return (
		<Row className={classStyle.container}>
			<div className={classStyle.banner} style={{ height: playerSize.height }}>
				<div className={classStyle.backVideo}>
					<Video
						url={link}
						playing={isPlay}
						loop
						isRwdType
						controls={false}
						muted
						playsinline
					/>
				</div>
				<div className={classStyle.bannerContent} style={{ height: playerSize.height }}>
					<div
						className={classNames([
							classStyle.content,
							classStyle.contentColor,
						])}
					>
						<Col xs={0} sm={24}>
							{renderTextDiv()}
						</Col>
						<PlayerIcon onClick={() => setPlay(!isPlay)}>
							{
								isPlay ? <VideoPauseIcon /> : <VideoPlayIcon />
							}
						</PlayerIcon>
					</div>
				</div>
			</div>
			{
				(title || subTitle) && (
					<Col xs={24} sm={0}>
						<div className={classStyle.mobileContent}>{renderTextDiv()}</div>
					</Col>
				)
			}
		</Row>
	);
}
