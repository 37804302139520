import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';

import { getJobsCall } from '@src/store/actions/job';
import useMediaQuery from '@src/hooks/useMediaQuery';
import { StyledPagination } from '@src/components/common/style';
import useTranslation from '@src/hooks/useTranslation';

import JobCard from './jobCard';

const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 40px 0;
	background: ${(props: { $backColor: string }) => props.$backColor};
`;

const Title = styled.div`
	font-size: 22px;
	color: #000;
`;

const Divider = styled.div`
	width: 36px;
	height: 4px;
	background: #9C9C9C;
	margin: 8px 0 40px 0; 
`;

const CardContent = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 12px 0;
	margin-bottom: 40px;
`;

interface JobProps {
	item: {
		editableList: string[];
		isPreview: boolean;
		key: string;
		name: string;
		type: string;
		sectionTitle: string;
		backColor: string;
	};
}

const JobList = ({ item }: JobProps): JSX.Element => {
	const { t } = useTranslation();
	const { sectionTitle, backColor } = item;
	const [pageNumber, setPageNumber] = useState(1);
	const dispatch = useDispatch();
	const { mediaMatch: { isLgDesktop, isDesktop, isTablet } } = useMediaQuery();

	const jobs = useSelector((state: RootStateOrAny) => state.job.jobs?.data) || [];
	const pageSize = 15;
	const totalCount = useSelector((state: RootStateOrAny) => state.job.jobs?.pagination?.totalCount) || 0;

	const getList = () => {
		dispatch(getJobsCall({ page: pageNumber, page_size: pageSize }));
	};

	useEffect(() => {
		getList();
		if (typeof window === 'object') {
			document.getElementById('theme-job-list')?.scrollIntoView({ behavior: 'smooth' });
		}
	}, [pageNumber]);

	if (totalCount === 0) return null;

	const paginationConfig = {
		hideOnSinglePage: true,
		pageNumber,
		pageSize,
		setPageNumber,
		total: totalCount,
		current: pageNumber,
		showTotal: (total: number): JSX.Element | string => {
			if (isLgDesktop || isDesktop || isTablet) {
				return (
					<Translation>
						{tra => (
							tra('pagination:total_style_large', {
								thisPage: pageNumber,
								totalPage: Math.ceil((totalCount || total) / pageSize),
								unit: tra('pagination:rating_page_unit'),
								total: (totalCount || total),
							})
						)}
					</Translation>
				);
			}
			return '';
		},
		showSizeChanger: false,
		onChange: currentPage => {
			if (pageNumber !== currentPage) setPageNumber(currentPage);
		},
	};
	return (
		<Content $backColor={backColor}>
			<Title id="theme-job-list">{sectionTitle || t('job:job_list')}</Title>
			<Divider />
			<CardContent>
				{
					jobs.map(job => (
						<JobCard
							key={job.id}
							id={job.id}
							jobTitle={job.title}
							requiredNumber={job.required_number || ''}
							jobType={job.job_type ? t(`job:job_type:${job.job_type}`) : ''}
							seniorityType={job.seniority ? t(`job:seniority_type:${job.seniority}`) : ''}
							salaryMin={job?.salary?.salary_min || ''}
							salaryMax={job?.salary?.salary_max || ''}
							currency={job?.salary?.currency || 'TWD'}
							salaryType={t(`job:salary_type:${job?.salary?.salary_type || 'annual_salary'}`)}
						/>
					))
				}
			</CardContent>
			<StyledPagination {...paginationConfig} />
		</Content>
	);
};

export default JobList;
