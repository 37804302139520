/* eslint-disable max-len */
import { createAction } from 'redux-actions';

import {
	GET_ATTENDANCE_HISTORY_CALL,
	GET_ATTENDANCE_HISTORY_DONE,
	GET_ATTENDANCE_HISTORY_FAIL,
	DELETE_PUNCH_IN_RECORD_CALL,
	DELETE_PUNCH_IN_RECORD_DONE,
	DELETE_PUNCH_IN_RECORD_FAIL,
} from '@src/store/types';

export const getAttendanceHistoryCall = createAction(GET_ATTENDANCE_HISTORY_CALL);
export const getAttendanceHistoryDone = createAction(GET_ATTENDANCE_HISTORY_DONE);
export const getAttendanceHistoryFail = createAction(GET_ATTENDANCE_HISTORY_FAIL);

export const deletePunchInRecordCall = createAction(DELETE_PUNCH_IN_RECORD_CALL);
export const deletePunchInRecordDone = createAction(DELETE_PUNCH_IN_RECORD_DONE);
export const deletePunchInRecordFail = createAction(DELETE_PUNCH_IN_RECORD_FAIL);
