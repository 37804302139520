import React from 'react';
import styled from 'styled-components';
import useTranslation from '@src/hooks/useTranslation';

import { calcRatingScore } from '@src/utils/course';

import Rating from '@src/components/common/rating';

const ComingSoon = styled.div`
	font-size: 14px;
	color: #797979;
`;

const rateStyle = {
	display: 'flex',
	fontSize: 15,
};

const CountDown = styled.div`
	font-size: 14px;
	color: #797979;

	.count-down__time {
		margin: 0px 4px;
		color: ${props => props.theme.colors.primary};
	}
`;

const RatingStarsWrapper = styled.div`
	display: flex;
	align-items: center;

	.rating-stars__votes {
		font-size: 14px;
		margin-left: 4px;
	}
	.ant-rate-star:not(:last-child) {
		margin-right: 4px;
	}
`;

interface ICourseInfo {
	type: string;
	status: string;
	rate: {
		total_votes: number;
		total_score: string;
	};
	countDown?: {
		days: number;
		hours: number;
		minutes: number;
		seconds: number;
	}
}

const CourseInfo = ({ type, status, rate, countDown }: ICourseInfo): JSX.Element => {
	const { t } = useTranslation();

	if (type === 'fund_course' && countDown) {
		if (status === 'fundraising') {
			const { days, hours, minutes, seconds } = countDown;
			return (
				<CountDown>
					<span>{t('common:count_down:left')}</span>
					<span className="count-down__time">{days}</span>
					{t('common:count_down:day')}
					<span className="count-down__time">{hours}</span>
					{t('common:count_down:hours')}
					<span className="count-down__time">{minutes}</span>
					{t('common:count_down:minutes')}
					<span className="count-down__time">{seconds}</span>
					{t('common:count_down:seconds')}
				</CountDown>
			);
		}
		return <ComingSoon>{t('card:coming_soon')}</ComingSoon>;
	}

	const { total_votes: totalVotes, total_score: totalScore } = rate;
	const { score, votes } = calcRatingScore({ totalScore, totalVotes });

	return (
		<RatingStarsWrapper>
			<Rating allowHalf value={totalVotes ? score : 5} style={rateStyle} />
			{votes >= 10 && (
				<span className="rating-stars__votes">{`(${votes})`}</span>
			)}
		</RatingStarsWrapper>
	);
};

export default CourseInfo;
