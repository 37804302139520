import { useState, useEffect } from 'react';
import { size } from 'src/styles/breakpoint';

const { lgDesktopMin, desktopMin, tabletMin, smTabletMin } = size;

const defaultMatch = {
	isLgDesktop: false,
	isDesktop: false,
	isTablet: false,
	isSmTablet: false,
	isMobile: false,
};

interface IUseMediaQuery {
	mediaMatch: {
		isLgDesktop: boolean;
		isDesktop: boolean;
		isTablet: boolean;
		isSmTablet: boolean;
		isMobile: boolean;
	}
}

const useMediaQuery = (): IUseMediaQuery => {
	const [mediaMatch, setMediaMatch] = useState(defaultMatch);
	const handleResize = () => {
		const width = window.innerWidth;

		if (width >= parseInt(lgDesktopMin)) {
			setMediaMatch({
				...defaultMatch,
				isLgDesktop: true,
			});
		} else if (width >= parseInt(desktopMin) && width < parseInt(lgDesktopMin)) {
			setMediaMatch({
				...defaultMatch,
				isDesktop: true,
			});
		} else if (width >= parseInt(tabletMin) && width < parseInt(desktopMin)) {
			setMediaMatch({
				...defaultMatch,
				isTablet: true,
			});
		} else if (width >= parseInt(smTabletMin) && width < parseInt(tabletMin)) {
			setMediaMatch({
				...defaultMatch,
				isSmTablet: true,
			});
		} else if (width < parseInt(smTabletMin)) {
			setMediaMatch({
				...defaultMatch,
				isMobile: true,
			});
		}
	};

	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return { mediaMatch };
};

export default useMediaQuery;
