import dayjs from 'dayjs';
import { flatten, find, isEmpty, cloneDeep } from 'lodash';
import { pipe } from 'lodash/fp';

import { TSaleType, TPublishStatus, IEventSlot, ISortedSlotTicket, ISortedEventSlot } from '@src/types/event';
import { isExpired, isBetween, getTimeRange, formatToTimestamp } from '@src/utils/time';
import {
	PUBLISH_STATUS_PUBLISHED,
	TICKET_SALE_DURATION_TYPE_DURATION,
	TICKET_SALE_DURATION_TYPE_BEFORE_SLOT_START,
	INFO_STATUS_ACTIVITY_COUNTDOWN,
	INFO_STATUS_ON_SALE_COUNTDOWN,
	INFO_STATUS_SOLD_OUT_COUNTDOWN,
} from '@src/const/event';

export const isPublished = (type: TPublishStatus): boolean => type === PUBLISH_STATUS_PUBLISHED;

export const isSaleDuration = (type: TSaleType): boolean => type === TICKET_SALE_DURATION_TYPE_DURATION;

export const isSaleBeforeSlotStart = (type: TSaleType): boolean => type === TICKET_SALE_DURATION_TYPE_BEFORE_SLOT_START;

// Generate sale start and end time for each ticket.
const genSaleTimeForTicket = (eventSlots: IEventSlot[]): ISortedEventSlot[] => (
	eventSlots.reduce((accSlot, currSlot) => {
		const slotStartTime = currSlot.start_at;
		const ticketResult = currSlot.ticket_info.reduce((accTicket, currTicket) => {
			const { sale_duration: { type, days, start_at, end_at } } = currTicket;
			let saleEndTime = '';

			if (isSaleDuration(type)) {
				saleEndTime = end_at;
			} else {
				const minusDay = (days === 0) ? 1 : days;

				saleEndTime = dayjs(slotStartTime).subtract(minusDay, 'day').endOf('day').toISOString();
			}
			return [...accTicket, { ...currTicket, saleStartTime: start_at, saleEndTime }];
		}, []);

		return [...accSlot, { ...currSlot, ticket_info: ticketResult }];
	}, [])
);

export const sortEventSlots = (eventSlots: IEventSlot[]): ISortedEventSlot[] => {
	if (!eventSlots) {
		return [];
	}

	const sortedSlotTickets = pipe(
		result => genSaleTimeForTicket(result),
		result => cloneDeep(result),
		// Sort slot by slot start time
		result => result.sort((a, b) => (
			formatToTimestamp(a.start_at) - formatToTimestamp(b.start_at)
		)),
		// Sort ticket by sale start time
		result => result.map(slot => ({
			...slot,
			ticket_info: slot.ticket_info.sort((a, b) => (
				formatToTimestamp(a.saleStartTime) - formatToTimestamp(b.saleStartTime)
			)),
		})),
	)(eventSlots);

	return sortedSlotTickets;
};

interface ITimeList {
	startTime: string;
	endTime: string;
}

export const getStartEndTime = (timeList: ITimeList[]): ITimeList => {
	let { startTime, endTime } = timeList[0];

	for (let i = 1; i < timeList.length; i += 1) {
		if (isExpired({ startTime, endTime: timeList[i].startTime })) {
			startTime = timeList[i].startTime;
		}

		if (isExpired({ startTime: timeList[i].endTime, endTime })) {
			endTime = timeList[i].endTime;
		}
	}

	return { startTime, endTime };
};

export const getEventTimeRange = ({ sortedEventSlots, t }: {
	sortedEventSlots: ISortedEventSlot[];
	t: (key: string) => string;
}): string => {
	const timeList = sortedEventSlots.map(slot => ({ startTime: slot.start_at, endTime: slot.end_at }));
	const { startTime, endTime } = getStartEndTime(timeList);

	return getTimeRange({ startTime, endTime, t });
};

export const getSlotPriceRange = (slotTickets: ISortedSlotTicket[]): {
	minPrice: number;
	maxPrice: number;
} => {
	let minPrice = slotTickets[0].price;
	let maxPrice = slotTickets[0].price;

	for (let i = 1; i < slotTickets.length; i += 1) {
		if (slotTickets[i].price < minPrice) {
			minPrice = slotTickets[i].price;
		}

		if (slotTickets[i].price > maxPrice) {
			maxPrice = slotTickets[i].price;
		}
	}

	return { minPrice, maxPrice };
};

export const getEventPriceRange = (sortedEventSlots: ISortedEventSlot[] | IEventSlot[]): {
	minPrice: number;
	maxPrice: number;
} => {
	const allTicketInfo = flatten(sortedEventSlots.map(slot => slot.ticket_info));
	let minPrice = allTicketInfo[0].price;
	let maxPrice = allTicketInfo[0].price;

	for (let i = 1; i < allTicketInfo.length; i += 1) {
		if (allTicketInfo[i].price < minPrice) {
			minPrice = allTicketInfo[i].price;
		}

		if (allTicketInfo[i].price > maxPrice) {
			maxPrice = allTicketInfo[i].price;
		}
	}

	return { minPrice, maxPrice };
};

// Check is there any ticket be able to purchase currently.
export const isEventSoldOut = (sortedEventSlots: ISortedEventSlot[]): boolean => {
	const allTicketInfo = flatten(sortedEventSlots.map(slot => slot.ticket_info));

	return !find(allTicketInfo, ticket => (
		isBetween({ startTime: ticket.saleStartTime, endTime: ticket.saleEndTime }) && (ticket.remain > 0)
	));
};

export const getSelectedTicket = ({ slotId, ticketId, sortedEventSlots }: {
	slotId: string;
	ticketId: string;
	sortedEventSlots: ISortedEventSlot[];
}): ISortedSlotTicket | null => {
	const selectedSlot = find(sortedEventSlots, slot => slot.id === slotId);

	return isEmpty(selectedSlot) ? null : find(selectedSlot.ticket_info, ticket => ticket.id === ticketId);
};

export const getShouldShowSoldOut = ({ sortedSlots }) => {
	const allTicketInfo = flatten(sortedSlots.map(slot => slot.ticket_info)) as any;
	const isOverSale = !find(allTicketInfo, ticket => isExpired({ startTime: ticket.saleEndTime }));
	const currentSaleTickets = allTicketInfo.filter(
		ticket => isBetween({ startTime: ticket.saleStartTime, endTime: ticket.saleEndTime }),
	);
	const notOnSaleTickets = allTicketInfo.filter(ticket => isExpired({ startTime: ticket.saleStartTime }));
	const isSoldOut = !isEmpty(currentSaleTickets) && !find(currentSaleTickets, ticket => ticket.remain > 0);
	const isNotOnSale = !find(allTicketInfo, ticket => isExpired({ endTime: ticket.saleStartTime })) || (
		isSoldOut && !isEmpty(notOnSaleTickets)
	) || (isEmpty(currentSaleTickets) && !isEmpty(notOnSaleTickets));
	const showSoldOut = !isNotOnSale && (isOverSale || isSoldOut);
	return showSoldOut;
};

export const getTicketStatus = ({ slots = [] }): {
	status: string;
	countDownTime?: number;
} => {
	const allRemainTickets = slots
		.flatMap(slot => slot.ticket_info)
		.filter(ticket => ticket.remain > 0);

	const isSoldOut = allRemainTickets.length > 0 &&
		dayjs(
			Math.max(
				...allRemainTickets.map(ticket => formatToTimestamp(ticket.sale_duration.end_at)),
			),
		).isBefore(dayjs(new Date()));

	if (isSoldOut || !slots.length) { // 完售
		return {
			status: INFO_STATUS_SOLD_OUT_COUNTDOWN,
		};
	}

	const allTicketsStartAt = slots
		.flatMap(slot => slot.ticket_info)
		.map(ticket => {
			const formattedTime = dayjs(ticket.sale_duration.start_at).format('YYYY/MM/DD HH:mm:ss');
			return dayjs(formattedTime).valueOf();
		});
	const minTicketStartAt = Math.min(...allTicketsStartAt);
	const isOnSaleCountdown = dayjs(minTicketStartAt).isAfter(dayjs(new Date()));

	// 活動未開賣 - 開賣到數：現在~第一張票開賣的時間
	if (isOnSaleCountdown) {
		return {
			status: INFO_STATUS_ON_SALE_COUNTDOWN,
			countDownTime: minTicketStartAt,
		};
	}

	const countDownTime = Math.max(
		...allRemainTickets.map(ticket => {
			const formattedTime = dayjs(ticket.sale_duration.end_at).format('YYYY/MM/DD HH:mm:ss');
			return dayjs(formattedTime).valueOf();
		}),
	);

	// 活動已開賣 - 售票截止倒數：現在~最後一張票種截止時間
	return {
		status: INFO_STATUS_ACTIVITY_COUNTDOWN,
		countDownTime,
	};
};
