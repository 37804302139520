/* eslint-disable react/prop-types */
import React from 'react';
import { ThemeButton } from '@src/components/home';
import HtmlParser from '@src/components/common/htmlParser';
import { Row, Col } from 'antd';
import { MyProgressiveImage } from '@src/components';
import { handleThemeClick } from '@src/utils/theme';
import classStyle from './ImageText.module.less';

const ImageText = props => {
	const { item, pages } = props;
	const { text, button, image, thumbnailLink, action } = item;

	const handleClick = () => {
		if (action?.openType) {
			const { openType, link, paramId } = action;

			handleThemeClick({ openType, link, paramId, pages });
		}
	};

	return (
		<div className={classStyle.container}>
			<Row
				align="center"
				justify="center"
				gutter={[30, { xs: 30, sm: 15, md: 0 }]}
			>
				<Col xs={24} md={12} onClick={handleClick}>
					<MyProgressiveImage
						className={classStyle.image}
						src={image}
						placeholder={thumbnailLink}
						style={{ cursor: action?.openType ? 'pointer' : 'default' }}
					/>
				</Col>
				<Col xs={24} md={12}>
					<div className={classStyle.textBox}>
						<HtmlParser html={text} />
						<ThemeButton className={classStyle.button} button={button} />
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default ImageText;
