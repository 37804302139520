import React, { ReactNode } from 'react';
import styled from 'styled-components';
import useTranslation from '@src/hooks/useTranslation';
import { useSelector } from 'react-redux';

import TagWithTail from '@src/components/common/TagWithTail';
import {
	CardWrapper,
	MediaWrapper,
	Title,
} from '@src/components/card/style';
import { getIsTagVisible } from '@src/utils/hardcode';
import * as selectors from '@src/store/selectors';

const SmallCardWrapper = styled(CardWrapper)`
	display: flex;
	flex-direction: column;
	.course-img {
		transition: transform 0.28s ease-in-out 0s;
	}
	&:hover {
		.course-img {
			transform: scale(1.3);
		}
		.card-title {
			color: ${props => props.theme.colors.primary};
		}
	}
`;

const StyledTag = styled(TagWithTail)`
	/* transform: translateY(-50%); */
	/* position: absolute; */
`;

const ImageWrapper = styled.div`
	position: relative;
	overflow: hidden;
`;

const Image = styled.img`
	width: 100%;
	height: 141px;
`;

const SoldOut = styled.div`
	position: absolute;
	left: 12px;
	top: 14px;
	background-color: rgba(34,34,34,0.5);
	width: calc(100% - 24px);
	height: calc(100% - 28px);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	color: #FFF;
`;

const DetailContent = styled.div`
	padding: 4px 8px;
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
`;

const TagWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
`;

interface IProps {
	onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	title: string;
	contentElem: string | ReactNode | ReactNode[];
	infoElem?: string | ReactNode | ReactNode[];
	imageUrl: string;
	imageAlt?: string;
	tagText: string | string[] | React.ReactNode;
	tagColor: string;
	tagBorderColor?: string;
	isSoldOut?: boolean;
	className?: string;
}

const SmallCard = ({
	onClick,
	title,
	contentElem,
	infoElem,
	imageUrl,
	imageAlt,
	tagText,
	tagColor,
	tagBorderColor,
	isSoldOut = false,
	className,
}: IProps): JSX.Element => {
	const { t } = useTranslation();
	const domain = useSelector(selectors.getDomain) as string;
	const isTagVisible = getIsTagVisible(domain);

	return (
		<div className={className}>
			<SmallCardWrapper className="card-wrapper" onClick={onClick}>
				<MediaWrapper className="media-wrapper">
					<ImageWrapper>
						{
							isSoldOut && (
								<SoldOut>
									{t('event:sold_out')}
								</SoldOut>
							)
						}
						<Image className="course-img" src={imageUrl} alt={imageAlt} />
					</ImageWrapper>
				</MediaWrapper>
				<DetailContent>
					{isTagVisible && (
						<TagWrapper>
							{
								Array.isArray(tagText) ? tagText.map(tag => (
									<StyledTag
										key={tag}
										text={tag}
										backgroundColor={tagColor}
										tagBorderColor={tagBorderColor}
									/>
								)) : (
									<StyledTag
										text={tagText}
										backgroundColor={tagColor}
										tagBorderColor={tagBorderColor}
									/>
								)
							}
						</TagWrapper>
					)}
					<Title className="card-title">{title}</Title>
					{contentElem}
				</DetailContent>
			</SmallCardWrapper>
			{infoElem}
		</div>
	);
};

export default SmallCard;
