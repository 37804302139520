export const PUBLISH_STATUS_DRAFT = 'draft';
export const PUBLISH_STATUS_SCHEDULED = 'scheduled';
export const PUBLISH_STATUS_PUBLISHED = 'published';
export const PUBLISH_STATUS_DISABLED = 'disabled';

export const TICKET_SALE_DURATION_TYPE_DURATION = 'duration';
export const TICKET_SALE_DURATION_TYPE_BEFORE_SLOT_START = 'days_before_slot_starts';

export const TAB_OFFLINE_EVENT_INTRODUCTION = 'event_introduction';
export const TAB_OFFLINE_EVENT_INFORMATION = 'event_information';
export const TAB_OFFLINE_EVENT_CONSIDERATIONS = 'event_considerations';
export const OFFLINE_EVENT_DROPDOWN_ANCHOR = 'OFFLINE_EVENT_DROPDOWN_ANCHOR';

export const DEFAULT_SLOT_TYPE = '';
export const DEFAULT_TICKET_TYPE = '';
export const DEFAULT_TICKET_NUMBER = 0;

export const INFO_STATUS_ACTIVITY_COUNTDOWN = 'activityCountdown';
export const INFO_STATUS_ON_SALE_COUNTDOWN = 'onSaleCountdown';
export const INFO_STATUS_SOLD_OUT_COUNTDOWN = 'soldOut';
export const INFO_STATUS_IS_OVER = 'isOver';
