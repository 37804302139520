import React from 'react';
import styled from 'styled-components';
import useTranslation from '@src/hooks/useTranslation';

import { getTimeRange } from '@src/utils/time';

const StatelessContentWrapper = styled.div`
	margin-top: 12px;
	font-size: 14px;
	color: #797979;
	& > div:not(:first-child) {
		margin-top: 8px;
	}
`;

const FlexDirection = styled.div`
	display: flex;
	flex-direction: column;

	@media
		${props => props.theme.device.mobile},
		${props => props.theme.device.smTablet} {
		display: block;
	}
`;

const Label = styled.span`
	white-space: nowrap;
`;

interface IProps {
	event: any;
}

const StatelessContent = ({ event }: IProps): JSX.Element => {
	const { t } = useTranslation();
	const { location, start_at, end_at } = event;
	const timeRage = getTimeRange({
		startTime: start_at,
		endTime: end_at,
		t,
	});

	return (
		<StatelessContentWrapper>
			<FlexDirection>
				<Label>{`${t('event:event_time')}：`}</Label>
				<span>{timeRage}</span>
			</FlexDirection>
			<FlexDirection>
				<Label>{`${t('event:event_location')}：`}</Label>
				<span>{location}</span>
			</FlexDirection>
		</StatelessContentWrapper>
	);
};

export default StatelessContent;
