import React from 'react';
import styled from 'styled-components';
import useTranslation from '@src/hooks/useTranslation';

import { IPurchaseWebinar } from '@src/types/purchase';
import Button from '@src/components/common/button';
import { getTimeRange } from '@src/utils/time';

const StyledButton = styled(Button)`
	height: 40px;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const StatelessContentWrapper = styled.div`
	margin-top: 12px;
	font-size: 14px;
	color: #797979;
	& > div:not(:first-child) {
		margin-top: 8px;
	}

	@media
		${props => props.theme.device.lgDesktop},
		${props => props.theme.device.desktop},
		${props => props.theme.device.tablet} {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
	}
`;

const FlexDirection = styled.div`
	display: flex;
	flex-direction: column;

	@media
		${props => props.theme.device.mobile},
		${props => props.theme.device.smTablet} {
		display: block;
	}
`;

const Label = styled.span`
	white-space: nowrap;
`;

const Description = styled.div`
	font-size: 12px;
	color: #333333;
	margin-top: 8px;
`;

interface IProps {
	webinar: IPurchaseWebinar;
}

const StatelessContent = ({ webinar }: IProps): JSX.Element => {
	const { t } = useTranslation();
	const {
		start_at: startTime,
		end_at: endTime,
		link_type: linkType,
		link_url: linkUrl,
		indication,
	} = webinar;
	const timeRange = getTimeRange({ startTime, endTime, t });

	const handleClickWebinarButton = event => {
		event.stopPropagation();
		window.open(linkUrl, '_blank');
	};

	return (
		<StatelessContentWrapper>
			<FlexDirection>
				<Label>{t('webinar:webinar_time')}</Label>
				<span>{timeRange}</span>
			</FlexDirection>
			<div className="space-between-item">
				<StyledButton handleClick={handleClickWebinarButton}>
					{t('webinar:go_to_webinar_room')}
				</StyledButton>
				<Description>
					{indication || t('webinar:link_type_using', { linkType: t(`webinar:${linkType}`) })}
				</Description>
			</div>
		</StatelessContentWrapper>
	);
};

export default StatelessContent;
