/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getHomeworksState = unit_id => state => state.homework.getHomeworksStates[unit_id];

export const getHomeworks = state => state.homework.homework;

export const getAddHomeworkState = unit_id => state => state.homework.addHomeworkStates[unit_id];

export const getUpdateHomeworkState = assignment_id => state => state.homework.updateHomeworkStates[assignment_id];

export const getHomeworkDetailState =
	assignment_id => state => state.homework.getCourseHomeworkDetailStates[assignment_id];

export const getHomeworkDetail = state => state.homework.homeworkDetail;

export const getCourseHomeworkRepliesState = state => state.homework.getCourseHomeworkRepliesState;

export const getCourseHomeworkReplies = state => state.homework.courseHomeworkReplies;

export const getAddCourseHomeworkReplyState = state => state.homework.addCourseHomeworkReplyState;
