import { createAction } from 'redux-actions';
import * as types from '@src/store/types';

export const setUser = createAction(types.SET_USER);
export const getNotificationsCall = createAction(types.GET_NOTIFICATIONS_CALL);
export const getNotificationsDone = createAction(types.GET_NOTIFICATIONS_DONE);
export const getNotificationsSeen = createAction(types.GET_NOTIFICATIONS_SEEN);
export const getNotificationsSeening = createAction(
  types.GET_NOTIFICATIONS_SEENING
);
export const getNotificationsHaveReadCall = createAction(
  types.GET_NOTIFICATIONS_HAVEREAD_CALL
);
export const getNotificationsAllHaveRead = createAction(
  types.GET_NOTIFICATIONS_ALL_HAVEREAD
);
export const getNotificationsIoCall = createAction(
  types.GET_NOTIFICATIONS_IO_CALL
);
export const getNotificationsIoDone = createAction(
  types.GET_NOTIFICATIONS_IO_DONE
);
export const getBindCreditCardInfoCall = createAction(
  types.GET_BIND_CREDITCARD_INFO_CALL
);
export const getBindCreditCardInfoDone = createAction(
  types.GET_BIND_CREDITCARD_INFO_DONE
);
export const deleteBindCreditCardInfoCall = createAction(
  types.DELETE_BIND_CREDITCARD_INFO_CALL
);
export const deleteBindCreditCardInfoDone = createAction(
  types.DELETE_BIND_CREDITCARD_INFO_DONE
);
export const rebindCreditCardInfoCall = createAction(
  types.REBIND_CREDITCARD_INFO_CALL
);
export const rebindCreditCardInfoDone = createAction(
  types.REBIND_CREDITCARD_INFO_DONE
);
export const lockActionCreditCardCall = createAction(
  types.LOCK_ACTION_CREDITCARD_CALL
);
export const getInvoiceListCall = createAction(types.GET_INVOICE_LIST_CALL);
export const getInvoiceListDone = createAction(types.GET_INVOICE_LIST_DOEN);
export const modifyInvoiceIncNumberCall = createAction(
  types.MODIFY_INVOICE_INC_NUMBER_CALL
);
export const modifyInvoiceIncNumberDone = createAction(
  types.MODIFY_INVOICE_INC_NUMBER_DOEN
);
export const downloadInvoiceCall = createAction(types.DOWNLOAD_INVOICE_CALL);
export const downloadInvoiceDoen = createAction(types.DOWNLOAD_INVOICE_DOEN);
export const getUserCurrentPlanCall = createAction(
  types.GET_USER_CURRENT_PLAN_CALL
);
export const getUserCurrentPlanDoen = createAction(
  types.GET_USER_CURRENT_PLAN_DOEN
);
export const deleteUserTrialPlanCall = createAction(
  types.DELETE_USER_TRIAL_PLAN_CALL
);
export const deleteUserTrialPlanDoen = createAction(
  types.DELETE_USER_TRIAL_PLAN_DOEN
);
export const putUserPlanCall = createAction(types.PUT_USER_PLAN_CALL);
export const putUserPlanDoen = createAction(types.PUT_USER_PLAN_DOEN);
export const cancelUserPlanCall = createAction(types.CANCEL_USER_PLAN_CALL);
export const cancelUserPlanDoen = createAction(types.CANCEL_USER_PLAN_DOEN);
export const opinionUserPlanCall = createAction(types.OPINION_USER_PLAN_CALL);
export const opinionUserPlanDoen = createAction(types.OPINION_USER_PLAN_DOEN);
export const changeUserFormalPlanCall = createAction(
  types.CHANGE_USER_FORMAL_PLAN_CALL
);
export const changeUserFormalPlanDOen = createAction(
  types.CHANGE_USER_FORMAL_PLAN_DOEN
);
export const putUserProfilePictureCall = createAction(
  types.PUT_USER_PROFILE_PICTURE_CALL
);
export const putUserProfilePictureDone = createAction(
  types.PUT_USER_PROFILE_PICTURE_DONE
);
export const putUserProfilePictureFail = createAction(
  types.PUT_USER_PROFILE_PICTURE_FAIL
);
export const putUserProfilePictureReset = createAction(
  types.PUT_USER_PROFILE_PICTURE_RESET
);
export const putUserProfileCall = createAction(types.PUT_USER_PROFILE_CALL);
export const putUserProfileDone = createAction(types.PUT_USER_PROFILE_DONE);
export const putUserProfileFail = createAction(types.PUT_USER_PROFILE_FAIL);
export const putProfileMobileCall = createAction(types.PUT_PROFILE_MOBILE_CALL);
export const putProfileMobileStatusCall = createAction(
  types.PUT_PROFILE_MOBILE_STATUS_CALL
);
export const verificationProfileMobileCall = createAction(
  types.VERIFICATION_PROFILE_MOBILE_STATUS_CALL
);
export const verificationProfileMobileDoen = createAction(
  types.VERIFICATION_PROFILE_MOBILE_STATUS_DOEN
);
export const putProfilePasswordCall = createAction(
  types.PUT_PROFILE_PASSWORD_CALL
);
export const putProfilePasswordDoen = createAction(
  types.PUT_PROFILE_PASSWORD_DOEN
);
export const putProfilePasswordFail = createAction(
  types.PUT_PROFILE_PASSWORD_FAIL
);
export const bindUserEmailCall = createAction(types.BIND_USER_EMAIL_CALL);
export const bindUserEmailDone = createAction(types.BIND_USER_EMAIL_DONE);
export const bindUserEmailFail = createAction(types.BIND_USER_EMAIL_FAIL);
export const getBindUserEmailVerificationCall = createAction(
  types.GET_BIND_USER_EMAIL_VERIFICATION_CALL
);
export const postVerificationUserEmailCall = createAction(
  types.GET_VERIFICATION_USER_EMAIL_CALL
);

export const bindOtherUsernameCall = createAction(
  types.BIND_OTHER_USERNAME_CALL
);
export const bindOtherUsernameDone = createAction(
  types.BIND_OTHER_USERNAME_DONE
);
export const bindOtherUsernameFail = createAction(
  types.BIND_OTHER_USERNAME_FAIL
);

export const unbindOtherUsernameCall = createAction(
  types.UNBIND_OTHER_USERNAME_CALL
);
export const unbindOtherUsernameDone = createAction(
  types.UNBIND_OTHER_USERNAME_DONE
);
export const unbindOtherUsernameFail = createAction(
  types.UNBIND_OTHER_USERNAME_FAIL
);

export const getUserProfileCall = createAction(types.GET_USER_PROFILE_CALL);
export const getUserProfileDone = createAction(types.GET_USER_PROFILE_DONE);
export const getUserProfileFail = createAction(types.GET_USER_PROFILE_FAIL);

export const getLearningCenterCall = createAction(types.GET_LEARNING_CENTER_CALL);
export const getLearningCenterDone = createAction(types.GET_LEARNING_CENTER_DONE);
export const getLearningCenterFail = createAction(types.GET_LEARNING_CENTER_FAIL);

export const getLearningCenterAssignCourseCall = createAction(types.GET_LEARNING_CENTER_ASSIGN_COURSE_CALL);
export const getLearningCenterAssignCourseDone = createAction(types.GET_LEARNING_CENTER_ASSIGN_COURSE_DONE);
export const getLearningCenterAssignCourseFail = createAction(types.GET_LEARNING_CENTER_ASSIGN_COURSE_FAIL);

export const getLearningCenterLearningPathCall = createAction(types.GET_LEARNING_CENTER_LEARNING_PATH_CALL);
export const getLearningCenterLearningPathDone = createAction(types.GET_LEARNING_CENTER_LEARNING_PATH_DONE);
export const getLearningCenterLearningPathFail = createAction(types.GET_LEARNING_CENTER_LEARNING_PATH_FAIL);

export const getUserJoinedCoursesStatisticsCall = createAction(types.GET_USER_JOINED_COURSES_STATISTICS_CALL);
export const getUserJoinedCoursesStatisticsDone = createAction(types.GET_USER_JOINED_COURSES_STATISTICS_DONE);
export const getUserJoinedCoursesStatisticsFall = createAction(types.GET_USER_JOINED_COURSES_STATISTICS_FAIL);

export const getUserJoinedLearningPathStatisticsCall = createAction(
  types.GET_USER_JOINED_LEARNING_PATH_STATISTICS_CALL,
);
export const getUserJoinedLearningPathStatisticsDone = createAction(
  types.GET_USER_JOINED_LEARNING_PATH_STATISTICS_DONE,
);
export const getUserJoinedLearningPathStatisticsFall = createAction(
  types.GET_USER_JOINED_LEARNING_PATH_STATISTICS_FAIL,
);

export const resetUserLearningStatisticsCall = createAction(types.RESET_USER_LEARNING_PATH_STATISTICS_CALL);
export const getUserLearningStatisticsCall = createAction(types.GET_USER_LEARNING_PATH_STATISTICS_CALL);
export const getUserLearningStatisticsDone = createAction(types.GET_USER_LEARNING_PATH_STATISTICS_DONE);
export const getUserLearningStatisticsFall = createAction(types.GET_USER_LEARNING_PATH_STATISTICS_FAIL);

export const getLearningRecordsMineCall = createAction(types.GET_LEARNING_RECORDS_MINE_CALL);
export const getLearningRecordsMineDone = createAction(types.GET_LEARNING_RECORDS_MINE_DONE);
export const getLearningRecordsMineFail = createAction(types.GET_LEARNING_RECORDS_MINE_FAIL);

export const getLearningLevelCall = createAction(types.GET_LEARNING_LEVEL_CALL);
export const getLearningLevelDone = createAction(types.GET_LEARNING_LEVEL_DONE);
export const getLearningLevelFail = createAction(types.GET_LEARNING_LEVEL_FAIL);

export const postUserLanguageCall = createAction(types.POST_USER_LANGUAGE_CALL);
export const postUserLanguageDone = createAction(types.POST_USER_LANGUAGE_DONE);
export const postUserLanguageFail = createAction(types.POST_USER_LANGUAGE_FAIL);
