import React, { Component } from 'react';
import classStyle from './CourseEvaluation.module.less';

export default class CourseEvaluation extends Component {
  rendeItem = () => {
    return <div></div>;
  };

  render() {
    const { isThemeEdit } = this.props;

    if (!isThemeEdit) {
      return null;
    }

    return (
      <div className={classStyle.container}>
        <div className={classStyle.titleBar}>
          <div className={classStyle.titleIcon} />
          <div className={classStyle.title}>口碑見證</div>
        </div>
        <div className={classStyle.list}>
          {this.rendeItem()}
          {this.rendeItem()}
          {this.rendeItem()}
        </div>
      </div>
    );
  }
}
