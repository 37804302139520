import { createAction } from 'redux-actions';

import {
	ADD_EVENT_TO_CART_CALL,
	ADD_EVENT_TO_CART_DONE,
	ADD_EVENT_TO_CART_FAIL,
	ADD_WEBINAR_TO_CART_CALL,
	ADD_WEBINAR_TO_CART_DONE,
	ADD_WEBINAR_TO_CART_FAIL,
	GET_CART_PRODUCTS_CALL,
	GET_CART_PRODUCTS_DONE,
	GET_CART_PRODUCTS_FAIL,
	UPDATE_CART_PRODUCTS_CALL,
	REMOVE_COURSE_FROM_CART_CALL,
	REMOVE_COURSE_FROM_CART_DONE,
	REMOVE_COURSE_FROM_CART_FAIL,
	REMOVE_EVENT_FROM_CART_CALL,
	REMOVE_EVENT_FROM_CART_DONE,
	REMOVE_EVENT_FROM_CART_FAIL,
	REMOVE_WEBINAR_FROM_CART_CALL,
	REMOVE_WEBINAR_FROM_CART_DONE,
	REMOVE_WEBINAR_FROM_CART_FAIL,
	UPDATE_EVENT_QUANTITY_CALL,
	UPDATE_EVENT_QUANTITY_DONE,
	UPDATE_EVENT_QUANTITY_FAIL,
	RESERVE_CART_PRODUCTS_CALL,
	RESERVE_CART_PRODUCTS_DONE,
	RESERVE_CART_PRODUCTS_FAIL,
	GET_PUBLIC_COURSE_COUPONS_CALL,
	GET_PUBLIC_COURSE_COUPONS_DONE,
	GET_PUBLIC_COURSE_COUPONS_FAIL,
	APPLY_COURSE_COUPONS_CALL,
	APPLY_COURSE_COUPONS_DONE,
	APPLY_COURSE_COUPONS_FAIL,
	DELETE_COURSE_COUPONS_CALL,
	DELETE_COURSE_COUPONS_DONE,
	DELETE_COURSE_COUPONS_FAIL,
	GET_PUBLIC_EVENT_COUPONS_CALL,
	GET_PUBLIC_EVENT_COUPONS_DONE,
	GET_PUBLIC_EVENT_COUPONS_FAIL,
	APPLY_EVENT_SLOT_COUPONS_CALL,
	APPLY_EVENT_SLOT_COUPONS_DONE,
	APPLY_EVENT_SLOT_COUPONS_FAIL,
	DELETE_EVENT_SLOT_COUPONS_CALL,
	DELETE_EVENT_SLOT_COUPONS_DONE,
	DELETE_EVENT_SLOT_COUPONS_FAIL,
	GET_PUBLIC_WEBINAR_COUPONS_CALL,
	GET_PUBLIC_WEBINAR_COUPONS_DONE,
	GET_PUBLIC_WEBINAR_COUPONS_FAIL,
	APPLY_WEBINAR_SLOT_COUPONS_CALL,
	APPLY_WEBINAR_SLOT_COUPONS_DONE,
	APPLY_WEBINAR_SLOT_COUPONS_FAIL,
	DELETE_WEBINAR_SLOT_COUPONS_CALL,
	DELETE_WEBINAR_SLOT_COUPONS_DONE,
	DELETE_WEBINAR_SLOT_COUPONS_FAIL,
} from '@src/store/types/cart';

export const addEventToCartCall = createAction(ADD_EVENT_TO_CART_CALL);
export const addEventToCartDone = createAction(ADD_EVENT_TO_CART_DONE);
export const addEventToCartFail = createAction(ADD_EVENT_TO_CART_FAIL);

export const addWebinarToCartCall = createAction(ADD_WEBINAR_TO_CART_CALL);
export const addWebinarToCartDone = createAction(ADD_WEBINAR_TO_CART_DONE);
export const addWebinarToCartFail = createAction(ADD_WEBINAR_TO_CART_FAIL);

export const getCartProductsCall = createAction(GET_CART_PRODUCTS_CALL);
export const getCartProductsDone = createAction(GET_CART_PRODUCTS_DONE);
export const getCartProductsFail = createAction(GET_CART_PRODUCTS_FAIL);

export const updateCartProductsCall = createAction(UPDATE_CART_PRODUCTS_CALL);

export const removeCourseFromCartCall = createAction(REMOVE_COURSE_FROM_CART_CALL);
export const removeCourseFromCartDone = createAction(REMOVE_COURSE_FROM_CART_DONE);
export const removeCourseFromCartFail = createAction(REMOVE_COURSE_FROM_CART_FAIL);

export const removeEventFromCartCall = createAction(REMOVE_EVENT_FROM_CART_CALL);
export const removeEventFromCartDone = createAction(REMOVE_EVENT_FROM_CART_DONE);
export const removeEventFromCartFail = createAction(REMOVE_EVENT_FROM_CART_FAIL);

export const removeWebinarFromCartCall = createAction(REMOVE_WEBINAR_FROM_CART_CALL);
export const removeWebinarFromCartDone = createAction(REMOVE_WEBINAR_FROM_CART_DONE);
export const removeWebinarFromCartFail = createAction(REMOVE_WEBINAR_FROM_CART_FAIL);

export const updateEventQuantityCall = createAction(UPDATE_EVENT_QUANTITY_CALL);
export const updateEventQuantityDone = createAction(UPDATE_EVENT_QUANTITY_DONE);
export const updateEventQuantityFail = createAction(UPDATE_EVENT_QUANTITY_FAIL);

export const reserveCartProductsCall = createAction(RESERVE_CART_PRODUCTS_CALL);
export const reserveCartProductsDone = createAction(RESERVE_CART_PRODUCTS_DONE);
export const reserveCartProductsFail = createAction(RESERVE_CART_PRODUCTS_FAIL);

export const getPublicCourseCouponsCall = createAction(GET_PUBLIC_COURSE_COUPONS_CALL);
export const getPublicCourseCouponsDone = createAction(GET_PUBLIC_COURSE_COUPONS_DONE);
export const getPublicCourseCouponsFail = createAction(GET_PUBLIC_COURSE_COUPONS_FAIL);

export const applyCourseCouponsCall = createAction(APPLY_COURSE_COUPONS_CALL);
export const applyCourseCouponsDone = createAction(APPLY_COURSE_COUPONS_DONE);
export const applyCourseCouponsFail = createAction(APPLY_COURSE_COUPONS_FAIL);

export const deleteCourseCouponsCall = createAction(DELETE_COURSE_COUPONS_CALL);
export const deleteCourseCouponsDone = createAction(DELETE_COURSE_COUPONS_DONE);
export const deleteCourseCouponsFail = createAction(DELETE_COURSE_COUPONS_FAIL);

export const getPublicEventCouponsCall = createAction(GET_PUBLIC_EVENT_COUPONS_CALL);
export const getPublicEventCouponsDone = createAction(GET_PUBLIC_EVENT_COUPONS_DONE);
export const getPublicEventCouponsFail = createAction(GET_PUBLIC_EVENT_COUPONS_FAIL);

export const applyEventSlotCouponsCall = createAction(APPLY_EVENT_SLOT_COUPONS_CALL);
export const applyEventSlotCouponsDone = createAction(APPLY_EVENT_SLOT_COUPONS_DONE);
export const applyEventSlotCouponsFail = createAction(APPLY_EVENT_SLOT_COUPONS_FAIL);

export const deleteEventSlotCouponsCall = createAction(DELETE_EVENT_SLOT_COUPONS_CALL);
export const deleteEventSlotCouponsDone = createAction(DELETE_EVENT_SLOT_COUPONS_DONE);
export const deleteEventSlotCouponsFail = createAction(DELETE_EVENT_SLOT_COUPONS_FAIL);

export const getPublicWebinarCouponsCall = createAction(GET_PUBLIC_WEBINAR_COUPONS_CALL);
export const getPublicWebinarCouponsDone = createAction(GET_PUBLIC_WEBINAR_COUPONS_DONE);
export const getPublicWebinarCouponsFail = createAction(GET_PUBLIC_WEBINAR_COUPONS_FAIL);

export const applyWebinarSlotCouponsCall = createAction(APPLY_WEBINAR_SLOT_COUPONS_CALL);
export const applyWebinarSlotCouponsDone = createAction(APPLY_WEBINAR_SLOT_COUPONS_DONE);
export const applyWebinarSlotCouponsFail = createAction(APPLY_WEBINAR_SLOT_COUPONS_FAIL);

export const deleteWebinarSlotCouponsCall = createAction(DELETE_WEBINAR_SLOT_COUPONS_CALL);
export const deleteWebinarSlotCouponsDone = createAction(DELETE_WEBINAR_SLOT_COUPONS_DONE);
export const deleteWebinarSlotCouponsFail = createAction(DELETE_WEBINAR_SLOT_COUPONS_FAIL);
