import React from 'react';
import PropTypes from 'prop-types';
import { Button as AntButton } from 'antd';
import cx from 'classnames';

import classes from './styles.module.less';

function Button(props) {
  const { children, text, className, onClick, variant, ...otherProps } = props;

  return (
    <AntButton
      {...otherProps}
      className={cx(
        classes.btn,
        variant === 'outlined' && classes.outlined,
        className
      )}
      onClick={onClick}
    >
      {text || children}
    </AntButton>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'outlined']),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Button;
