export const getIsSignIn = (state) => {
  return !!state.session.isSignedIn;
};

export const getIsSignInLoading = (state) => {
  return state.session.buttonLoading;
};

export const getUpdateMobileState = (state) => {
  return state.session.updateMobileState;
};

export const getConfirmMobileState = (state) => {
  return state.session.confirmMobileState;
};

export const getEmailResendState = (state) => {
  return state.session.emailResendState;
};

export const getIsPreview = (state) => {
  return state.session.isPreview;
};
