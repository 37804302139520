import { isPublished, isFundraising, isPreparing } from '@src/utils/course';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTagText = ({ t, status }): string[] => {
	if (isPublished(status)) { // 已開課
		return [t('course:course_products')];
	}
	if (isFundraising(status)) { // 募資中
		return [t('course:course_products'), t('course:status:fundraising')];
	}
	if (isPreparing(status)) { // 募資成功
		return [t('course:course_products'), t('course:status:preparing_short')];
	}
	return [t('course:course_products')];
};

export const makePercentageValue = ({
	isExpired,
	totalBackers,
	goalBackers,
}: {
	isExpired: boolean;
	totalBackers: number;
	goalBackers: number;
}): number => {
	const percentage = Number((totalBackers / goalBackers).toFixed(2)) * 100;
	if (isExpired && percentage < 100) {
		return 100;
	}
	return Math.round(percentage);
};
