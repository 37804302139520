/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getCurrentCourse = state => state.course.currentCourse;

export const getCurrentCourseState = state => state.course.currentCourseState;

export const getCurrentCourseGeneralState = state => state.course.currentCourseGeneralState;

export const getCurrentCourseRefetchState = state => state.course.currentCourseRefetchState;

export const getCoursesView = state => state.course.coursesView;

export const getCoursesViewState = state => state.course.coursesViewState;

export const getCurrentCourseProgress = state => state.course.currentCourseProgress;

export const getCurrentCourseProgressState = state => state.course.currentCourseProgressState;

export const getCourseRatings = state => state.course.courseRatings;

export const getCourseRatingsById = courseId => state => state.course.courseRatingsById[courseId];

export const getCourseRatingState = courseId => state => state.course.getCourseRatingStates[courseId];

export const getAddCourseRatingState = courseId => state => state.course.addCourseRatingStates[courseId];

export const getPropagandaReview = courseId => state => state.course.propagandaReviews[courseId] || [];

export const getCourseCertificate = state => state.course.certificate;

export const getCourseCertificateState = state => state.course.getCertificateState;

export const createCourseCertificateState = state => state.course.createCertificateState;

export const getCoursesViewByCourseIdMap = state => state.course.coursesViewByCourseIdMap;

export const getCoursesViewByCourseIdMapState = state => state.course.coursesViewByCourseIdMapState;

export const selectCourse = state => state.course;
