import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import { handleGetDateDuration } from '@src/utils/utilDate';

import classStyle from './CountDown.module.less';
export default class CountDown extends React.Component {
  constructor(props) {
    super(props);

    const { item } = this.props;
    const { date } = item;
    const { dates } = handleGetDateDuration({
      startDate: moment(),
      endDate: date,
    });

    this.state = {
      dates,
    };
  }

  componentDidMount() {
    if (!this.autoSave) {
      let count = 0;

      this.autoSave = setInterval(() => {
        count++;

        if (count >= 1800) {
          clearInterval(this.autoSave);

          return;
        }

        const { item } = this.props;
        const { date } = item;
        const { dates } = handleGetDateDuration({
          startDate: moment(),
          endDate: date,
        });

        this.setState({
          dates,
        });
      }, 1000);
    }
  }

  componentWillUnmount() {
    if (this.countDown) {
      clearInterval(this.autoSave);
      this.countDown = undefined;
    }
  }

  renderCountDownItem = (countDownItem, index) => {
    const { item } = this.props;
    const { numberColor, textColor } = item;
    const { key, number, numberText } = countDownItem;
    const numberValue = number >= 0 ? number : 0;
    const showSeparate = index !== 0;

    return (
      <div key={key} className={classStyle.countDownItem}>
        {showSeparate && (
          <div
            className={classStyle.separate}
            style={{
              color: this.handleHexToRGB(textColor, 0.1),
            }}
          >
            {':'}
          </div>
        )}
        <div className={classStyle.numberDiv}>
          <div
            className={classStyle.number}
            style={{
              color: numberColor,
            }}
          >
            {numberValue}
          </div>
          {numberText && (
            <div
              className={classStyle.numberText}
              style={{
                color: this.handleHexToRGB(textColor, 0.5),
              }}
            >
              {numberText}
            </div>
          )}
        </div>
      </div>
    );
  };

  handleHexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  };

  render() {
    const { item } = this.props;
    const {
      title,
      text,
      days,
      hours,
      mins,
      secs,
      numberColor,
      textColor,
    } = item;
    const { dates } = this.state;
    const {
      days: daysNumber,
      hours: hoursNumber,
      minutes: minutesNumber,
      seconds: secondsNumber,
    } = dates;
    const list = [
      {
        key: 'days',
        number: daysNumber,
        numberText: days,
      },
      {
        key: 'hours',
        number: hoursNumber,
        numberText: hours,
      },
      {
        key: 'mins',
        number: minutesNumber,
        numberText: mins,
      },
      {
        key: 'secs',
        number: secondsNumber,
        numberText: secs,
      },
    ];

    return (
      <div className={classStyle.container}>
        {title && (
          <div
            className={classStyle.title}
            style={{
              color: textColor,
            }}
          >
            {title}
          </div>
        )}
        {text && (
          <div
            className={classStyle.text}
            style={{
              color: this.handleHexToRGB(textColor, 0.7),
            }}
          >
            {text}
          </div>
        )}
        <div
          className={classStyle.countDown}
          style={{
            borderColor: this.handleHexToRGB(numberColor, 0.5),
          }}
        >
          {_.map(list, this.renderCountDownItem)}
        </div>
      </div>
    );
  }
}
