import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Menu as AntMenu } from 'antd';

import * as actionCreators from '@src/store/actions';
import Cart from '@src/components/home/components/cart';
import { IconComponent } from '@src/icons';
import { getThemeSelector } from '@src/store/selectors';
import { colorThemes } from 'src/const/theme';
import {
	SettingIcon,
	CoursesIcon,
	EventIcon,
	LiveIcon,
	OrderOutlineIcon,
	LogoutIcon,
	LoginIcon,
	LearningPathMenuIcon,
	MemberCenterIcon,
	KnowledgeBaseIcon,
	EnterpriseIcon,
	DepartmentOwnerIcon,
	SwitchHRSystemIcon,
} from '@src/components/common/icon';
import useTranslation from '@src/hooks/useTranslation';

import { Wrapper, Menu } from './MobileHeaderComponentStyled';
import LogoComponent from './LogoComponent';

const { SubMenu } = AntMenu;

const MenuBack = styled.div`
	position: absolute;
	top: 52px;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.6);
	z-index: 1;
	opacity: ${({ show }) => (show ? 1 : 0)};
	display: ${({ show }) => (show ? 'block' : 'none')};
	transition: all 0.2s ease-in-out;
`;

const MenuContent = styled.div`
	padding: 8px 0;
`;

const StyledAntMenu = styled(AntMenu)`
	margin: 8px 0;
	background: #F5F5F7;
`;

const StyledSubMenu = styled(SubMenu)`
	font-size: 16px;
	& .ant-menu-submenu-title {
		margin-top: unset !important;
		margin-bottom: unset !important;
		height: 48px !important;
	}
	& .ant-menu-submenu-active {
		color: #333333;
	}
	& .ant-menu-submenu-title:hover {
		color: #333333;
		background: #FFF;
	}
	&.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
		color: #333333;
	}
	&.ant-menu-submenu-selected {
		color: #333333;
	}
	& .ant-menu-sub.ant-menu-inline {
		background: #FFF;
	}
	& .ant-menu-item-active {
		color: #676767;
	}
	& .ant-menu-item:hover {
		color: #676767;
	}
	& .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background: #FFF;
	}
	& .ant-menu-item::after {
		border-right: none;
	}
`;

const MenuItemWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const StyledIconComponent = styled(IconComponent)`
	margin-right: 16px;
`;

const StyledAntMenuItem = styled(AntMenu.Item)`
	font-size: 16px;
	color: #676767;
	padding-left: 66px !important;
	background: #FFF;
`;

const AntMenuItem = styled(AntMenu.Item)`
	font-size: 16px;
	color: #333333;
	&.ant-menu-item-selected {
		background: #FFF !important;
	}
	&.ant-menu-item::after {
		border-right: none;
	}
`;

const Wrap = styled.div`
	padding: 12px 20px;
	background: #F5F5F7;
	background: ${props => props.$backgroundColor || '#F5F5F7' };
	font-size: 16px;
	color: #333333;
`;

const Divider = styled.div`
	width: 100%;
	height: 1px;
	background: #E9E9E9;
`;

const StyledSubMenuBG = styled(StyledSubMenu)`
	background: #F5F5F7;

	& .ant-menu-submenu-title:hover {
		background: #F5F5F7;
	}
	& .ant-menu-sub.ant-menu-inline {
		background: #F5F5F7;
	}
	& .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background: #F5F5F7;
	}
	& .ant-menu-submenu-title {
		padding-left: 20px !important;
	}
`;

const StyledAntMenuItemBG = styled(StyledAntMenuItem)`
	background: #F5F5F7;
	
`;

const IconContent = styled.div`
	margin-right: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Title = styled.div`
	color: #333333;
`;

export function MobileHeaderComponent(props) {
	const { t } = useTranslation();
	const {
		actions,
		isSignedIn,
		themes,
		headerContent,
		onItemClick,
		asPath,
		homePage,
		profile: { department_owner },
	} = props;

	const {
		logo_link,
		disable_purchase,
		front_desk_knowledge_base_enabled,
		other_school_login_enabled,
		front_desk_user_learning_statistics,
		can_switch_hr_system,
	} = themes;
	const { link: logoLink } = logo_link;
	const { list } = headerContent;
	const { content } = homePage;
	const headerTheme = _.find(content?.list, { key: 'header' });
	const currentTheme = colorThemes.find(theme => theme?.id === themes?.color_theme) || colorThemes[0];
	const backgroundColor = headerTheme?.backColor || currentTheme?.colors?.background || '#FFFFFF';
	const textColor = headerTheme?.textColor || currentTheme?.colors?.textColor || '#101010';

	const backColorStyle = {
		backgroundColor,
	};
	const textColorStyle = {
		color: textColor,
	};
	const [showMenu, setShowMenu] = useState(false);

	useEffect(() => {
		setShowMenu(false);
	}, [asPath]);

	const handleMenuButtonClick = () => {
		setShowMenu(!showMenu);
	};
	const handleLogOut = () => {
		actions.logoutCall();
		setShowMenu(false);
	};
	const handleShowModal = type => {
		actions.setModalType({ type });

		setShowMenu(false);
	};
	const handleRedirect = path => {
		setShowMenu(false);
		actions.historyPushCall(path);
	};

	const renderUserProfileItem = item => {
		const { onClick, Icon, title, iconColor, subMenu, backgroundColor } = item;
		if (subMenu) {
			return (
				<div>
					<StyledSubMenuBG
						title={(
							<MenuItemWrapper>
								<IconContent>
									<Icon fill={iconColor || '#333333'} />
								</IconContent>
								<Title className="title">{title}</Title>
							</MenuItemWrapper>
						)}
						key={title}
					>
						{
							subMenu.map(option => (
								<StyledAntMenuItemBG key={option.title} onClick={option.onClick}>
									{option.title}
								</StyledAntMenuItemBG>
							))
						}
					</StyledSubMenuBG>
					<Divider />
				</div>
			);
		}
		return (
			<div onClick={onClick}>
				<Wrap $backgroundColor={backgroundColor}>
					<MenuItemWrapper>
						<IconContent>
							<Icon fill={iconColor || '#333333'} />
						</IconContent>
						<div className="title">{title}</div>
					</MenuItemWrapper>
				</Wrap>
				<Divider />
			</div>
		);
	};

	const handleItemClick = item => {
		onItemClick(item);
		setShowMenu(false);
	};

	const renderItem = item => {
		const { name, type, options } = item;
		const isSelect = type === 'select';
		const icon = 'icon_dot';
		return (
			<AntMenu key={name} mode="inline">
				{
					isSelect ? (
						<StyledSubMenu
							title={(
								<MenuItemWrapper>
									<StyledIconComponent size={10} icon={icon} />
									<div className="title">{name}</div>
								</MenuItemWrapper>
							)}
						>
							{
								options.map(option => (
									<StyledAntMenuItem
										key={option.name}
										onClick={() => handleItemClick(option)}
									>
										{option.name}
									</StyledAntMenuItem>
								))
							}
						</StyledSubMenu>
					) : (
						<AntMenuItem onClick={() => handleItemClick(item)}>
							<MenuItemWrapper>
								<StyledIconComponent size={10} icon={icon} />
								<div className="title">{name}</div>
							</MenuItemWrapper>
						</AntMenuItem>
					)
				}
			</AntMenu>
		);
	};

	const renderList = () => <MenuContent>{_.map(list, renderItem)}</MenuContent>;

	const renderUserProfileBox = () => {
		const menuList = [
			{
				title: t('all:header_member'),
				Icon: MemberCenterIcon,
				iconColor: '#FFFFFF',
				onClick: () => window.open('/member', '_blank'),
			},
			...front_desk_user_learning_statistics ? [
				{
					title: t('account:learning_record'),
					Icon: OrderOutlineIcon,
					iconColor: '#333333',
					onClick: () => handleRedirect('/member/statistics'),
				},
			] : [],
			...department_owner ? [
				{
					title: t('all:account_department_learning_record'),
					Icon: DepartmentOwnerIcon,
					iconColor: 'transparent',
					onClick: () => handleRedirect('/department/member/list'),
				},
			] : [],
			{
				title: t('account:settings'),
				Icon: SettingIcon,
				onClick: () => handleRedirect('/account/settings'),
			},
			{
				title: t('all:account_learning_path'),
				Icon: LearningPathMenuIcon,
				subMenu: [
					{
						title: t('account:courses'),
						Icon: CoursesIcon,
						onClick: () => handleRedirect('/account/courses'),
					},
					{
						title: t('account:events'),
						Icon: EventIcon,
						onClick: () => handleRedirect('/account/events'),
					},
					{
						title: t('account:webinars'),
						Icon: LiveIcon,
						onClick: () => handleRedirect('/account/webinars'),
					},
					{
						title: t('account:learning_paths'),
						Icon: LearningPathMenuIcon,
						onClick: () => handleRedirect('/account/learning-paths'),
					},
				],
			},
			...!disable_purchase ? [
				{
					title: t('all:account_all_orders'),
					Icon: OrderOutlineIcon,
					subMenu: [
						{
							title: t('all:account_courses'),
							Icon: OrderOutlineIcon,
							onClick: () => handleRedirect('/account/course-orders'),
						},
						{
							title: t('all:account_order'),
							Icon: OrderOutlineIcon,
							onClick: () => handleRedirect('/account/orders'),
						},
					],
				},
			] : [],
			...front_desk_knowledge_base_enabled ? [{
				title: t('all:header_knowledge'),
				Icon: KnowledgeBaseIcon,
				iconColor: '#FFFFFF',
				backgroundColor: '#FFFFFF',
				onClick: () => window.open('/knowledge', '_blank'),
			}] : [],
			...other_school_login_enabled ? [{
				title: t('all:header_enterprise'),
				Icon: EnterpriseIcon,
				iconColor: '#FFFFFF',
				backgroundColor: '#FFFFFF',
				onClick: () => window.open('/enterprise', '_blank'),
			}] : [],
			...can_switch_hr_system ? [{
				title: t('account:switch_hr_system'),
				Icon: SwitchHRSystemIcon,
				iconColor: 'transparent',
				onClick: () => window.open('/hr', '_blank'),
			}] : [],
			{
				title: t('common:logout'),
				Icon: LogoutIcon,
				onClick: handleLogOut,
				backgroundColor: '#FFFFFF',
			},
		];

		return (
			<StyledAntMenu mode="inline">
				{_.map(menuList, renderUserProfileItem)}
			</StyledAntMenu>
		);
	};

	return (
		<Wrapper id="mobile-header" style={backColorStyle}>
			<LogoComponent logoLink={logoLink} />
			{/* <div onClick={handleSearchButtonClick} style={textColorStyle}>
        <IconComponent size={18} icon="icon_mobile_search_39" />
      </div> */}
			<div className="rightBox" style={textColorStyle}>
				{isSignedIn && !disable_purchase && <Cart textColor={textColor} />}
				<div onClick={handleMenuButtonClick} style={textColorStyle}>
					<IconComponent size={18} icon="icon_mobile_home" />
				</div>
				{/* <SearchBar show={showSearch}>
          <div className="searchIcon" onClick={handleSearchButtonClick}>
            <IconComponent size={18} icon="icon_mobile_arrow_45" />
          </div>
          <div className="search">
            <div
              className="search-course-btn"
              onClick={handleSearchButtonClick}
            >
              <IconComponent size={16} icon="icon_mobile_search_39" />
            </div>
            <input type="text" placeholder="輸入關鍵字" />
          </div>
        </SearchBar> */}
				<MenuBack show={showMenu} onClick={() => setShowMenu(false)} />
				<Menu show={showMenu}>
					{renderList()}
					{isSignedIn ? (
						renderUserProfileBox()
					) : (
						<>
							{other_school_login_enabled && renderUserProfileItem({
								title: t('all:header_enterprise'),
								Icon: EnterpriseIcon,
								iconColor: '#FFFFFF',
								backgroundColor: '#FFFFFF',
								onClick: () => window.open('/enterprise', '_blank'),
							})}
							{renderUserProfileItem({
								title: t('login:login'),
								Icon: LoginIcon,
								backgroundColor: '#FFFFFF',
								onClick: () => handleShowModal('login'),
							})}
						</>
					)}
				</Menu>
			</div>
		</Wrapper>
	);
}

function mapStateToProps(state) {
	const {
		session: { isSignedIn },
		user: { profile },
	} = state;
	const { themes, headerContent, homePage } = getThemeSelector(state);

	return {
		isSignedIn,
		themes,
		headerContent,
		homePage,
		profile,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({ ...actionCreators }, dispatch),
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(MobileHeaderComponent);
