import { createAction } from 'redux-actions';
import * as types from '@src/store/types';

export const getCourseAnnouncementsCall = createAction(
  types.GET_COURSE_ANNOUNCEMENTS_CALL
);
export const getCourseAnnouncementsDone = createAction(
  types.GET_COURSE_ANNOUNCEMENTS_DONE
);
export const getCourseAnnouncementsFail = createAction(
  types.GET_COURSE_ANNOUNCEMENTS_FAIL
);
