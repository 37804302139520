import moment from 'moment';

export const handleGetDateDuration = ({ startDate, endDate }) => {
  const end = moment(endDate);
  const duration = moment.duration(end.diff(moment(startDate)));
  const dates = {
    days: Math.floor(duration.asDays()),
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
  };
  let dateType;
  let date;
  let days = duration.asDays();
  let hours = duration.asHours();
  let minutes = duration.asMinutes();

  if (days >= 1) {
    dateType = 'day';
    date = Math.ceil(days);
  } else if (hours >= 1) {
    dateType = 'hours';
    date = Math.ceil(hours);
  } else if (minutes >= 1) {
    dateType = 'minutes';
    date = Math.ceil(minutes);
  } else {
    dateType = 'minutes';
    date = 0;
  }

  return {
    date,
    dateType,
    dates,
  };
};
