import React from 'react';
import styled from 'styled-components';
import useTranslation from '@src/hooks/useTranslation';

import {
	BUTTON_VARIANT_OUTLINED,
	BUTTON_VARIANT_OUTLINED_DEFAULT_COLOR,
	BUTTON_VARIANT_CONTAINED,
	BUTTON_VARIANT_CONTAINED_DEFAULT_COLOR,
} from '@src/const/common';

import Button from '@src/components/common/button';

const StyledButton = styled(Button)`
	padding: 8px 24px;
	white-space: nowrap;
	width: 120px;
	height: 40px;
	@media ${props => props.theme.device.mobile} {
		width: 80px;
	}
`;

const FlexWrapper = styled.div`
	display: flex;

	& > *:not(:first-child) {
		margin-left: 8px;
	}

	@media ${props => props.theme.device.mobile} {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
`;

const ModalFooter = styled.div`
	display: flex;
	justify-content: ${({ hasStartElem }: { hasStartElem: boolean }) => (hasStartElem ? 'space-between' : 'flex-end')};
	width: 100%;
	background: #ffffff;

	@media ${props => props.theme.device.mobile} {
		flex-direction: column;
		justify-content: center;
		bottom: 0;
	}
`;

interface IAddTagModalFooter {
	className?: string;
	handleSubmit?: () => void;
	handleCancel?: () => void;
	isDisable?: boolean;
	isLoading?: boolean;
	submitText?: string;
	showCancel?: boolean;
	showSubmit?: boolean;
	footerStartElem?: React.ReactNode;
	cancelText?: string;
	isDefaultColor?: boolean;
}

const Footer = ({
	className, handleSubmit, handleCancel, submitText, isDisable = false, isLoading = false,
	showCancel = true, showSubmit = true, footerStartElem, cancelText, isDefaultColor = false,
}: IAddTagModalFooter): JSX.Element => {
	const { t } = useTranslation();

	return (
		<ModalFooter className={className} hasStartElem={!!footerStartElem}>
			{footerStartElem}
			<FlexWrapper className="button-wrapper">
				{
					showCancel && (
						<StyledButton
							className="cancel-button"
							variant={isDefaultColor ? BUTTON_VARIANT_OUTLINED : BUTTON_VARIANT_OUTLINED_DEFAULT_COLOR}
							handleClick={() => {
								if (handleCancel) {
									handleCancel();
								}
							}}
						>
							{cancelText || t('common:cancel')}
						</StyledButton>
					)
				}
				{
					showSubmit && (
						<StyledButton
							className="submit-button"
							variant={isDefaultColor ? BUTTON_VARIANT_CONTAINED : BUTTON_VARIANT_CONTAINED_DEFAULT_COLOR}
							isLoading={isLoading}
							isDisable={isDisable}
							handleClick={() => {
								if (!isDisable && handleSubmit) {
									handleSubmit();
								}
							}}
						>
							{submitText || t('common:confirm')}
						</StyledButton>
					)
				}
			</FlexWrapper>
		</ModalFooter>
	);
};

export default Footer;
