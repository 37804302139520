import styled from 'styled-components';

export const StyledHtmlParser = styled.div`
	font-size: 16px;
	font-weight: 300;
	color: #101010;
	line-height: 1.5;

	h1 {
		font-size: 32px;
		font-weight: 600;
	}
	h2 {
		font-size: 24px;
		font-weight: 600;
	}
	h3 {
		font-size: 20px;
		font-weight: 500;
	}
	h4 {
		font-size: 18px;
		font-weight: 400;
	}

	* {
		white-space: pre-line;
		word-wrap: break-word;
	}

	img {
		width: 100%;
		height: auto;
		object-fit: cover;
	}

	blockquote {
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: "PingFang TC", "Noto Sans TC", "微軟正黑體", sans-serif !important;
	}

	blockquote:before,
	blockquote:after {
		font-size: 52px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 32px;
		letter-spacing: normal;
		text-align: center;
		color: #222222;
	}

	blockquote:before {
		content: '“';
		align-self: flex-start;
		transform: translate(-15px, 15px);
	}

	blockquote:after {
		content: '”';
		align-self: flex-end;
		transform: translate(15px, 20px);
	}

	.iframeRwd {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	& table {
		width: 100%;
		table-layout: fixed;
		border-style: hidden;
		border-collapse: collapse;
		border-spacing: 0;
		border: 1px solid #c5c5c5;
		border-width: 0.5px;
	}
	& td {
		background-color: #fff;
    border: 1px solid #c5c5c5;
    border-width: 0.5px;
    overflow: visible;
    cursor: text;
		padding: 10px 15px;
	}
`;

export const StyledCourseHtmlParser = styled(StyledHtmlParser)`
	p {
		margin-bottom: 0;
		font-size: 1rem;
		font-weight: 400;
	}

	p:empty {
		height: 1rem;
	}
	& table {
		width: 100%;
		table-layout: fixed;
		border-style: hidden;
		border-collapse: collapse;
		border-spacing: 0;
		border: 1px solid #c5c5c5;
		border-width: 0.5px;
	}
	& td {
		background-color: #fff;
    border: 1px solid #c5c5c5;
    border-width: 0.5px;
    overflow: visible;
    cursor: text;
		padding: 10px 15px;
	}
`;
