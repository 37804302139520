export const TOAST_SHOW_CALL = 'TOAST_SHOW_CALL';
export const TOAST_SHOW_DONE = 'TOAST_SHOW_DONE';
export const TOAST_SHOW_FAIL = 'TOAST_SHOW_FAIL';

export const SET_MODAL = 'SET_MODAL';
export const SET_CHECK_MODAL = 'SET_CHECK_MODAL';

export const SET_LOCK_SCROLL = 'SET_LOCK_SCROLL';
export const SET_MODAL_TYPE = 'SET_MODAL_TYPE';
export const SET_KEYBOARD_SHOW = 'SET_KEYBOARD_SHOW';
