export const GET_EVENT_CALL = 'GET_EVENT_CALL';
export const GET_EVENT_DONE = 'GET_EVENT_DONE';
export const GET_EVENT_FAIL = 'GET_EVENT_FAIL';
export const CLEAN_EVENT_CALL = 'CLEAN_EVENT_CALL';

export const SET_CURRENT_PRICE = 'SET_CURRENT_PRICE';

export const GET_EVENTS_CALL = 'GET_EVENTS_CALL';
export const GET_EVENTS_DONE = 'GET_EVENTS_DONE';
export const GET_EVENTS_FAIL = 'GET_EVENTS_FAIL';

export const SET_EVENT_COUNTDOWN_FOR_SALE = 'SET_EVENT_COUNTDOWN_FOR_SALE';

export const GET_TAGS_FAIL = 'GET_TAGS_FAIL';
export const GET_TAGS_CALL = 'GET_TAGS_CALL';
export const GET_TAGS_DONE = 'GET_TAGS_DONE';

export const EVENT_INSPECTOR_VERIFY_CALL = 'EVENT_INSPECTOR_VERIFY_CALL';
export const EVENT_INSPECTOR_VERIFY_DONE = 'EVENT_INSPECTOR_VERIFY_DONE';
export const EVENT_INSPECTOR_VERIFY_FAIL = 'EVENT_INSPECTOR_VERIFY_FAIL';

export const EVENT_REGISTER_FORM_CHECK_IN_CALL = 'EVENT_REGISTER_FORM_CHECK_IN_CALL';
export const EVENT_REGISTER_FORM_CHECK_IN_DONE = 'EVENT_REGISTER_FORM_CHECK_IN_DONE';
export const EVENT_REGISTER_FORM_CHECK_IN_FAIL = 'EVENT_REGISTER_FORM_CHECK_IN_FAIL';

export const DELETE_EVENT_REGISTER_FORM_CHECK_IN_CALL = 'DELETE_EVENT_REGISTER_FORM_CHECK_IN_CALL';
export const DELETE_EVENT_REGISTER_FORM_CHECK_IN_DONE = 'DELETE_EVENT_REGISTER_FORM_CHECK_IN_DONE';
export const DELETE_EVENT_REGISTER_FORM_CHECK_IN_FAIL = 'DELETE_EVENT_REGISTER_FORM_CHECK_IN_FAIL';

export const REVERT_DELETE_EVENT_REGISTER_FORM_CHECK_IN_CALL = 'REVERT_DELETE_EVENT_REGISTER_FORM_CHECK_IN_CALL';
export const REVERT_DELETE_EVENT_REGISTER_FORM_CHECK_IN_DONE = 'REVERT_DELETE_EVENT_REGISTER_FORM_CHECK_IN_DONE';
export const REVERT_DELETE_EVENT_REGISTER_FORM_CHECK_IN_FAIL = 'REVERT_DELETE_EVENT_REGISTER_FORM_CHECK_IN_FAIL';

export const GET_EVENT_REGISTER_FORM_CALL = 'GET_EVENT_REGISTER_FORM_CALL';
export const GET_EVENT_REGISTER_FORM_DONE = 'GET_EVENT_REGISTER_FORM_DONE';
export const GET_EVENT_REGISTER_FORM_FAIL = 'GET_EVENT_REGISTER_FORM_FAIL';

export const POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_CALL = 'POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_CALL';
export const POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_DONE = 'POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_DONE';
export const POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_FAIL = 'POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_FAIL';

export const GET_REGISTER_FORM_STATISTICS_CALL = 'GET_REGISTER_FORM_STATISTICS_CALL';
export const GET_REGISTER_FORM_STATISTICS_DONE = 'GET_REGISTER_FORM_STATISTICS_DONE';
export const GET_REGISTER_FORM_STATISTICS_FAIL = 'GET_REGISTER_FORM_STATISTICS_FAIL';

export const GET_REGISTER_FORM_CHECK_IN_LIST_CALL = 'GET_REGISTER_FORM_CHECK_IN_LIST_CALL';
export const GET_REGISTER_FORM_CHECK_IN_LIST_DONE = 'GET_REGISTER_FORM_CHECK_IN_LIST_DONE';
export const GET_REGISTER_FORM_CHECK_IN_LIST_FAIL = 'GET_REGISTER_FORM_CHECK_IN_LIST_FAIL';
