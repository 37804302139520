import React from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Popover } from 'antd';

import useMediaQuery from '@src/hooks/useMediaQuery';
import { getShoppingCartProductsState, getShoppingCartProducts } from '@src/store/selectors/cart';
import { STATE_LOADING } from '@src/const/common';

import CartEntrance from './cartEntrance';
import LoadingContent from './loadingContent';
import EmptyContent from './emptyContent';
import CartContent from './cartContent';

const CartWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`;

const EntranceOuter = styled.div`
	margin-right: 16px;
`;

const renderContent = ({ cartState, cartProducts }) => {
	if (cartState === STATE_LOADING) { return <LoadingContent />; }

	const totalProductAmount =
		cartProducts.products_numbers + cartProducts.event_slots_numbers + cartProducts.webinar_slots_numbers;

	return (totalProductAmount === 0) ? <EmptyContent /> : <CartContent />;
};

interface ICart {
	className: string;
}

const Cart = ({ className }: ICart): JSX.Element => {
	const router = useRouter();
	const { mediaMatch: { isDesktop, isLgDesktop } } = useMediaQuery();
	const cartState = useSelector(getShoppingCartProductsState);
	const cartProducts = useSelector(getShoppingCartProducts);

	const handleClickEntrance = () => { router.push('/cart'); };

	return (
		<CartWrapper className={className}>
			{(isDesktop || isLgDesktop) ? (
				<Popover
					content={renderContent({ cartState, cartProducts })}
					trigger="hover"
					placement="bottomRight"
					mouseEnterDelay={0}
				>
					<EntranceOuter>
						<CartEntrance />
					</EntranceOuter>
				</Popover>
			) : (
				<EntranceOuter>
					<CartEntrance handleClick={handleClickEntrance} />
				</EntranceOuter>
			)}
		</CartWrapper>
	);
};

export default Cart;
