import { COURSE_STATUS, COURSE_STATUS_LABELS } from '@src/const/deprecatedCourse';

export const calcCourseCanBuy = (status) => {
  if (
    status === COURSE_STATUS.fundraising_refunded ||
    status === COURSE_STATUS.fundraising_failed ||
    status === COURSE_STATUS.archived ||
    status === COURSE_STATUS.disabled
  ) {
    return false;
  }
  return true;
};

export const calcCourseFundFailed = (status) => {
  return (
    status === COURSE_STATUS.fundraising_refunded ||
    status === COURSE_STATUS.fundraising_failed
  );
};

export const getCourseStatus = (status) => {
  return COURSE_STATUS_LABELS[status] || null;
};

export const calcPaymentState = (purchase) => {
  const isPaid = !!purchase.charged_at;
  const isExpired = !!purchase.expired_at;
  const isCancelSubmitted = !!purchase.canceled_at;
  const isCancelSuccess = !!purchase.refunded_at;

  return {
    isPaid,
    isExpired,
    isCancelSubmitted,
    isCancelSuccess,
  };
};

export const calcFundGoalPercentage = (course) => {
  const { total_backers, goal_backers } = course;

  return Math.ceil((total_backers / goal_backers) * 100);
};
