export const GET_CREDIT_CARDS_CALL = 'GET_CREDIT_CARDS_CALL';
export const GET_CREDIT_CARDS_DONE = 'GET_CREDIT_CARDS_DONE';
export const GET_CREDIT_CARDS_FAIL = 'GET_CREDIT_CARDS_FAIL';

export const ADD_CREDIT_CARD_CALL = 'ADD_CREDIT_CARD_CALL';
export const ADD_CREDIT_CARD_DONE = 'ADD_CREDIT_CARD_DONE';
export const ADD_CREDIT_CARD_FAIL = 'ADD_CREDIT_CARD_FAIL';

export const SET_SHOW_CARD = 'SET_SHOW_CARD';

export const EDIT_CREDIT_CARD_META_CALL = 'EDIT_CREDIT_CARD_META_CALL';
export const EDIT_CREDIT_CARD_META_DONE = 'EDIT_CREDIT_CARD_META_DONE';
export const EDIT_CREDIT_CARD_META_FAIL = 'EDIT_CREDIT_CARD_META_FAIL';

export const PAY_VIA_CREDIT_CARD_CALL = 'PAY_VIA_CREDIT_CARD_CALL';
export const PAY_VIA_CREDIT_CARD_DONE = 'PAY_VIA_CREDIT_CARD_DONE';
export const PAY_VIA_CREDIT_CARD_FAIL = 'PAY_VIA_CREDIT_CARD_FAIL';

export const PAY_VIA_VACC_CALL = 'PAY_VIA_VACC_CALL';
export const PAY_VIA_VACC_DONE = 'PAY_VIA_VACC_DONE';
export const PAY_VIA_VACC_FAIL = 'PAY_VIA_VACC_FAIL';
