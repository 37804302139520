import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { getCartProductsCall } from '@src/store/actions/cart';
import * as selectors from '@src/store/selectors';
import { handleThemeClick } from '@src/utils/theme';
import useTranslation from '@src/hooks/useTranslation';

import WebHeader from './WebHeaderComponent';
import MobileHeader from './MobileHeaderComponent';

export default function Header(props) {
	const { pages } = useSelector(selectors.getThemeSelector);
	const { isThemeEdit, item } = props;
	const router = useRouter();
	const { asPath } = router;

	const dispatch = useDispatch();
	const isSignIn = useSelector(selectors.getIsSignIn);
	const { t } = useTranslation();

	useEffect(() => {
		if (isSignIn && asPath !== '/cart' && asPath !== '/checkout') {
			dispatch(getCartProductsCall());
		}
  }, [isSignIn]); // eslint-disable-line

	const handleItemClick = (item) => {
		const { openType, link, paramId } = item;

		handleThemeClick({ openType, link, paramId, pages });
	};

	const showTopBar = asPath === '/';

	return (
		<>
			<MobileHeader
				onItemClick={handleItemClick}
				isThemeEdit={isThemeEdit}
				item={item}
				asPath={asPath}
			/>
			<WebHeader
				showTopBar={showTopBar}
				onItemClick={handleItemClick}
				isThemeEdit={isThemeEdit}
				item={item}
				asPath={asPath}
				t={t}
			/>
		</>
	);
}
