import React from 'react';
import Image from 'react-image';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getDomain } from '@src/store/selectors/ssr';
import logoLink from '@src/assets/images/logo.svg';
import useTranslation from '@src/hooks/useTranslation';
import { footerPowerByVisible } from '@src/utils/hardcode';

import classStyle from './Footer.module.less';

const PoweredByDiv = styled.div`
	margin-left: auto;
	display: inline-flex;
	align-items: center;
	font-size: 12px;

	@media (max-width: 576px) {
		margin-top: 10px;
		margin-left: 0px;
	}
`;

const PoweredBy = ({ textColorStyle }: { textColorStyle: React.CSSProperties }): JSX.Element => {
	const domain = useSelector(getDomain) as string;
	const { t } = useTranslation();
	const handleToHome = () => {
		window.open('https://teaches.cc/');
	};

	if (domain.indexOf('automan.collegeplus.tw') > -1) {
		return (
			<PoweredByDiv style={textColorStyle}>
				Powered by ITRI 大機械學研學院
			</PoweredByDiv>
		);
	}
	if (domain.indexOf('collegeplus.tw') > -1) {
		return (
			<PoweredByDiv style={textColorStyle}>
				Powered by ITRI College+ 產業學院
			</PoweredByDiv>
		);
	}
	return (
		<div role="presentation" className={classStyle.linkDiv} onClick={handleToHome}>
			{footerPowerByVisible(domain) && <Image className={classStyle.logoImage} src={logoLink} />}
			<div style={textColorStyle}>{t('login:footerPowerBy')}</div>
		</div>
	);
};

export default PoweredBy;
