import { createAction } from 'redux-actions';

import {
	GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_CALL,
	GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_DONE,
	GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_FAIL,
	GET_EACH_PURCHASE_COURSE_DETAIL_BY_ID_CALL,
	GET_EACH_PURCHASE_COURSE_DETAIL_BY_ID_DONE,
	GET_EACH_PURCHASE_COURSE_DETAIL_BY_ID_FAIL,
	GET_EACH_PURCHASE_EVENT_DETAIL_BY_LINK_CALL,
	GET_EACH_PURCHASE_EVENT_DETAIL_BY_LINK_DONE,
	GET_EACH_PURCHASE_EVENT_DETAIL_BY_LINK_FAIL,
	GET_EACH_PURCHASE_WEBINAR_DETAIL_BY_LINK_CALL,
	GET_EACH_PURCHASE_WEBINAR_DETAIL_BY_LINK_DONE,
	GET_EACH_PURCHASE_WEBINAR_DETAIL_BY_LINK_FAIL,
} from '@src/store/types/checkoutSuccess';

export const getAllPurchaseRecordsByOrderListCall = createAction(GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_CALL);
export const getAllPurchaseRecordsByOrderListDone = createAction(GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_DONE);
export const getAllPurchaseRecordsByOrderListFail = createAction(GET_ALL_PURCHASE_RECORDS_BY_ORDER_LIST_FAIL);

export const getEachPurchaseCourseDetailByIdCall = createAction(GET_EACH_PURCHASE_COURSE_DETAIL_BY_ID_CALL);
export const getEachPurchaseCourseDetailByIdDone = createAction(GET_EACH_PURCHASE_COURSE_DETAIL_BY_ID_DONE);
export const getEachPurchaseCourseDetailByIdFail = createAction(GET_EACH_PURCHASE_COURSE_DETAIL_BY_ID_FAIL);

export const getEachPurchaseEventDetailByLinkCall = createAction(GET_EACH_PURCHASE_EVENT_DETAIL_BY_LINK_CALL);
export const getEachPurchaseEventDetailByLinkDone = createAction(GET_EACH_PURCHASE_EVENT_DETAIL_BY_LINK_DONE);
export const getEachPurchaseEventDetailByLinkFail = createAction(GET_EACH_PURCHASE_EVENT_DETAIL_BY_LINK_FAIL);

export const getEachPurchaseWebinarDetailByLinkCall = createAction(GET_EACH_PURCHASE_WEBINAR_DETAIL_BY_LINK_CALL);
export const getEachPurchaseWebinarDetailByLinkDone = createAction(GET_EACH_PURCHASE_WEBINAR_DETAIL_BY_LINK_DONE);
export const getEachPurchaseWebinarDetailByLinkFail = createAction(GET_EACH_PURCHASE_WEBINAR_DETAIL_BY_LINK_FAIL);
