export const COURSE_STATUS_PUBLISHED = 'published';
export const COURSE_STATUS_FUNDRAISING = 'fundraising';
export const COURSE_STATUS_FUNDRAISING_FAILED = 'fundraising_failed';
// Will combine `fundraising_refunding` and `fundraising_refunded` status soon.
export const COURSE_STATUS_FUNDRAISING_REFUNDING = 'fundraising_refunding';
export const COURSE_STATUS_FUNDRAISING_REFUNDED = 'fundraising_refunded';
export const COURSE_STATUS_PREPARING = 'preparing';
export const COURSE_STATUS_DISABLED = 'disabled';

export const COURSE_UNIT_TYPE_HOMEWORK = 'homework';
export const COURSE_UNIT_TYPE_SECTION = 'section';
export const COURSE_UNIT_TYPE_QUIZ = 'quiz';
export const COURSE_UNIT_TYPE_SURVEY = 'survey';

export const PROGRESS_STATUS_DONE = 'done';
export const PROGRESS_STATUS_IN_PROGRESS = 'in progress';
export const PROGRESS_STATUS_UNDONE = 'undone';

export const COURSE_INTRODUCTION = 'course_introduction';
export const PREPARATION_BEFORE_COURSE = 'preparation_before_course';
export const COURSE_TRAILER = 'course_trailer';
export const LECTURER_INTRODUCTION = 'lecturer_introduction';
export const DISCUSSIONS = 'discussions';

export const PREPARATION_MATERIAL = 'material_required';
export const PREPARATION_BACKGROUND = 'background_required';
export const PREPARATION_OUTCOME = 'outcome';
export const PREPARATION_TARGET_AUDIENCE = 'target_audience';

export const SORT_OPTION_TIME_DES = 'TIME_DES';
export const SORT_OPTION_TIME_ASC = 'TIME_ASC';
export const SORT_OPTION_RATING_DES = 'RATING_DES';
export const SORT_OPTION_RATING_ASC = 'RATING_ASC';

export const ROLE_STUDENT = 'student';
export const ROLE_TEACHER = 'teacher';

export const SOCIAL_MEDIA_FB = 'Facebook';
export const SOCIAL_MEDIA_LINE = 'Line';
export const SOCIAL_MEDIA_IG = 'Instagram';
export const SOCIAL_MEDIA_YOUTUBE = 'Youtube';
export const SOCIAL_MEDIA_BEHANCE = 'Behance';
export const SOCIAL_MEDIA_PINTEREST = 'Pinterest';
export const SOCIAL_MEDIA_PERSONAL_WEB = 'personalWeb';

export const REVIEW_MODAL_TYPE_ADD = 'ADD';
export const REVIEW_MODAL_TYPE_EDIT = 'EDIT';

export const PROPAGANDA_REVIEW_SOURCE_ONLINE = 'ONLINE';
export const PROPAGANDA_REVIEW_SOURCE_PHYSICAL = 'PHYSICAL';

export const LEARNING_ORDER_STEP_BY_STEP = 'step_by_step';

export const SHOW_BUYER_NUMBER_NEVER = 'never';
export const SHOW_BUYER_NUMBER_ALWAYS = 'always';
export const SHOW_BUYER_NUMBER_MORE_THAN = 'more_than';

export const ACCESS_RIGHT_ALWAYS = 'always';
export const ACCESS_RIGHT_DAYS = 'valid_days';
export const ACCESS_RIGHT_DATE = 'valid_until';

export const COURSE_TYPE_MIRROR = 'mirror_course';
export const COURSE_TYPE_INTERNAL_COURSE = 'internal_course';
export const COURSE_TYPE_NORMAL = 'course';
export const COURSE_TYPE_FUND_COURSE = 'fund_course';

export const COURSE_UNIT_TYPE = {
	DEFAULT: 'default',
	LOCK: 'lock',
	DONE: 'done',
	ACTIVE: 'active',
	FAIL: 'fail',
};

export const HOMEWORK_STATUS_PASS = 'marked_pass';
export const HOMEWORK_STATUS_FAILED = 'marked_fail';
