import _ from 'lodash';
import { colorThemes } from '@src/const/theme';
import { getPages } from '@src/utils/theme';

export const getFaqPages = state => state.theme?.themes?.faq_pages;

export const getColorTheme = state => {
	const targetThemeId = state.theme?.themes?.color_theme;

	return colorThemes.find(theme => theme.id === targetThemeId) || colorThemes[0];
};

export const getThemeState = state => state.theme.themeState;

export const getThemeSelector = state => {
	const { theme } = state;
	const { themes, themeState } = theme;
	const { pages } = themes;
	const newPages = getPages(pages);
	const homePage = _.find(newPages, { key: 'home' });
	let headerContent = {};
	let footerContent = {};

	if (homePage) {
		headerContent = _.find(homePage.content.list, { key: 'header' }) || {};
		footerContent = _.find(homePage.content.list, { key: 'footer' }) || {};
	}

	return {
		...theme,
		pages: newPages,
		homePage,
		headerContent,
		footerContent,
		themeState,
	};
};
