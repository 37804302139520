import { createAction } from 'redux-actions';
import {
	GET_KNOWLEDGE_BASE_PAGES_CALL,
	GET_KNOWLEDGE_BASE_PAGES_DONE,
	GET_KNOWLEDGE_BASE_PAGES_FAIL,
	POST_KNOWLEDGE_BASE_PAGE_CALL,
	POST_KNOWLEDGE_BASE_PAGE_DONE,
	POST_KNOWLEDGE_BASE_PAGE_FAIL,
	POST_KNOWLEDGE_BASE_PAGES_ORDER_CALL,
	POST_KNOWLEDGE_BASE_PAGES_ORDER_DONE,
	POST_KNOWLEDGE_BASE_PAGES_ORDER_FAIL,
	DELETE_KNOWLEDGE_BASE_PAGE_CALL,
	DELETE_KNOWLEDGE_BASE_PAGE_DONE,
	DELETE_KNOWLEDGE_BASE_PAGE_FAIL,
	GET_KNOWLEDGE_BASE_PAGE_DETAIL_CALL,
	GET_KNOWLEDGE_BASE_PAGE_DETAIL_DONE,
	GET_KNOWLEDGE_BASE_PAGE_DETAIL_FAIL,
	GET_KNOWLEDGE_BASE_PAGE_SEO_CALL,
	GET_KNOWLEDGE_BASE_PAGE_SEO_DONE,
	GET_KNOWLEDGE_BASE_PAGE_SEO_FAIL,
	PATCH_KNOWLEDGE_BASE_PAGE_CALL,
	PATCH_KNOWLEDGE_BASE_PAGE_DONE,
	PATCH_KNOWLEDGE_BASE_PAGE_FAIL,
	POST_KNOWLEDGE_BASE_PAGE_READ_CALL,
	POST_KNOWLEDGE_BASE_PAGE_READ_DONE,
	POST_KNOWLEDGE_BASE_PAGE_READ_FAIL,
} from '@src/store/types';

export const getKnowledgeBasePagesCall = createAction(GET_KNOWLEDGE_BASE_PAGES_CALL);
export const getKnowledgeBasePagesDone = createAction(GET_KNOWLEDGE_BASE_PAGES_DONE);
export const getKnowledgeBasePagesFail = createAction(GET_KNOWLEDGE_BASE_PAGES_FAIL);
export const postKnowledgeBasePageCall = createAction(POST_KNOWLEDGE_BASE_PAGE_CALL);
export const postKnowledgeBasePageDone = createAction(POST_KNOWLEDGE_BASE_PAGE_DONE);
export const postKnowledgeBasePageFail = createAction(POST_KNOWLEDGE_BASE_PAGE_FAIL);
export const postKnowledgeBasePagesOrderCall = createAction(POST_KNOWLEDGE_BASE_PAGES_ORDER_CALL);
export const postKnowledgeBasePagesOrderDone = createAction(POST_KNOWLEDGE_BASE_PAGES_ORDER_DONE);
export const postKnowledgeBasePagesOrderFail = createAction(POST_KNOWLEDGE_BASE_PAGES_ORDER_FAIL);
export const deleteKnowledgeBasePageCall = createAction(DELETE_KNOWLEDGE_BASE_PAGE_CALL);
export const deleteKnowledgeBasePageDone = createAction(DELETE_KNOWLEDGE_BASE_PAGE_DONE);
export const deleteKnowledgeBasePageFail = createAction(DELETE_KNOWLEDGE_BASE_PAGE_FAIL);
export const getKnowledgeBasePageDetailCall = createAction(GET_KNOWLEDGE_BASE_PAGE_DETAIL_CALL);
export const getKnowledgeBasePageDetailDone = createAction(GET_KNOWLEDGE_BASE_PAGE_DETAIL_DONE);
export const getKnowledgeBasePageDetailFail = createAction(GET_KNOWLEDGE_BASE_PAGE_DETAIL_FAIL);
export const getKnowledgeBasePageSEOCall = createAction(GET_KNOWLEDGE_BASE_PAGE_SEO_CALL);
export const getKnowledgeBasePageSEODone = createAction(GET_KNOWLEDGE_BASE_PAGE_SEO_DONE);
export const getKnowledgeBasePageSEOFail = createAction(GET_KNOWLEDGE_BASE_PAGE_SEO_FAIL);
export const patchKnowledgeBasePageCall = createAction(PATCH_KNOWLEDGE_BASE_PAGE_CALL);
export const patchKnowledgeBasePageDone = createAction(PATCH_KNOWLEDGE_BASE_PAGE_DONE);
export const patchKnowledgeBasePageFail = createAction(PATCH_KNOWLEDGE_BASE_PAGE_FAIL);
export const postKnowledgeBasePageReadCall = createAction(POST_KNOWLEDGE_BASE_PAGE_READ_CALL);
export const postKnowledgeBasePageReadDone = createAction(POST_KNOWLEDGE_BASE_PAGE_READ_DONE);
export const postKnowledgeBasePageReadFail = createAction(POST_KNOWLEDGE_BASE_PAGE_READ_FAIL);
