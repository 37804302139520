import React from 'react';
import styled from 'styled-components';
import useTranslation from '@src/hooks/useTranslation';

import { TOAST_TYPE_SUCCESS, TOAST_TYPE_WARN, TOAST_TYPE_ERROR, TOAST_TYPE_INFO } from '@src/const/common';

import { CircleCheckIcon, CircleCrossIcon, CircleInfoIcon, CircleWarningIcon } from '@src/components/common/icon';
import classStyle from '@src/styles/MyToast.module.less';

const CheckIcon = styled(CircleCheckIcon)`
	fill: #52C41A;
	margin-right: 10px;
	margin-top: 3px;
`;

const ErrorIcon = styled(CircleCrossIcon)`
	fill: #F5222D;
	margin-right: 10px;
	margin-top: 3px;
`;

const WarningIcon = styled(CircleWarningIcon)`
	fill: #FAAD14;
	margin-right: 10px;
	margin-top: 3px;
`;

const InfoIcon = styled(CircleInfoIcon)`
	fill: #3091FD;
	margin-right: 10px;
	margin-top: 3px;
`;

const MyToast = ({ type, message, messageParam, actionText, actionTextStyle, actionOnClick }) => {
	const { t } = useTranslation();
	let Icon = CheckIcon;

	switch (type) {
		case TOAST_TYPE_SUCCESS:
			Icon = CheckIcon;
			break;
		case TOAST_TYPE_ERROR:
			Icon = ErrorIcon;
			break;
		case TOAST_TYPE_WARN:
			Icon = WarningIcon;
			break;
		case TOAST_TYPE_INFO:
			Icon = InfoIcon;
			break;
		default:
			break;
	}

	return (
		<div className={classStyle.toast}>
			<Icon />
			<div className={classStyle.text}>
				{t(message, messageParam)}
				{
					actionText && (
						// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
						<span onClick={actionOnClick} style={actionTextStyle} className={classStyle.text}>
							{actionText}
						</span>
					)
				}
			</div>
		</div>
	);
};

export default MyToast;
