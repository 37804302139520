// This file is copied from `src/components/home/themeComponents/HtmlParser.js`.
import React from 'react';
// import DOMPurify from 'isomorphic-dompurify';
import { StyledHtmlParser, StyledCourseHtmlParser } from './styled';

// DOMPurify.addHook('uponSanitizeElement', (node, data) => {
// 	if (data.tagName === 'iframe') {
// 		const src = node.getAttribute('src') || '';

// 		if (!src.toLowerCase().startsWith('https://www.youtube.com/embed/')) {
// 			return node?.parentNode?.removeChild(node);
// 		}
// 	}
// });

interface IHtmlParser {
	className?: string;
	isCourse?: boolean;
	html: string;
}

const HtmlParser = ({ html, className, isCourse = false }: IHtmlParser): JSX.Element => {
	// Note: DOMPurify would remove or add attribute 'target' of 'a' tag incorrectly,
	// so remove DOMPurify temporarily to fix hyper link setting in back platform.
	// const cleanHtml = DOMPurify.sanitize(html, { ADD_TAGS: ['iframe'] });
	const StyledWrapper = isCourse ? StyledCourseHtmlParser : StyledHtmlParser;

	return (
		<StyledWrapper
			className={className}
			dangerouslySetInnerHTML={{ __html: html }}
		/>
	);
};

export default HtmlParser;
