import { createAction } from 'redux-actions';

import {
	UPDATE_PAYMENT_METHOD,
	UPDATE_CREDIT_CARD_NO,
	UPDATE_EXPIRY_DATE,
	UPDATE_CVC,
	UPDATE_INVOICE_METHOD,
	UPDATE_CARRIER_TYPE,
	UPDATE_DONATE,
	UPDATE_UNIFORM_NUMBER,
	UPDATE_COMPANY_NAME,
	UPDATE_MOBILE_BARCODE_CARRIER,
	UPDATE_CITIZEN_DIGITAL_CERTIFICATE,
	UPDATE_IS_CREDIT_CARD_ERROR,
	UPDATE_IS_EXP_ERROR,
	UPDATE_IS_CVC_ERROR,
	UPDATE_IS_INVOICE_METHOD_ERROR,
	UPDATE_IS_MOBILE_BARCODE_CARRIER_ERROR,
	UPDATE_IS_CITIZEN_DIGITAL_CERTIFICATE_ERROR,
	UPDATE_REGISTER_FORM_BY_SLOT_ID,
	UPDATE_WEBINAR_REGISTER_FORM_BY_SLOT_ID,
	GET_CART_PRODUCTS_AND_REGISTER_FORMS,
	GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_CALL,
	GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_DONE,
	GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_FAIL,
	GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_CALL,
	GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_DONE,
	GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_FAIL,
	UPDATE_REGISTER_FORM_ERROR_FIELD_BY_SLOT_ID_MAP,
	UPDATE_WEBINAR_REGISTER_FORM_ERROR_FIELD_BY_SLOT_ID_MAP,
	PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_CALL,
	PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_DONE,
	PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_FAIL,
	PAY_VIA_ECPAY_WITH_REGISTER_FORM_CALL,
	PAY_VIA_ECPAY_WITH_REGISTER_FORM_DONE,
	PAY_VIA_ECPAY_WITH_REGISTER_FORM_FAIL,
	PAY_VIA_VACC_WITH_REGISTER_FORM_CALL,
	PAY_VIA_VACC_WITH_REGISTER_FORM_DONE,
	PAY_VIA_VACC_WITH_REGISTER_FORM_FAIL,
	SAVE_REGISTER_FORM_BY_SLOT_CALL,
	SAVE_REGISTER_FORM_BY_SLOT_DONE,
	SAVE_REGISTER_FORM_BY_SLOT_FAIL,
	SAVE_WEBINAR_REGISTER_FORM_BY_SLOT_CALL,
	SAVE_WEBINAR_REGISTER_FORM_BY_SLOT_DONE,
	SAVE_WEBINAR_REGISTER_FORM_BY_SLOT_FAIL,
	SET_RESERVED_UNTIL_COUNT_DOWN,
	INIT_CHECKOUT_STATE,
	SET_PAYMENT_STATE,
	PAYMENT_ERROR_HANDLER_ACTION_CALL,
	GET_AREA_INFO_CALL,
	GET_AREA_INFO_DONE,
	GET_AREA_INFO_FAIL,
	// GET_PURCHASE_INFO_FIELDS_CALL,
	// GET_PURCHASE_INFO_FIELDS_DONE,
	// GET_PURCHASE_INFO_FIELDS_FAIL,
	GET_RECIPIENT_INFO_CALL,
	GET_RECIPIENT_INFO_DONE,
	GET_RECIPIENT_INFO_FAIL,
	UPDATE_PURCHASE_INFO,
	UPDATE_DEFAULT_SETTING,
	UPDATE_PURCHASE_INFO_FORM_ERROR_FIELDS,
	SAVE_CHECKOUT_FORM_PURCHASE_CALL,
	SAVE_CHECKOUT_FORM_PURCHASE_DONE,
	SAVE_CHECKOUT_FORM_PURCHASE_FAIL,
	GET_CHECKOUT_FORM_CALL,
	GET_CHECKOUT_FORM_DONE,
	GET_CHECKOUT_FORM_FAIL,
	SET_CUSTOM_CHECKOUT_FORM_FIELDS_INFO,
	SET_CUSTOM_CHECKOUT_FORM_ERROR_FIELDS,
	PAYMENT_SUCCESS_ACTIONS,
} from '@src/store/types/checkout';

export const updatePaymentMethod = createAction(UPDATE_PAYMENT_METHOD);
export const updateCreditCardNo = createAction(UPDATE_CREDIT_CARD_NO);
export const updateExpiryDate = createAction(UPDATE_EXPIRY_DATE);
export const updateCvc = createAction(UPDATE_CVC);
export const updateInvoiceMethod = createAction(UPDATE_INVOICE_METHOD);
export const updateCarrierType = createAction(UPDATE_CARRIER_TYPE);
export const updateDonate = createAction(UPDATE_DONATE);
export const updateUniformNumber = createAction(UPDATE_UNIFORM_NUMBER);
export const updateCompanyName = createAction(UPDATE_COMPANY_NAME);
export const updateMobileBarcodeCarrier = createAction(UPDATE_MOBILE_BARCODE_CARRIER);
export const updateCitizenDigitalCertificate = createAction(UPDATE_CITIZEN_DIGITAL_CERTIFICATE);

export const updateIsCreditCardError = createAction(UPDATE_IS_CREDIT_CARD_ERROR);
export const updateIsExpError = createAction(UPDATE_IS_EXP_ERROR);
export const updateIsCvcError = createAction(UPDATE_IS_CVC_ERROR);
export const updateIsInvoiceMethodError = createAction(UPDATE_IS_INVOICE_METHOD_ERROR);
export const updateIsMobileBarcodeCarrierError = createAction(UPDATE_IS_MOBILE_BARCODE_CARRIER_ERROR);
export const updateIsCitizenDigitalCertificateError = createAction(UPDATE_IS_CITIZEN_DIGITAL_CERTIFICATE_ERROR);

export const updateRegisterFormBySlotId = createAction(UPDATE_REGISTER_FORM_BY_SLOT_ID);
export const updateWebinarRegisterFormBySlotId = createAction(UPDATE_WEBINAR_REGISTER_FORM_BY_SLOT_ID);

export const getCartProductsAndRegisterForms = createAction(GET_CART_PRODUCTS_AND_REGISTER_FORMS);

export const getRegisterFormFieldsByEventIdCall = createAction(GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_CALL);
export const getRegisterFormFieldsByEventIdDone = createAction(GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_DONE);
export const getRegisterFormFieldsByEventIdFail = createAction(GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_FAIL);

export const getRegisterFormFieldsByWebinarIdCall = createAction(GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_CALL);
export const getRegisterFormFieldsByWebinarIdDone = createAction(GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_DONE);
export const getRegisterFormFieldsByWebinarIdFail = createAction(GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_FAIL);

export const updateRegisterFormErrorFieldBySlotIdMap = createAction(UPDATE_REGISTER_FORM_ERROR_FIELD_BY_SLOT_ID_MAP);
export const updateWebinarRegisterFormErrorFieldBySlotIdMap =
	createAction(UPDATE_WEBINAR_REGISTER_FORM_ERROR_FIELD_BY_SLOT_ID_MAP);

// Pay via credit card with register form
export const payViaCreditCardWithRegisterFormCall = createAction(PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_CALL);
export const payViaCreditCardWithRegisterFormDone = createAction(PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_DONE);
export const payViaCreditCardWithRegisterFormFail = createAction(PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_FAIL);

// Pay via ECPay with register form
export const payViaECPayWithRegisterFormCall = createAction(PAY_VIA_ECPAY_WITH_REGISTER_FORM_CALL);
export const payViaECPayWithRegisterFormDone = createAction(PAY_VIA_ECPAY_WITH_REGISTER_FORM_DONE);
export const payViaECPayWithRegisterFormFail = createAction(PAY_VIA_ECPAY_WITH_REGISTER_FORM_FAIL);

// Pay via VACC with register form
export const payViaVaccWithRegisterFormCall = createAction(PAY_VIA_VACC_WITH_REGISTER_FORM_CALL);
export const payViaVaccWithRegisterFormDone = createAction(PAY_VIA_VACC_WITH_REGISTER_FORM_DONE);
export const payViaVaccWithRegisterFormFail = createAction(PAY_VIA_VACC_WITH_REGISTER_FORM_FAIL);

// Save register form by slot
export const saveRegisterFormBySlotCall = createAction(SAVE_REGISTER_FORM_BY_SLOT_CALL);
export const saveRegisterFormBySlotDone = createAction(SAVE_REGISTER_FORM_BY_SLOT_DONE);
export const saveRegisterFormBySlotFail = createAction(SAVE_REGISTER_FORM_BY_SLOT_FAIL);

export const saveWebinarRegisterFormBySlotCall = createAction(SAVE_WEBINAR_REGISTER_FORM_BY_SLOT_CALL);
export const saveWebinarRegisterFormBySlotDone = createAction(SAVE_WEBINAR_REGISTER_FORM_BY_SLOT_DONE);
export const saveWebinarRegisterFormBySlotFail = createAction(SAVE_WEBINAR_REGISTER_FORM_BY_SLOT_FAIL);

export const setReservedUntilCountdown = createAction(SET_RESERVED_UNTIL_COUNT_DOWN);

export const initCheckoutState = createAction(INIT_CHECKOUT_STATE);

export const setPaymentState = createAction(SET_PAYMENT_STATE);

export const paymentErrorHandlerActionCall = createAction(PAYMENT_ERROR_HANDLER_ACTION_CALL);

export const getAreaInfoCall = createAction(GET_AREA_INFO_CALL);
export const getAreaInfoDone = createAction(GET_AREA_INFO_DONE);
export const getAreaInfoFail = createAction(GET_AREA_INFO_FAIL);

// export const getPurchaseInfoFieldsCall = createAction(GET_PURCHASE_INFO_FIELDS_CALL);
// export const getPurchaseInfoFieldsDone = createAction(GET_PURCHASE_INFO_FIELDS_DONE);
// export const getPurchaseInfoFieldsFail = createAction(GET_PURCHASE_INFO_FIELDS_FAIL);

export const saveCheckoutFormPurchaseCall = createAction(SAVE_CHECKOUT_FORM_PURCHASE_CALL);
export const saveCheckoutFormPurchaseDone = createAction(SAVE_CHECKOUT_FORM_PURCHASE_DONE);
export const saveCheckoutFormPurchaseFail = createAction(SAVE_CHECKOUT_FORM_PURCHASE_FAIL);

export const getCheckoutFormCall = createAction(GET_CHECKOUT_FORM_CALL);
export const getCheckoutFormDone = createAction(GET_CHECKOUT_FORM_DONE);
export const getCheckoutFormFail = createAction(GET_CHECKOUT_FORM_FAIL);

export const getRecipientInfoCall = createAction(GET_RECIPIENT_INFO_CALL);
export const getRecipientInfoDone = createAction(GET_RECIPIENT_INFO_DONE);
export const getRecipientInfoFail = createAction(GET_RECIPIENT_INFO_FAIL);

export const updatePurchaseInfo = createAction(UPDATE_PURCHASE_INFO);

export const updateDefaultSetting = createAction(UPDATE_DEFAULT_SETTING);

export const updatePurchaseInfoFormErrorFields = createAction(UPDATE_PURCHASE_INFO_FORM_ERROR_FIELDS);

export const setCustomCheckoutFormFieldsInfo = createAction(SET_CUSTOM_CHECKOUT_FORM_FIELDS_INFO);
export const setCustomCheckoutFormErrorFields = createAction(SET_CUSTOM_CHECKOUT_FORM_ERROR_FIELDS);

export const paymentSuccessActions = createAction(PAYMENT_SUCCESS_ACTIONS);
