/* eslint-disable react/prop-types */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as actionCreators from '@src/store/actions';
import { withRouter } from 'next/router';
import { connect } from 'react-redux';
import _ from 'lodash';

import { checkIsServer } from '@src/utils/deprecatedCommon';
import { Header, Footer } from '@src/components';
import BusinessFooter from '@src/components/business/footer';
import BottomKeepSpace from '@src/components/bottomKeepSpace';
import config from '@src/config';
import { getThemeSelector } from '@src/store/selectors';
import Maintenance from '@src/components/maintenance';
import HrMenu from '@src/components/menu';

import classStyle from './AppBackground.module.less';

import SideMenuWrapper from './SideMenuWrapper';

const { isTest, type } = config;

class AppBackground extends React.PureComponent {
	constructor(props) {
		super(props);

		const {
			router: { pathname },
		} = props;

		if (!checkIsServer() && !isTest) {
			window.onerror = this.handleError;
		}

		// 取得參數判斷
		if (!checkIsServer()) {
			this.handleHistoryParams();
		}

		this.state = {
			pathname,
		};
	}

	// 傳送錯誤
	handleError = (msg, url, lineNo, columnNo, error) => {
		const { actions } = this.props;
		const string = msg.toLowerCase();
		const substring = 'script error';
		let errorMessage = '';

		if (msg === 'ResizeObserver loop limit exceeded') {
			return;
		}

		if (string.indexOf(substring) > -1) {
			errorMessage = {
				type,
				msg: 'Script Error: See Browser Console for Detail',
			};
		} else {
			errorMessage = {
				type,
				msg,
				url,
				lineNo,
				columnNo,
				errorObject: JSON.stringify(error),
			};

			if (lineNo !== 1 && columnNo !== 1) {
				actions.sendBugMsgCall({ msg: JSON.stringify(errorMessage) });
			}
		}

		return false;
	};

	handleHistoryParams = () => {
		const { actions, router } = this.props;

		const { pathname, query } = router;

		// 處理oauth登入
		if (pathname.indexOf('/ext/oauth/') !== -1) {
			const name = _.last(_.split(pathname, '/'));

			actions.socialLoginCall({
				params: query,
				name,
			});

			return;
		}

		if (pathname === '/action' || pathname === '/admin/action') {
			// http://localhost:3000/action?action=check_login_session&session=fyVx9g4Qppy1QZ0QP1NMsCmG3go5hbbAGMtaoLvKUC85Mv2YTBFMzDha7bavSJx3
			// action?action=check_login_session&session=fyVx9g4Qppy1QZ0QP1NMsCmG3go5hbbAGMtaoLvKUC85Mv2YTBFMzDha7bavSJx3

			const { action, verification_code, staff_invitation, session, cb } = query;

			switch (action) {
				case 'check_email':
					actions.emailConfirmCall({
						verification_code,
					});
					break;
				case 'check_forget_password':
					actions.getForgetPasswordEmailCall({
						verification_code,
					});
					break;
				case 'check_business_forget_password':
					actions.getBusinessForgetPasswordEmailCall({
						verification_code,
					});
					break;
				case 'google_domain_not_allow':
					actions.googleDomainNotAllow();
					break;
				case 'check_invitation':
					// staff_invitation
					break;
				case 'check_login_session':
					actions.setToken({ token: session });
					actions.checkLoginSessionCall({ session, cb });
					break;
				default:
					break;
			}
		} else {
			// 確認登入
			this.handleLoginCheck();
		}
	};

	handleLoginCheck = payload => {
		const { session, actions } = this.props;
		const { isSignedIn } = session;

		if (isSignedIn) {
			actions.checkLoginCall({ fromLogin: false });
		}
	};

	render() {
		const {
			children,
			showPageLoading,
			homePage,
			headerContent,
			footerContent,
			router,
			themeState,
		} = this.props;
		let { closedWeb } = this.props; // 當 closedWeb = true，該學校為封閉學校
		const { pathname } = router;

		if (themeState === 'NOT_FOUND') {
			if (!checkIsServer()) {
				window.location.replace('https://teaches.cc');
			}
		} else if (themeState === 'PAYMENT_REQUIRED' || themeState === 'FAIL') {
			return <Maintenance />;
		}

		if (showPageLoading || !homePage) {
			return null;
		}

		const isActionPage = pathname === '/action';
		const isBusinessAuthPage = pathname.indexOf('/business') > -1;
		const isHrPage = pathname.indexOf('/hr') > -1;
		const { hide: hideHeader } = headerContent;
		const { hide: hideFooter } = footerContent;
		const isBusinessPageHideHeader = isBusinessAuthPage && closedWeb;
		const showHeader = !hideHeader && !isActionPage && !isBusinessPageHideHeader;
		const showFooter = !hideFooter && !isActionPage;
		const showBusinessFooter = closedWeb && !isActionPage && !isBusinessAuthPage;
		if (isHrPage) {
			return (
				<SideMenuWrapper>
					{children}
				</SideMenuWrapper>
			);
		}
		return (
			<div id="container" className={classStyle.container}>
				{isHrPage && <HrMenu />}
				<BottomKeepSpace>
					{showHeader && <Header />}
					{children}
					{showFooter && <Footer />}
					{showBusinessFooter && <BusinessFooter />}
				</BottomKeepSpace>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { session } = state;
	const {
		showPageLoading,
		homePage,
		headerContent,
		footerContent,
		themeState,
		themes: {
			closed_web,
		},
	} = getThemeSelector(state);

	return {
		session,
		showPageLoading,
		homePage,
		headerContent,
		footerContent,
		themeState,
		closedWeb: closed_web,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({ ...actionCreators }, dispatch),
	};
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(AppBackground),
);
