import { createAction } from 'redux-actions';
import * as types from '@src/store/types';

export const getCreditCardsCall = createAction(types.GET_CREDIT_CARDS_CALL);
export const getCreditCardsDone = createAction(types.GET_CREDIT_CARDS_DONE);
export const getCreditCardsFail = createAction(types.GET_CREDIT_CARDS_FAIL);

export const addCreditCardCall = createAction(types.ADD_CREDIT_CARD_CALL);
export const addCreditCardDone = createAction(types.ADD_CREDIT_CARD_DONE);
export const addCreditCardFail = createAction(types.ADD_CREDIT_CARD_FAIL);

export const setShowCard = createAction(types.SET_SHOW_CARD);

export const editCreditCardMetaCall = createAction(
  types.EDIT_CREDIT_CARD_META_CALL
);
export const editCreditCardMetaDone = createAction(
  types.EDIT_CREDIT_CARD_META_DONE
);
export const editCreditCardMetaFail = createAction(
  types.EDIT_CREDIT_CARD_META_FAIL
);

export const payViaCreditCardCall = createAction(
  types.PAY_VIA_CREDIT_CARD_CALL
);
export const payViaCreditCardDone = createAction(
  types.PAY_VIA_CREDIT_CARD_DONE
);
export const payViaCreditCardFail = createAction(
  types.PAY_VIA_CREDIT_CARD_FAIL
);

export const payViaVaccCall = createAction(types.PAY_VIA_VACC_CALL);
export const payViaVaccDone = createAction(types.PAY_VIA_VACC_DONE);
export const payViaVaccFail = createAction(types.PAY_VIA_VACC_FAIL);
