export const getUsername = (state) => {
  return state.user?.profile?.name || null;
};

export const getUserId = (state) => {
  return state.user?.profile?.id || null;
};

export const getUserPictureLinkObject = (state) => {
  return state.user?.profile?.profile_picture_link || {};
};

export const getUserPictureLink = (state) => {
  return state.user?.profile?.profile_picture_link?.link || null;
};

export const getUserDepartments = (state) => {
  return state.user?.profile?.departments || [];
};

export const getUserEmployeeId = (state) => {
  return state.user?.profile?.employee_id || '';
};
export const getUserEmployeeTitle = (state) => {
  return state.user?.profile?.employee_title || '';
};

export const getUnbindOtherUsernameStates = (type) => (state) => {
  return state.user.unbindOtherUsernameStates[type];
};

export const getPutUserProfileState = (state) => {
  return state.user.putUserProfileState;
};

export const getRefetchUserProfileState = (state) => {
  return state?.user?.refetchUserProfileState;
};

export const getUserProfile = (state) => {
  return state.user.profile;
};

export const getIsVerifyEmail = (state) => {
  const profile = state.user.profile || {};
  const { email, pwd_updated_at } = profile;

  return email && pwd_updated_at;
};

export const getBindOauthUser = (type) => (state) => {
  return state.user.profile?.bind_oauth_user?.find(
    (oauth) => oauth.provider === type
  );
};

export const getPutProfilePasswordState = (state) => {
  return state.user.putProfilePasswordState;
};

export const getPutUserProfilePictureState = (state) => {
  return state.user.putUserProfilePictureState;
};

export const getBindUserEmailState = (state) => {
  return state.user.bindUserEmailState;
};

export const getKnowledgeBasePermissions = (state) => {
  return state.user.profile?.knowledge_base_permissions;
};
