export const GET_OVERTIME_APPLICATIONS_CALL = 'GET_OVERTIME_APPLICATIONS_CALL';
export const GET_OVERTIME_APPLICATIONS_DONE = 'GET_OVERTIME_APPLICATIONS_DONE';
export const GET_OVERTIME_APPLICATIONS_FAIL = 'GET_OVERTIME_APPLICATIONS_FAIL';

export const POST_OVERTIME_APPLICATION_CALL = 'POST_OVERTIME_APPLICATION_CALL';
export const POST_OVERTIME_APPLICATION_DONE = 'POST_OVERTIME_APPLICATION_DONE';
export const POST_OVERTIME_APPLICATION_FAIL = 'POST_OVERTIME_APPLICATION_FAIL';

export const GET_OVERTIME_APPLICATION_INFO_CALL = 'GET_OVERTIME_APPLICATION_INFO_CALL';
export const GET_OVERTIME_APPLICATION_INFO_DONE = 'GET_OVERTIME_APPLICATION_INFO_DONE';
export const GET_OVERTIME_APPLICATION_INFO_FAIL = 'GET_OVERTIME_APPLICATION_INFO_FAIL';

export const GET_OVERTIME_APPLICATION_DETAIL_CALL = 'GET_OVERTIME_APPLICATION_DETAIL_CALL';
export const GET_OVERTIME_APPLICATION_DETAIL_DONE = 'GET_OVERTIME_APPLICATION_DETAIL_DONE';
export const GET_OVERTIME_APPLICATION_DETAIL_FAIL = 'GET_OVERTIME_APPLICATION_DETAIL_FAIL';
export const REST_OVERTIME_APPLICATION_DETAIL_CALL = 'REST_OVERTIME_APPLICATION_DETAIL_CALL';

export const POST_OVERTIME_APPLICATION_APPROVE_CALL = 'POST_OVERTIME_APPLICATION_APPROVE_CALL';
export const POST_OVERTIME_APPLICATION_APPROVE_DONE = 'POST_OVERTIME_APPLICATION_APPROVE_DONE';
export const POST_OVERTIME_APPLICATION_APPROVE_FAIL = 'POST_OVERTIME_APPLICATION_APPROVE_FAIL';

export const GET_OVERTIME_APPLICATION_CONFIRM_CALL = 'GET_OVERTIME_APPLICATION_CONFIRM_CALL';
export const GET_OVERTIME_APPLICATION_CONFIRM_DONE = 'GET_OVERTIME_APPLICATION_CONFIRM_DONE';
export const GET_OVERTIME_APPLICATION_CONFIRM_FAIL = 'GET_OVERTIME_APPLICATION_CONFIRM_FAIL';

export const POST_OVERTIME_APPLICATION_CONFIRM_CALL = 'POST_OVERTIME_APPLICATION_CONFIRM_CALL';
export const POST_OVERTIME_APPLICATION_CONFIRM_DONE = 'POST_OVERTIME_APPLICATION_CONFIRM_DONE';
export const POST_OVERTIME_APPLICATION_CONFIRM_FAIL = 'POST_OVERTIME_APPLICATION_CONFIRM_FAIL';

export const POST_OVERTIME_APPLICATION_WITHDRAW_CALL = 'POST_OVERTIME_APPLICATION_WITHDRAW_CALL';
export const POST_OVERTIME_APPLICATION_WITHDRAW_DONE = 'POST_OVERTIME_APPLICATION_WITHDRAW_DONE';
export const POST_OVERTIME_APPLICATION_WITHDRAW_FAIL = 'POST_OVERTIME_APPLICATION_WITHDRAW_FAIL';

export const GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_CALL = 'GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_CALL';
export const GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_DONE = 'GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_DONE';
export const GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_FAIL = 'GET_OVERTIME_APPLICATIONS_APPROVER_VIEW_FAIL';

export const POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_CALL = 'POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_CALL';
export const POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_DONE = 'POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_DONE';
export const POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_FAIL = 'POST_OVERTIME_APPLICATION_WITHDRAW_APPROVAL_FAIL';
