/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { Carousel } from 'antd';
import styled from 'styled-components';

import { handleThemeClick } from '@src/utils/theme';
import { NextArrowIcon, PrevArrowIcon } from '@src/components/common/icon';

const ImageWrap = styled.div`
	position: relative;
`;

const StyledCarousel = styled(Carousel)`
	& .slick-dots {
		bottom: 20px;
	}
	& .slick-dots .slick-active {
		width: 8px !important;
		height: 8px !important;
	};
	& .slick-dots .slick-active button {
		border-radius: 100px !important;
		width: 8px !important;
		height: 8px !important;
	};
	& .slick-dots {
		width: 8px !important;
		height: 8px !important;
		margin: unset !important;
		left: 50% !important;
	};
	& .slick-dots button {
		border-radius: 100px !important;
		width: 8px !important;
		height: 8px !important;
	};

	@media ${props => props.theme.device.tablet} {
		& .slick-dots {
			bottom: 16px;
		}
	}
	@media ${props => props.theme.device.mobile} {
		& .slick-dots {
			bottom: 12px;
		}
	}
`;

const BtnWrap = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 64px;
	background: #FFFFFF;
	opacity: 0.6;
	cursor: pointer;
	svg {
		path {
			fill: #676767;
		}
	}
	@media ${props => props.theme.device.mobile} {
		display: none;
	}
`;

const NextBtn = styled(BtnWrap)`
	position: absolute;
	top: calc(50% - 32px);
	right: 0px;
	z-index: 1;
`;

const PrevBtn = styled(BtnWrap)`
	position: absolute;
	top: calc(50% - 32px);
	left: 0px;
	z-index: 1;
`;

const Image = styled.img`
	cursor: pointer;
`;

// eslint-disable-next-line react/prop-types
const CarouselBanner = ({ pages, item }):JSX.Element => {
	const { list } = item;

	const	handleClick = ({ bannerItem }) => {
		const { action } = bannerItem;
		if (action) {
			const { openType, link, paramId } = action;

			handleThemeClick({ openType, link, paramId, pages });
		}
	};

	const slider = useRef(null);
	const nextButton = e => {
		e.stopPropagation();
		slider.current.next();
	};
	const prevButton = e => {
		e.stopPropagation();
		slider.current.prev();
	};

	return (
		<ImageWrap>
			{
				list.length > 1 && (
					<PrevBtn onClick={prevButton}>
						<PrevArrowIcon />
					</PrevBtn>
				)
			}
			<StyledCarousel
				autoplay
				autoplaySpeed={5000}
				swipeToSlide
				draggable
				ref={slider}
			>
				{
					// eslint-disable-next-line no-shadow
					list.map(bannerItem => (
						<Image
							key={bannerItem.image}
							src={bannerItem.image}
							onClick={() => handleClick({ bannerItem })}
						/>
					))
				}
			</StyledCarousel>
			{
				list.length > 1 && (
					<NextBtn onClick={nextButton}>
						<NextArrowIcon />
					</NextBtn>
				)
			}
		</ImageWrap>
	);
};

export default CarouselBanner;
