export const GET_KNOWLEDGE_BASE_SPACES_CALL = 'GET_KNOWLEDGE_BASE_SPACES_CALL';
export const GET_KNOWLEDGE_BASE_SPACES_DONE = 'GET_KNOWLEDGE_BASE_SPACES_DONE';
export const GET_KNOWLEDGE_BASE_SPACES_FAIL = 'GET_KNOWLEDGE_BASE_SPACES_FAIL';

export const POST_KNOWLEDGE_BASE_SPACE_CALL = 'POST_KNOWLEDGE_BASE_SPACE_CALL';
export const POST_KNOWLEDGE_BASE_SPACE_DONE = 'POST_KNOWLEDGE_BASE_SPACE_DONE';
export const POST_KNOWLEDGE_BASE_SPACE_FAIL = 'POST_KNOWLEDGE_BASE_SPACE_FAIL';

export const DELETE_KNOWLEDGE_BASE_SPACE_CALL = 'DELETE_KNOWLEDGE_BASE_SPACE_CALL';
export const DELETE_KNOWLEDGE_BASE_SPACE_DONE = 'DELETE_KNOWLEDGE_BASE_SPACE_DONE';
export const DELETE_KNOWLEDGE_BASE_SPACE_FAIL = 'DELETE_KNOWLEDGE_BASE_SPACE_FAIL';

export const GET_KNOWLEDGE_BASE_SPACE_DETAIL_CALL = 'GET_KNOWLEDGE_BASE_SPACE_DETAIL_CALL';
export const GET_KNOWLEDGE_BASE_SPACE_DETAIL_DONE = 'GET_KNOWLEDGE_BASE_SPACE_DETAIL_DONE';
export const GET_KNOWLEDGE_BASE_SPACE_DETAIL_FAIL = 'GET_KNOWLEDGE_BASE_SPACE_DETAIL_FAIL';

export const PATCH_KNOWLEDGE_BASE_SPACE_CALL = 'PATCH_KNOWLEDGE_BASE_SPACE_CALL';
export const PATCH_KNOWLEDGE_BASE_SPACE_DONE = 'PATCH_KNOWLEDGE_BASE_SPACE_DONE';
export const PATCH_KNOWLEDGE_BASE_SPACE_FAIL = 'PATCH_KNOWLEDGE_BASE_SPACE_FAIL';
