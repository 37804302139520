import React from 'react';
import styled from 'styled-components';

import { EmptyIcon } from '@src/components/common/icon';

const StyledEmptyIcon = styled(EmptyIcon)`
	fill: #F5F5F7;
`;

const Content = styled.div`
	margin-top: 60px;
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Text = styled.div`
	font-weight: 300;
	font-size: 16px;
	margin-top: 12px;
	color: #676767;
	text-align: center;
`;

const EmptyInfo = ({ className, text }: { className?: string; text: string }):JSX.Element => (
	<Content className={`empty-info ${className}`}>
		<StyledEmptyIcon />
		<Text>{text}</Text>
	</Content>
);

export default EmptyInfo;
