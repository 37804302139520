export const getAddCourseDiscussionState = (course_id) => (state) => {
  return state.comment.addDiscussionStates[course_id];
};

export const getAddCourseDiscussionReplyStates = (state) => {
  return state.comment.addDiscussionReplyStates;
};

export const getCourseDiscussionsState = (course_id) => (state) => {
  return state.comment.getDiscussionsStates[course_id];
};

export const getCourseDiscussions = (state) => {
  return state.comment.discussions;
};

export const getCourseDiscussionsBeforeState = (course_id) => (state) => {
  return state.comment.getDiscussionsBeforeStates[course_id];
};

export const getCourseDiscussionsBefore = (state) => {
  return state.comment.discussionsBefore.data;
};

export const getCourseDiscussionsBeforeTotal = (state) => {
  return state.comment.discussionsBefore?.pagination?.totalCount;
};
