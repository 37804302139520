import React from 'react';
import styled from 'styled-components';
import useTranslation from '@src/hooks/useTranslation';

import { calcRatingScore, isFundraising, isPreparing } from '@src/utils/course';
import {
	SHOW_BUYER_NUMBER_ALWAYS,
	SHOW_BUYER_NUMBER_MORE_THAN,
} from '@src/const/course';

import {
	InfoWrapper,
	InfoItem,
	StyledRateStarIcon,
	InfoHr,
} from '@src/components/card/style';

const StyledFundraisingWrapper = styled(InfoWrapper)`
	color: #797979;
`;

interface ICourseInfo {
	status: string;
	showStudentsType: string;
	showStudentsNumber: number;
	rate: {
		total_votes: number;
		total_score: string;
	};
	courseTime?: string;
	students?: number;
	percentage?: number;
}

const InfoBar = ({
	status,
	showStudentsType,
	showStudentsNumber = 0,
	rate,
	courseTime,
	students,
	percentage,
}: ICourseInfo): JSX.Element => {
	const { t } = useTranslation();

	const isCourseFundraising = isFundraising(status);
	const isCoursePreparing = isPreparing(status);
	let showStudents = false;
	if (showStudentsType === SHOW_BUYER_NUMBER_ALWAYS) {
		showStudents = true;
	} else if (showStudentsType === SHOW_BUYER_NUMBER_MORE_THAN && students > showStudentsNumber) {
		showStudents = true;
	}

	if (isCourseFundraising) {
		return (
			<StyledFundraisingWrapper>
				<InfoItem>
					{t('course:status:fundraising')}
				</InfoItem>
				{
					showStudents && (
						<>
							<InfoHr />
							<InfoItem>
								{`${t('course:progress:fundraising_percentage')} ${percentage}%`}
							</InfoItem>
						</>
					)
				}
			</StyledFundraisingWrapper>
		);
	} else if (isCoursePreparing) {
		return (
			<StyledFundraisingWrapper>
				<InfoItem>
					{t('course:status:preparing')}
				</InfoItem>
				{
					showStudents && (
						<>
							<InfoHr />
							<InfoItem>
								{`${t('course:progress:fundraising_percentage')} ${percentage}%`}
							</InfoItem>
						</>
					)
				}
			</StyledFundraisingWrapper>
		);
	}

	const { total_votes: totalVotes, total_score: totalScore } = rate;
	const { score } = calcRatingScore({ totalScore, totalVotes });
	const studentAmount = t('card:student_amount', { students });

	return (
		<InfoWrapper>
			<InfoItem>
				<StyledRateStarIcon />
				{score}
			</InfoItem>
			<InfoHr />
			<InfoItem>
				{courseTime}
			</InfoItem>
			{
				showStudents && (
					<>
						<InfoHr />
						<InfoItem>
							{studentAmount}
						</InfoItem>
					</>
				)
			}
		</InfoWrapper>
	);
};

export default InfoBar;
