export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD';
export const UPDATE_CREDIT_CARD_NO = 'UPDATE_CREDIT_CARD_NO';
export const UPDATE_EXPIRY_DATE = 'UPDATE_EXPIRY_DATE';
export const UPDATE_CVC = 'UPDATE_CVC';
export const UPDATE_INVOICE_METHOD = 'UPDATE_INVOICE_METHOD';
export const UPDATE_CARRIER_TYPE = 'UPDATE_CARRIER_TYPE';
export const UPDATE_DONATE = 'UPDATE_DONATE';
export const UPDATE_UNIFORM_NUMBER = 'UPDATE_UNIFORM_NUMBER';
export const UPDATE_COMPANY_NAME = 'UPDATE_COMPANY_NAME';
export const UPDATE_MOBILE_BARCODE_CARRIER = 'UPDATE_MOBILE_BARCODE_CARRIER';
export const UPDATE_CITIZEN_DIGITAL_CERTIFICATE = 'UPDATE_CITIZEN_DIGITAL_CERTIFICATE';

export const UPDATE_IS_CREDIT_CARD_ERROR = 'UPDATE_IS_CREDIT_CARD_ERROR';
export const UPDATE_IS_EXP_ERROR = 'UPDATE_IS_EXP_ERROR';
export const UPDATE_IS_CVC_ERROR = 'UPDATE_IS_CVC_ERROR';
export const UPDATE_IS_INVOICE_METHOD_ERROR = 'UPDATE_IS_INVOICE_METHOD_ERROR';
export const UPDATE_IS_MOBILE_BARCODE_CARRIER_ERROR = 'UPDATE_IS_MOBILE_BARCODE_CARRIER_ERROR';
export const UPDATE_IS_CITIZEN_DIGITAL_CERTIFICATE_ERROR = 'UPDATE_IS_CITIZEN_DIGITAL_CERTIFICATE_ERROR';

export const UPDATE_REGISTER_FORM_BY_SLOT_ID = 'UPDATE_REGISTER_FORM_BY_SLOT_ID';
export const UPDATE_WEBINAR_REGISTER_FORM_BY_SLOT_ID = 'UPDATE_WEBINAR_REGISTER_FORM_BY_SLOT_ID';

export const GET_CART_PRODUCTS_AND_REGISTER_FORMS = 'GET_CART_PRODUCTS_AND_REGISTER_FORMS';

export const GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_CALL = 'GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_CALL';
export const GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_DONE = 'GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_DONE';
export const GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_FAIL = 'GET_REGISTER_FORM_FIELDS_BY_EVENT_ID_FAIL';

export const GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_CALL = 'GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_CALL';
export const GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_DONE = 'GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_DONE';
export const GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_FAIL = 'GET_REGISTER_FORM_FIELDS_BY_WEBINAR_ID_FAIL';

export const UPDATE_REGISTER_FORM_ERROR_FIELD_BY_SLOT_ID_MAP = 'UPDATE_REGISTER_FORM_ERROR_FIELD_BY_SLOT_ID_MAP';
export const UPDATE_WEBINAR_REGISTER_FORM_ERROR_FIELD_BY_SLOT_ID_MAP =
	'UPDATE_WEBINAR_REGISTER_FORM_ERROR_FIELD_BY_SLOT_ID_MAP';

// Pay via Credit-card
export const PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_CALL = 'PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_CALL';
export const PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_DONE = 'PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_DONE';
export const PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_FAIL = 'PAY_VIA_CREDIT_CARD_WITH_REGISTER_FORM_FAIL';

// Pay via ECPay
export const PAY_VIA_ECPAY_WITH_REGISTER_FORM_CALL = 'PAY_VIA_ECPAY_WITH_REGISTER_FORM_CALL';
export const PAY_VIA_ECPAY_WITH_REGISTER_FORM_DONE = 'PAY_VIA_ECPAY_WITH_REGISTER_FORM_DONE';
export const PAY_VIA_ECPAY_WITH_REGISTER_FORM_FAIL = 'PAY_VIA_ECPAY_WITH_REGISTER_FORM_FAIL';

// Pay via ATM
export const PAY_VIA_VACC_WITH_REGISTER_FORM_CALL = 'PAY_VIA_VACC_WITH_REGISTER_FORM_CALL';
export const PAY_VIA_VACC_WITH_REGISTER_FORM_DONE = 'PAY_VIA_VACC_WITH_REGISTER_FORM_DONE';
export const PAY_VIA_VACC_WITH_REGISTER_FORM_FAIL = 'PAY_VIA_VACC_WITH_REGISTER_FORM_FAIL';

// Save register form by slot
export const SAVE_REGISTER_FORM_BY_SLOT_CALL = 'SAVE_REGISTER_FORM_BY_SLOT_CALL';
export const SAVE_REGISTER_FORM_BY_SLOT_DONE = 'SAVE_REGISTER_FORM_BY_SLOT_DONE';
export const SAVE_REGISTER_FORM_BY_SLOT_FAIL = 'SAVE_REGISTER_FORM_BY_SLOT_FAIL';

export const SAVE_WEBINAR_REGISTER_FORM_BY_SLOT_CALL = 'SAVE_WEBINAR_REGISTER_FORM_BY_SLOT_CALL';
export const SAVE_WEBINAR_REGISTER_FORM_BY_SLOT_DONE = 'SAVE_WEBINAR_REGISTER_FORM_BY_SLOT_DONE';
export const SAVE_WEBINAR_REGISTER_FORM_BY_SLOT_FAIL = 'SAVE_WEBINAR_REGISTER_FORM_BY_SLOT_FAIL';

export const SET_RESERVED_UNTIL_COUNT_DOWN = 'SET_RESERVED_UNTIL_COUNT_DOWN';

export const INIT_CHECKOUT_STATE = 'INIT_CHECKOUT_STATE';

export const SET_PAYMENT_STATE = 'SET_PAYMENT_STATE';

export const PAYMENT_ERROR_HANDLER_ACTION_CALL = 'PAYMENT_ERROR_HANDLER_ACTION_CALL';

export const GET_AREA_INFO_CALL = 'GET_AREA_INFO_CALL';
export const GET_AREA_INFO_DONE = 'GET_AREA_INFO_DONE';
export const GET_AREA_INFO_FAIL = 'GET_AREA_INFO_FAIL';

// export const GET_PURCHASE_INFO_FIELDS_CALL = 'GET_PURCHASE_INFO_FIELDS_CALL';
// export const GET_PURCHASE_INFO_FIELDS_DONE = 'GET_PURCHASE_INFO_FIELDS_DONE';
// export const GET_PURCHASE_INFO_FIELDS_FAIL = 'GET_PURCHASE_INFO_FIELDS_FAIL';

export const SAVE_CHECKOUT_FORM_PURCHASE_CALL = 'SAVE_CHECKOUT_FORM_PURCHASE_CALL';
export const SAVE_CHECKOUT_FORM_PURCHASE_DONE = 'SAVE_CHECKOUT_FORM_PURCHASE_DONE';
export const SAVE_CHECKOUT_FORM_PURCHASE_FAIL = 'SAVE_CHECKOUT_FORM_PURCHASE_FAIL';

export const GET_RECIPIENT_INFO_CALL = 'GET_RECIPIENT_INFO_CALL';
export const GET_RECIPIENT_INFO_DONE = 'GET_RECIPIENT_INFO_DONE';
export const GET_RECIPIENT_INFO_FAIL = 'GET_RECIPIENT_INFO_FAIL';

export const UPDATE_PURCHASE_INFO = 'UPDATE_PURCHASE_INFO';

export const UPDATE_DEFAULT_SETTING = 'UPDATE_DEFAULT_SETTING';

export const UPDATE_PURCHASE_INFO_FORM_ERROR_FIELDS = 'UPDATE_PURCHASE_INFO_FORM_ERROR_FIELDS';

export const GET_CHECKOUT_FORM_CALL = 'GET_CHECKOUT_FORM_CALL';
export const GET_CHECKOUT_FORM_DONE = 'GET_CHECKOUT_FORM_DONE';
export const GET_CHECKOUT_FORM_FAIL = 'GET_CHECKOUT_FORM_FAIL';

export const SET_CUSTOM_CHECKOUT_FORM_FIELDS_INFO = 'SET_CUSTOM_CHECKOUT_FORM_FIELDS_INFO';
export const SET_CUSTOM_CHECKOUT_FORM_ERROR_FIELDS = 'SET_CUSTOM_CHECKOUT_FORM_ERROR_FIELDS';

export const PAYMENT_SUCCESS_ACTIONS = 'PAYMENT_SUCCESS_ACTIONS';
