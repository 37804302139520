import { reactLocalStorage } from '@src/utils/localStorage';

export const isPositive = (value: string): boolean => /^\d*$/.test(value);

export const formatToPercentage = (partial: number, total: number): number | string => {
	if ((partial === 0) || (total === 0)) return 0;

	return ((partial / total) * 100).toFixed();
};

export const findAttributeInEvent = (event, attr) => {
	const end = event.currentTarget;

	let temp = event.target;
	let dataId = temp.getAttribute(attr);

	while (temp !== end && !dataId) {
		temp = temp.parentElement;
		if (temp === null) {
			break;
		}
		dataId = temp.getAttribute(attr);
	}
	return dataId;
};

export const htmlToPlainText = (html: string): string => (html || '').replace(/<[^>]*>/g, '');

export const getDashboardUrl = (domain: string): string => {
	if (domain.indexOf('localhost') > -1 || domain.indexOf('127.0.0.1') > -1) {
		return 'http://localhost:3000/';
	} else if (domain.indexOf('.dev.teaches') > -1) {
		return 'https://dev.teaches.cc/';
	} else if (domain.indexOf('.staging.teaches') > -1) {
		return 'https://staging.teaches.cc/';
	} else {
		return 'https://teaches.cc/';
	}
};

export const checkIsServer = (): boolean => typeof window === 'undefined';

export const isPrivatePageNeedLogin = ({
	isPrivatePage,
	closedWeb,
}: {
	isPrivatePage?: boolean;
	closedWeb: boolean;
}): boolean => {
	/**
		 * isPrivatePage 是後續加的值，沒有設定過的話都為 undefined
		 * 規格:
		 * 預設所有頁面均為「開放頁面」，不需登入即可查看
		 * 預設所有頁面均為「封閉頁面」，需登入才可查看
		 * 所以當 isPrivatePage === undefined 的時候照 closedWeb 的值來判斷
		 * 還有 closedWeb === false 也就是公開學校，就沒辦法設定 PrivatePage
		 */
	const isSignedIn = reactLocalStorage.get('isSignedIn') === 'true';
	const displayPage = isPrivatePage === undefined || !closedWeb ? closedWeb : isPrivatePage;
	return displayPage && !isSignedIn && closedWeb;
};

export const clearQueryString = (url = ''): string => url.split('?')[0];

// if ('userAgent' in navigator) {
// 	return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
// }
// return false;
// 待討論，暫時全回傳 true
export const isSafari = () => true;
