import React from 'react';
import { ThemeButton } from '@src/components/home';
import { Col } from 'antd';
import classStyle from './Promotion.module.less';
import { MyProgressiveImage } from '@src/components';

export default function Promotion(props) {
  const { item } = props;
  const { title, subTitle, image, button, thumbnailLink } = item;

  return (
    <div className={classStyle.wrapper}>
      <MyProgressiveImage
        className={classStyle.image}
        src={image}
        placeholder={thumbnailLink}
      />
      <div className={classStyle.content}>
        <Col sm={{ offset: 13, span: 11 }} xs={24}>
          <div className={classStyle.item}>
            <div className={classStyle.title}>{title}</div>
            <div className={classStyle.subTitle}>{subTitle}</div>
            <ThemeButton button={button} />
          </div>
        </Col>
      </div>
    </div>
  );
}
