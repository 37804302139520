import React from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';

const MyToastContainer = () => (
	<ToastContainer
		transition={Slide}
		pauseOnFocusLoss={false}
		hideProgressBar
		closeButton={false}
		draggable={false}
		autoClose={1000}
		position={toast.POSITION.TOP_CENTER}
	/>
);

export default MyToastContainer;
