/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getCategories = ({ category }) => category.categories;

export const getHierarchy = ({ category }) => category.hierarchy;

export const getCategoriesState = ({ category }) => category.getCategoriesState;

export const getSearch = ({ category }) => category.search;

export const getSearchPagination = ({ category }) => category.searchPagination;

export const getSearchState = ({ category }) => category.getSearchState;

export const getCourseByCategory = ({ category }) => category.courseByCategory;

export const getCommodityPublishedType = ({ category }) => category.commodityPublishedType;
