import React from 'react';
import classStyle from './ButtonComponent.module.less';
import { ThemeButton } from '@src/components/home';

export default class ButtonComponent extends React.Component {
  render() {
    const { item } = this.props;
    const { button } = item;

    return (
      <div className={classStyle.container}>
        <ThemeButton button={button} />
      </div>
    );
  }
}
