import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getShoppingCartProductsState, getShoppingCartProducts } from '@src/store/selectors/cart';
import { STATE_SUCCESS } from '@src/const/common';

import { ShoppingCartIcon } from '@src/components/common/icon';

const PRODUCT_AMOUNT_LIMIT = 99;

const CartEntranceWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	// To make the position of popover be the center of cart entrance.
	width: 40px;
	// To make the position of popover be same as profile box.
	height: 40px;
	cursor: pointer;
`;

const StyledShoppingCartIcon = styled(ShoppingCartIcon)`
	width: 24px;
	height: 24px;
	fill: #676767;
`;

const ProductAmount = styled.div`
	position: absolute;
	bottom: 4px;
	left: 22px;
	width: ${props => (props.overTwoDigits ? '24px' : '18px')};
	height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 18px;
	font-size: 12px;
	color: #FFF;
	background-color: #d0021b;
`;

interface ICartEntrance {
	handleClick?: () => void;
}

const CartEntrance = ({ handleClick }: ICartEntrance): JSX.Element => {
	const cartState = useSelector(getShoppingCartProductsState);
	const cartProducts = useSelector(getShoppingCartProducts);
	const totalProductAmount =
		cartProducts.products_numbers + cartProducts.event_slots_numbers + cartProducts.webinar_slots_numbers;
	const overTwoDigits = totalProductAmount > PRODUCT_AMOUNT_LIMIT;

	return (
		<CartEntranceWrapper onClick={handleClick}>
			<StyledShoppingCartIcon />
			{(cartState === STATE_SUCCESS) && (
				<ProductAmount overTwoDigits={overTwoDigits}>
					{overTwoDigits ? `${PRODUCT_AMOUNT_LIMIT}+` : totalProductAmount}
				</ProductAmount>
			)}
		</CartEntranceWrapper>
	);
};

export default CartEntrance;
