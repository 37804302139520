import { createAction } from 'redux-actions';
import {
	GET_DEPARTMENTS_OWNED_DEPARTMENT_CALL,
	GET_DEPARTMENTS_OWNED_DEPARTMENT_DONE,
	GET_DEPARTMENTS_OWNED_DEPARTMENT_FAIL,
	GET_DEPARTMENTS_LEARNING_RECORDS_CALL,
	GET_DEPARTMENTS_LEARNING_RECORDS_DONE,
	GET_DEPARTMENTS_LEARNING_RECORDS_FAIL,
} from '@src/store/types';

export const getOwnedDepartmentCall = createAction(GET_DEPARTMENTS_OWNED_DEPARTMENT_CALL);
export const getOwnedDepartmentDone = createAction(GET_DEPARTMENTS_OWNED_DEPARTMENT_DONE);
export const getOwnedDepartmentFail = createAction(GET_DEPARTMENTS_OWNED_DEPARTMENT_FAIL);

export const getDepartmentsLearningRecordsCall = createAction(GET_DEPARTMENTS_LEARNING_RECORDS_CALL);
export const getDepartmentsLearningRecordsDone = createAction(GET_DEPARTMENTS_LEARNING_RECORDS_DONE);
export const getDepartmentsLearningRecordsFail = createAction(GET_DEPARTMENTS_LEARNING_RECORDS_FAIL);
