import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation as i18nUseTranslation } from 'react-i18next';

import useTranslation from '@src/hooks/useTranslation';
import Modal from '@src/components/common/modal';
import Footer from '@src/components/common/modalFooter';
import {
	LanguageCHTIcon,
	LanguageCHSIcon,
	LanguageENIcon,
} from '@src/components/common/icon';
import { postUserLanguageCall } from '@src/store/actions/user';
import { LOCALE } from '@src/const/common';

const MODAL_WIDTH = {
	desktop: 288,
	tablet: 288,
};

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const StyledModal = styled(Modal)`
	#modal-body {
		padding-bottom: 20px;
		padding-top: 44px;
	}
`;

const StyledFooter = styled(Footer)`
	.submit-button {
		width: 100%;
	}
	.button-wrapper {
		width: 100%;
	}
`;

const LanguageList = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const LanguageText = styled.div`
	cursor: pointer;
	width: 100%;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: ${props => (props.isActive ? '#3091FD' : '#222222')};
	margin-bottom: 24px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	svg {
		margin-right: 14px;
	}
`;

interface ILanguageSettingModal {
	isActive: boolean;
	handleClose: () => void;
	headerText: string;
	supportedLanguages: string[];
	languagePreference: string;
}

const LanguageSettingModal = ({
	isActive,
	headerText,
	handleClose,
	supportedLanguages,
	languagePreference,
}: ILanguageSettingModal):JSX.Element => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { i18n } = i18nUseTranslation();
	const [language, setLanguage] = useState(languagePreference || i18n.language.toLocaleLowerCase());
	const changeLanguage = (lang) => {
		i18n.changeLanguage(LOCALE[lang]);
	};
	return (
		<StyledModal
			disableScroll={false}
			modalWidth={MODAL_WIDTH}
			isActive={isActive}
			headerText={headerText}
			handleClose={handleClose}
			footer={(
				<StyledFooter
					submitText={t('common:confirm')}
					handleSubmit={() => {
						dispatch(postUserLanguageCall({ language }));
						changeLanguage(language);
						handleClose();
					}}
					showCancel={false}
				/>
			)}
		>
			<ModalContent>
				<LanguageList>
					<LanguageText
						isActive={language === 'zh-CHT' || language === null || language === 'zh-tw'}
						onClick={() => {
							setLanguage('zh-CHT');
						}}
					>
						<LanguageCHTIcon />
						{t('language:zh-CHT')}
					</LanguageText>
					{
						supportedLanguages.indexOf('zh-CHS') > -1 && (
							<LanguageText
								isActive={language === 'zh-CHS' || language === 'zh-cn'}
								onClick={() => {
									setLanguage('zh-CHS');
								}}
							>
								<LanguageCHSIcon />
								{t('language:zh-CHS')}
							</LanguageText>
						)
					}
					{
						supportedLanguages.indexOf('en') > -1 && (
							<LanguageText
								isActive={language === 'en'}
								onClick={() => {
									setLanguage('en');
								}}
							>
								<LanguageENIcon />
								{t('language:en')}
							</LanguageText>
						)
					}
				</LanguageList>
			</ModalContent>
		</StyledModal>
	);
};

export default LanguageSettingModal;
