/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import useTranslation from '@src/hooks/useTranslation';
import dayjs from 'dayjs';

import { formatTimeNumber } from '@src/utils/time';
import useCountdown from '@src/hooks/useCountdown';
import { IColors } from '@src/types/theme';
import { getColorTheme } from '@src/store/selectors/theme';
import { isPublished, isFundraising, isPreparing, formatCourseTimeMinutes } from '@src/utils/course';
import {
	SHOW_BUYER_NUMBER_ALWAYS,
	SHOW_BUYER_NUMBER_MORE_THAN,
} from '@src/const/course';
import FundraisingProgress from './fundraisingProgress';
import { makePercentageValue } from './utils';
import CourseInfo from './courseInfo';
import Price from './price';

const StyledPrice = styled(Price)`
	margin-top: 8px;
`;

const TeacherInfo = styled.div`
	display: flex;
	align-items: center;
	margin-top: 12px;
	color: #797979;
`;

const TeacherAvatar = styled.img`
	border-radius: 100%;
	width: 40px;
	height: 40px;
`;

const TeacherName = styled.div`
	font-size: 14px;
`;

const TeacherDetail = styled.div`
	margin-left: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const Description = styled.div`
	white-space: pre-line;
	margin-top: 12px;
	color: #676767;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;

	@media
		${props => props.theme.device.smTablet},
		${props => props.theme.device.mobile}{
		font-size: 14px;
	}
`;

const StatisticalData = styled.div`
	color: #797979;
	font-size: 12px;
	margin-top: 12px;
`;

const PriceWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
`;

const PreOrderPrice = styled.span`
	color: #797979;
	font-size: 16px;
	margin-right: 8px;
	height: 18px;
	line-height: 18px;
`;

const FundraisingWrapper = styled.div`
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const FundraisingProgressWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const FundraisingLabel = styled.div`
	white-space: nowrap;
	margin-top: 4px;
	font-size: 14px;
	color: #676767;
	& > *:not(:first-child) {
		margin-left: 4px;
	}
`;

const Countdown = styled.div`
	color: #676767;
	margin-bottom: 4px;
`;

const FundraisingSuccessAndPreparing = styled.div`
	margin-bottom: 8px;
	color: #676767;
`;

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	course: any;
}

const CommonContent = ({
	course,
}: IProps): JSX.Element => {
	const {
		type,
		status,
		teacher,
		rate,
		video_duration_seconds,
		is_free_course: isFreeCourse,
		init_price: initPrice,
		subtotal_price: subtotalPrice,
		students,
		highlights,
		total_backers: totalBackers,
		goal_backers: goalBackers,
		fund_end_at: fundEndAt,
		advance_settings: advanceSettings,
	} = course;
	const { t } = useTranslation();
	const courseTimeMinutes = formatCourseTimeMinutes(video_duration_seconds);
	const { colors: { primary, secondary } } = useSelector(getColorTheme) as { colors: IColors };
	const tagColor = status === 'published' ? primary : secondary;
	const courseTime = t('card:course_time', { minutes: courseTimeMinutes });
	const studentAmount = t('card:student_amount', { students });
	const startTime = dayjs(dayjs(new Date())).valueOf();
	const isExpired = dayjs(startTime).isAfter(dayjs(fundEndAt));
	const percentage = makePercentageValue({
		isExpired, totalBackers, goalBackers,
	});
	const { days, hours, minutes, seconds } = isExpired ? {
		days: 0, hours: 0, minutes: 0, seconds: 0,
	} : useCountdown({ endTime: fundEndAt });
	const isCourseFundraising = isFundraising(status);
	const isCoursePreparing = isPreparing(status);

	let showStudents = false;
	const showStudentsType = advanceSettings?.show_buyer_number?.type;
	const showStudentsNumber = advanceSettings?.show_buyer_number?.number;
	if (showStudentsType === SHOW_BUYER_NUMBER_ALWAYS) {
		showStudents = true;
	} else if (showStudentsType === SHOW_BUYER_NUMBER_MORE_THAN && students > showStudentsNumber) {
		showStudents = true;
	}

	return (
		<>
			{(isCourseFundraising || isCoursePreparing) && (
				<FundraisingWrapper>
					<Description>
						{highlights}
					</Description>
					<div className="fundraising__flex-item">
						<PriceWrapper>
							<StyledPrice
								preTextElem={<PreOrderPrice>{t('checkout:pre_order_price')}</PreOrderPrice>}
								status={status}
								color={tagColor}
								initPrice={initPrice}
								subtotalPrice={subtotalPrice}
								subtotalPriceSize={28}
								showInitPrice
							/>
							{!isExpired && (
								<Countdown>
									{`剩 ${formatTimeNumber(days)} 天 ${formatTimeNumber(hours)} 小時 ${formatTimeNumber(minutes)} 分 ${formatTimeNumber(seconds)} 秒`}
								</Countdown>
							)}
							{isExpired && (
								<FundraisingSuccessAndPreparing>{t('course:progress:fundraising_success_and_preparing')}</FundraisingSuccessAndPreparing>
							)}
						</PriceWrapper>
						<FundraisingProgressWrapper>
							<FundraisingProgress
								isFundraisingSuccess
								goalBackers={goalBackers}
								totalBackers={totalBackers}
								progressColor={tagColor}
								progressHeight={12}
							/>
							{
								showStudents && (
									<FundraisingLabel>
										<span>{t('course:progress:fundraising_percentage')}</span>
										<span>{`${percentage}%`}</span>
									</FundraisingLabel>
								)
							}
						</FundraisingProgressWrapper>
					</div>
				</FundraisingWrapper>
			)}
			{isPublished(status) && (
				<FundraisingWrapper>
					<div className="fundraising__flex-item">
						<TeacherInfo>
							<TeacherAvatar src={teacher.cover_link.link} alt="" />
							<TeacherDetail>
								<TeacherName>{teacher.name}</TeacherName>
								<CourseInfo
									type={type}
									status={status}
									rate={rate}
								/>
							</TeacherDetail>
						</TeacherInfo>
						<StatisticalData>{`${courseTime} ${showStudents ? `| ${studentAmount}` : ''}`}</StatisticalData>
					</div>
					<StyledPrice
						color={tagColor}
						isFreeCourse={isFreeCourse}
						initPrice={initPrice}
						subtotalPrice={subtotalPrice}
						subtotalPriceSize={28}
					/>
				</FundraisingWrapper>
			)}
		</>
	);
};

export default CommonContent;
