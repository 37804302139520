import React from 'react';
import { IconComponent } from '@src/icons';
import classStyle from '@src/styles/MyInput.module.less';
import classNames from 'classnames';
import { ErrorMessage } from '@src/components';

class MyInput extends React.PureComponent {
  constructor(props) {
    super(props);
    const { type, textLimit = 100 } = props;

    this.state = {
      hidePassword: type === 'password' || type === 'textPassword',
      inputType: type,
      textLimit,
      showLimit: false,
    };
  }

  renderSuffix = () => {
    const { name } = this.props;
    const { hidePassword } = this.state;
    let suffixIcon;

    switch (name) {
      case 'firstName':
        suffixIcon = 'login_form_man';
        break;
      case 'lastName':
        suffixIcon = 'login_form_man';
        break;
      case 'email':
        suffixIcon = 'login_form_email';
        break;
      case 'newPassword':
        suffixIcon = hidePassword
          ? 'login_form_eye_onsvg'
          : 'login_form_eye_off';
        break;
      case 'reNewPassword':
        suffixIcon = hidePassword
          ? 'login_form_eye_onsvg'
          : 'login_form_eye_off';
        break;
      case 'password':
        suffixIcon = hidePassword
          ? 'login_form_eye_onsvg'
          : 'login_form_eye_off';
        break;
      default:
        break;
    }

    return (
      <div onClick={this.handleClick}>
        <IconComponent size={22} color="#c6c6c6" icon={suffixIcon} />
      </div>
    );
  };

  handleClick = () => {
    const { type } = this.props;

    if (type === 'password' || type === 'textPassword') {
      const { hidePassword } = this.state;
      const newHidePassword = !hidePassword;
      const inputType = newHidePassword ? type : 'text';

      this.setState({
        hidePassword: newHidePassword,
        inputType,
      });
    }
  };

  handleChangeValue = (evt) => {
    const { textLimit } = this.state;
    const { value } = evt.target;
    const { name, onChange } = this.props;

    if (value.length > textLimit) {
      this.setState({
        showLimit: true,
      });

      const limitValue = value.substr(0, textLimit);

      onChange({ name, value: limitValue });
    } else {
      this.setState({
        showLimit: false,
      });

      onChange({ name, value });
    }
  };

  returnMaxLengthItem = () => {
    const { value, maxLength } = this.props;
    const text = `${value.length}/${maxLength}`;

    return <div className={classStyle.maxLengthItem}>{text}</div>;
  };

  hanldeOnBlur = () => {
    const { name, value, onBlur } = this.props;

    if (onBlur) {
      onBlur({ name, value });
    }
  };

  render() {
    const { inputType, showLimit, textLimit } = this.state;
    const {
      t,
      isMargin,
      placeholder,
      value,
      showSuffix,
      autoFocus,
      required,
      minLength,
      showError,
      isCol,
      inputErrorMessage,
      isLast,
      name,
      maxLength,
      className,
      inputItemClassName,
      showMySuffix,
      mySuffix,
      showMaxLengthItem,
      showCheckIcon,
      checkIconSuccess,
      disabled,
      autoComplete,
    } = this.props;
    const showErrorMessage = !!inputErrorMessage || showLimit;
    const containerClassNames = [
      classStyle.container,
      isMargin && classStyle.inputDivMargin,
      isCol && classStyle.inputDivCol,
      className,
    ];
    const classNameInput = [
      classStyle.inputItem,
      (showError || showLimit) && classStyle.inputItemError,
      !showError && !isMargin && isLast && classStyle.inputItemLast,
      disabled && classStyle.disabled,
      inputItemClassName,
    ];
    const showMessageItem = showErrorMessage || showMaxLengthItem;
    const icon = checkIconSuccess ? 'login_form_check' : 'cross';
    const iconColor = checkIconSuccess ? '#02b902' : '#d0021b';
    const inputValue = value || value == '0' ? value : '';
    const isTextPasswordType = inputType === 'textPassword';

    return (
      <div className={classNames(containerClassNames)}>
        <div className={classStyle.inputView}>
          <div className={classNames(...classNameInput)}>
            <input
              name={name}
              className={classNames([
                classStyle.input,
                isTextPasswordType && classStyle.password,
              ])}
              placeholder={placeholder}
              value={inputValue}
              onChange={this.handleChangeValue}
              onBlur={this.hanldeOnBlur}
              autoComplete={autoComplete ? 'on' : 'off'}
              //new-password
              autoFocus={autoFocus}
              type={inputType}
              required={required}
              minLength={minLength}
              maxLength={maxLength}
              disabled={disabled}
            />
            {showSuffix && this.renderSuffix()}
            {showMySuffix && mySuffix}
          </div>
          {showCheckIcon && (
            <div className={classStyle.checkIcon}>
              <IconComponent size={20} icon={icon} color={iconColor} />
            </div>
          )}
        </div>
        {showMessageItem && (
          <div className={classStyle.messageItem}>
            {showLimit ? (
              <ErrorMessage message={t('errors:textLimit', { textLimit })} />
            ) : (
              showErrorMessage && (
                <ErrorMessage message={t(inputErrorMessage)} />
              )
            )}
            {showMaxLengthItem && this.returnMaxLengthItem()}
          </div>
        )}
      </div>
    );
  }
}

export default MyInput;
