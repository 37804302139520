import React from 'react';
import _ from 'lodash';
import HtmlParser from '@src/components/common/htmlParser';
import { Row, Col } from 'antd';
import Image from 'react-image';
import classStyle from './Cards.module.less';

export default function Cards(props) {
	const { item } = props;
	const { list, cardType } = item;

	const renderItem = (item, index) => {
		const { text, image, showImage } = item;
		const md = 24 / list.length;
		const imageClassName =
      cardType?.toLowerCase() === 'square' ?
      	classStyle.imageSquare :
      	classStyle.image;

		return (
			<Col className={classStyle.item} xs={24} md={md} key={`cardItem${index}`}>
				{showImage && <Image className={imageClassName} src={image} />}
				<HtmlParser className={classStyle.textDiv} html={text} />
			</Col>
		);
	};

	return (
		<div className={classStyle.wrapper}>
			<Row
				className={classStyle.cardRow}
				gutter={[15, { xs: 30, sm: 15, md: 0 }]}
			>
				{_.map(list, renderItem)}
			</Row>
		</div>
	);
}
