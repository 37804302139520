import React from 'react';
import IcoMoon from 'react-icomoon';
import selectionIcon from './selection.json';

export const MyIcon = (props) => {
  const { color, size = '100%', icon, className, style, onClick } = props;

  return (
    <IcoMoon
      className={className}
      iconSet={selectionIcon}
      color={color}
      size={size}
      icon={icon}
      style={style}
      onClick={onClick}
    />
  );
};
