import React from 'react';
import styled from 'styled-components';
import useTranslation from '@src/hooks/useTranslation';

import { RedLiveIcon } from '@src/components/common/icon';

const StyledTagContent = styled.div`
	color: #333333;
	display: flex;
	align-items: center;
	font-size: 12px;
	& > svg {
		margin-right: 4px;
	}
`;

const StyledRedLiveIcon = styled(RedLiveIcon)`
	height: 14px;
`;

const TagContent = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<StyledTagContent>
			<StyledRedLiveIcon />
			<span>{t('webinar:webinar')}</span>
		</StyledTagContent>
	);
};

export default TagContent;
