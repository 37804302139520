import { createAction } from 'redux-actions';

import {
	GET_EVENT_CALL,
	GET_EVENT_DONE,
	GET_EVENT_FAIL,
	CLEAN_EVENT_CALL,
	GET_EVENTS_CALL,
	GET_EVENTS_DONE,
	GET_EVENTS_FAIL,
	SET_CURRENT_PRICE,
	SET_EVENT_COUNTDOWN_FOR_SALE,
	GET_TAGS_CALL,
	GET_TAGS_DONE,
	GET_TAGS_FAIL,
	EVENT_INSPECTOR_VERIFY_CALL,
	EVENT_INSPECTOR_VERIFY_DONE,
	EVENT_INSPECTOR_VERIFY_FAIL,
	EVENT_REGISTER_FORM_CHECK_IN_CALL,
	EVENT_REGISTER_FORM_CHECK_IN_DONE,
	EVENT_REGISTER_FORM_CHECK_IN_FAIL,
	DELETE_EVENT_REGISTER_FORM_CHECK_IN_CALL,
	DELETE_EVENT_REGISTER_FORM_CHECK_IN_DONE,
	DELETE_EVENT_REGISTER_FORM_CHECK_IN_FAIL,
	REVERT_DELETE_EVENT_REGISTER_FORM_CHECK_IN_CALL,
	REVERT_DELETE_EVENT_REGISTER_FORM_CHECK_IN_DONE,
	REVERT_DELETE_EVENT_REGISTER_FORM_CHECK_IN_FAIL,
	GET_EVENT_REGISTER_FORM_CALL,
	GET_EVENT_REGISTER_FORM_DONE,
	GET_EVENT_REGISTER_FORM_FAIL,
	POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_CALL,
	POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_DONE,
	POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_FAIL,
	GET_REGISTER_FORM_STATISTICS_CALL,
	GET_REGISTER_FORM_STATISTICS_DONE,
	GET_REGISTER_FORM_STATISTICS_FAIL,
	GET_REGISTER_FORM_CHECK_IN_LIST_CALL,
	GET_REGISTER_FORM_CHECK_IN_LIST_DONE,
	GET_REGISTER_FORM_CHECK_IN_LIST_FAIL,

} from '@src/store/types/event';

export const getEventCall = createAction(GET_EVENT_CALL);
export const getEventDone = createAction(GET_EVENT_DONE);
export const getEventFail = createAction(GET_EVENT_FAIL);
export const cleanEventCall = createAction(CLEAN_EVENT_CALL);

export const setCurrentPrice = createAction(SET_CURRENT_PRICE);

export const getEventsCall = createAction(GET_EVENTS_CALL);
export const getEventsDone = createAction(GET_EVENTS_DONE);
export const getEventsFail = createAction(GET_EVENTS_FAIL);

export const setEventCountdownForSale = createAction(SET_EVENT_COUNTDOWN_FOR_SALE);

export const getTagsCall = createAction(GET_TAGS_CALL);
export const getTagsDone = createAction(GET_TAGS_DONE);
export const getTagsFail = createAction(GET_TAGS_FAIL);

export const eventInspectorVerifyCall = createAction(EVENT_INSPECTOR_VERIFY_CALL);
export const eventInspectorVerifyDone = createAction(EVENT_INSPECTOR_VERIFY_DONE);
export const eventInspectorVerifyFail = createAction(EVENT_INSPECTOR_VERIFY_FAIL);

export const eventRegisterFormCheckInCall = createAction(EVENT_REGISTER_FORM_CHECK_IN_CALL);
export const eventRegisterFormCheckInDone = createAction(EVENT_REGISTER_FORM_CHECK_IN_DONE);
export const eventRegisterFormCheckInFail = createAction(EVENT_REGISTER_FORM_CHECK_IN_FAIL);

export const deleteEventRegisterFormCheckInCall = createAction(DELETE_EVENT_REGISTER_FORM_CHECK_IN_CALL);
export const deleteEventRegisterFormCheckInDone = createAction(DELETE_EVENT_REGISTER_FORM_CHECK_IN_DONE);
export const deleteEventRegisterFormCheckInFail = createAction(DELETE_EVENT_REGISTER_FORM_CHECK_IN_FAIL);

export const revertDeleteEventRegisterFormCheckInCall = createAction(REVERT_DELETE_EVENT_REGISTER_FORM_CHECK_IN_CALL);
export const revertDeleteEventRegisterFormCheckInDone = createAction(REVERT_DELETE_EVENT_REGISTER_FORM_CHECK_IN_DONE);
export const revertDeleteEventRegisterFormCheckInFail = createAction(REVERT_DELETE_EVENT_REGISTER_FORM_CHECK_IN_FAIL);

export const getEventRegisterFormCall = createAction(GET_EVENT_REGISTER_FORM_CALL);
export const getEventRegisterFormDone = createAction(GET_EVENT_REGISTER_FORM_DONE);
export const getEventRegisterFormFail = createAction(GET_EVENT_REGISTER_FORM_FAIL);

export const postEventRegisterFormNotifySettingCall = createAction(POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_CALL);
export const postEventRegisterFormNotifySettingDone = createAction(POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_DONE);
export const postEventRegisterFormNotifySettingFail = createAction(POST_EVENT_REGISTER_FORM_NOTIFY_SETTING_FAIL);

export const getRegisterFormStatisticsCall = createAction(GET_REGISTER_FORM_STATISTICS_CALL);
export const getRegisterFormStatisticsDone = createAction(GET_REGISTER_FORM_STATISTICS_DONE);
export const getRegisterFormStatisticsFail = createAction(GET_REGISTER_FORM_STATISTICS_FAIL);

export const getRegisterFormCheckInListCall = createAction(GET_REGISTER_FORM_CHECK_IN_LIST_CALL);
export const getRegisterFormCheckInListDone = createAction(GET_REGISTER_FORM_CHECK_IN_LIST_DONE);
export const getRegisterFormCheckInListFail = createAction(GET_REGISTER_FORM_CHECK_IN_LIST_FAIL);
