export const GET_SCHOOL_ANNOUNCEMENTS_CALL = 'GET_SCHOOL_ANNOUNCEMENTS_CALL';
export const GET_SCHOOL_ANNOUNCEMENTS_DONE = 'GET_SCHOOL_ANNOUNCEMENTS_DONE';
export const GET_SCHOOL_ANNOUNCEMENTS_FAIL = 'GET_SCHOOL_ANNOUNCEMENTS_FAIL';

export const GET_SCHOOL_ANNOUNCEMENT_DETAIL_CALL = 'GET_SCHOOL_ANNOUNCEMENT_DETAIL_CALL';
export const GET_SCHOOL_ANNOUNCEMENT_DETAIL_DONE = 'GET_SCHOOL_ANNOUNCEMENT_DETAIL_DONE';
export const GET_SCHOOL_ANNOUNCEMENT_DETAIL_FAIL = 'GET_SCHOOL_ANNOUNCEMENT_DETAIL_FAIL';

export const GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_CALL = 'GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_CALL';
export const GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_DONE = 'GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_DONE';
export const GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_FAIL = 'GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_FAIL';

export const POST_SCHOOL_ANNOUNCEMENT_READ_CALL = 'POST_SCHOOL_ANNOUNCEMENT_READ_CALL';
export const POST_SCHOOL_ANNOUNCEMENT_READ_DONE = 'POST_SCHOOL_ANNOUNCEMENT_READ_DONE';
export const POST_SCHOOL_ANNOUNCEMENT_READ_FAIL = 'POST_SCHOOL_ANNOUNCEMENT_READ_FAIL';

export const POST_SCHOOL_ANNOUNCEMENT_READ_ALL_CALL = 'POST_SCHOOL_ANNOUNCEMENT_READ_ALL_CALL';
export const POST_SCHOOL_ANNOUNCEMENT_READ_ALL_DONE = 'POST_SCHOOL_ANNOUNCEMENT_READ_ALL_DONE';
export const POST_SCHOOL_ANNOUNCEMENT_READ_ALL_FAIL = 'POST_SCHOOL_ANNOUNCEMENT_READ_ALL_FAIL';
