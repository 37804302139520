/* eslint-disable react/prop-types */
import React from 'react';
import { Row, Col } from 'antd';
import { handleThemeClick } from '@src/utils/theme';
import { MyProgressiveImage } from '@src/components';
import classStyle from './ImagesComponent.module.less';

const ImagesComponent = props => {
	const { item, pages } = props;
	const { list } = item;

	const handleClick = selectedItem => {
		if (selectedItem?.action?.openType) {
			const { openType, link, paramId } = selectedItem.action;

			handleThemeClick({ openType, link, paramId, pages });
		}
	};

	return (
		<Row className={classStyle.container} gutter={[15, 15]}>
			{
				list.map((listItem, index) => {
					const { image, thumbnailLink } = listItem;
					const itemKey = `${item}_${index}`;

					return (
						<Col
							xs={24}
							md={8}
							key={itemKey}
							onClick={() => handleClick(listItem)}
						>
							<MyProgressiveImage
								className={classStyle.image}
								src={image}
								placeholder={thumbnailLink}
								style={{ cursor: listItem.action?.openType ? 'pointer' : 'default' }}
							/>
						</Col>
					);
				})
			}
		</Row>
	);
};

export default ImagesComponent;
