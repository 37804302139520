import { createAction } from 'redux-actions';
import * as types from '@src/store/types';

export const getSurveysQuestionSheetCall = createAction(types.GET_SURVEYS_QUESTION_SHEET_CALL);
export const getSurveysQuestionSheetDone = createAction(types.GET_SURVEYS_QUESTION_SHEET_DONE);
export const getSurveysQuestionSheetFail = createAction(types.GET_SURVEYS_QUESTION_SHEET_FAIL);

export const postSurveysAnswerSheetCall = createAction(types.POST_SURVEYS_ANSWER_SHEET_CALL);
export const postSurveysAnswerSheetDone = createAction(types.POST_SURVEYS_ANSWER_SHEET_DONE);
export const postSurveysAnswerSheetFail = createAction(types.POST_SURVEYS_ANSWER_SHEET_FAIL);

export const getSurveysAnswerSheetCall = createAction(types.GET_SURVEYS_ANSWER_SHEET_CALL);
export const getSurveysAnswerSheetDone = createAction(types.GET_SURVEYS_ANSWER_SHEET_DONE);
export const getSurveysAnswerSheetFail = createAction(types.GET_SURVEYS_ANSWER_SHEET_FAIL);
