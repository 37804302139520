import React from 'react';
import _ from 'lodash';
import Image from 'react-image';
import { ThemeCarousel } from '@src/components/home';
import HtmlParser from '@src/components/common/htmlParser';
import classStyle from './UserLogo.module.less';

export default class UserLogo extends React.Component {
	handleOnDragStart = e => {
		e.preventDefault();
	};

	renderItem = (item, index) => {
		const { image } = item;

		return (
			<div className={classStyle.item} key={index}>
				<Image
					className={classStyle.image}
					src={image}
					onDragStart={this.handleOnDragStart}
				/>
			</div>
		);
	};

	render() {
		const { item, startIndex } = this.props;
		const { text } = item;

		return (
			<div>
				<div className={classStyle.title}>
					<HtmlParser html={text} />
				</div>
				<ThemeCarousel
					item={item}
					onRenderItem={this.renderItem}
					responsive={{
						0: { items: 2 },
						576: { items: 4 },
						768: { items: 4 },
						992: { items: 6 },
						1200: { items: 6 },
					}}
					startIndex={startIndex}
					infinite
				/>
			</div>
		);
	}
}
