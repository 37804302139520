import { createAction } from 'redux-actions';
import * as types from '@src/store/types';

export const uploadCall = createAction(types.UPLOAD_CALL);
export const uploadDone = createAction(types.UPLOAD_DONE);
export const uploadFail = createAction(types.UPLOAD_FAIL);

export const uploadFileLinkCall = createAction(types.UPLOAD_FILE_LINK_CALL);
export const uploadFileLinkDone = createAction(types.UPLOAD_FILE_LINK_DONE);
export const uploadFileLinkFail = createAction(types.UPLOAD_FILE_LINK_FAIL);

export const getFileVideoUrlCall = createAction(types.GET_FILE_VIDEO_LINK_CALL);
export const getFileVideoUrlDone = createAction(types.GET_FILE_VIDEO_LINK_DONE);
export const getFileVideoUrlFail = createAction(types.GET_FILE_VIDEO_LINK_FAIL);
