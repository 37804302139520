import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { formatTimeFloor } from '@src/utils/time';

interface IReturn {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
}

interface ITimer {
	endTime?: number;
	startTime?: number;
}

const useCountdown = ({ endTime, startTime }: ITimer): IReturn => {
	const endTimeSeconds = dayjs(endTime || dayjs(new Date())).valueOf();
	const startTimeSeconds = dayjs(startTime || dayjs(new Date())).valueOf();
	const remainingSeconds = endTimeSeconds - startTimeSeconds;
	const [milliseconds, setＭilliseconds] = useState(remainingSeconds);
	const time = formatTimeFloor(milliseconds);

	useEffect(() => {
		const interval = setInterval(() => {
			if (milliseconds <= 0) {
				clearInterval(interval);
			} else {
				setＭilliseconds(seconds => seconds - 1000);
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return {
		days: time.days,
		hours: time.hours,
		minutes: time.minutes,
		seconds: time.seconds,
	};
};

export default useCountdown;
