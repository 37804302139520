import React from 'react';
import styled from 'styled-components';

const FundraisingProgressWrapper = styled.div`
	position: relative;
	width: 100%;
`;

const ProgressBackground = styled.div`
	width: 100%;
	border-radius: 50px;
	background: #ECECEC;
	height: ${props => props.progressHeight}px;
`;

const ProgressBar = styled.div`
	position: absolute;
	width: ${props => props.percentage}%;
	border-radius: 50px;
	background: ${props => props.progressColor};
	background: linear-gradient(to right, #ECECEC, ${props => props.progressColor});
	height: ${props => props.progressHeight}px;
	top: 0px;
`;

interface IProps {
	isFundraisingSuccess?: boolean;
	goalBackers: number;
	totalBackers: number;
	progressColor: string;
	progressHeight?: number;
}

const FundraisingProgress = ({
	isFundraisingSuccess, goalBackers, totalBackers, progressColor, progressHeight = 8,
}: IProps): JSX.Element => {
	const percentage = isFundraisingSuccess ? 100 : Number((totalBackers / goalBackers).toFixed(2)) * 100;

	return (
		<FundraisingProgressWrapper>
			<ProgressBackground progressHeight={progressHeight} />
			<ProgressBar
				percentage={percentage > 100 ? 100 : percentage}
				progressColor={progressColor}
				progressHeight={progressHeight}
			/>
		</FundraisingProgressWrapper>
	);
};

export default FundraisingProgress;
