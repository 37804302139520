import React from 'react';
import styled from 'styled-components';

import useTranslation from '@src/hooks/useTranslation';
import config from '@src/config';

import { RowWrapper } from './hr/punchIn/styles';

const FooterContent = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 16px 20px;
`;

const ReservedText = styled.div`
	font-size: 12px;
	color: #676767;
`;

const BleakText = styled.div`
	font-size: 14px;
	:hover {
		cursor: pointer;
		color: #222;
		font-weight: 500;
	}
`;

const Divider = styled.div`
	width: 1px;
	height: 16px;
	background: #DDD;
	margin: 0 12px;
`;

const SideMenuFooter = (): JSX.Element => {
	const { t } = useTranslation();
	return (
		<FooterContent>
			<ReservedText>
				{t('all:reserved', { version: config.version })}
			</ReservedText>
			<RowWrapper>
				<BleakText
					onClick={() => window.open('https://teaches.cc/policies', '_blank')}
				>
					{t('all:service')}
				</BleakText>
				<Divider />
				<BleakText
					onClick={() => window.open('https://teaches.cc/privacy', '_blank')}
				>
					{t('all:policy')}
				</BleakText>
				<Divider />
				<BleakText
					onClick={() => window.open('https://teaches.cc/contact', '_blank')}
				>
					{t('all:contact_us')}
				</BleakText>
			</RowWrapper>
		</FooterContent>
	);
};

export default SideMenuFooter;
