import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import useTranslation from '@src/hooks/useTranslation';
import dayjs from 'dayjs';

import { IColors } from '@src/types/theme';
import { getColorTheme } from '@src/store/selectors/theme';
import { isPublished, isFundraising, isPreparing, formatCourseTimeMinutes } from '@src/utils/course';
import FundraisingProgress from './fundraisingProgress';

import CourseInfo from './courseInfo';
import Price from './price';
import { makePercentageValue } from './utils';

const StyledPrice = styled(Price)`
	margin-top: 8px;
`;

const TeacherInfo = styled.div`
	display: flex;
	align-items: center;
	margin-top: 12px;
	color: #797979;
`;

const TeacherAvatar = styled.img`
	border-radius: 100%;
	width: 40px;
	height: 40px;
`;

const TeacherName = styled.div`
	font-size: 14px;
`;

const TeacherDetail = styled.div`
	margin-left: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const Description = styled.div`
	white-space: pre-line;
	margin-top: 12px;
	color: #676767;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;

	@media
		${props => props.theme.device.smTablet},
		${props => props.theme.device.mobile}{
		font-size: 14px;
	}
`;

const StatisticalData = styled.div`
	color: #797979;
	font-size: 12px;
	margin-top: 12px;
`;

const PriceWrapper = styled.div`
	display: flex;
	align-items: flex-end;
`;

const PreOrderPrice = styled.span`
	color: #797979;
	font-size: 12px;
	margin-right: 8px;
	height: 18px;
	line-height: 18px;
`;

const FundraisingWrapper = styled.div`
	width: 100%;
`;

const FundraisingProgressWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const FundraisingLabel = styled.div`
	white-space: nowrap;
	margin-left: 4px;
	font-size: 14px;
	color: #676767;
`;

interface IProps {
	course: any;
}

const CommonContent = ({
	course,
}: IProps): JSX.Element => {
	const {
		type,
		status,
		teacher,
		rate,
		video_duration_seconds,
		is_free_course: isFreeCourse,
		init_price: initPrice,
		subtotal_price: subtotalPrice,
		students,
		highlights,
		total_backers: totalBackers,
		goal_backers: goalBackers,
		fund_end_at: fundEndAt,
	} = course;
	const { t } = useTranslation();
	const courseTimeMinutes = formatCourseTimeMinutes(video_duration_seconds);
	const { colors: { primary, secondary } } = useSelector(getColorTheme) as { colors: IColors };
	const tagColor = status === 'published' ? primary : secondary;
	const courseTime = t('card:course_time', { minutes: courseTimeMinutes });
	const studentAmount = t('card:student_amount', { students });
	const startTime = dayjs(dayjs(new Date())).valueOf();
	const isExpired = dayjs(startTime).isAfter(dayjs(fundEndAt));
	const percentage = makePercentageValue({
		isExpired, totalBackers, goalBackers,
	});

	return (
		<>
			{(isFundraising(status) || isPreparing(status)) && (
				<FundraisingWrapper>
					<Description>
						{highlights}
					</Description>
					<PriceWrapper>
						<StyledPrice
							preTextElem={<PreOrderPrice>{t('checkout:pre_order_price')}</PreOrderPrice>}
							status={status}
							color={tagColor}
							initPrice={initPrice}
							subtotalPrice={subtotalPrice}
						/>
					</PriceWrapper>
					<FundraisingProgressWrapper>
						<FundraisingProgress
							isFundraisingSuccess
							goalBackers={goalBackers}
							totalBackers={totalBackers}
							progressColor={tagColor}
						/>
						<FundraisingLabel>
							<span>{t('course:progress:fundraising_percentage')}</span>
							<span>{`${percentage}%`}</span>
						</FundraisingLabel>
					</FundraisingProgressWrapper>
				</FundraisingWrapper>
			)}
			{isPublished(status) && (
				<>
					<TeacherInfo>
						<TeacherAvatar src={teacher.cover_link.link} alt="" />
						<TeacherDetail>
							<TeacherName>{teacher.name}</TeacherName>
							<CourseInfo
								type={type}
								status={status}
								rate={rate}
							/>
						</TeacherDetail>
					</TeacherInfo>
					<StatisticalData>{`${courseTime} | ${studentAmount}`}</StatisticalData>
					<StyledPrice
						color={tagColor}
						isFreeCourse={isFreeCourse}
						initPrice={initPrice}
						subtotalPrice={subtotalPrice}
					/>
				</>
			)}
		</>
	);
};

export default CommonContent;
