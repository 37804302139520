import React from 'react';
import styled from 'styled-components';

interface IStyledLabel {
	isRequired: boolean;
}

const Label = styled.label.attrs({ className: 'label' })`
	position: relative;
	margin-bottom: 4px;
	font-size: 14px;
	font-weight: 400;
	color: #333333;

	:after {
		content: "*";
		position: absolute;
		top: 0;
		display: ${(props: IStyledLabel) => (props.isRequired ? 'inline-block' : 'none')};
		padding-left: 2px;
		margin-right: 4px;
		font-size: 14px;
		color: #D0021B;
	}
`;

interface ILabel {
	children: string | JSX.Element;
	htmlFor?: string;
	isRequired?: boolean;
	className?: string;
}

const InputLabel = ({ children, htmlFor, isRequired = false, className }: ILabel):JSX.Element => (
	<Label
		htmlFor={htmlFor}
		isRequired={isRequired}
		className={className}
	>
		{children}
	</Label>
);

export default InputLabel;
