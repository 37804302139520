/* eslint-disable max-len */
import { createAction } from 'redux-actions';

import {
	GET_MAKE_UP_PUNCH_APPLICATIONS_CALL,
	GET_MAKE_UP_PUNCH_APPLICATIONS_DONE,
	GET_MAKE_UP_PUNCH_APPLICATIONS_FAIL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_CALL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_DONE,
	POST_MAKE_UP_PUNCH_APPLICATIONS_FAIL,
	GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_CALL,
	GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_DONE,
	GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_FAIL,
	GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_CALL,
	GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_DONE,
	GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_FAIL,
	GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_CALL,
	GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_DONE,
	GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_FAIL,
	RESET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_CALL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_DONE,
	POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_FAIL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_CALL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_DONE,
	POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_FAIL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_CALL,
	POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_DONE,
	POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_FAIL,
} from '@src/store/types';

export const getMakeUpPunchApplicationsCall = createAction(GET_MAKE_UP_PUNCH_APPLICATIONS_CALL);
export const getMakeUpPunchApplicationsDone = createAction(GET_MAKE_UP_PUNCH_APPLICATIONS_DONE);
export const getMakeUpPunchApplicationsFail = createAction(GET_MAKE_UP_PUNCH_APPLICATIONS_FAIL);
export const postMakeUpPunchApplicationsCall = createAction(POST_MAKE_UP_PUNCH_APPLICATIONS_CALL);
export const postMakeUpPunchApplicationsDone = createAction(POST_MAKE_UP_PUNCH_APPLICATIONS_DONE);
export const postMakeUpPunchApplicationsFail = createAction(POST_MAKE_UP_PUNCH_APPLICATIONS_FAIL);
export const getMakeUpPunchApplicationsApproverViewCall = createAction(GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_CALL);
export const getMakeUpPunchApplicationsApproverViewDone = createAction(GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_DONE);
export const getMakeUpPunchApplicationsApproverViewFail = createAction(GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_FAIL);
export const getMakeUpPunchApplicationsPunchOptionsCall = createAction(GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_CALL);
export const getMakeUpPunchApplicationsPunchOptionsDone = createAction(GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_DONE);
export const getMakeUpPunchApplicationsPunchOptionsFail = createAction(GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_FAIL);
export const getMakeUpPunchApplicationsDetailCall = createAction(GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_CALL);
export const getMakeUpPunchApplicationsDetailDone = createAction(GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_DONE);
export const getMakeUpPunchApplicationsDetailFail = createAction(GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_FAIL);
export const resetMakeUpPunchApplicationsDetail = createAction(RESET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL);
export const postMakeUpPunchApplicationsApproveCall = createAction(POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_CALL);
export const postMakeUpPunchApplicationsApproveDone = createAction(POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_DONE);
export const postMakeUpPunchApplicationsApproveFail = createAction(POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_FAIL);
export const postMakeUpPunchApplicationsWithdrawCall = createAction(POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_CALL);
export const postMakeUpPunchApplicationsWithdrawDone = createAction(POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_DONE);
export const postMakeUpPunchApplicationsWithdrawFail = createAction(POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_FAIL);
export const postMakeUpPunchApplicationsWithdrawApprovalCall = createAction(POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_CALL);
export const postMakeUpPunchApplicationsWithdrawApprovalDone = createAction(POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_DONE);
export const postMakeUpPunchApplicationsWithdrawApprovalFail = createAction(POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_FAIL);
