import React from 'react';
import PropTypes from 'prop-types';
import { Select as AntdSelect } from 'antd';
import cx from 'classnames';
import classes from './styles.module.less';

const { Option, OptGroup } = AntdSelect;

function renderCustomOption(option, index) {
  return (
    <Option key={index} value={option.value}>
      {option.iconImg && (
        <img className={classes.icon} src={option.iconImg} alt={option.label} />
      )}
      {option.label}
    </Option>
  );
}

function Select(props) {
  const { options, value, onChange, className, ...otherProps } = props;

  return (
    <AntdSelect
      value={value}
      defaultValue={value}
      className={cx(classes.selectBox, className)}
      onChange={onChange}
      getPopupContainer={(trigger) => trigger.parentNode}
      virtual={false}
      {...otherProps}
    >
      {options.map((option, index) => {
        if (option.type === 'group') {
          return (
            <OptGroup key={index} label={option.label}>
              {option.optionList.map(renderCustomOption)}
            </OptGroup>
          );
        }

        return renderCustomOption(option, index);
      })}
    </AntdSelect>
  );
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['group', 'option']),
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      iconImg: PropTypes.string,
      optionList: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          iconImg: PropTypes.string,
        })
      ),
    }).isRequired
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
};

export default Select;
