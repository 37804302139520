import { createAction } from 'redux-actions';
import * as types from '@src/store/types';

export const getSchoolsCall = createAction(types.GET_SCHOOLS_CALL);
export const getSchoolsDone = createAction(types.GET_SCHOOLS_DONE);
export const getSchoolsFail = createAction(types.GET_SCHOOLS_FAIL);

export const addSchoolCall = createAction(types.ADD_SCHOOL_CALL);
export const addSchoolDone = createAction(types.ADD_SCHOOL_DONE);
export const addSchoolFail = createAction(types.ADD_SCHOOL_FAIL);

export const getSchoolPlansCall = createAction(types.GET_SCHOOL_PLANS_CALL);
export const getSchoolPlansDone = createAction(types.GET_SCHOOL_PLANS_DONE);
export const getSchoolPlansFail = createAction(types.GET_SCHOOL_PLANS_FAIL);

export const checkHostNameExistCall = createAction(types.CHECK_HOST_NAME_EXIST_CALL);
export const checkHostNameExistDone = createAction(types.CHECK_HOST_NAME_EXIST_DONE);
export const checkHostNameExistFail = createAction(types.CHECK_HOST_NAME_EXIST_FAIL);

export const setSchoolPlan = createAction(types.SET_SCHOOL_PLAN);
export const setHostNameSuccess = createAction(types.SET_HOST_NAME_SUCCESS);
export const setSchoolId = createAction(types.SET_SCHOOL_ID);

export const getTeachersCall = createAction(types.GET_TEACHERS_CALL);
export const getTeachersDone = createAction(types.GET_TEACHERS_DONE);
export const getTeachersFail = createAction(types.GET_TEACHERS_FAIL);

export const addTeacherCall = createAction(types.ADD_TEACHER_CALL);
export const addTeacherDone = createAction(types.ADD_TEACHER_DONE);
export const addTeacherFail = createAction(types.ADD_TEACHER_FAIL);

export const updateTeacherCall = createAction(types.UPDATE_TEACHER_CALL);
export const updateTeacherDone = createAction(types.UPDATE_TEACHER_DONE);
export const updateTeacherFail = createAction(types.UPDATE_TEACHER_FAIL);

export const deleteTeacherCall = createAction(types.DELETE_TEACHER_CALL);
export const deleteTeacherDone = createAction(types.DELETE_TEACHER_DONE);
export const deleteTeacherFail = createAction(types.DELETE_TEACHER_FAIL);

// 取得所有註冊欄位
export const getRegisterFieldsCall = createAction(types.GET_REGISTER_FIELDS_CALL);
export const getRegisterFieldsDone = createAction(types.GET_REGISTER_FIELDS_DONE);
export const getRegisterFieldsFail = createAction(types.GET_REGISTER_FIELDS_FAIL);

export const getSchoolSearchCall = createAction(types.GET_SCHOOL_SEARCH_CALL);
export const getSchoolSearchDone = createAction(types.GET_SCHOOL_SEARCH_DONE);
export const getSchoolSearchFail = createAction(types.GET_SCHOOL_SEARCH_FAIL);
