export const GET_COURSE_HOMEWORKS_CALL = 'GET_COURSE_HOMEWORKS_CALL';
export const GET_COURSE_HOMEWORKS_DONE = 'GET_COURSE_HOMEWORKS_DONE';
export const GET_COURSE_HOMEWORKS_FAIL = 'GET_COURSE_HOMEWORKS_FAIL';

export const ADD_COURSE_HOMEWORK_CALL = 'ADD_COURSE_HOMEWORK_CALL';
export const ADD_COURSE_HOMEWORK_DONE = 'ADD_COURSE_HOMEWORK_DONE';
export const ADD_COURSE_HOMEWORK_FAIL = 'ADD_COURSE_HOMEWORK_FAIL';

export const UPDATE_COURSE_HOMEWORK_CALL = 'UPDATE_COURSE_HOMEWORK_CALL';
export const UPDATE_COURSE_HOMEWORK_DONE = 'UPDATE_COURSE_HOMEWORK_DONE';
export const UPDATE_COURSE_HOMEWORK_FAIL = 'UPDATE_COURSE_HOMEWORK_FAIL';

export const GET_COURSE_HOMEWORK_DETAIL_CALL =
  'GET_COURSE_HOMEWORK_DETAIL_CALL';
export const GET_COURSE_HOMEWORK_DETAIL_DONE =
  'GET_COURSE_HOMEWORK_DETAIL_DONE';
export const GET_COURSE_HOMEWORK_DETAIL_FAIL =
  'GET_COURSE_HOMEWORK_DETAIL_FAIL';

export const GET_COURSE_HOMEWORK_REPLIES_CALL =
  'GET_COURSE_HOMEWORK_REPLIES_CALL';
export const GET_COURSE_HOMEWORK_REPLIES_DONE =
  'GET_COURSE_HOMEWORK_REPLIES_DONE';
export const GET_COURSE_HOMEWORK_REPLIES_FAIL =
  'GET_COURSE_HOMEWORK_REPLIES_FAIL';

export const ADD_COURSE_HOMEWORK_REPLY_CALL = 'ADD_COURSE_HOMEWORK_REPLY_CALL';
export const ADD_COURSE_HOMEWORK_REPLY_DONE = 'ADD_COURSE_HOMEWORK_REPLY_DONE';
export const ADD_COURSE_HOMEWORK_REPLY_FAIL = 'ADD_COURSE_HOMEWORK_REPLY_FAIL';
export const ADD_COURSE_HOMEWORK_REPLY_RESET =
  'ADD_COURSE_HOMEWORK_REPLY_RESET';
