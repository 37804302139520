import React from 'react';
import useTranslation from '@src/hooks/useTranslation';
import Img from 'react-image';
import { useDispatch, useSelector } from 'react-redux';
import { historyPushCall } from '@src/store/actions';
// import MyButton from '@src/components/myButton';
import { Row, Col } from 'antd';
import * as selectors from '@src/store/selectors';
import { find } from 'lodash';
import styled, { ThemeProvider } from 'styled-components';

import { colorThemes } from '@src/const/theme';
import device from '@src/styles/breakpoint';
import img from '@src/assets/images/noCourse.svg';

import { Wrapper } from './Styled';

const StyledButton = styled.div`

	background: ${props => props.theme.colors.primary};
	color: #FFFFFF;
	border-radius: 4px;
	padding: 12px 20px;
	cursor: pointer;
`;

const NoCoursePage = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { themes } = useSelector(selectors.getThemeSelector);
	const handleBackHome = () => {
		dispatch(historyPushCall('/'));
	};

	const themeName = themes.color_theme;
	const selectedTheme = find(colorThemes, theme => theme.id === themeName) || colorThemes[0];
	const themeObj = {
		device,
		colors: selectedTheme?.colors,
	};

	return (
		<ThemeProvider theme={themeObj}>
			<Wrapper>
				<Row gutter={[50, 50]} justify="center" align="middle">
					<Col md={6} sm={24} xs={24}>
						<div className="img-box">
							<Img className="img" src={img} />
						</div>
					</Col>
					<Col md={18} sm={24} xs={24}>
						<div className="text">
							<div className="title">喔喔，此頁面已不存在</div>
							<div className="sub-title">若有疑問請洽網站管理員</div>
							<StyledButton
								role="presentation"
								onClick={handleBackHome}
							>
								{t('errors:backHome')}
							</StyledButton>
						</div>
					</Col>
				</Row>
			</Wrapper>
		</ThemeProvider>
	);
};

export default NoCoursePage;
