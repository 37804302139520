import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import useTranslation from '@src/hooks/useTranslation';

import { getEventPriceRange, getTicketStatus } from '@src/utils/event';
import { IColors } from '@src/types/theme';
import { getColorTheme } from '@src/store/selectors/theme';
import { CURRENCY_SYMBOL } from '@src/const/common';
import {
	HostBy,
	PriceRange,
} from '@src/components/card/style';
import InfoByStatus from '@src/components/card/infoByStatus';
import { LargeCard as BasicLargeCard } from '@src/components/card/basic';
import { getPrice } from '@src/components/card/utils';
import { getIsPriceVisible } from '@src/utils/hardcode';

const StyledInfoByStatus = styled(InfoByStatus)`
	font-size: 16px;
`;

const StyledHostBy = styled(HostBy)`
	margin-top: 24px;
	font-size: 16px;
`;

const StyledPriceRange = styled(PriceRange)`
	margin-top: 18px;
	color: ${props => props.theme.colors.primary};

	.price-range__number {
		font-size: 28px;
		font-weight: 500;
	}
`;

interface IProps {
	onClick: () => void;
	event: any;
}

const LargeCard = ({
	onClick,
	event,
}: IProps): JSX.Element => {
	const { t } = useTranslation();
	const { id: eventId } = event || {};
	const isPriceVisible = getIsPriceVisible(eventId);
	const {
		name: title,
		host_by: hostBy,
		photos,
		slots,
	} = event;
	const photoLinks = photos.map(photo => photo.link);
	const { status, countDownTime } = getTicketStatus({ slots });
	let price = { minPrice: 0, maxPrice: 0 };
	if (event.slots.length) {
		price = getEventPriceRange(event.slots);
	}
	const { minPrice, maxPrice } = price;
	const { colors: { primary } } = useSelector(getColorTheme) as { colors: IColors };
	const isFree = (minPrice === maxPrice) && minPrice === 0;

	return (
		<BasicLargeCard
			onClick={onClick}
			title={title}
			tagText={t('event:event')}
			tagColor={primary}
			imageUrl={photoLinks[0]}
			imageAlt={photos[0]?.alt_title}
			contentElem={(
				<>
					<StyledHostBy>{hostBy}</StyledHostBy>
					<StyledInfoByStatus status={status} countDownTime={countDownTime} slots={slots} />
					<StyledPriceRange>
						{
							isPriceVisible ? (
								<>
									{!isFree && <span className="price-range__currency">{CURRENCY_SYMBOL}</span>}
									<span className="price-range__number">
										{getPrice({ minPrice, maxPrice })}
									</span>
								</>
							) : (
								<>{t('common:consultation')}</>
							)
						}
					</StyledPriceRange>
				</>
			)}
		/>
	);
};

export default LargeCard;
