// import React from 'react';
// import useTranslation from '@src/hooks/useTranslation';
// import Img from 'react-image';
// import { useDispatch } from 'react-redux';

// import classStyle from '@src/styles/NotMatchPage.module.less';
// import { historyPushCall } from '@src/store/actions';

// const img = require('@src/assets/images/404.svg');

// const NotMatchPage = () => {
//   const { t } = useTranslation();
//   const dispatch = useDispatch();
//   const handleBackHome = () => {
//     dispatch(historyPushCall('/'));
//   };

//   return (
//     <div className={classStyle.container}>
//       <Img className={classStyle.img} src={img} />
//       <div className={classStyle.button} onClick={handleBackHome}>
//         {t('errors:backHome')}
//       </div>
//     </div>
//   );
// };

// export default NotMatchPage;

import NoCoursePage from '@src/pages/noCoursePage/NoCoursePage';

export default NoCoursePage;
