export const ADD_COURSE_DISCUSSION_CALL = 'ADD_COURSE_DISCUSSION_CALL';
export const ADD_COURSE_DISCUSSION_DONE = 'ADD_COURSE_DISCUSSION_DONE';
export const ADD_COURSE_DISCUSSION_FAIL = 'ADD_COURSE_DISCUSSION_FAIL';
export const ADD_COURSE_DISCUSSION_RESET = 'ADD_COURSE_DISCUSSION_RESET';

export const ADD_COURSE_DISCUSSION_REPLY_CALL =
	'ADD_COURSE_DISCUSSION_REPLY_CALL';
export const ADD_COURSE_DISCUSSION_REPLY_DONE =
	'ADD_COURSE_DISCUSSION_REPLY_DONE';
export const ADD_COURSE_DISCUSSION_REPLY_FAIL =
	'ADD_COURSE_DISCUSSION_REPLY_FAIL';
export const ADD_COURSE_DISCUSSION_REPLY_RESET =
	'ADD_COURSE_DISCUSSION_REPLY_RESET';

export const GET_COURSE_DISCUSSIONS_BEFORE_CALL =
	'GET_COURSE_DISCUSSIONS_BEFORE_CALL';
export const GET_COURSE_DISCUSSIONS_BEFORE_DONE =
	'GET_COURSE_DISCUSSIONS_BEFORE_DONE';
export const GET_COURSE_DISCUSSIONS_BEFORE_FAIL =
	'GET_COURSE_DISCUSSIONS_BEFORE_FAIL';

export const ADD_COURSE_DISCUSSIONS_BEFORE_CALL =
	'ADD_COURSE_DISCUSSIONS_BEFORE_CALL';
export const ADD_COURSE_DISCUSSIONS_BEFORE_DONE =
	'ADD_COURSE_DISCUSSIONS_BEFORE_DONE';
export const ADD_COURSE_DISCUSSIONS_BEFORE_FAIL =
	'ADD_COURSE_DISCUSSIONS_BEFORE_FAIL';

export const GET_COURSE_DISCUSSIONS_CALL = 'GET_COURSE_DISCUSSIONS_CALL';
export const GET_COURSE_DISCUSSIONS_DONE = 'GET_COURSE_DISCUSSIONS_DONE';
export const GET_COURSE_DISCUSSIONS_FAIL = 'GET_COURSE_DISCUSSIONS_FAIL';
