import { some, flatten } from 'lodash';
import dayjs from 'dayjs';
import NP from 'number-precision';

import {
	COURSE_STATUS_PUBLISHED,
	COURSE_STATUS_FUNDRAISING,
	COURSE_STATUS_FUNDRAISING_FAILED,
	COURSE_STATUS_FUNDRAISING_REFUNDING,
	COURSE_STATUS_FUNDRAISING_REFUNDED,
	COURSE_STATUS_PREPARING,
	COURSE_STATUS_DISABLED,
	COURSE_UNIT_TYPE_HOMEWORK,
	LEARNING_ORDER_STEP_BY_STEP,
	SHOW_BUYER_NUMBER_ALWAYS,
	SHOW_BUYER_NUMBER_MORE_THAN,
	ACCESS_RIGHT_ALWAYS,
	ACCESS_RIGHT_DAYS,
	ACCESS_RIGHT_DATE,
} from '@src/const/course';

import { IChapter, ICourse } from '@src/types/course';

export const isPublished = (status: string): boolean => status === COURSE_STATUS_PUBLISHED;
export const isFundraising = (status: string): boolean => status === COURSE_STATUS_FUNDRAISING;
export const isFundraisingFailed = (status: string): boolean => status === COURSE_STATUS_FUNDRAISING_FAILED;
export const isFundraisingRefund = (status: string): boolean => (
	(status === COURSE_STATUS_FUNDRAISING_REFUNDING) || (status === COURSE_STATUS_FUNDRAISING_REFUNDED)
);
export const isPreparing = (status: string): boolean => status === COURSE_STATUS_PREPARING;
export const isDiscontinued = (status: string): boolean => status === COURSE_STATUS_DISABLED;

export const isLearnOrderStepByStep = (type: string): boolean => type === LEARNING_ORDER_STEP_BY_STEP;

export const formatCourseTimeMinutes = (time: number): number => (time ? Math.ceil(time / 60) : 0);

export const formatCourseStartTime = (value: number): string => dayjs(value).format('YYYY-MM-DD');

export const hasHomeworks = (chapters: IChapter[]): boolean => (
	some(chapters, chapter => (
		chapter.units.some(unit => unit.type === COURSE_UNIT_TYPE_HOMEWORK)
	))
);

export const isCompleted = (value: string): boolean => (parseFloat(value) * 100).toFixed() === '100';

export const formatViews = (value: number): number | string => {
	if (value > 1000000000000) {
		return `${(value / 1000000000000).toFixed()}T`;
	}

	if (value > 1000000000) {
		return `${(value / 1000000000).toFixed()}B`;
	}

	if (value > 1000000) {
		return `${(value / 1000000).toFixed()}M`;
	}

	if (value > 1000) {
		return `${(value / 1000).toFixed()}K`;
	}

	return value;
};

export const calcRatingScore = ({ totalScore, totalVotes }: {
	totalScore: string;
	totalVotes: number;
}): {
	votes: number;
	score: number | string;
} => {
	let averageScore = NP.divide(totalScore, totalVotes);
	if (totalVotes === 0) averageScore = 5;
	return {
		votes: totalVotes || 0,
		score: (averageScore || 0).toFixed(1),
	};
};

const findChapter = (chapterList = [], unitId) => (
	chapterList.find(chapter => chapter.units.find(unit => unit.id === unitId))
);

export const getNextHomeworkLink = (currentCourse: ICourse): string => {
	const allUnits = flatten(currentCourse.chapters.map(chapter => chapter.units));
	const hasUnsubmittedHW = allUnits.find(({ type, submitted }) => !!(
		(type === COURSE_UNIT_TYPE_HOMEWORK) && !submitted
	));

	const HWID = allUnits.find(({ type, submitted }) => (
		hasUnsubmittedHW ?
			((type === COURSE_UNIT_TYPE_HOMEWORK) && !submitted) :
			(type === COURSE_UNIT_TYPE_HOMEWORK)
	)).id;

	const chapter = findChapter(currentCourse.chapters, HWID);

	return `/my_courses/${currentCourse.short_link}/${chapter.id}/${HWID}`;
};

export const showStudent = ({ advanceSettings, students }: {
	advanceSettings: {
		show_buyer_number: {
			type: string;
			number: number;
		};
	};
	students: number;
}): boolean => {
	const showStudentsType = advanceSettings?.show_buyer_number?.type;
	const showStudentsNumber = advanceSettings?.show_buyer_number?.number;
	let showStudents = false;
	if (showStudentsType === SHOW_BUYER_NUMBER_ALWAYS) {
		showStudents = true;
	} else if (showStudentsType === SHOW_BUYER_NUMBER_MORE_THAN && students > showStudentsNumber) {
		showStudents = true;
	}
	return showStudents;
};

export const accessRightText = ({ advanceSettings, t }: {
	advanceSettings: {
		access_right: {
			type: string;
			valid_days?: number;
			valid_until?: string;
		};
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	t: any;
}): string => {
	if (advanceSettings?.access_right?.type === ACCESS_RIGHT_ALWAYS) {
		return t('course:view_limited:unlimited');
	} else if (advanceSettings?.access_right?.type === ACCESS_RIGHT_DAYS) {
		return t('course:view_limited:unlimited_days', { days: advanceSettings?.access_right?.valid_days });
	} else if (advanceSettings?.access_right?.type === ACCESS_RIGHT_DATE) {
		return t('course:view_limited:unlimited_date', {
			date: dayjs(advanceSettings?.access_right?.valid_until).format('YYYY-MM-DD'),
		});
	}
	return t('course:view_limited:unlimited');
};
