import styled from 'styled-components';
import { Pagination } from 'antd';

export const StyledPagination = styled(Pagination)`
	padding-top: 20px !important;
	font-size: 16px;
	.ant-table-pagination {
		display: flex;
		align-items: center;
	}

	.ant-select-selector {
		height: 32px !important;
	}

	.ant-select-selection-item {
		height: 32px;
		display: flex;
		align-items: center;
	}

	.ant-pagination-item-active {
		background: #3091FD;
		& > a {
			color: #FFFFFF;
		}
	}
`;
