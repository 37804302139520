import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import * as actionCreators from '@src/store/actions';
import { getThemeSelector } from '@src/store/selectors';
import { handleThemeClick } from '@src/utils/theme';
import { GlobeIcon } from '@src/components/common/icon';
import classStyle from './Footer.module.less';
import CopyRight from './CopyRight';
import PoweredBy from './PoweredBy';
import LanguageSettingModal from './languageSettingModal';

const LanguageWrapper = styled.div`
	padding-left: 24px;
`;

const StyleGlobeIcon = styled(GlobeIcon)`
	margin-right: 5px;
	position: absolute;
`;

class Footer extends React.Component {
	state = {
    modalStatus: false,
  };

	handleItemClick = item => {
		const { pages } = this.props;
		const { openType, link, paramId } = item;

		handleThemeClick({ openType, link, paramId, pages });
	};

	renderItem = (item, index) => {
		const { name, isDefault } = item;
		const { textColorStyle } = this.handleGetColorStyle();

		// 先隱藏 『申請成為經銷商』＋『訂閱電子報』
		if (isDefault) {
			return null;
		}

		return (
			<div
				key={index}
				role="presentation"
				className={classStyle.item}
				style={textColorStyle}
				onClick={() => this.handleItemClick(item)}
			>
				{name}
			</div>
		);
	};

	handleGetColorStyle = () => {
		const { footerContent, item, isThemeEdit } = this.props;
		const content = isThemeEdit ? item : footerContent;
		const { backColor, textColor } = content;
		const backColorStyle = {
			backgroundColor: backColor,
		};
		const textColorStyle = {
			color: textColor,
		};

		return {
			backColorStyle,
			textColorStyle,
		};
	};

	render() {
		const {
			footerContent,
			item,
			isThemeEdit,
			closedWeb,
			multiLanguageEnabled,
			languagePreference,
			supportedLanguages,
			t,
			i18n
		} = this.props;
		const content = isThemeEdit ? item : footerContent;
		const { list } = content;
		const { backColorStyle, textColorStyle } = this.handleGetColorStyle();
		return (
			<div id="footer" className={classStyle.footer} style={backColorStyle}>
				<LanguageSettingModal
					isActive={this.state.modalStatus}
					handleClose={() => this.setState({ modalStatus: false })}
					headerText={t('language:model_title')}
					supportedLanguages={supportedLanguages}
					languagePreference={languagePreference}
				/>
				<div className={classStyle.list}>
					{_.map(list, this.renderItem)}
					{
						multiLanguageEnabled && (
							<div
								role="presentation"
								className={classStyle.item}
								style={textColorStyle}
								onClick={() => {
									this.setState({ modalStatus: true });
								}}
							>
								<StyleGlobeIcon />
								<LanguageWrapper>
									{t(`language:${i18n.language.toLocaleLowerCase()}`)}
								</LanguageWrapper>
							</div>
						)
					}
				</div>
				{
					!closedWeb && (
						<div className={classStyle.bottomDiv}>
							<div className={classStyle.version} style={textColorStyle}>
								<CopyRight />
							</div>
							<PoweredBy textColorStyle={textColorStyle} />
						</div>
					)
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		pages,
		themes: {
			closed_web,
			multi_language_enabled,
			language_preference,
			supported_languages,
		},
		footerContent,
	} = getThemeSelector(state);

	return {
		pages,
		footerContent,
		closedWeb: closed_web,
		multiLanguageEnabled: multi_language_enabled,
		languagePreference: language_preference,
		supportedLanguages: supported_languages,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({ ...actionCreators }, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Footer));
