import { createAction } from 'redux-actions';
import {
	GET_JOBS_CALL,
	GET_JOBS_DONE,
	GET_JOBS_FAIL,
	POST_JOB_APPLY_CALL,
	POST_JOB_APPLY_DONE,
	POST_JOB_APPLY_FAIL,
	GET_JOB_DETAIL_CALL,
	GET_JOB_DETAIL_DONE,
	GET_JOB_DETAIL_FAIL,
	POST_JOB_BINDING_CALL,
	POST_JOB_BINDING_DONE,
	POST_JOB_BINDING_FAIL,
	POST_JOB_VIEWED_CALL,
	POST_JOB_VIEWED_DONE,
	POST_JOB_VIEWED_FAIL,
} from '@src/store/types';

export const getJobsCall = createAction(GET_JOBS_CALL);
export const getJobsDone = createAction(GET_JOBS_DONE);
export const getJobsFail = createAction(GET_JOBS_FAIL);
export const postJobApplyCall = createAction(POST_JOB_APPLY_CALL);
export const postJobApplyDone = createAction(POST_JOB_APPLY_DONE);
export const postJobApplyFail = createAction(POST_JOB_APPLY_FAIL);
export const getJobDetailCall = createAction(GET_JOB_DETAIL_CALL);
export const getJobDetailDone = createAction(GET_JOB_DETAIL_DONE);
export const getJobDetailFail = createAction(GET_JOB_DETAIL_FAIL);
export const postJobBingingCall = createAction(POST_JOB_BINDING_CALL);
export const postJobBingingDone = createAction(POST_JOB_BINDING_DONE);
export const postJobBingingFail = createAction(POST_JOB_BINDING_FAIL);
export const postJobViewedCall = createAction(POST_JOB_VIEWED_CALL);
export const postJobViewedDone = createAction(POST_JOB_VIEWED_DONE);
export const postJobViewedFail = createAction(POST_JOB_VIEWED_FAIL);
