/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import useTranslation from '@src/hooks/useTranslation';
import Link from 'next/link';

import CardList from '@src/components/category/cardList';
import { RightArrowIcon } from '@src/components/common/icon';

const PaddingWrapper = styled.div`
  @media (max-width: 576px) {
    padding: 16px 0px 0px;
  }
  @media (min-width: 576px) {
    padding: 16px 0px 0px;
  }
  @media (min-width: 768px) {
    padding: 32px 0px 0px;
  }
  @media (min-width: 992px) {
    padding: 50px 0px 0px;
  }
  @media (min-width: 1200px) {
    padding: 50px 0px 0px;
  }
`;

const Title = styled.div`
	font-size: 22px;
	font-weight: 500;
	padding-left: 10px;
	border-left: 4px solid ${props => props.theme.colors.secondary};
	color: ${props => props.theme.colors.secondary};
`;

const Divider = styled.div`
  border-bottom: 1px solid #cfcfcf;
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
`;

const MoreWrapper = styled.div`
	display: flex;
	flex-direction: row;
	font-size: 16px;
	color: #676767;
	cursor: pointer;
	align-items: center;
	height: 20px;
`;
const MoreText = styled.div`
	padding-right: 4px;
	padding-top: 2px;
`;

interface IProps {
	title: string;
	// eslint-disable-next-line react/no-unused-prop-types
	course: any[];
	amount: number;
	categoryId: string;
}
const Courses = ({ title, course, amount = 8, categoryId }: IProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<PaddingWrapper>
			<TitleWrapper>
				<Title>{title}</Title>
				<Link href={`/category/${categoryId}`}>
					<MoreWrapper>
						<MoreText>{t('category:more')}</MoreText>
						<RightArrowIcon />
					</MoreWrapper>
				</Link>
			</TitleWrapper>
			<Divider />
			<CardList list={course.slice(0, amount)} />
		</PaddingWrapper>
	);
};

export default Courses;
