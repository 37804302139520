/* eslint-disable react/prop-types */
import React from 'react';
import ReactPlayer from 'react-player/lib';
import classNames from 'classnames';
import classStyle from './Video.module.less';

const Video = props => {
	const {
		url,
		videoDivClassName,
		videoClassName,
		isRwdType,
		loop,
		playing,
		controls = true,
		muted,
		light,
		playsinline = false,
	} = props;
	const videoDivClassNames = [
		isRwdType && classStyle.videoRwdDiv,
		videoDivClassName,
	];
	const videoClassNames = [isRwdType && classStyle.videoRwd, videoClassName];

	if (!url) {
		return null;
	}

	return (
		<div className={classNames(videoDivClassNames)}>
			<ReactPlayer
				id="react-player"
				muted={muted}
				className={classNames(videoClassNames)}
				url={url}
				light={light}
				config={{
					vimeo: {
						playerOptions: {
							speed: true,
							color: '3091fd',
							// quality: '540'
						},
						preload: true,
					},
					clientId: '44503cca1be605b5', // 測試用，網路上找的 https://codepen.io/amalajith/pen/pYmYOW
					playerId: 'youkuplayer',
				}}
				playsinline={playsinline}
				playbackRate={1}
				controls={controls}
				width={isRwdType && '100%'}
				height={isRwdType && '100%'}
				playing={playing}
				loop={loop}
			/>
		</div>
	);
};

export default Video;
