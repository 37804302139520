import { createAction } from 'redux-actions';
import * as types from '@src/store/types';

export const addProductToCartCall = createAction(types.ADD_PRODUCT_TO_CART_CALL);
export const addProductToCartDone = createAction(types.ADD_PRODUCT_TO_CART_DONE);
export const addProductToCartFail = createAction(types.ADD_PRODUCT_TO_CART_FAIL);
export const addProductToCartCancel = createAction(types.ADD_PRODUCT_TO_CART_CANCEL);

export const getAllPurchaseListCall = createAction(types.GET_ALL_PURCHASE_LIST_CALL);
export const getCompletedPurchaseListCall = createAction(types.GET_COMPLETED_PURCHASE_LIST_CALL);
export const getCanceledPurchaseListCall = createAction(types.GET_CANCELED_PURCHASE_LIST_CALL);
export const getPurchaseListDone = createAction(types.GET_PURCHASE_LIST_DONE);
export const getPurchaseListFail = createAction(types.GET_PURCHASE_LIST_FAIL);

export const getPurchaseCall = createAction(types.GET_PURCHASE_CALL);
export const getPurchaseDone = createAction(types.GET_PURCHASE_DONE);
export const getPurchaseFail = createAction(types.GET_PURCHASE_FAIL);

export const getPurchaseCourseListCall = createAction(types.GET_PURCHASE_COURSE_LIST_CALL);
export const getPurchaseCourseListDone = createAction(types.GET_PURCHASE_COURSE_LIST_DONE);
export const getPurchaseCourseListFail = createAction(types.GET_PURCHASE_COURSE_LIST_FAIL);

export const getPurchaseCourseTeacherListCall = createAction(types.GET_PURCHASE_COURSE_TEACHER_LIST_CALL);
export const getPurchaseCourseTeacherListDone = createAction(types.GET_PURCHASE_COURSE_TEACHER_LIST_DONE);
export const getPurchaseCourseTeacherListFail = createAction(types.GET_PURCHASE_COURSE_TEACHER_LIST_FAIL);

export const cancelPurchaseOrderCall = createAction(types.CANCEL_PURCHASE_ORDER_CALL);
export const cancelPurchaseOrderDone = createAction(types.CANCEL_PURCHASE_ORDER_DONE);
export const cancelPurchaseOrderFail = createAction(types.CANCEL_PURCHASE_ORDER_FAIL);

export const buyTheFreeCourseCall = createAction(types.BUY_THE_FREE_COURSE_CALL);
export const buyTheFreeCourseDone = createAction(types.BUY_THE_FREE_COURSE_DONE);
export const buyTheFreeCourseFail = createAction(types.BUY_THE_FREE_COURSE_FAIL);
export const buyTheFreeCourseCancel = createAction(types.BUY_THE_FREE_COURSE_CANCEL);

export const getPurchaseEventsCall = createAction(types.GET_PURCHASE_EVENTS_CALL);
export const getPurchaseEventsDone = createAction(types.GET_PURCHASE_EVENTS_DONE);
export const getPurchaseEventsFail = createAction(types.GET_PURCHASE_EVENTS_FAIL);

export const getPurchaseWebinarsCall = createAction(types.GET_PURCHASE_WEBINARS_CALL);
export const getPurchaseWebinarsDone = createAction(types.GET_PURCHASE_WEBINARS_DONE);
export const getPurchaseWebinarsFail = createAction(types.GET_PURCHASE_WEBINARS_FAIL);
