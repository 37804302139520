/* eslint-disable max-len */
import { createAction } from 'redux-actions';

import {
	GET_APPROVAL_CENTER_CALL,
	GET_APPROVAL_CENTER_DONE,
	GET_APPROVAL_CENTER_FAIL,
} from '@src/store/types';

export const getApprovalCenterCall = createAction(GET_APPROVAL_CENTER_CALL);
export const getApprovalCenterDone = createAction(GET_APPROVAL_CENTER_DONE);
export const getApprovalCenterFail = createAction(GET_APPROVAL_CENTER_FAIL);
