import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as actionCreators from '@src/store/actions';
import { IconComponent } from '@src/icons';
import Cart from '@src/components/home/components/cart';
import Image from 'react-image';
import { getThemeSelector } from '@src/store/selectors';
import _ from 'lodash';
import { Popover } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { colorThemes } from 'src/const/theme';
import { isMobile } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import {
	SettingIcon,
	CoursesIcon,
	EventIcon,
	OrderOutlineIcon,
	LiveIcon,
	LogoutIcon,
	LearningPathMenuIcon,
	CollapseArrowDownIcon,
	SwitchHRSystemIcon,
} from '@src/components/common/icon';
import Dropdown from '@src/components/common/subMenuDropdown';
import { getRegisterUrl } from '@src/utils/hardcode';

import LogoComponent from './LogoComponent';
import classStyle from './WebHeaderComponent.module.less';

const HeaderText = styled.div`
	position: relative;
	margin-right: 18px;
	font-weight: 500;
	font-size: 16px;
	cursor: pointer;
	@media 
		${props => props.theme.device.mobile},
		${props => props.theme.device.smTablet},
		{
			display: hidden;
		}
`;

const RedPoint = styled.div`
	width: 6px;
	height: 6px;
	background: #D0021B;
	border-radius: 100px;
	position: absolute;
	top: -3px;
	right: -5px;
`;

const SelectItem = styled.div`
	.ant-dropdown {
		width: 200px;
		left: 212px !important;
		top: 58px  !important;
	}

	& .ant-dropdown-menu-item {
		padding: 6px 12px;
	}
	& .ant-dropdown-menu-title-content {
		width: 164px;
		max-height: 44px;
		white-space: break-spaces;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		font-size: 16px;
		font-weight: 500;
	}
	& .ant-dropdown .ant-dropdown-menu-item-active {
		background-color: #EBEBED !important;
	}
	& .ant-dropdown	.ant-dropdown-menu-submenu-active {
		background-color: #EBEBED !important;
	}
	&	.ant-dropdown .ant-dropdown-menu-submenu-title:hover {
		background-color: #EBEBED !important;
	}
	&	.ant-dropdown .ant-dropdown-menu-submenu-expand-icon {
		top: 4px;
	}
	.menu-open {
		color: #3091FD;
		font-weight: 500;
		border-top: 2px solid transparent;
		border-bottom: 2px solid #3091FD;
		.title-styled {
			color: #3091FD;
		}
		.icon-style {
			svg {
				fill: #3091FD;
			}
		}
	}
`;

const TitleContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 51px;
	:hover {
		font-weight: 500;
		border-top: 2px solid transparent;
		border-bottom: 2px solid #3091FD;
		.title-styled {
			color: #3091FD;
		}
		.icon-style {
			svg {
				fill: #3091FD;
			}
		}
	}
`;

const StyledCaretDownOutlined = styled(CaretDownOutlined)`
	margin-left: 4px;
	svg {
		width: 12px;
	}
`;

export class WebHeaderComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			displayTopBar: true,
			windowWidth: 1200,
		};
	}

	componentDidMount() {
		this.props.actions.getSchoolAnnouncementsCall({ unread_only: true });
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	handleShowModal = type => {
		this.props.actions.setModalType({ type });
	};

	handleResize = () => {
		const width = window.innerWidth;
		this.setState({ windowWidth: width });
	}

	handleCloseModal = () => {
		this.props.actions.setModalType({ type: null });
	};

	handleShowTopBar = () => {
		this.setState({ displayTopBar: true });
	};

	handleHideTopBar = () => {
		this.setState({ displayTopBar: false });
	};

	renderBanner = () => {
		const { themes } = this.props;
		const { banner } = themes;

		return (
			<div className={classStyle.topBar}>
				<div className={classStyle.topBarText}>{banner}</div>
				<div className={classStyle.topBarClose} onClick={this.handleHideTopBar}>
					<IconComponent size={15} color="white" icon="cross" />
				</div>
			</div>
		);
	};

	handleRedirect = path => {
		this.props.actions.historyPushCall(path);
	};

	handleLogOut = () => {
		this.props.actions.logoutCall();
	};

	renderUserProfileItem = (item, index) => {
		const { title, icon, onClick, Icon, iconColor } = item;

		return (
			<div key={index} className={classStyle.boxItem} onClick={onClick}>
				<Icon fill={iconColor || '#333333'} className={classStyle.listIcon} />
				<div className={classStyle.boxItemText}>{title}</div>
			</div>
		);
	};

	renderUserProfileBox = ({ disable_purchase, can_switch_hr_system }) => {
		const { t } = this.props;
		const list = [
			{
				title: t('account:settings'),
				Icon: SettingIcon,
				onClick: () => this.handleRedirect('/account/settings'),
			},
			{
				title: t('account:courses'),
				Icon: CoursesIcon,
				onClick: () => this.handleRedirect('/account/courses'),
			},
			{
				title: t('account:webinars'),
				Icon: LiveIcon,
				onClick: () => this.handleRedirect('/account/webinars'),
			},
			{
				title: t('account:events'),
				Icon: EventIcon,
				onClick: () => this.handleRedirect('/account/events'),
			},
			...!disable_purchase ? [
				{
					title: t('account:course_orders'),
					Icon: OrderOutlineIcon,
					onClick: () => this.handleRedirect('/account/course-orders'),
				},
				{
					title: t('account:orders'),
					Icon: OrderOutlineIcon,
					onClick: () => this.handleRedirect('/account/orders'),
				},
			] : [],
			{
				title: t('account:learning_paths'),
				Icon: LearningPathMenuIcon,
				onClick: () => this.handleRedirect('/account/learning-paths'),
			},
			...can_switch_hr_system ? [
				{
					title: t('account:switch_hr_system'),
					Icon: SwitchHRSystemIcon,
					iconColor: 'transparent',
					onClick: () => this.handleRedirect('/hr'),
				},
			] : [],
			{
				title: t('common:logout'),
				Icon: LogoutIcon,
				onClick: this.handleLogOut,
			},
		];

		return (
			<div className={classStyle.boxList}>
				{_.map(list, this.renderUserProfileItem)}
			</div>
		);
	};

	handleGetColorStyle = () => {
		const { themes, homePage } = this.props;
		const { content } = homePage;
		const headerTheme = _.find(content?.list, { key: 'header' });
		const currentTheme = colorThemes.find(theme => theme?.id === themes?.color_theme) || colorThemes[0];
		const backgroundColor = headerTheme?.backColor || currentTheme?.colors?.background || '#FFFFFF';
		const textColor = headerTheme?.textColor || currentTheme?.colors?.textColor || '#101010';

		return {
			backColorStyle: {
				backgroundColor,
			},
			textColorStyle: {
				color: textColor,
			},
		};
	};

	handleItemClick = item => {
		const { onItemClick } = this.props;

		onItemClick(item);
	};

	renderOptionItem = (item, index) => {
		const { name } = item;

		return (
			<div
				key={index}
				className={classNames(classStyle.boxItem, classStyle.alignCenter)}
				onClick={() => this.handleItemClick(item)}
			>
				<div className={classStyle.boxItemText}>{name}</div>
			</div>
		);
	};

	renderOptions = options => (
		<div className={classStyle.boxList}>
			{_.map(options, this.renderOptionItem)}
		</div>
	);

	renderSelect = (item, index) => {
		const { options, name, key } = item;
		const { textColorStyle } = this.handleGetColorStyle();
		return (
			<div key={key}>
				<div id={key} />
				<SelectItem key={index} className={classStyle.item}>
					<Dropdown
						popupContainerKey={key}
						defaultId=""
						defaultText=""
						onSelect={({ id }) => {
							let findOption = _.find(options, { key: id });
							if (findOption) {
								this.handleItemClick(findOption);
							} else {
								options.forEach(item => {
									if (item.options) {
										findOption = _.find(item.options, { key: id });
										this.handleItemClick(findOption);
									}
								});
							}
						}}
						component={() => (
							<TitleContent className={classStyle.itemTitle} style={textColorStyle}>
								<div className="title-styled">{name}</div>
								<StyledCaretDownOutlined className="icon-style" />
							</TitleContent>
						)}
						menu={[
							...options.map(item => ({
								id: item.key,
								text: item.name,
								...item?.options ? {
									subMenu: item?.options.map((subItem) => ({
										id: subItem.key,
										text: subItem.name,
									})),
								} : {},
							})),
						]}
					/>
				</SelectItem>
			</div>
		);
	};

	renderItem = (item, index) => {
		const { key, name, type } = item;
		const { textColorStyle } = this.handleGetColorStyle();
		const isSelect = type === 'select';
		if (this.state.windowWidth >= 1200 && index > 4) {
			return null;
		} else if (this.state.windowWidth >= 992 && this.state.windowWidth < 1200 && index > 2) {
			return null;
		} else if (this.state.windowWidth >= 768 && this.state.windowWidth < 992 && index > 0) {
			return null;
		}
		if (isSelect) {
			return this.renderSelect(item, index);
		}

		return (
			<div
				key={key}
				className={classStyle.item}
				style={textColorStyle}
				onClick={() => this.handleItemClick(item)}
			>
				<div className={classStyle.itemTitle}>{name}</div>
			</div>
		);
	};

	renderMore = (list) => {
		const { t } = this.props;
		let moreList = [...list];
		if (this.state.windowWidth >= 1200) {
			moreList = moreList.slice(5);
		} else if (this.state.windowWidth >= 992 && this.state.windowWidth < 1200) {
			moreList = moreList.slice(3);
		} else if (this.state.windowWidth >= 768 && this.state.windowWidth < 992) {
			moreList = moreList.slice(1);
		}
		if (moreList.length === 0) return null;
		return this.renderItem({
			key: 'header-more',
			name: t('all:header_more'),
			type: 'select',
			options: moreList,
		}, 0);
	}

	render() {
		const {
			themes,
			headerContent,
			item,
			isThemeEdit,
			isSignedIn,
			profile = {},
			showTopBar,
			asPath,
			unreadSchoolAnnouncementTotalCount,
			t,
			domain,
		} = this.props;
		const { displayTopBar } = this.state;
		const content = isThemeEdit ? item : headerContent;
		const { list } = content;
		const {
			logo_link,
			enable_banner,
			closed_web,
			disable_purchase,
			front_desk_knowledge_base_enabled,
			other_school_login_enabled,
			can_switch_hr_system,
		} = themes;
		const { link: logoLink } = logo_link;
		const isLogin = isThemeEdit ? false : isSignedIn;
		const photoUrl = profile?.profile_picture_link?.link;
		const { name = '' } = profile;
		const { backColorStyle, textColorStyle } = this.handleGetColorStyle();
		const hasNews = unreadSchoolAnnouncementTotalCount > 0;
		return (
			<div id="header" className={classStyle.header}>
				{showTopBar && displayTopBar && enable_banner && this.renderBanner()}
				<div className={classStyle.headerBar} style={backColorStyle}>
					<LogoComponent logoLink={logoLink} textColorStyle={textColorStyle} />
					<div className={classStyle.list}>{[..._.map(list, this.renderItem), this.renderMore(list)]}</div>
					{isLogin ? (
						<div className={classStyle.headerNavRight} style={backColorStyle}>
							{
								other_school_login_enabled && (
									<HeaderText
										style={textColorStyle}
										onClick={() => window.open('/enterprise', '_blank')}
									>
										{t('all:header_enterprise')}
									</HeaderText>
								)
							}
							{
								front_desk_knowledge_base_enabled && (
									<HeaderText
										style={textColorStyle}
										onClick={() => window.open('/knowledge', '_blank')}
									>
										{t('all:header_knowledge')}
									</HeaderText>
								)
							}
							<HeaderText
								style={textColorStyle}
								onClick={() => window.open('/member', '_blank')}
							>
								{t('all:header_member')}
								{ hasNews && <RedPoint />}
							</HeaderText>
							{!disable_purchase && <Cart />}
							<Popover
								content={() => this.renderUserProfileBox({ disable_purchase, can_switch_hr_system })}
								trigger={isMobile ? 'click' : 'hover'}
								placement="bottomRight"
								getPopupContainer={trigger => trigger.parentNode}
								mouseEnterDelay={0}
							>
								<div className={classStyle.userProfile}>
									{photoUrl ? (
										<Image
											className={classStyle.userProfileImage}
											src={photoUrl}
										/>
									) : (
										<div className={classStyle.userName} style={textColorStyle}>
											{name.trim().split('')[0]}
										</div>
									)}
								</div>
							</Popover>
						</div>
					) : (
						<div className={classStyle.headerNavRight} style={backColorStyle}>
							{
								other_school_login_enabled && (
									<HeaderText
										style={textColorStyle}
										onClick={() => window.open('/enterprise', '_blank')}
									>
										{t('all:header_enterprise')}
									</HeaderText>
								)
							}
							{
								front_desk_knowledge_base_enabled && (
									<HeaderText
										style={textColorStyle}
										onClick={() => window.open('/knowledge', '_blank')}
									>
										{t('all:header_knowledge')}
									</HeaderText>
								)
							}
							{
								!closed_web && (
									<>
										<div
											className={classStyle.navDiv}
											style={textColorStyle}
											onClick={() => this.handleShowModal('login')}
										>
											{t('login:title')}
										</div>
										<div
											className={classStyle.navDiv}
											style={textColorStyle}
											onClick={() => {
												const hardcodeRegisterUrl = getRegisterUrl(domain);
												if (hardcodeRegisterUrl) {
													window.open(hardcodeRegisterUrl, '_self');
													return;
												}
												this.handleShowModal('register')
											}}
										>
											{t('login:register')}
										</div>
									</>
								)
							}
						</div>
					)}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		user: { profile },
		session: { isSignedIn },
		schoolAnnouncement: {
			schoolAnnouncements: {
				unread: {
					pagination: { totalCount },
				},
			},
		},
		ssr: { domain },
	} = state;
	const { themes, headerContent, homePage } = getThemeSelector(state);

	return {
		isSignedIn,
		themes,
		homePage,
		headerContent,
		profile,
		unreadSchoolAnnouncementTotalCount: totalCount,
		domain,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({ ...actionCreators }, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(WebHeaderComponent);
