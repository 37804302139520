export const GET_ALL_CATEGORIES_CALL = 'GET_ALL_CATEGORIES_CALL';
export const GET_ALL_CATEGORIES_DONE = 'GET_ALL_CATEGORIES_DONE';
export const GET_ALL_CATEGORIES_FAIL = 'GET_ALL_CATEGORIES_FAIL';

export const GET_COMMODITY_SEARCH_CALL = 'GET_COMMODITY_SEARCH_CALL';
export const GET_COMMODITY_SEARCH_SWITCH_CALL = 'GET_COMMODITY_SEARCH_SWITCH_CALL';
export const GET_COMMODITY_SEARCH_DONE = 'GET_COMMODITY_SEARCH_DONE';
export const GET_COMMODITY_SEARCH_FAIL = 'GET_COMMODITY_SEARCH_FAIL';

export const GET_COMMODITY_PUBLISHED_TYPES_CALL = 'GET_COMMODITY_PUBLISHED_TYPES_CALL';
export const GET_COMMODITY_PUBLISHED_TYPES_DONE = 'GET_COMMODITY_PUBLISHED_TYPES_DONE';
export const GET_COMMODITY_PUBLISHED_TYPES_FAIL = 'GET_COMMODITY_PUBLISHED_TYPES_FAIL';
