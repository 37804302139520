import { calcCourseCanBuy } from '@src/utils/purchase';
import { PRODUCT_TYPES } from '@src/const/deprecatedCourse';

export const getCartState = state => state.purchase.cartState;

// Remove after removing legacy cart page.
export const getCartUpdateState = state => state.purchase.cartUpdateState;

export const getCartId = state => state.purchase.purchasePending.id;

export const getCartProducts = state => state.purchase.purchasePending.products || [];

export const getCartProductsNumber = state => state.purchase.purchasePending.products_number || 0;

export const getCartInitPrice = state => state.purchase.purchasePending.init_price;

export const getCartDiscountPrice = state => state.purchase.purchasePending.discount_price;

export const getCartCouponDiscountPrice = state => state.purchase.purchasePending.coupon_discount_price;

export const getCartCoupon = state => state.purchase.purchasePending.coupon;

export const getCartTotalPrice = state => state.purchase.purchasePending.total_price;

export const getCart = state => state.purchase.purchasePending;

export const getCartCanCheckout = state => (
	state.purchase.purchasePending.products?.every(
		product => calcCourseCanBuy(product.status),
	)
);

export const getIsProductInCart = product_id => state => (
	!!state.purchase.purchasePending.products?.find(
		product => product.id === product_id,
	)
);

export const getIsCourseInCart = course_id => state => (
	!!state.purchase.purchasePending.products?.find(
		product => product.course_id === course_id,
	)
);

export const getIsCreditCardOnly = state => (
	state.purchase.purchasePending.products?.some(
		product => product.type === PRODUCT_TYPES.fund_course,
	)
);

export const getPurchaseState = state => state.purchase.purchaseState;

export const getPurchaseProductList = state => state.purchase.purchase?.products || [];

export const getPurchase = state => state.purchase.purchase;

export const getPurchaseAtmInfo = state => {
	const extra = state.purchase.purchase?.extra;

	if (extra?.vacc_no) {
		return {
			bank_code: extra.bank_code,
			expired_at: extra.expired_at,
			vacc_no: extra.vacc_no,
		};
	}

	return null;
};

export const getPurchaseListState = purchaseType => state => state.purchase.purchaseListStates[purchaseType];

export const getPurchaseList = purchaseType => state => state.purchase.purchaseLists[purchaseType] || [];

export const getPurchaseListPagination = purchaseType => state => (
	state.purchase.purchaseListPaginations[purchaseType] || {}
);

export const getPurchaseCourseListState = state => state.purchase.purchaseCourseListState;

export const getPurchaseCourseList = state => state.purchase.purchaseCourseList;

export const getPurchaseCourseListPagination = state => state.purchase.purchaseCourseListPagination;

export const getPurchaseCourseTeacherListState = state => state.purchase.purchaseCourseTeacherListState;

export const getPurchaseCourseTeacherList = state => state.purchase.purchaseCourseTeacherList;

export const getCancelPurchaseOrderState = purchase_id => state => (
	state.purchase.cancelPurchaseOrderStates[purchase_id]
);

export const getBuyTheFreeCourseState = product_id => state => state.purchase.buyTheFreeCourseStates[product_id];

export const getAddingProductState = prodcut_id => state => state.purchase.adding_products[prodcut_id]?.state;

export const getPurchaseEvents = state => state.purchase.purchaseEvents;

export const getPurchaseEventsState = state => state.purchase.purchaseEventsState;

export const getPurchaseWebinars = state => state.purchase.purchaseWebinars;

export const getPurchaseWebinarsState = state => state.purchase.purchaseWebinarsState;
