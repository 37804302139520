/* eslint-disable max-len */
export const GET_MAKE_UP_PUNCH_APPLICATIONS_CALL = 'GET_MAKE_UP_PUNCH_APPLICATIONS_CALL';
export const GET_MAKE_UP_PUNCH_APPLICATIONS_DONE = 'GET_MAKE_UP_PUNCH_APPLICATIONS_DONE';
export const GET_MAKE_UP_PUNCH_APPLICATIONS_FAIL = 'GET_MAKE_UP_PUNCH_APPLICATIONS_FAIL';

export const POST_MAKE_UP_PUNCH_APPLICATIONS_CALL = 'POST_MAKE_UP_PUNCH_APPLICATIONS_CALL';
export const POST_MAKE_UP_PUNCH_APPLICATIONS_DONE = 'POST_MAKE_UP_PUNCH_APPLICATIONS_DONE';
export const POST_MAKE_UP_PUNCH_APPLICATIONS_FAIL = 'POST_MAKE_UP_PUNCH_APPLICATIONS_FAIL';

export const GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_CALL = 'GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_CALL';
export const GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_DONE = 'GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_DONE';
export const GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_FAIL = 'GET_MAKE_UP_PUNCH_APPLICATIONS_APPROVER_VIEW_FAIL';

export const GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_CALL = 'GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_CALL';
export const GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_DONE = 'GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_DONE';
export const GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_FAIL = 'GET_MAKE_UP_PUNCH_APPLICATIONS_PUNCH_OPTIONS_FAIL';

export const GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_CALL = 'GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_CALL';
export const GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_DONE = 'GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_DONE';
export const GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_FAIL = 'GET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL_FAIL';
export const RESET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL = 'RESET_MAKE_UP_PUNCH_APPLICATIONS_DETAIL';

export const POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_CALL = 'POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_CALL';
export const POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_DONE = 'POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_DONE';
export const POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_FAIL = 'POST_MAKE_UP_PUNCH_APPLICATIONS_APPROVE_FAIL';

export const POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_CALL = 'POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_CALL';
export const POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_DONE = 'POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_DONE';
export const POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_FAIL = 'POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_FAIL';

export const POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_CALL = 'POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_CALL';
export const POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_DONE = 'POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_DONE';
export const POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_FAIL = 'POST_MAKE_UP_PUNCH_APPLICATIONS_WITHDRAW_APPROVAL_FAIL';
