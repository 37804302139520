import React, { ReactNode } from 'react';
import styled from 'styled-components';
import useTranslation from '@src/hooks/useTranslation';
import { useSelector } from 'react-redux';

import MobileCardTypeTag from '@src/components/common/MobileCardTypeTag';
import {
	CardWrapper,
	Title,
} from '@src/components/card/style';
import { getIsTagVisible } from '@src/utils/hardcode';
import * as selectors from '@src/store/selectors';

const StyledCardWrapper = styled(CardWrapper)`
	display: flex;
	border-bottom: 1px solid #E9E9E9;
`;

const MediaWrapper = styled.div`
	margin-right: 8px;
`;

const DetailContent = styled.div`
	width: 100%;
`;

const ImageWrapper = styled.div`
	height: 67px;
	position: relative;
	margin-bottom: 6px;
`;

const Image = styled.img`
	width: 123px;
	height: 67px;
	border-radius: 4px;
`;

const SoldOut = styled.div`
	position: absolute;
	left: 12px;
	top: 12px;
	background-color: rgba(34,34,34,0.5);
	width: 99px;
	height: 49px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	color: #FFF;
`;

interface IProps {
	onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	title: string;
	contentElem: string | ReactNode | ReactNode[];
	imageUrl: string;
	imageAlt?: string;
	tagText: string | ReactNode;
	tagColor: string;
	tagBorderColor?: string;
	isSoldOut?: boolean;
	className?: string;
}

const HorizontalSmallCard = ({
	onClick,
	title,
	contentElem,
	imageUrl,
	imageAlt,
	tagText,
	tagColor,
	tagBorderColor,
	isSoldOut = false,
	className,
}: IProps): JSX.Element => {
	const { t } = useTranslation();
	const domain = useSelector(selectors.getDomain) as string;
	const isTagVisible = getIsTagVisible(domain);
	return (
		<StyledCardWrapper className={className} onClick={onClick}>
			<MediaWrapper>
				<ImageWrapper>
					{
						isSoldOut && (
							<SoldOut>
								{t('event:sold_out')}
							</SoldOut>
						)
					}
					<Image src={imageUrl} alt={imageAlt} />
				</ImageWrapper>
				{isTagVisible && (
					<MobileCardTypeTag text={tagText} backgroundColor={tagColor} tagBorderColor={tagBorderColor} />
				)}
			</MediaWrapper>
			<DetailContent className="detail-content">
				<Title>{title}</Title>
				{contentElem}
			</DetailContent>
		</StyledCardWrapper>
	);
};

export default HorizontalSmallCard;
