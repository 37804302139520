import _ from 'lodash';
import router from 'next/router';

import { checkUrlAddHttps } from '@src/utils/regExpCheck';

export const handleThemeClick = props => {
	const { openType, link, paramId, pages } = props;

	if (!link) {
		return;
	}

	switch (openType) {
		case 'openPage': {
			if (link === 'course') {
				if (paramId) {
					window.open(`/${link}/${paramId}`, '_blank');
				} else {
					window.open(`/${link}`, '_blank');
				}
			} else if (link === 'search') {
				window.open('/category/all', '_blank');
			} else {
				// 舊資料防呆
				const key = link.replace('/', '');
				const findPage = _.find(pages, { key });

				if (findPage) {
					const { allPath } = findPage;

					if (allPath) {
						window.open(allPath, '_blank');
					} else {
						const { pathname } = window.location;
						if (pathname === '/') {
							router.reload('/');
						} else {
							window.open('/', '_self');
						}
					}
				}
			}
			break;
		}
		case 'openLink': {
			const httpsLink = checkUrlAddHttps(link);

			window.open(httpsLink, '_blank');
			break;
		}
		case 'openEmail': {
			const mailLink = `mailto:${link}?subject=&body=`;

			window.open(mailLink);
			break;
		}
		default:
			break;
	}
};

export const getPages = pages => {
	const newPages = _.map(pages, page => {
		const { content } = page;
		const newContent = JSON.parse(content);
		const { key, allPath, path } = newContent;

		return {
			...page,
			key,
			path,
			allPath,
			content: JSON.parse(content),
		};
	});
	return newPages;
};
