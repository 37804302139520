/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/prop-types */
import React from 'react';
import useTranslation from '@src/hooks/useTranslation';
import { useSelector } from 'react-redux';

import * as selectors from '@src/store/selectors';

const ContentComponent = ({
	type,
	itemKey,
	item,
	backColorStyle,
	isHomePage,
	ssrProps,
	pages,
	contentClassName,
	itemClassName,
	Content,
}) => {
	const { t } = useTranslation();
	const events = useSelector(selectors.getEvents) || [];
	if (type === 'offlineEvents' && events.length === 0) {
		return null;
	}

	return (
		<div key={itemKey} className={itemClassName} style={backColorStyle}>
			<div className={contentClassName}>
				<Content
					item={item}
					t={t}
					pages={pages}
					isHomePage={isHomePage}
					ssrProps={ssrProps}
				/>
			</div>
		</div>
	);
};

export default ContentComponent;
