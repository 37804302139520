export const SET_TOKEN = 'SET_TOKEN';
export const SET_PREVIEW_TOKEN = 'SET_PREVIEW_TOKEN';
export const SET_SCHOOL_END = 'SET_SCHOOL_END';

export const CHECK_IS_SIGNED_IN = 'CHECK_IS_SIGNED_IN';
export const CHECK_LOGIN_CALL = 'CHECK_LOGIN_CALL';
export const CHECK_LOGIN_DONE = 'CHECK_LOGIN_DONE';
export const CHECK_LOGIN_FAIL = 'CHECK_LOGIN_FAIL';

export const CHECK_LOGIN_SESSION_CALL = 'CHECK_LOGIN_SESSION_CALL';
export const CHECK_LOGIN_SESSION_DONE = 'CHECK_LOGIN_SESSION_DONE';
export const CHECK_LOGIN_SESSION_FAIL = 'CHECK_LOGIN_SESSION_FAIL';

export const LOGOUT_CALL = 'LOGOUT_CALL';
export const LOGOUT_DONE = 'LOGOUT_DONE';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const EMAIL_LOGIN_CALL = 'EMAIL_LOGIN_CALL';
export const EMAIL_LOGIN_DONE = 'EMAIL_LOGIN_DONE';
export const EMAIL_LOGIN_FAIL = 'EMAIL_LOGIN_FAIL';

export const EMAIL_REGISTER_CALL = 'EMAIL_REGISTER_CALL';
export const EMAIL_REGISTER_DONE = 'EMAIL_REGISTER_DONE';
export const EMAIL_REGISTER_FAIL = 'EMAIL_REGISTER_FAIL';
export const EMAIL_RESEND_STATE_RESET = 'EMAIL_RESEND_STATE_RESET';

export const SOCIAL_LOGIN_PREPARE_CALL = 'SOCIAL_LOGIN_PREPARE_CALL';
export const SOCIAL_LOGIN_PREPARE_DONE = 'SOCIAL_LOGIN_PREPARE_DONE';
export const SOCIAL_LOGIN_PREPARE_FAIL = 'SOCIAL_LOGIN_PREPARE_FAIL';

export const SOCIAL_LOGIN_CALL = 'SOCIAL_LOGIN_CALL';
export const SOCIAL_LOGIN_DONE = 'SOCIAL_LOGIN_DONE';
export const SOCIAL_LOGIN_FAIL = 'SOCIAL_LOGIN_FAIL';

export const EMAIL_CONFIRM_CALL = 'EMAIL_CONFIRM_CALL';
export const EMAIL_CONFIRM_DONE = 'EMAIL_CONFIRM_DONE';
export const EMAIL_CONFIRM_FAIL = 'EMAIL_CONFIRM_FAIL';

export const EMAIL_RE_SEND_CALL = 'EMAIL_RE_SEND_CALL';
export const EMAIL_RE_SEND_DONE = 'EMAIL_RE_SEND_DONE';
export const EMAIL_RE_SEND_FAIL = 'EMAIL_RE_SEND_FAIL';

export const UPDATE_MOBILE_CALL = 'UPDATE_MOBILE_CALL';
export const UPDATE_MOBILE_DONE = 'UPDATE_MOBILE_DONE';
export const UPDATE_MOBILE_FAIL = 'UPDATE_MOBILE_FAIL';

export const CONFIRM_MOBILE_CALL = 'CONFIRM_MOBILE_CALL';
export const CONFIRM_MOBILE_DONE = 'CONFIRM_MOBILE_DONE';
export const CONFIRM_MOBILE_FAIL = 'CONFIRM_MOBILE_FAIL';

export const RESET_UPDATE_MOBILE = 'RESET_UPDATE_MOBILE';

export const FORGET_PASSWORD_CALL = 'FORGET_PASSWORD_CALL';
export const FORGET_PASSWORD_DONE = 'FORGET_PASSWORD_DONE';
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL';

export const GET_BUSINESS_FORGET_PASSWORD_EMAIL_CALL = 'GET_BUSINESS_FORGET_PASSWORD_EMAIL_CALL';
export const GET_FORGET_PASSWORD_EMAIL_CALL = 'GET_FORGET_PASSWORD_EMAIL_CALL';
export const GET_FORGET_PASSWORD_EMAIL_DONE = 'GET_FORGET_PASSWORD_EMAIL_DONE';
export const GET_FORGET_PASSWORD_EMAIL_FAIL = 'GET_FORGET_PASSWORD_EMAIL_FAIL';

export const RESET_PASSWORD_CALL = 'RESET_PASSWORD_CALL';
export const RESET_PASSWORD_DONE = 'RESET_PASSWORD_DONE';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const SEND_BUG_MSG_CALL = 'SEND_BUG_MSG_CALL';
export const SEND_BUG_MSG_DONE = 'SEND_BUG_MSG_DONE';
export const SEND_BUG_MSG_FAIL = 'SEND_BUG_MSG_FAIL';

// RFA 中華民國退休協會
export const RFA_LOGIN_CALL = 'RFA_LOGIN_CALL';
export const RFA_LOGIN_DONE = 'RFA_LOGIN_DONE';
export const RFA_LOGIN_FAIL = 'RFA_LOGIN_FAIL';

export const GOOGLE_DOMAIN_NOT_ALLOW = 'GOOGLE_DOMAIN_NOT_ALLOW';
