import { TSideEffectState } from '@src/types/common';
import { IProductCoupon, IShoppingCartProducts, ICoupon, IShoppingCart } from '@src/types/cart';
import { COUPON_TYPE_PRODUCT, COUPON_TYPE_EVENT_SLOT, COUPON_TYPE_WEBINAR_SLOT } from '@src/const/cart';

export const getShoppingCartProductsState = ({ cart }: {
	cart: IShoppingCart
}): TSideEffectState => cart.getShoppingCartProductsState;

export const getShoppingCartProducts = ({ cart }: {
	cart: IShoppingCart
}): IShoppingCartProducts => cart.shoppingCartProducts;

export const getCourseProductCoupon = ({ cart }: {
	cart: IShoppingCart
}): IProductCoupon[] => cart.shoppingCartProducts.coupons.filter(({ type }) => type === COUPON_TYPE_PRODUCT);

export const getEventSlotProductCoupons = ({ cart }: {
	cart: IShoppingCart
}): IProductCoupon[] => cart.shoppingCartProducts.coupons.filter(({ type }) => type === COUPON_TYPE_EVENT_SLOT);

export const getWebinarSlotProductCoupons = ({ cart }: {
	cart: IShoppingCart
}): IProductCoupon[] => cart.shoppingCartProducts.coupons.filter(({ type }) => type === COUPON_TYPE_WEBINAR_SLOT);

export const updateShoppingCartProductsState = ({ cart }: {
	cart: IShoppingCart
}): TSideEffectState => cart.updateShoppingCartProductsState;

export const getReserveCartProductsState = ({ cart }: {
	cart: IShoppingCart
}): TSideEffectState => cart.reserveCartProductsState;

export const getPublicCourseCouponsState = ({ cart }: {
	cart: IShoppingCart;
}): TSideEffectState => cart.getPublicCourseCouponsState;

export const getPublicCourseCoupons = ({ cart }: {
	cart: IShoppingCart;
}): ICoupon[] => cart.publicCourseCoupons;

export const getApplyCourseCouponsState = ({ cart }: {
	cart: IShoppingCart;
}): TSideEffectState => cart.applyCourseCouponsState;

export const getPublicEventCouponsState = ({ cart }: {
	cart: IShoppingCart;
}): TSideEffectState => cart.getPublicEventCouponsState;

export const getPublicEventCoupons = ({ cart }: {
	cart: IShoppingCart;
}): ICoupon[] => cart.publicEventCoupons;

export const getApplyEventSlotCouponsState = ({ cart }: {
	cart: IShoppingCart;
}): TSideEffectState => cart.applyEventSlotCouponsState;

export const getPublicWebinarCouponsState = ({ cart }: {
	cart: IShoppingCart;
}): TSideEffectState => cart.getPublicWebinarCouponsState;

export const getPublicWebinarCoupons = ({ cart }: {
	cart: IShoppingCart;
}): ICoupon[] => cart.publicWebinarCoupons;

export const getApplyWebinarSlotCouponsState = ({ cart }: {
	cart: IShoppingCart;
}): TSideEffectState => cart.applyWebinarSlotCouponsState;
