import { createAction } from 'redux-actions';
import {
	GET_LEARNING_PATHS_CALL,
	GET_LEARNING_PATHS_DONE,
	GET_LEARNING_PATHS_FAIL,
	GET_MINE_LEARNING_PATHS_CALL,
	GET_MINE_LEARNING_PATHS_DONE,
	GET_MINE_LEARNING_PATHS_FAIL,
	GET_LEARNING_PATHS_BY_SHORT_LINK_CALL,
	GET_LEARNING_PATHS_BY_SHORT_LINK_DONE,
	GET_LEARNING_PATHS_BY_SHORT_LINK_FAIL,
	JOIN_LEARNING_PATHS_CALL,
	JOIN_LEARNING_PATHS_DONE,
	JOIN_LEARNING_PATHS_FAIL,
	LEAVE_LEARNING_PATHS_CALL,
	LEAVE_LEARNING_PATHS_DONE,
	LEAVE_LEARNING_PATHS_FAIL,
	LEARNING_PATHS_PROGRESS_CALL,
	LEARNING_PATHS_PROGRESS_DONE,
	LEARNING_PATHS_PROGRESS_FAIL,
	GET_LEARNING_PATHS_COMMODITIES_CALL,
	GET_LEARNING_PATHS_COMMODITIES_DONE,
	GET_LEARNING_PATHS_COMMODITIES_FAIL,
	POST_LEARNING_PATHS_CERTIFICATE_CALL,
	POST_LEARNING_PATHS_CERTIFICATE_DONE,
	POST_LEARNING_PATHS_CERTIFICATE_FAIL,
} from '@src/store/types';

export const getLearningPathsCall = createAction(GET_LEARNING_PATHS_CALL);
export const getLearningPathsDone = createAction(GET_LEARNING_PATHS_DONE);
export const getLearningPathsFail = createAction(GET_LEARNING_PATHS_FAIL);

export const getMineLearningPathsCall = createAction(GET_MINE_LEARNING_PATHS_CALL);
export const getMineLearningPathsDone = createAction(GET_MINE_LEARNING_PATHS_DONE);
export const getMineLearningPathsFail = createAction(GET_MINE_LEARNING_PATHS_FAIL);

export const getLearningPathsByShortLinkCall = createAction(GET_LEARNING_PATHS_BY_SHORT_LINK_CALL);
export const getLearningPathsByShortLinkDone = createAction(GET_LEARNING_PATHS_BY_SHORT_LINK_DONE);
export const getLearningPathsByShortLinkFail = createAction(GET_LEARNING_PATHS_BY_SHORT_LINK_FAIL);

export const joinLearningPathCall = createAction(JOIN_LEARNING_PATHS_CALL);
export const joinLearningPathDone = createAction(JOIN_LEARNING_PATHS_DONE);
export const joinLearningPathFail = createAction(JOIN_LEARNING_PATHS_FAIL);

export const leaveLearningPathCall = createAction(LEAVE_LEARNING_PATHS_CALL);
export const leaveLearningPathDone = createAction(LEAVE_LEARNING_PATHS_DONE);
export const leaveLearningPathFail = createAction(LEAVE_LEARNING_PATHS_FAIL);

export const learningProgressCall = createAction(LEARNING_PATHS_PROGRESS_CALL);
export const learningProgressDone = createAction(LEARNING_PATHS_PROGRESS_DONE);
export const learningProgressFail = createAction(LEARNING_PATHS_PROGRESS_FAIL);

export const getLearningPathsCommoditiesCall = createAction(GET_LEARNING_PATHS_COMMODITIES_CALL);
export const getLearningPathsCommoditiesDone = createAction(GET_LEARNING_PATHS_COMMODITIES_DONE);
export const getLearningPathsCommoditiesFail = createAction(GET_LEARNING_PATHS_COMMODITIES_FAIL);

export const postLearningPathsCertificateCall = createAction(POST_LEARNING_PATHS_CERTIFICATE_CALL);
export const postLearningPathsCertificateDone = createAction(POST_LEARNING_PATHS_CERTIFICATE_DONE);
export const postLearningPathsCertificateFail = createAction(POST_LEARNING_PATHS_CERTIFICATE_FAIL);
