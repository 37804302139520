/* eslint-disable max-len */
// 英數字
export const numberEnCheck = text => {
	const regex = new RegExp('^[a-zA-Z0-9]+$');

	return regex.test(text);
};

// 數字
export const numberCheck = text => {
	const regex = new RegExp(/^[0-9]+$/);

	return regex.test(text);
};

// 網址
export const httpCheck = text => {
	// const regex = new RegExp(
	// 	/(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/g,
	// );
	// POWER BY https://stackoverflow.com/a/5717133
	const regex = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
		'(\\#[-a-z\\d_]*)?$', 'i', // fragment locator
	);

	return !!regex.test(text);
};

// 網址增加http
export const checkUrlAddHttps = text => {
	if (!/^https?:\/\//i.test(text) && text !== '') {
		text = `https://${text}`;
	}

	return text;
};

// 統編
export const companyIdCheck = text => {
	const regex = new RegExp(/^[0-9]{8}$/);

	return regex.test(text);
};

// 手機
export const mobileCheck = ({ mobile, dialingCode }) => {
	const mobileChecks = {
		'+86': new RegExp(/^(\+?0?86\-?)?1[3456789]\d{9}$/),
		'+852': new RegExp(/^(\+?852\-?)?[569]\d{3}\-?\d{4}$/),
		'+853': new RegExp(/^[0][9]\d{8}$/),
		'+886': new RegExp(/^(\+?886\-?|0)?9\d{8}$/),
	};

	const mobileCheck = mobileChecks[dialingCode];

	return mobileCheck.test(mobile);
};

// 電子郵件 email
export const emailCheck = value => {
	const emailRegex = new RegExp(
		/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
	);
	return emailRegex.test(value);
};
