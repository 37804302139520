// export const SET_COURSE_DATA = 'SET_COURSE_DATA';
// export const SET_COURSE_ID = 'SET_COURSE_ID';

// export const ADD_COURSE_CALL = 'ADD_COURSE_CALL';
// export const ADD_COURSE_DONE = 'ADD_COURSE_DONE';
// export const ADD_COURSE_FAIL = 'ADD_COURSE_FAIL';

// export const UPDATE_COURSE_CALL = 'UPDATE_COURSE_CALL';
// export const UPDATE_COURSE_DONE = 'UPDATE_COURSE_DONE';
// export const UPDATE_COURSE_FAIL = 'UPDATE_COURSE_FAIL';

// export const GET_COURSES_CALL = 'GET_COURSES_CALL';
// export const GET_COURSES_DONE = 'GET_COURSES_DONE';
// export const GET_COURSES_FAIL = 'GET_COURSES_FAIL';

// export const GET_COURSE_INFO_CALL = 'GET_COURSE_INFO_CALL';
// export const GET_COURSE_INFO_DONE = 'GET_COURSE_INFO_DONE';
// export const GET_COURSE_INFO_FAIL = 'GET_COURSE_INFO_FAIL';

// export const GET_COURSE_INTRO_CALL = 'GET_COURSE_INTRO_CALL';
// export const GET_COURSE_INTRO_DONE = 'GET_COURSE_INTRO_DONE';
// export const GET_COURSE_INTRO_FAIL = 'GET_COURSE_INTRO_FAIL';

// export const UPDATE_COURSE_INTRO_CALL = 'UPDATE_COURSE_INTRO_CALL';
// export const UPDATE_COURSE_INTRO_DONE = 'UPDATE_COURSE_INTRO_DONE';
// export const UPDATE_COURSE_INTRO_FAIL = 'UPDATE_COURSE_INTRO_FAIL';

// export const GET_COURSE_LEVELS_CALL = 'GET_COURSE_LEVELS_CALL';
// export const GET_COURSE_LEVELS_DONE = 'GET_COURSE_LEVELS_DONE';
// export const GET_COURSE_LEVELS_FAIL = 'GET_COURSE_LEVELS_FAIL';

// export const GET_COURSE_CHAPTERS_CALL = 'GET_COURSE_CHAPTERS_CALL';
// export const GET_COURSE_CHAPTERS_DONE = 'GET_COURSE_CHAPTERS_DONE';
// export const GET_COURSE_CHAPTERS_FAIL = 'GET_COURSE_CHAPTERS_FAIL';

// export const EDIT_CHAPTERS_ORDER_CALL = 'EDIT_CHAPTERS_ORDER_CALL';
// export const EDIT_CHAPTERS_ORDER_DONE = 'EDIT_CHAPTERS_ORDER_DONE';
// export const EDIT_CHAPTERS_ORDER_FAIL = 'EDIT_CHAPTERS_ORDER_FAIL';

// export const ADD_CHAPTER_CALL = 'ADD_CHAPTER_CALL';
// export const ADD_CHAPTER_DONE = 'ADD_CHAPTER_DONE';
// export const ADD_CHAPTER_FAIL = 'ADD_CHAPTER_FAIL';

// export const EDIT_CHAPTER_CALL = 'EDIT_CHAPTER_CALL';
// export const EDIT_CHAPTER_DONE = 'EDIT_CHAPTER_DONE';
// export const EDIT_CHAPTER_FAIL = 'EDIT_CHAPTER_FAIL';

// export const DELETE_CHAPTER_CALL = 'DELETE_CHAPTER_CALL';
// export const DELETE_CHAPTER_DONE = 'DELETE_CHAPTER_DONE';
// export const DELETE_CHAPTER_FAIL = 'DELETE_CHAPTER_FAIL';

// export const GET_CHAPTERS_UNITS_CALL = 'GET_CHAPTERS_UNITS_CALL';
// export const GET_CHAPTERS_UNITS_DONE = 'GET_CHAPTERS_UNITS_DONE';
// export const GET_CHAPTERS_UNITS_FAIL = 'GET_CHAPTERS_UNITS_FAIL';

// export const ADD_CHAPTER_UNIT_CALL = 'ADD_CHAPTER_UNIT_CALL';
// export const ADD_CHAPTER_UNIT_DONE = 'ADD_CHAPTER_UNIT_DONE';
// export const ADD_CHAPTER_UNIT_FAIL = 'ADD_CHAPTER_UNIT_FAIL';

// export const EDIT_CHAPTER_UNIT_CALL = 'EDIT_CHAPTER_UNIT_CALL';
// export const EDIT_CHAPTER_UNIT_DONE = 'EDIT_CHAPTER_UNIT_DONE';
// export const EDIT_CHAPTER_UNIT_FAIL = 'EDIT_CHAPTER_UNIT_FAIL';

// export const DELETE_CHAPTER_UNIT_CALL = 'DELETE_CHAPTER_UNIT_CALL';
// export const DELETE_CHAPTER_UNIT_DONE = 'DELETE_CHAPTER_UNIT_DONE';
// export const DELETE_CHAPTER_UNIT_FAIL = 'DELETE_CHAPTER_UNIT_FAIL';

// export const SET_CHAPTER_UNIT = 'SET_CHAPTER_UNIT';
// export const SET_SELECT_UNIT = 'SET_SELECT_UNIT';

// export const GET_COURSE_PRICE_CALL = 'GET_COURSE_PRICE_CALL';
// export const GET_COURSE_PRICE_DONE = 'GET_COURSE_PRICE_DONE';
// export const GET_COURSE_PRICE_FAIL = 'GET_COURSE_PRICE_FAIL';

// export const ADD_COURSE_PRICE_CALL = 'ADD_COURSE_PRICE_CALL';
// export const ADD_COURSE_PRICE_DONE = 'ADD_COURSE_PRICE_DONE';
// export const ADD_COURSE_PRICE_FAIL = 'ADD_COURSE_PRICE_FAIL';

// export const SET_COURSE_PRICE = 'SET_COURSE_PRICE';

// export const GET_COURSE_PUBLISH_CALL = 'GET_COURSE_PUBLISH_CALL';
// export const GET_COURSE_PUBLISH_DONE = 'GET_COURSE_PUBLISH_DONE';
// export const GET_COURSE_PUBLISH_FAIL = 'GET_COURSE_PUBLISH_FAIL';

// export const ADD_COURSE_PUBLISH_CALL = 'ADD_COURSE_PUBLISH_CALL';
// export const ADD_COURSE_PUBLISH_DONE = 'ADD_COURSE_PUBLISH_DONE';
// export const ADD_COURSE_PUBLISH_FAIL = 'ADD_COURSE_PUBLISH_FAIL';

// export const SET_COURSE_PUBLISH = 'SET_COURSE_PUBLISH';

// export const SET_COURSE_CONVERTING_MODAL = 'SET_COURSE_CONVERTING_MODAL';

// 首頁
export const GET_COURSE_VIEW_CALL = 'GET_COURSE_VIEW_CALL';
export const GET_COURSE_VIEW_DONE = 'GET_COURSE_VIEW_DONE';
export const GET_COURSE_VIEW_FAIL = 'GET_COURSE_VIEW_FAIL';

// 課程頁
export const GET_CURRENT_COURSE_CALL = 'GET_CURRENT_COURSE_CALL';
export const GET_CURRENT_COURSE_DONE = 'GET_CURRENT_COURSE_DONE';
export const GET_CURRENT_COURSE_FAIL = 'GET_CURRENT_COURSE_FAIL';
export const GET_CURRENT_COURSE_GENERAL_CALL = 'GET_CURRENT_COURSE_GENERAL_CALL';
export const GET_CURRENT_COURSE_GENERAL_DONE = 'GET_CURRENT_COURSE_GENERAL_DONE';
export const GET_CURRENT_COURSE_GENERAL_FAIL = 'GET_CURRENT_COURSE_GENERAL_FAIL';
export const GET_CURRENT_COURSE_CALL_UPDATE = 'GET_CURRENT_COURSE_CALL_UPDATE';
export const CLEAN_CURRENT_COURSE_CALL = 'CLEAN_CURRENT_COURSE_CALL';

export const UPDATE_COURSE_UNIT_PROGRESS_CALL = 'UPDATE_COURSE_UNIT_PROGRESS_CALL';
export const UPDATE_COURSE_UNIT_PROGRESS_DONE = 'UPDATE_COURSE_UNIT_PROGRESS_DONE';

export const GET_CURRENT_COURSE_PROGRESS_CALL = 'GET_CURRENT_COURSE_PROGRESS_CALL';
export const GET_CURRENT_COURSE_PROGRESS_DONE = 'GET_CURRENT_COURSE_PROGRESS_DONE';
export const GET_CURRENT_COURSE_PROGRESS_FAIL = 'GET_CURRENT_COURSE_PROGRESS_FAIL';
export const GET_CURRENT_COURSE_PROGRESS_UPDATE = 'GET_CURRENT_COURSE_PROGRESS_UPDATE';

export const GET_COURSE_RATING_CALL = 'GET_COURSE_RATING_CALL';
export const GET_COURSE_RATING_DONE = 'GET_COURSE_RATING_DONE';
export const GET_COURSE_RATING_FAIL = 'GET_COURSE_RATING_FAIL';
export const GET_COURSE_RATING_CALL_UPDATE = 'GET_COURSE_RATING_CALL_UPDATE';

export const ADD_COURSE_RATING_CALL = 'ADD_COURSE_RATING_CALL';
export const ADD_COURSE_RATING_DONE = 'ADD_COURSE_RATING_DONE';
export const ADD_COURSE_RATING_FAIL = 'ADD_COURSE_RATING_FAIL';

export const EDIT_COURSE_RATING_CALL = 'EDIT_COURSE_RATING_CALL';
export const EDIT_COURSE_RATING_DONE = 'EDIT_COURSE_RATING_DONE';
export const EDIT_COURSE_RATING_FAIL = 'EDIT_COURSE_RATING_FAIL';

export const GET_PROPAGANDA_REVIEW_CALL = 'GET_PROPAGANDA_REVIEW_CALL';
export const GET_PROPAGANDA_REVIEW_DONE = 'GET_PROPAGANDA_REVIEW_DONE';
export const GET_PROPAGANDA_REVIEW_FAIL = 'GET_PROPAGANDA_REVIEW_FAIL';

export const GET_COURSE_CERTIFICATE_CALL = 'GET_COURSE_CERTIFICATE_CALL';
export const GET_COURSE_CERTIFICATE_DONE = 'GET_COURSE_CERTIFICATE_DONE';
export const GET_COURSE_CERTIFICATE_FAIL = 'GET_COURSE_CERTIFICATE_FAIL';

export const CREATE_COURSE_CERTIFICATE_CALL = 'CREATE_COURSE_CERTIFICATE_CALL';
export const CREATE_COURSE_CERTIFICATE_DONE = 'CREATE_COURSE_CERTIFICATE_DONE';
export const CREATE_COURSE_CERTIFICATE_FAIL = 'CREATE_COURSE_CERTIFICATE_FAIL';

export const UNIT_VIEWED_CALL = 'UNIT_VIEWED_CALL';
export const UNIT_VIEWED_DONE = 'UNIT_VIEWED_DONE';
export const UNIT_VIEWED_FAIL = 'UNIT_VIEWED_FAIL';

export const UNIT_VIEWED_RECORD_CALL = 'UNIT_VIEWED_RECORD_CALL';
export const UNIT_VIEWED_RECORD_DONE = 'UNIT_VIEWED_RECORD_DONE';
export const UNIT_VIEWED_RECORD_FAIL = 'UNIT_VIEWED_RECORD_FAIL';

export const COURSE_VIEWED_RECORD_CALL = 'COURSE_VIEWED_RECORD_CALL';
export const COURSE_VIEWED_RECORD_DONE = 'COURSE_VIEWED_RECORD_DONE';
export const COURSE_VIEWED_RECORD_FAIL = 'COURSE_VIEWED_RECORD_FAIL';
