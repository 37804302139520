import React from 'react';
import styled from 'styled-components';
import { isBoolean } from 'lodash';

import { CURRENCY_SYMBOL } from '@src/const/common';
import { getEventPriceRange, sortEventSlots, getShouldShowSoldOut } from '@src/utils/event';
import {
	HostBy,
	PriceRange,
} from '@src/components/card/style';
import { HorizontalSmallCard as BasicHorizontalSmallCard } from '@src/components/card/basic';
import { getPrice } from '@src/components/card/utils';

import TagContent from './mobileTagContent';
import StatelessContent from './statelessContent';

const StyledHostBy = styled(HostBy)``;

const StyledPriceRange = styled(PriceRange)`
	display: flex;
	justify-content: flex-end;
	.price-range__currency {
		font-size: 16px;
	}

	.price-range__number {
		font-size: 20px;
	}
`;

const getCardContent = ({ isStateless, webinar }) => {
	if (isStateless) {
		return <StatelessContent webinar={webinar} />;
	}

	const {
		host_by: hostBy,
		slots,
	} = webinar;
	let price = { minPrice: 0, maxPrice: 0 };
	if (slots.length) {
		price = getEventPriceRange(slots);
	}
	const { minPrice, maxPrice } = price;
	const isFree = (minPrice === maxPrice) && minPrice === 0;

	return (
		<>
			<StyledHostBy>{hostBy}</StyledHostBy>
			<StyledPriceRange>
				{!isFree && <span className="price-range__currency">{CURRENCY_SYMBOL}</span>}
				<span className="price-range__number">
					{getPrice({ minPrice, maxPrice })}
				</span>
			</StyledPriceRange>
		</>
	);
};

interface IProps {
	onClick?: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	webinar?: any;
	isStateless?: boolean;
	isSoldOut?: boolean
}

const HorizontalSmallCard = ({
	onClick,
	webinar,
	isStateless = false,
	isSoldOut,
}: IProps): JSX.Element => {
	const {
		name: title,
		photos,
		slots,
	} = webinar;
	const photoLinks = photos.map(photo => photo.link);
	const contentElem = getCardContent({ isStateless, webinar });
	const sortedSlots = sortEventSlots(slots);
	const isTicketSoldOut = isBoolean(isSoldOut) ? isSoldOut : getShouldShowSoldOut({ sortedSlots });

	return (
		<BasicHorizontalSmallCard
			onClick={onClick}
			title={title}
			tagText={<TagContent />}
			tagColor="#FFFFFF"
			tagBorderColor="#DDDDDD"
			imageUrl={photoLinks[0]}
			imageAlt={photos[0]?.alt_title}
			contentElem={contentElem}
			isSoldOut={isTicketSoldOut}
		/>
	);
};

export default HorizontalSmallCard;
