import React from 'react';
import styled from 'styled-components';
import useTranslation from '@src/hooks/useTranslation';

import { formatPrice } from '@src/utils/price';
import { CURRENCY_SYMBOL } from '@src/const/common';

const PriceWrapper = styled.div`
	color: ${props => props.color};
	font-size: 22px;
	font-weight: 500;

	.price-wrapper__currency {
		/* font-size: 12px; */
		font-weight: 400;
		margin-right: 4px;
	}

	.price-wrapper__subtotal_price {
		font-size: ${props => props.subtotalPriceSize}px;
		font-weight: 500;
	}

	.price-wrapper__init-price {
		font-size: 12px;
		margin-left: 8px;
		color: #797979;
		text-decoration: line-through;
	}

	.price-wrapper__pre-sale-price-text {
		margin-right: 8px;
		font-size: 12px;
		color: #797979;
	}
`;

interface IProps {
	className?: string;
	color: string;
	isFreeCourse: boolean;
	initPrice: string;
	showInitPrice?: boolean;
	subtotalPrice: string;
	preTextElem?: React.ReactNode;
	subtotalPriceSize?: number;
}

const Price = ({
	className,
	color,
	isFreeCourse,
	initPrice,
	showInitPrice = false,
	subtotalPrice,
	preTextElem,
	subtotalPriceSize = 22,
}: IProps): JSX.Element => {
	const { t } = useTranslation();
	const isFree = formatPrice(subtotalPrice) <= 0 || isFreeCourse;

	return (
		<PriceWrapper className={className} color={color} subtotalPriceSize={subtotalPriceSize}>
			{!isFree ? (
				<>
					{preTextElem && preTextElem}
					<span className="price-wrapper__currency">{CURRENCY_SYMBOL}</span>
					<span className="price-wrapper__subtotal_price">{formatPrice(subtotalPrice)}</span>
					{subtotalPrice !== initPrice && initPrice && showInitPrice && (
						<span className="price-wrapper__init-price">
							{`${CURRENCY_SYMBOL} ${formatPrice(initPrice)}`}
						</span>
					)}
				</>
			) : (
				<div className="price-wrapper__subtotal_price">{t('common:free')}</div>
			)}
		</PriceWrapper>
	);
};

export default Price;
