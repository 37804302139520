import React from 'react';
import cx from 'classnames';
import { Row as AntdRow, Col as AntdCol } from 'antd';

import classes from './styles.module.less';

export function Row(props) {
  const { children, gutter, ...otherProps } = props;

  return (
    <AntdRow gutter={gutter != null ? gutter : 15} {...otherProps}>
      {children}
    </AntdRow>
  );
}

export function Col(props) {
  const { children, ...otherProps } = props;

  return <AntdCol {...otherProps}>{children}</AntdCol>;
}

export function Container(props) {
  const { children, className, ...otherProps } = props;

  return (
    <div className={cx(classes.container, className)} {...otherProps}>
      {children}
    </div>
  );
}
