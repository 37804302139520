import React from 'react';
import useTranslation from '@src/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { getDomain } from '@src/store/selectors/ssr';

const CopyRight = (): JSX.Element => {
	const domain = useSelector(getDomain) as string;
	const { t } = useTranslation();

	// hard code copyright for TC-6133
	if (domain.indexOf('automan.collegeplus.tw') > -1) {
		return <div>© ITRI. 工業技術研究院著作</div>;
	} else if (domain.indexOf('collegeplus.tw') > -1) {
		return <div>© ITRI College+ 產業學院</div>;
	} else {
		return <div>{t('login:footerLessen')}</div>;
	}
};

export default CopyRight;
