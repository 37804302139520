import React from 'react';
import classStyle from './WorkView.module.less';

export default class WorkView extends React.Component {
  render() {
    return (
      <div className={classStyle.container}>
        <img
          className={classStyle.img}
          src={require('@src/assets/images/work.svg')}
        />
      </div>
    );
  }
}
