import { IRecipient, ICheckout } from '@src/types/checkout';

export const selectCheckout = ({ checkout }: { checkout: ICheckout }): ICheckout => checkout;

interface IField {
	fieldId: string;
	value: string;
}

export const getRegisterFormFieldsByEventId = (eventId: string) => ({ checkout }: {
	checkout: ICheckout
}): IField[] => {
	const { registerFormFieldsByEventIdMap } = checkout;
	return registerFormFieldsByEventIdMap[eventId] || [];
};

export const getRegisterFormFieldsByEventIdMap = ({ checkout }: {
	checkout: ICheckout
}): IField[] => {
	const { registerFormFieldsByEventIdMap } = checkout;
	return registerFormFieldsByEventIdMap;
};

export const getRegisterFormFieldsSideEffectsMap = ({ checkout }: {
	checkout: ICheckout
}): IField[] => {
	const { registerFormFieldsSideEffectsMap } = checkout;
	return registerFormFieldsSideEffectsMap;
};
export const getWebinarRegisterFormFieldsSideEffectsMap = ({ checkout }: {
	checkout: ICheckout
}): IField[] => {
	const { webinarRegisterFormFieldsSideEffectsMap } = checkout;
	return webinarRegisterFormFieldsSideEffectsMap;
};

export const getRegisterFormDataBySlotIdMap = ({ checkout }) => checkout.registerFormDataBySlotIdMap;

export const getRegisterFormErrorFieldBySlotIdMap = ({ checkout }) => checkout.registerFormErrorFieldBySlotIdMap;

export const getCheckoutPaymentState = ({ checkout }) => checkout.paymentState;

export const getReservedUntilCountdown = ({ checkout }) => checkout.reservedUntilCountDown;

export const selectAreaInfo = ({ checkout }) => checkout.areaInfo;

export const selectAreaInfoState = ({ checkout }) => checkout.areaInfoState;

// export const selectPurchaseInfoFieldsState = ({ checkout }) => checkout.purchaseInfoFieldsState;

export const selectRecipientInfoFormErrorFields = ({ checkout }) => checkout.recipientInfoFormErrorFields;

export const selectCheckoutFormSetting = ({ checkout }) => checkout.checkoutFormSetting;

export const selectCustomCheckoutFormFieldsInfo = ({ checkout }) => checkout.customCheckoutFormFieldsInfo;

export const selectCustomCheckoutFormErrorFields = ({ checkout }) => checkout.customCheckoutFormErrorFields;

export const getRecipientInfo = ({ checkout }: {
	checkout: ICheckout;
}): IRecipient => checkout.recipientInfo;

export const getIsDefaultAddress = ({ checkout }: {
	checkout: ICheckout;
}): boolean => checkout.isDefaultAddress;
