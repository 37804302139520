export const GET_JOBS_CALL = 'GET_JOBS_CALL';
export const GET_JOBS_DONE = 'GET_JOBS_DONE';
export const GET_JOBS_FAIL = 'GET_JOBS_FAIL';

export const POST_JOB_APPLY_CALL = 'POST_JOB_APPLY_CALL';
export const POST_JOB_APPLY_DONE = 'POST_JOB_APPLY_DONE';
export const POST_JOB_APPLY_FAIL = 'POST_JOB_APPLY_FAIL';

export const GET_JOB_DETAIL_CALL = 'GET_JOB_DETAIL_CALL';
export const GET_JOB_DETAIL_DONE = 'GET_JOB_DETAIL_DONE';
export const GET_JOB_DETAIL_FAIL = 'GET_JOB_DETAIL_FAIL';

export const POST_JOB_BINDING_CALL = 'POST_JOB_BINDING_CALL';
export const POST_JOB_BINDING_DONE = 'POST_JOB_BINDING_DONE';
export const POST_JOB_BINDING_FAIL = 'POST_JOB_BINDING_FAIL';

export const POST_JOB_VIEWED_CALL = 'POST_JOB_VIEWED_CALL';
export const POST_JOB_VIEWED_DONE = 'POST_JOB_VIEWED_DONE';
export const POST_JOB_VIEWED_FAIL = 'POST_JOB_VIEWED_FAIL';
