import React, { ReactNode } from 'react';
import styled from 'styled-components';

import {
	CardWrapper,
	Title,
} from '@src/components/card/style';
import TagWithTail from '@src/components/common/TagWithTail';

const StyledTitle = styled(Title)`
	margin-top: 16px;
	font-size: 24px;

	@media ${props => props.theme.device.smTablet} {
		font-size: 16px;
	}
`;

const StyledCardWrapper = styled(CardWrapper)`
	display: flex;
`;

const DetailContent = styled.div`
	padding: 4px 8px 8px 8px;
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
`;

const MediaWrapper = styled.div`
	width: 484px;
	display: flex;
	align-items: center;
	flex: 0 0 484px;

	@media ${props => props.theme.device.desktop} {
		width: 420px;
		flex: 0 0 420px;
	}
	@media ${props => props.theme.device.tablet} {
		width: 300px;
		flex: 0 0 300px;
	}
	@media ${props => props.theme.device.smTablet} {
		width: 230px;
		flex: 0 0 230px;
	}
`;

const Image = styled.img`
	width: 100%;
`;

interface IProps {
	onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	title: string;
	contentElem: string | ReactNode | ReactNode[];
	imageUrl: string;
	imageAlt?: string;
	tagText: string | ReactNode;
	tagColor: string;
	tagBorderColor?: string;
}

const LargeCard = ({
	onClick,
	title,
	contentElem,
	imageUrl,
	imageAlt,
	tagText,
	tagColor,
	tagBorderColor,
}: IProps): JSX.Element => (
	<StyledCardWrapper onClick={onClick}>
		<MediaWrapper>
			<Image src={imageUrl} alt={imageAlt} />
		</MediaWrapper>
		<DetailContent>
			<TagWithTail text={tagText} backgroundColor={tagColor} tagBorderColor={tagBorderColor} />
			<StyledTitle>{title}</StyledTitle>
			{contentElem}
		</DetailContent>
	</StyledCardWrapper>
);

export default LargeCard;
