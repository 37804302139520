import React from 'react';
import styled from 'styled-components';
import { Skeleton } from 'antd';

const LoadingWrapper = styled.div`
	width: 376px;
`;

const ProductList = styled.div`
	border-bottom: 1px solid #E9E9E9;
	padding: 16px;
`;

const ListItem = styled.div`
	display: flex;
	padding-bottom: 4px;

	&:not(:last-child) {
		border-bottom: 1px solid #E9E9E9;
		padding-bottom: 20px;
		margin-bottom: 16px;
	}

	& > * :not(:first-child) {
		margin-left: 20px;
	}
`;

const PriceButton = styled.div`
	display: flex;
	padding: 16px 16px 0;

	& > * :not(:first-child) {
		margin-left: 20px;
	}
`;

const LoadingContent = (): JSX.Element => (
	<LoadingWrapper>
		<ProductList>
			<ListItem>
				<Skeleton.Input active style={{ width: 100 }} />
				<Skeleton active round title={false} paragraph={{ rows: 2 }} />
			</ListItem>
			<ListItem>
				<Skeleton.Input active style={{ width: 100 }} />
				<Skeleton active round title={false} paragraph={{ rows: 2 }} />
			</ListItem>
		</ProductList>
		<PriceButton>
			<Skeleton active round title={false} paragraph={{ rows: 2 }} />
			<Skeleton.Input active style={{ width: 136 }} />
		</PriceButton>
	</LoadingWrapper>
);

export default LoadingContent;
