import React from 'react';
import { DefaultBanner } from '@src/components/home';

export default class ButtonBackground extends React.Component {
  render() {
    const { item } = this.props;
    const newItem = {
      list: [item],
    };

    return <DefaultBanner item={newItem} isButtonBackground />;
  }
}
