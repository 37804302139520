import { createAction } from 'redux-actions';
import * as types from '@src/store/types';

export const toastShowCall = createAction(types.TOAST_SHOW_CALL);
export const toastShowDone = createAction(types.TOAST_SHOW_DONE);
export const toastShowFail = createAction(types.TOAST_SHOW_FAIL);

export const setModal = createAction(types.SET_MODAL);
export const setCheckModal = createAction(types.SET_CHECK_MODAL);
export const setLockScroll = createAction(types.SET_LOCK_SCROLL);
export const setModalType = createAction(types.SET_MODAL_TYPE);
export const setKeyboardShow = createAction(types.SET_KEYBOARD_SHOW);
