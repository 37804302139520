/* eslint-disable react/prop-types */
import React from 'react';
import ProgressiveImage from 'react-progressive-image';

const MyProgressiveImage = props => {
	const { src, placeholder, className, onLoad, style: propsStyle } = props;

	const handleOnLoad = () => {
		if (onLoad) {
			onLoad(src);
		}
	};

	return (
		<ProgressiveImage src={src} placeholder={placeholder}>
			{(_, loading) => {
				const srcStyle = {
					filter: 'none',
					transition: 'filter 0.15s ease 0s',
				};
				const placeholderStyle = {
					filter: 'blur(20px)',
				};
				const style = loading ? placeholderStyle : srcStyle;

				return (
					<img
						className={className}
						style={{
							...style,
							...propsStyle,
						}}
						src={src}
						onLoad={() => handleOnLoad(src)}
						alt=""
					/>
				);
			}}
		</ProgressiveImage>
	);
};

export default MyProgressiveImage;
