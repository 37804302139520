/* eslint-disable max-len */
export const GET_LEAVE_APPLICATIONS_CALL = 'GET_LEAVE_APPLICATIONS_CALL';
export const GET_LEAVE_APPLICATIONS_DONE = 'GET_LEAVE_APPLICATIONS_DONE';
export const GET_LEAVE_APPLICATIONS_FAIL = 'GET_LEAVE_APPLICATIONS_FAIL';

export const POST_LEAVE_APPLICATION_CALL = 'POST_LEAVE_APPLICATION_CALL';
export const POST_LEAVE_APPLICATION_DONE = 'POST_LEAVE_APPLICATION_DONE';
export const POST_LEAVE_APPLICATION_FAIL = 'POST_LEAVE_APPLICATION_FAIL';

export const GET_LEAVE_APPLICATION_INFO_CALL = 'GET_LEAVE_APPLICATION_INFO_CALL';
export const GET_LEAVE_APPLICATION_INFO_DONE = 'GET_LEAVE_APPLICATION_INFO_DONE';
export const GET_LEAVE_APPLICATION_INFO_FAIL = 'GET_LEAVE_APPLICATION_INFO_FAIL';

export const GET_LEAVE_APPLICATION_DETAIL_CALL = 'GET_LEAVE_APPLICATION_DETAIL_CALL';
export const GET_LEAVE_APPLICATION_DETAIL_DONE = 'GET_LEAVE_APPLICATION_DETAIL_DONE';
export const GET_LEAVE_APPLICATION_DETAIL_FAIL = 'GET_LEAVE_APPLICATION_DETAIL_FAIL';
export const REST_LEAVE_APPLICATION_DETAIL_CALL = 'REST_LEAVE_APPLICATION_DETAIL_CALL';

export const POST_LEAVE_APPLICATION_APPROVE_CALL = 'POST_LEAVE_APPLICATION_APPROVE_CALL';
export const POST_LEAVE_APPLICATION_APPROVE_DONE = 'POST_LEAVE_APPLICATION_APPROVE_DONE';
export const POST_LEAVE_APPLICATION_APPROVE_FAIL = 'POST_LEAVE_APPLICATION_APPROVE_FAIL';

export const GET_LEAVE_APPLICATION_CONFIRM_CALL = 'GET_LEAVE_APPLICATION_CONFIRM_CALL';
export const GET_LEAVE_APPLICATION_CONFIRM_DONE = 'GET_LEAVE_APPLICATION_CONFIRM_DONE';
export const GET_LEAVE_APPLICATION_CONFIRM_FAIL = 'GET_LEAVE_APPLICATION_CONFIRM_FAIL';

export const POST_LEAVE_APPLICATION_CONFIRM_CALL = 'POST_LEAVE_APPLICATION_CONFIRM_CALL';
export const POST_LEAVE_APPLICATION_CONFIRM_DONE = 'POST_LEAVE_APPLICATION_CONFIRM_DONE';
export const POST_LEAVE_APPLICATION_CONFIRM_FAIL = 'POST_LEAVE_APPLICATION_CONFIRM_FAIL';

export const POST_LEAVE_APPLICATION_WITHDRAW_CALL = 'POST_LEAVE_APPLICATION_WITHDRAW_CALL';
export const POST_LEAVE_APPLICATION_WITHDRAW_DONE = 'POST_LEAVE_APPLICATION_WITHDRAW_DONE';
export const POST_LEAVE_APPLICATION_WITHDRAW_FAIL = 'POST_LEAVE_APPLICATION_WITHDRAW_FAIL';

export const GET_LEAVE_APPLICATIONS_APPROVER_VIEW_CALL = 'GET_LEAVE_APPLICATIONS_APPROVER_VIEW_CALL';
export const GET_LEAVE_APPLICATIONS_APPROVER_VIEW_DONE = 'GET_LEAVE_APPLICATIONS_APPROVER_VIEW_DONE';
export const GET_LEAVE_APPLICATIONS_APPROVER_VIEW_FAIL = 'GET_LEAVE_APPLICATIONS_APPROVER_VIEW_FAIL';

export const POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_CALL = 'POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_CALL';
export const POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_DONE = 'POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_DONE';
export const POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_FAIL = 'POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_FAIL';

export const DELETE_LEAVE_APPLICATION_ATTACHMENT_CALL = 'DELETE_LEAVE_APPLICATION_ATTACHMENT_CALL';
export const DELETE_LEAVE_APPLICATION_ATTACHMENT_DONE = 'DELETE_LEAVE_APPLICATION_ATTACHMENT_DONE';
export const DELETE_LEAVE_APPLICATION_ATTACHMENT_FAIL = 'DELETE_LEAVE_APPLICATION_ATTACHMENT_FAIL';

export const GET_AVAILABLE_LEAVE_TYPES_CALL = 'GET_AVAILABLE_LEAVE_TYPES_CALL';
export const GET_AVAILABLE_LEAVE_TYPES_DONE = 'GET_AVAILABLE_LEAVE_TYPES_DONE';
export const GET_AVAILABLE_LEAVE_TYPES_FAIL = 'GET_AVAILABLE_LEAVE_TYPES_FAIL';

export const GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_CALL = 'GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_CALL';
export const GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_DONE = 'GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_DONE';
export const GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_FAIL = 'GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_FAIL';

export const GET_LEAVE_ENTITLEMENT_CALL = 'GET_LEAVE_ENTITLEMENT_CALL';
export const GET_LEAVE_ENTITLEMENT_DONE = 'GET_LEAVE_ENTITLEMENT_DONE';
export const GET_LEAVE_ENTITLEMENT_FAIL = 'GET_LEAVE_ENTITLEMENT_FAIL';

export const GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_CALL = 'GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_CALL';
export const GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_DONE = 'GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_DONE';
export const GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_FAIL = 'GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_FAIL';

export const GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_CALL = 'GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_CALL';
export const GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_DONE = 'GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_DONE';
export const GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_FAIL = 'GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_FAIL';

export const GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_CALL = 'GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_CALL';
export const GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_DONE = 'GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_DONE';
export const GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_FAIL = 'GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_FAIL';

export const GET_CUSTOM_LEAVE_TYPE_RULES_CALL = 'GET_CUSTOM_LEAVE_TYPE_RULES_CALL';
export const GET_CUSTOM_LEAVE_TYPE_RULES_DONE = 'GET_CUSTOM_LEAVE_TYPE_RULES_DONE';
export const GET_CUSTOM_LEAVE_TYPE_RULES_FAIL = 'GET_CUSTOM_LEAVE_TYPE_RULES_FAIL';

export const POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_CALL = 'POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_CALL';
export const POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_DONE = 'POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_DONE';
export const POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_FAIL = 'POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_FAIL';
