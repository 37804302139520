import { createAction } from 'redux-actions';
import * as types from '@src/store/types';

export const setToken = createAction(types.SET_TOKEN);
export const setPreviewToken = createAction(types.SET_PREVIEW_TOKEN);

export const checkLoginCall = createAction(types.CHECK_LOGIN_CALL);
export const checkLoginDone = createAction(types.CHECK_LOGIN_DONE);
export const checkLoginFail = createAction(types.CHECK_LOGIN_FAIL);

export const checkIsSignedIn = createAction(types.CHECK_IS_SIGNED_IN);

export const checkLoginSessionCall = createAction(types.CHECK_LOGIN_SESSION_CALL);
export const checkLoginSessionDone = createAction(types.CHECK_LOGIN_SESSION_DONE);
export const checkLoginSessionFail = createAction(types.CHECK_LOGIN_SESSION_FAIL);

export const logoutCall = createAction(types.LOGOUT_CALL);
export const logoutDone = createAction(types.LOGOUT_DONE);
export const logoutFail = createAction(types.LOGOUT_FAIL);

export const emailLoginCall = createAction(types.EMAIL_LOGIN_CALL);
export const emailLoginDone = createAction(types.EMAIL_LOGIN_DONE);
export const emailLoginFail = createAction(types.EMAIL_LOGIN_FAIL);

export const emailRegisterCall = createAction(types.EMAIL_REGISTER_CALL);
export const emailRegisterDone = createAction(types.EMAIL_REGISTER_DONE);
export const emailRegisterFail = createAction(types.EMAIL_REGISTER_FAIL);

export const socialLoginPrepareCall = createAction(types.SOCIAL_LOGIN_PREPARE_CALL);
export const socialLoginPrepareDone = createAction(types.SOCIAL_LOGIN_PREPARE_DONE);
export const socialLoginPrepareFail = createAction(types.SOCIAL_LOGIN_PREPARE_FAIL);

export const socialLoginCall = createAction(types.SOCIAL_LOGIN_CALL);
export const socialLoginDone = createAction(types.SOCIAL_LOGIN_DONE);
export const socialLoginFail = createAction(types.SOCIAL_LOGIN_FAIL);

export const emailConfirmCall = createAction(types.EMAIL_CONFIRM_CALL);
export const emailConfirmDone = createAction(types.EMAIL_CONFIRM_DONE);
export const emailConfirmFail = createAction(types.EMAIL_CONFIRM_FAIL);

export const emailResendCall = createAction(types.EMAIL_RE_SEND_CALL);
export const emailReSendDone = createAction(types.EMAIL_RE_SEND_DONE);
export const emailReSendFail = createAction(types.EMAIL_RE_SEND_FAIL);
export const emailResendStateReset = createAction(types.EMAIL_RESEND_STATE_RESET);

export const updateMobileCall = createAction(types.UPDATE_MOBILE_CALL);
export const updateMobileDone = createAction(types.UPDATE_MOBILE_DONE);
export const updateMobileFail = createAction(types.UPDATE_MOBILE_FAIL);

export const confirmMobileCall = createAction(types.CONFIRM_MOBILE_CALL);
export const confirmMobileDone = createAction(types.CONFIRM_MOBILE_DONE);
export const confirmMobileFail = createAction(types.CONFIRM_MOBILE_FAIL);

export const resetUpdateMobile = createAction(types.RESET_UPDATE_MOBILE);

export const forgetPasswordCall = createAction(types.FORGET_PASSWORD_CALL);
export const forgetPasswordDone = createAction(types.FORGET_PASSWORD_DONE);
export const forgetPasswordFail = createAction(types.FORGET_PASSWORD_FAIL);

export const getBusinessForgetPasswordEmailCall = createAction(types.GET_BUSINESS_FORGET_PASSWORD_EMAIL_CALL);
export const getForgetPasswordEmailCall = createAction(types.GET_FORGET_PASSWORD_EMAIL_CALL);
export const getForgetPasswordEmailDone = createAction(types.GET_FORGET_PASSWORD_EMAIL_DONE);
export const getForgetPasswordEmailFail = createAction(types.GET_FORGET_PASSWORD_EMAIL_FAIL);

export const resetPasswordCall = createAction(types.RESET_PASSWORD_CALL);
export const resetPasswordDone = createAction(types.RESET_PASSWORD_DONE);
export const resetPasswordFail = createAction(types.RESET_PASSWORD_FAIL);

export const sendBugMsgCall = createAction(types.SEND_BUG_MSG_CALL);
export const sendBugMsgDone = createAction(types.SEND_BUG_MSG_DONE);
export const sendBugMsgFail = createAction(types.SEND_BUG_MSG_FAIL);

export const rfaLoginCall = createAction(types.RFA_LOGIN_CALL);
export const rfaLoginDone = createAction(types.RFA_LOGIN_DONE);
export const rfaLoginFail = createAction(types.RFA_LOGIN_FAIL);

export const googleDomainNotAllow = createAction(types.GOOGLE_DOMAIN_NOT_ALLOW);
