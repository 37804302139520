import { createAction } from 'redux-actions';

import {
	GET_WEBINAR_CALL,
	GET_WEBINAR_DONE,
	GET_WEBINAR_FAIL,
	GET_WEBINARS_CALL,
	GET_WEBINARS_DONE,
	GET_WEBINARS_FAIL,
	SET_CURRENT_WEBINAR_PRICE,
	SET_WEBINAR_COUNTDOWN_FOR_SALE,
} from '@src/store/types/webinar';

export const getWebinarCall = createAction(GET_WEBINAR_CALL);
export const getWebinarDone = createAction(GET_WEBINAR_DONE);
export const getWebinarFail = createAction(GET_WEBINAR_FAIL);

export const getWebinarsCall = createAction(GET_WEBINARS_CALL);
export const getWebinarsDone = createAction(GET_WEBINARS_DONE);
export const getWebinarsFail = createAction(GET_WEBINARS_FAIL);

export const setCurrentWebinarPrice = createAction(SET_CURRENT_WEBINAR_PRICE);

export const setWebinarCountdownForSale = createAction(SET_WEBINAR_COUNTDOWN_FOR_SALE);
