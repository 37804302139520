import { createAction } from 'redux-actions';
import * as types from '@src/store/types';

// 首頁
export const getCourseViewCall = createAction(types.GET_COURSE_VIEW_CALL);
export const getCourseViewDone = createAction(types.GET_COURSE_VIEW_DONE);
export const getCourseViewFail = createAction(types.GET_COURSE_VIEW_FAIL);

// 課程頁
export const getCurrentCourseCall = createAction(types.GET_CURRENT_COURSE_CALL);
export const getCurrentCourseDone = createAction(types.GET_CURRENT_COURSE_DONE);
export const getCurrentCourseFail = createAction(types.GET_CURRENT_COURSE_FAIL);
export const getCurrentCourseGeneralCall = createAction(types.GET_CURRENT_COURSE_GENERAL_CALL);
export const getCurrentCourseGeneralDone = createAction(types.GET_CURRENT_COURSE_GENERAL_DONE);
export const getCurrentCourseGeneralFail = createAction(types.GET_CURRENT_COURSE_GENERAL_FAIL);
export const getCurrentCourseCallUpdate = createAction(types.GET_CURRENT_COURSE_CALL_UPDATE);
export const cleanCurrentCourse = createAction(types.CLEAN_CURRENT_COURSE_CALL);

export const updateCourseUnitProgressCall = createAction(types.UPDATE_COURSE_UNIT_PROGRESS_CALL);
export const updateCourseUnitProgressDone = createAction(types.UPDATE_COURSE_UNIT_PROGRESS_DONE);

export const getCurrentCourseProgressCall = createAction(types.GET_CURRENT_COURSE_PROGRESS_CALL);
export const getCurrentCourseProgressDone = createAction(types.GET_CURRENT_COURSE_PROGRESS_DONE);
export const getCurrentCourseProgressFail = createAction(types.GET_CURRENT_COURSE_PROGRESS_FAIL);
export const getCurrentCourseProgressUpdate = createAction(types.GET_CURRENT_COURSE_PROGRESS_UPDATE);

export const getCourseRatingCall = createAction(types.GET_COURSE_RATING_CALL);
export const getCourseRatingDone = createAction(types.GET_COURSE_RATING_DONE);
export const getCourseRatingFail = createAction(types.GET_COURSE_RATING_FAIL);
export const getCourseRatingCallUpdate = createAction(types.GET_COURSE_RATING_CALL_UPDATE);

export const addCourseRatingCall = createAction(types.ADD_COURSE_RATING_CALL);
export const addCourseRatingDone = createAction(types.ADD_COURSE_RATING_DONE);
export const addCourseRatingFail = createAction(types.ADD_COURSE_RATING_FAIL);

export const editCourseRatingCall = createAction(types.EDIT_COURSE_RATING_CALL);
export const editCourseRatingDone = createAction(types.EDIT_COURSE_RATING_DONE);
export const editCourseRatingFail = createAction(types.EDIT_COURSE_RATING_FAIL);

export const getPropagandaReviewCall = createAction(types.GET_PROPAGANDA_REVIEW_CALL);
export const getPropagandaReviewDone = createAction(types.GET_PROPAGANDA_REVIEW_DONE);
export const getPropagandaReviewFail = createAction(types.GET_PROPAGANDA_REVIEW_FAIL);

export const getCourseCertificateCall = createAction(types.GET_COURSE_CERTIFICATE_CALL);
export const getCourseCertificateDone = createAction(types.GET_COURSE_CERTIFICATE_DONE);
export const getCourseCertificateFail = createAction(types.GET_COURSE_CERTIFICATE_FAIL);

export const createCourseCertificateCall = createAction(types.CREATE_COURSE_CERTIFICATE_CALL);
export const createCourseCertificateDone = createAction(types.CREATE_COURSE_CERTIFICATE_DONE);
export const createCourseCertificateFail = createAction(types.CREATE_COURSE_CERTIFICATE_FAIL);

export const unitViewedCall = createAction(types.UNIT_VIEWED_CALL);
export const unitViewedDone = createAction(types.UNIT_VIEWED_DONE);
export const unitViewedFail = createAction(types.UNIT_VIEWED_FAIL);

export const unitViewRecordCall = createAction(types.UNIT_VIEWED_RECORD_CALL);
export const unitViewRecordDone = createAction(types.UNIT_VIEWED_RECORD_DONE);
export const unitViewRecordFail = createAction(types.UNIT_VIEWED_RECORD_FAIL);

export const courseViewRecordCall = createAction(types.COURSE_VIEWED_RECORD_CALL);
export const courseViewRecordDone = createAction(types.COURSE_VIEWED_RECORD_DONE);
export const courseViewRecordFail = createAction(types.COURSE_VIEWED_RECORD_FAIL);
