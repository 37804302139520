export const PRODUCT_TYPES = {
  course: 'course',
  fund_course: 'fund_course'
};

export const CLASS_LEVEL_ICONS = {
  junior: 'icon_level1_21',
  senior: 'icon_level2_22',
  pro: 'icon_level3_23'
};

export const COURSE_STATUS = {
  fundraising: 'fundraising',
  fundraising_scheduled: 'fundraising_scheduled',
  fundraising_refunded: 'fundraising_refunded',
  fundraising_failed: 'fundraising_failed',
  preparing: 'preparing',
  published: 'published',
  archived: 'archived',
  disabled: 'disabled'
};

// TODO: Use translation to prevent hard code
export const COURSE_STATUS_LABELS = {
  fundraising: '募資中',
  fundraising_scheduled: '募資中',
  fundraising_refunded: '募資結束',
  fundraising_failed: '募資結束',
  preparing: '募資成功',
  published: '已開課',
  archived: '已刪除',
  disabled: '已下架'
};
