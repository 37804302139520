/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { ThemeButton } from '@src/components/home';
import classNames from 'classnames';
import { MyProgressiveImage } from '@src/components';
import classStyle from './DefaultBanner.module.less';

const DefaultBanner = props => {
	const { item, isButtonBackground } = props;
	const { list = [] } = item;

	if (list.length === 0) {
		return null;
	}

	const [isLoadImage, setIsLoadImage] = useState(false);
	const data = list[0];
	const { title, subTitle, button, image, thumbnailLink } = data;

	const handleOnLoad = () => {
		setIsLoadImage(true);
	};

	return (
		<div
			className={classNames([
				classStyle.container,
				isButtonBackground && classStyle.isButtonBackground,
			])}
		>
			<MyProgressiveImage
				className={classStyle.image}
				src={image}
				placeholder={thumbnailLink}
				onLoad={handleOnLoad}
			/>
			<div
				className={classNames([
					classStyle.banner,
					isLoadImage && classStyle.bannerLinear,
				])}
			>
				<div className={classStyle.content}>
					<div className={classStyle.title}>{title}</div>
					<div className={classStyle.subTitle}>{subTitle}</div>
					<ThemeButton className={classStyle.button} button={button} />
				</div>
			</div>
		</div>
	);
};

export default DefaultBanner;
