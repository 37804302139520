import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import useTranslation from '@src/hooks/useTranslation';

import { IColors } from '@src/types/theme';
import { getColorTheme } from '@src/store/selectors/theme';
import { HorizontalSmallCard as BasicHorizontalSmallCard } from '@src/components/card/basic';
import {
	HostBy,
	InfoItem,
	StyledRateStarIcon,
} from '@src/components/card/style';
import { calcRatingScore, isFundraising, isPreparing, showStudent } from '@src/utils/course';
import { COURSE_TYPE_MIRROR, COURSE_TYPE_INTERNAL_COURSE } from '@src/const/course';
import { getDomain } from '@src/store/selectors';
import { coursePriceVisible } from '@src/utils/hardcode';

import { getTagText } from './utils';
import Price from './price';

const StyledInfoItem = styled(InfoItem)`
	padding: 2px 0px 0px 0px;
	color: #797979;
`;

const StyledPrice = styled(Price)`
	font-size: 16px;
	align-items: center;
	justify-content: flex-end;
	display: flex;
`;

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const StyledWrapperEnd = styled(StyledWrapper)`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
`;

const StudentHr = styled.div`
	border-left: 1px solid #E9E9E9;
	width: 1px;
	height: 10px;
	margin: 0px 6px;
`;

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	course: any;
	photos: string[];
	onClick: () => void;
}

const HorizontalSmallCard = ({
	course,
	photos,
	onClick,
}: IProps): JSX.Element => {
	const {
		status,
		teacher,
		title,
		is_free_course: isFreeCourse,
		init_price: initPrice,
		subtotal_price: subtotalPrice,
		rate,
		students,
		advance_settings: advanceSettings,
		type,
		hide_price: isHidePrice,
	} = course;
	const { t } = useTranslation();
	const { colors: { primary, secondary } } = useSelector(getColorTheme) as { colors: IColors };
	const tagColor = status === 'published' ? primary : secondary;
	const tagText = getTagText({ t, status });
	const { total_votes: totalVotes, total_score: totalScore } = rate;
	const { score } = calcRatingScore({ totalScore, totalVotes });
	const domain = useSelector(getDomain) as string;
	const isCoursePriceVisible = coursePriceVisible(domain);
	const isCourseFundraising = isFundraising(status);
	const isCoursePreparing = isPreparing(status);
	const showStudents = showStudent({ advanceSettings, students });
	const isMirrorCourse = type === COURSE_TYPE_MIRROR;
	const isInternalCourse = type === COURSE_TYPE_INTERNAL_COURSE;
	const info = () => {
		if (isCourseFundraising) {
			return (
				<StyledInfoItem>
					{t('course:status:fundraising')}
				</StyledInfoItem>
			);
		} else if (isCoursePreparing) {
			return (
				<StyledInfoItem>
					{t('course:status:preparing')}
				</StyledInfoItem>
			);
		}
		return (
			<StyledInfoItem>
				<StyledRateStarIcon />
				{score}
				{
					showStudents && (
						<>
							<StudentHr />
							{t('card:student_amount', { students })}
						</>
					)
				}
			</StyledInfoItem>
		);
	};

	return (
		<BasicHorizontalSmallCard
			onClick={onClick}
			title={title}
			tagText={tagText[0]}
			tagColor={tagColor}
			imageUrl={photos[0]}
			contentElem={(
				<>
					<HostBy>{teacher.name}</HostBy>
					{
						showStudents ? (
							<>
								<StyledWrapper>
									{info()}
								</StyledWrapper>
								<StyledWrapperEnd>
									{
										// eslint-disable-next-line max-len
										!isMirrorCourse && !isInternalCourse && isCoursePriceVisible && !isHidePrice && (
											<StyledPrice
												color={tagColor}
												isFreeCourse={isFreeCourse}
												initPrice={initPrice}
												subtotalPrice={subtotalPrice}
												subtotalPriceSize={20}
											/>
										)
									}
								</StyledWrapperEnd>
							</>
						) : (
							<StyledWrapper>
								{info()}
								{
									!isMirrorCourse && !isInternalCourse && isCoursePriceVisible && !isHidePrice && (
										<StyledPrice
											color={tagColor}
											isFreeCourse={isFreeCourse}
											initPrice={initPrice}
											subtotalPrice={subtotalPrice}
											subtotalPriceSize={20}
										/>
									)
								}
							</StyledWrapper>
						)
					}
				</>
			)}
		/>
	);
};

export default HorizontalSmallCard;
