export const GET_QUIZZES_QUESTION_SHEET_CALL = 'GET_QUIZZES_QUESTION_SHEET_CALL';
export const GET_QUIZZES_QUESTION_SHEET_DONE = 'GET_QUIZZES_QUESTION_SHEET_DONE';
export const GET_QUIZZES_QUESTION_SHEET_FAIL = 'GET_QUIZZES_QUESTION_SHEET_FAIL';

export const GET_QUIZZES_ANSWER_SHEET_CALL = 'GET_QUIZZES_ANSWER_SHEET_CALL';
export const GET_QUIZZES_ANSWER_SHEET_DONE = 'GET_QUIZZES_ANSWER_SHEET_DONE';
export const GET_QUIZZES_ALL_ANSWER_SHEET_DONE = 'GET_QUIZZES_ALL_ANSWER_SHEET_DONE';
export const GET_QUIZZES_ANSWER_SHEET_FAIL = 'GET_QUIZZES_ANSWER_SHEET_FAIL';

export const GET_QUIZZES_ANSWER_SHEET_VIEW_ANSWER_CALL = 'GET_QUIZZES_ANSWER_SHEET_VIEW_ANSWER_CALL';
export const GET_QUIZZES_ANSWER_SHEET_VIEW_ANSWER_DONE = 'GET_QUIZZES_ANSWER_SHEET_VIEW_ANSWER_DONE';
export const GET_QUIZZES_ANSWER_SHEET_VIEW_ANSWER_FAIL = 'GET_QUIZZES_ANSWER_SHEET_VIEW_ANSWER_FAIL';

export const SET_QUIZZES_ANSWER_SHEET_CALL = 'SET_QUIZZES_ANSWER_SHEET_CALL';
export const SET_QUIZZES_ANSWER_SHEET_DONE = 'SET_QUIZZES_ANSWER_SHEET_DONE';
export const SET_QUIZZES_ANSWER_SHEET_FAIL = 'SET_QUIZZES_ANSWER_SHEET_FAIL';
