import { createAction } from 'redux-actions'
import * as types from '@src/store/types'

export const getThemesCall = createAction(types.GET_THEMES_CALL)
export const getThemesDone = createAction(types.GET_THEMES_DONE)
export const getThemesFail = createAction(types.GET_THEMES_FAIL)

export const updateThemesCall = createAction(types.UPDATE_THEMES_CALL)
export const updateThemesDone = createAction(types.UPDATE_THEMES_DONE)
export const updateThemesFail = createAction(types.UPDATE_THEMES_FAIL)

export const setThemes = createAction(types.SET_THEMES)