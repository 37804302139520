import React from 'react';
import Image from 'react-image';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import classStyle from './LogoComponent.module.less';

const LogoWrapper = styled.div`
	cursor: pointer;
`;

export function LogoComponent({ logoLink, textColorStyle }) {
	const router = useRouter();

	const handleOnClickLogo = () => {
		const { pathname } = router;
		if (pathname === '/') {
			router.reload('/');
		} else {
			router.push('/');
		}
	};

	return (
		<LogoWrapper role="presentation" onClick={handleOnClickLogo}>
			<div className={classStyle.logoDiv}>
				{logoLink ? (
					<Image className={classStyle.logoImage} src={logoLink} />
				) : (
					<div className={classStyle.defaultLogo} style={textColorStyle}>
						YOUR LOGO
					</div>
				)}
			</div>
		</LogoWrapper>
	);
}

export default LogoComponent;
