/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getLearningPaths = ({ learningPath }) => learningPath.learningPaths;

export const getLearningPathsByKey = key => ({ learningPath }) => learningPath.learningPathsByKey[key] || [];

export const getMineLearningPaths = ({ user }) => user.mineLearningPath;

export const getMineLearningPathsSideEffect = ({ user }) => user.mineLearningPathSideEffect;

export const getLearningPathDetail = ({ learningPath }) => learningPath.learningPathDetail;

export const getLearningPathsDetailSideEffect = ({ learningPath }) => learningPath.getLearningPathsDetailSideEffect;

export const getLearningPathsCommodities = ({ learningPath }) => learningPath.learningPathsCommodities;

export const getLearningPathsCommoditiesSideEffect = ({ learningPath }) => (
	learningPath.getLearningPathsCommoditiesSideEffect
);

export const getLearningPathProgress = ({ learningPath }) => learningPath.learningPathsProgress;

export const getLearningPathCertificate = ({ learningPath }) => learningPath.postLearningPathCertificate;
