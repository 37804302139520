import styled from 'styled-components';

export const CardsPaddingWrapper = styled.div`
padding: 24px 0px;
`;

export const StyledHorizontalSmallCard = styled(CardsPaddingWrapper)`
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 0px;
`;

export const MediumCardsWrapper = styled(CardsPaddingWrapper)`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 20px;
	grid-row-gap: 20px;

	@media ${props => props.theme.device.smTablet} {
		grid-template-columns: repeat(1, 1fr);
		
	}
	@media ${props => props.theme.device.mobile} {
		grid-template-columns: repeat(1, 1fr);
	}
`;

export const SmallCardsWrapper = styled(CardsPaddingWrapper)`
	display: grid;
	grid-template-columns: repeat(${props => (props.itemNumber || 4)}, 1fr);
	grid-column-gap: 20px;
	grid-row-gap: 20px;

	@media ${props => props.theme.device.tablet} {
		grid-template-columns: repeat(3, 1fr);
	}
	@media ${props => props.theme.device.smTablet} {
		grid-template-columns: repeat(2, 1fr);
	}
	@media ${props => props.theme.device.mobile} {
		grid-template-columns: repeat(1, 1fr);
		grid-row-gap: 8px;
	}
`;
