import React, { useEffect } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import useTranslation from '@src/hooks/useTranslation';
import { useDispatch, useSelector } from 'react-redux';

import { getCommoditySearchCall } from '@src/store/actions/category';
import { getCourseByCategory } from '@src/store/selectors/category';

import Courses from '../components/courses';

const PaddingWrapper = styled.div`
	@media (max-width: 576px) {
		padding: 16px 0;
	}
	@media (min-width: 576px) {
		padding: 16px 0;
	}
	@media (min-width: 768px) {
		padding: 32px 0;
	}
	@media (min-width: 992px) {
		padding: 50px 0;
	}
	@media (min-width: 1200px) {
		padding: 50px 0;
	}
`;

const CourseProducts = ({ item }) => {
	const { t } = useTranslation();
	const defaultTitle = t('course:course_products');
	const { sectionTitle, categoryId = 'all', amount = 8 } = item;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			getCommoditySearchCall({
				category_short_link: categoryId === 'all' ? '' : categoryId,
				page: 0,
				page_size: parseInt(amount),
			}),
		);
	}, [categoryId]);

	const courseCategory = useSelector(getCourseByCategory);
	return (
		<PaddingWrapper>
			<Courses
				title={sectionTitle || defaultTitle}
				categoryId={categoryId}
				amount={amount}
				course={courseCategory[categoryId] || []}
			/>
		</PaddingWrapper>
	);
};

export default CourseProducts;
