import { checkIsServer } from '@src/utils/deprecatedCommon';

export const reactLocalStorage = {
	get(key, defaultValue = null, silent = true) {
		if (checkIsServer()) {
			return defaultValue;
		}

		const value = window.localStorage[key] || defaultValue;

		// if silent=false throw error
		if (!silent && !value) {
			throw new Error(`${key} not found in localStorage`);
		}

		return value;
	},
	set(key, value) {
		if (checkIsServer()) return;
		window.localStorage[key] = value;
		return window.localStorage[key];
	},
	remove(key) {
		if (checkIsServer()) return;
		return window.localStorage.removeItem(key);
	},
	getAll() {
		if (checkIsServer()) return;
		const values = {};
		for (const key in window.localStorage) {
			if (window.localStorage.hasOwnProperty(key)) {
				values[key] = this.get(key);
			}
		}
		return values;
	}
};
