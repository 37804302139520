import React from 'react';
import styled from 'styled-components';
import useTranslation from '@src/hooks/useTranslation';
import numeral from 'numeral';

import Button from '@src/components/common/button';
import { BUTTON_VARIANT_CONTAINED } from '@src/const/common';
import { AccountIcon, DollarSignIcon } from '@src/components/common/icon';

const Content = styled.div`
	width: 100%;
	max-width: 800px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	background: #F9F9F9;
	border-radius: 12px;
	padding: 20px;
	cursor: pointer;

	:hover {
		.button-style {
			background: #005ABE;
		}
	}
`;

const Title = styled.div`
	font-weight: 500;
	font-size: 18px;
	color: #000;
`;

const BlueText = styled.span`
	color: #3091FD;
	:last-child {
		margin-right: 6px;
	}
`;

const SpaceText = styled.span`
	margin-left: 4px;
`;

const RowWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 16px;
	font-size: 14px;
	color: #000;

	@media ${props => props.theme.device.mobile} {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const InfoWrap = styled.div`
	display: flex;
	align-items: center;
`;

const StyledDollarSignIcon = styled(DollarSignIcon)`
	margin-left: 6px;

	@media ${props => props.theme.device.mobile} {
		margin-left: 0;
	}
`;

const StyledButton = styled(Button)`
	padding: 8px 0;
	width: 120px;
	border: unset;
	white-space: nowrap;
	background: #3091FD;
	margin-left: 12px;

	@media ${props => props.theme.device.mobile} {
		width: 80px;
	}
`;

const JobCard = ({
	id,
	jobTitle,
	requiredNumber,
	jobType,
	seniorityType,
	salaryMin,
	salaryMax,
	currency,
	salaryType,
	className,
} : {
	id: string;
	jobTitle: string;
	requiredNumber: number;
	jobType: string;
	seniorityType: string;
	salaryMin: number;
	salaryMax: number;
	currency: string;
	salaryType: string;
	className?: string;
}): JSX.Element => {
	const { t } = useTranslation();
	return (
		<Content
			className={className}
			onClick={() => {
				window.open(`/jobs/${id}`, '_blank');
			}}
		>
			<div>
				<Title>{jobTitle}</Title>
				<RowWrap>
					<InfoWrap>
						{
							requiredNumber && (
								<>
									<AccountIcon />
									<span>{requiredNumber}</span>
								</>
							)
						}
						{
							jobType && (
								<>
									{requiredNumber && <span>・</span>}
									<BlueText>{jobType}</BlueText>
								</>
							)
						}
						{
							seniorityType && (
								<>
									{(requiredNumber || seniorityType) && <span>・</span>}
									<BlueText>{seniorityType}</BlueText>
								</>
							)
						}
					</InfoWrap>
					{
						(salaryMin || salaryMax) && (
							<InfoWrap>
								{(salaryMin || !salaryMax) && <StyledDollarSignIcon />}
								<span>{salaryMin ? numeral(salaryMin).format('0,0') : ''}</span>
								<span>~</span>
								{!salaryMin && salaryMax && <DollarSignIcon />}
								<span>{salaryMax ? numeral(salaryMax).format('0,0') : ''}</span>
								<SpaceText>{currency}</SpaceText>
								<span>/</span>
								<span>{salaryType}</span>
							</InfoWrap>
						)
					}
				</RowWrap>
			</div>
			<StyledButton
				className="button-style"
				variant={BUTTON_VARIANT_CONTAINED}
			>
				{t('job:view')}
			</StyledButton>
		</Content>
	);
};

export default JobCard;
