import styled from 'styled-components';

interface IErrorMsg {
	isError: boolean;
}

const ErrorMsg = styled.div`
	display: ${(props: IErrorMsg) => (props.isError ? 'block' : 'none')};
	color: #D0021B;
	font-size: 14px;
	font-weight: 300;
`;

export default ErrorMsg;
