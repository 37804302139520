export const GET_SCHOOLS_CALL = 'GET_SCHOOLS_CALL';
export const GET_SCHOOLS_DONE = 'GET_SCHOOLS_DONE';
export const GET_SCHOOLS_FAIL = 'GET_SCHOOLS_FAIL';

export const ADD_SCHOOL_CALL = 'ADD_SCHOOL_CALL';
export const ADD_SCHOOL_DONE = 'ADD_SCHOOL_DONE';
export const ADD_SCHOOL_FAIL = 'ADD_SCHOOL_FAIL';

export const GET_SCHOOL_PLANS_CALL = 'GET_SCHOOL_PLANS_CALL';
export const GET_SCHOOL_PLANS_DONE = 'GET_SCHOOL_PLANS_DONE';
export const GET_SCHOOL_PLANS_FAIL = 'GET_SCHOOL_PLANS_FAIL';

export const CHECK_HOST_NAME_EXIST_CALL = 'CHECK_HOST_NAME_EXIST_CALL';
export const CHECK_HOST_NAME_EXIST_DONE = 'CHECK_HOST_NAME_EXIST_DONE';
export const CHECK_HOST_NAME_EXIST_FAIL = 'CHECK_HOST_NAME_EXIST_FAIL';

export const SET_HOST_NAME_SUCCESS = 'SET_HOST_NAME_SUCCESS';
export const SET_SCHOOL_PLAN = 'SET_SCHOOL_PLAN';
export const SET_SCHOOL_ID = 'SET_SCHOOL_ID';

export const GET_TEACHERS_CALL = 'GET_TEACHERS_CALL';
export const GET_TEACHERS_DONE = 'GET_TEACHERS_DONE';
export const GET_TEACHERS_FAIL = 'GET_TEACHERS_FAIL';

export const ADD_TEACHER_CALL = 'ADD_TEACHER_CALL';
export const ADD_TEACHER_DONE = 'ADD_TEACHER_DONE';
export const ADD_TEACHER_FAIL = 'ADD_TEACHER_FAIL';

export const UPDATE_TEACHER_CALL = 'UPDATE_TEACHER_CALL';
export const UPDATE_TEACHER_DONE = 'UPDATE_TEACHER_DONE';
export const UPDATE_TEACHER_FAIL = 'UPDATE_TEACHER_FAIL';

export const DELETE_TEACHER_CALL = 'DELETE_TEACHER_CALL';
export const DELETE_TEACHER_DONE = 'DELETE_TEACHER_DONE';
export const DELETE_TEACHER_FAIL = 'DELETE_TEACHER_FAIL';

// 取得所有註冊欄位
export const GET_REGISTER_FIELDS_CALL = 'GET_REGISTER_FIELDS_CALL';
export const GET_REGISTER_FIELDS_DONE = 'GET_REGISTER_FIELDS_DONE';
export const GET_REGISTER_FIELDS_FAIL = 'GET_REGISTER_FIELDS_FAIL';

export const GET_SCHOOL_SEARCH_CALL = 'GET_SCHOOL_SEARCH_CALL';
export const GET_SCHOOL_SEARCH_DONE = 'GET_SCHOOL_SEARCH_DONE';
export const GET_SCHOOL_SEARCH_FAIL = 'GET_SCHOOL_SEARCH_FAIL';
