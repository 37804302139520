/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import useTranslation from '@src/hooks/useTranslation';

import useMediaQuery from '@src/hooks/useMediaQuery';
import { SmallCard, HorizontalSmallCard } from '@src/components/card/learningPath';
import { SmallCardsWrapper, StyledHorizontalSmallCard } from '@src/components/home/components/cardStyle';
import { getLearningPathsCall } from '@src/store/actions/learningPath';
import { getLearningPaths, getLearningPathsByKey } from '@src/store/selectors/learningPath';

const PaddingWrapper = styled.div`
	@media (min-width: 768px) {
		padding: 32px 0;
	}
  @media
		(max-width: 576px),
		(min-width: 576px) {
			padding: 16px 0;
		}
  @media
		(min-width: 992px),
		(min-width: 1200px) {
			padding: 50px 0;
		}
`;

const Title = styled.div`
	font-size: 22px;
	font-weight: 500;
	padding-left: 10px;
	margin-bottom: 15px;
	border-left: 4px solid ${props => props.theme.colors.secondary};
	color: ${props => props.theme.colors.secondary};
`;

const Divider = styled.div`
  border-bottom: 1px solid #cfcfcf;
`;

const selectCardComponents = ({ mediaMatch }) => {
	const { isMobile } = mediaMatch;

	if (isMobile) {
		return {
			Card: HorizontalSmallCard,
			CardsWrapper: StyledHorizontalSmallCard,
		};
	}

	return {
		Card: SmallCard,
		CardsWrapper: SmallCardsWrapper,
	};
};

interface LearningPathProps {
	item: {
		chosen: boolean;
		editableList: string[];
		isPreview: boolean;
		key: string;
		name: string;
		type: string;
		sectionTitle: string;
		list?: string[];
	};
}

const LearningPath = ({ item }: LearningPathProps): JSX.Element => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { mediaMatch } = useMediaQuery();
	const { sectionTitle, key } = item;
	let { list = [] } = item;
	list = list.filter(i => !!i);
	const path_ids = list.join(',');
	useEffect(() => {
		if (path_ids) {
			dispatch(getLearningPathsCall({ key, path_ids }));
		}
	}, []);
	let paths = [];
	if (path_ids) {
		paths = useSelector(getLearningPathsByKey(key));
	} else {
		paths = useSelector(getLearningPaths) || [];
	}
	const defaultTitle = t('learning_path:title');
	const { Card, CardsWrapper } = selectCardComponents({ mediaMatch });

	const handleClickCard = shortLink => {
		window.open(`/learning-path/${shortLink}`, '_blank');
	};

	if (paths.length === 0) return null;
	return (
		<PaddingWrapper>
			<Title>{sectionTitle || defaultTitle}</Title>
			<Divider />
			<CardsWrapper>
				{
					paths.map(learningPath => (
						<Card
							key={learningPath.id}
							onClick={() => handleClickCard(learningPath.short_link)}
							photos={learningPath.photos.map(photo => ({
								id: photo?.id,
								link: photo?.link,
								alt: photo?.alt_title,
							}))}
							title={learningPath.name}
							desc={learningPath.intro}
							courseNumber={learningPath.course_number}
							hasCertificate={learningPath.has_certificate}
						/>
					))
				}
			</CardsWrapper>
		</PaddingWrapper>
	);
};

export default LearningPath;
