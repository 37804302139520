import React from 'react';
import commonStyle from '@src/styles/Common.module.less';
import classStyle from '@src/styles/ErrorMessage.module.less';
import classNames from 'classnames';
import { IconComponent } from '@src/icons';

class ErrorMessage extends React.PureComponent {
  render() {
    const { className, message, showIcon, isCenter } = this.props;

    return (
      <div
        className={classNames(
          classStyle.container,
          isCenter && commonStyle.center,
          className
        )}
      >
        {showIcon && (
          <IconComponent
            className={classStyle.errorIcon}
            size={11}
            color="#d0021b"
            icon="cross"
          />
        )}
        {message}
      </div>
    );
  }
}

export default ErrorMessage;
