import { TSideEffectState } from '@src/types/common';
import { IEvent } from '@src/types/event';

interface ICountdown {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
}

interface IEventState {
	events: IEvent[];
	getEventsState: TSideEffectState;
	event: IEvent;
	getEventState: TSideEffectState;
	currentPrice: number;
	eventsMap: {
		[shortLink: string]: IEvent;
	};
	eventsMapState: TSideEffectState;
	countDownForSale: ICountdown;
	tags: ITag[];
	getTagsState: TSideEffectState;
}

interface ITag {
	id: string;
	name: string;
	school_id: string;
}

export const getEvents = ({ event }: { event: IEventState }): IEvent[] => event.events;

export const getEventsState = ({ event }: { event: IEventState }): TSideEffectState => event.getEventsState;

export const getEvent = ({ event }: { event: IEventState }): IEvent => event.event;

export const getEventState = ({ event }: { event: IEventState }): TSideEffectState => event.getEventState;

export const getCurrentPrice = ({ event }: { event: IEventState }): number => event.currentPrice;

export const getEventsMap = ({ event }: { event: IEventState }): {
	[shortLink: string]: IEvent;
} => event.eventsMap;

export const getEventsMapState = ({ event }: { event: IEventState }): TSideEffectState => event.eventsMapState;

export const selectCountDownForSale = ({ event }: { event: IEventState }): ICountdown => event.countDownForSale;

export const getTags = ({ event }: { event: IEventState }): ITag[] => event.tags;

export const getTagsState = ({ event }: { event: IEventState }): TSideEffectState => event.getTagsState;
