import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { IColors } from '@src/types/theme';
import { getColorTheme } from '@src/store/selectors/theme';
import useTranslation from '@src/hooks/useTranslation';
import { SmallCard as BasicSmallCard } from '@src/components/card/basic';
import { HostBy } from '@src/components/card/style';
import { formatCourseTimeMinutes } from '@src/utils/course';
import { COURSE_TYPE_MIRROR, COURSE_TYPE_INTERNAL_COURSE } from '@src/const/course';
import { getDomain } from '@src/store/selectors';
import { coursePriceVisible } from '@src/utils/hardcode';

import InfoBar from './infoBar';
import Price from './price';
import { getTagText, makePercentageValue } from './utils';

const SpaceBetweenWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1 1 auto;
`;

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	course: any;
	photos: string[];
	onClick: () => void;
	className?: string;
}

const SmallCard = ({
	course,
	photos,
	onClick,
	className,
}: IProps): JSX.Element => {
	const {
		status,
		title,
		is_free_course: isFreeCourse,
		init_price: initPrice,
		subtotal_price: subtotalPrice,
		fund_end_at: fundEndAt,
		total_backers: totalBackers,
		goal_backers: goalBackers,
		teacher,
		rate,
		students,
		video_duration_seconds,
		advance_settings: advanceSettings,
		type,
		hide_price: isHidePrice,
	} = course;
	const { t } = useTranslation();
	const domain = useSelector(getDomain) as string;
	const isCoursePriceVisible = coursePriceVisible(domain);
	const courseTimeMinutes = formatCourseTimeMinutes(video_duration_seconds);
	const { colors: { primary, secondary } } = useSelector(getColorTheme) as { colors: IColors };
	const tagColor = status === 'published' ? primary : secondary;
	const tagText = getTagText({ t, status });
	const courseTime = t('card:course_time', { minutes: courseTimeMinutes });
	const startTime = dayjs(dayjs(new Date())).valueOf();
	const isExpired = dayjs(startTime).isAfter(dayjs(fundEndAt));
	const percentage = makePercentageValue({
		isExpired, totalBackers, goalBackers,
	});
	const isMirrorCourse = type === COURSE_TYPE_MIRROR;
	const isInternalCourse = type === COURSE_TYPE_INTERNAL_COURSE;
	return (
		<BasicSmallCard
			className={className}
			onClick={onClick}
			title={title}
			tagText={tagText}
			tagColor={tagColor}
			imageUrl={photos[0]}
			contentElem={(
				<SpaceBetweenWrapper>
					<div>
						<HostBy>{teacher.name}</HostBy>
					</div>
					{
						!isMirrorCourse && !isInternalCourse && isCoursePriceVisible && !isHidePrice && (
							<Price
								color={tagColor}
								isFreeCourse={isFreeCourse}
								initPrice={initPrice}
								subtotalPrice={subtotalPrice}
							/>
						)
					}
				</SpaceBetweenWrapper>
			)}
			infoElem={(
				<InfoBar
					status={status}
					rate={rate}
					students={students}
					showStudentsType={advanceSettings?.show_buyer_number?.type}
					showStudentsNumber={advanceSettings?.show_buyer_number?.number}
					courseTime={courseTime}
					percentage={percentage}
				/>
			)}
		/>
	);
};

export default SmallCard;
