export const GET_LEARNING_PATHS_CALL = 'GET_LEARNING_PATHS_CALL';
export const GET_LEARNING_PATHS_DONE = 'GET_LEARNING_PATHS_DONE';
export const GET_LEARNING_PATHS_FAIL = 'GET_LEARNING_PATHS_FAIL';

export const GET_MINE_LEARNING_PATHS_CALL = 'GET_MINE_LEARNING_PATHS_CALL';
export const GET_MINE_LEARNING_PATHS_DONE = 'GET_MINE_LEARNING_PATHS_DONE';
export const GET_MINE_LEARNING_PATHS_FAIL = 'GET_MINE_LEARNING_PATHS_FAIL';

export const GET_LEARNING_PATHS_BY_SHORT_LINK_CALL = 'GET_LEARNING_PATHS_BY_SHORT_LINK_CALL';
export const GET_LEARNING_PATHS_BY_SHORT_LINK_DONE = 'GET_LEARNING_PATHS_BY_SHORT_LINK_DONE';
export const GET_LEARNING_PATHS_BY_SHORT_LINK_FAIL = 'GET_LEARNING_PATHS_BY_SHORT_LINK_FAIL';

export const JOIN_LEARNING_PATHS_CALL = 'JOIN_LEARNING_PATHS_CALL';
export const JOIN_LEARNING_PATHS_DONE = 'JOIN_LEARNING_PATHS_DONE';
export const JOIN_LEARNING_PATHS_FAIL = 'JOIN_LEARNING_PATHS_FAIL';

export const LEAVE_LEARNING_PATHS_CALL = 'LEAVE_LEARNING_PATHS_CALL';
export const LEAVE_LEARNING_PATHS_DONE = 'LEAVE_LEARNING_PATHS_DONE';
export const LEAVE_LEARNING_PATHS_FAIL = 'LEAVE_LEARNING_PATHS_FAIL';

export const LEARNING_PATHS_PROGRESS_CALL = 'LEARNING_PATHS_PROGRESS_CALL';
export const LEARNING_PATHS_PROGRESS_DONE = 'LEARNING_PATHS_PROGRESS_DONE';
export const LEARNING_PATHS_PROGRESS_FAIL = 'LEARNING_PATHS_PROGRESS_FAIL';

export const GET_LEARNING_PATHS_COMMODITIES_CALL = 'GET_LEARNING_PATHS_COMMODITIES_CALL';
export const GET_LEARNING_PATHS_COMMODITIES_DONE = 'GET_LEARNING_PATHS_COMMODITIES_DONE';
export const GET_LEARNING_PATHS_COMMODITIES_FAIL = 'GET_LEARNING_PATHS_COMMODITIES_FAIL';

export const POST_LEARNING_PATHS_CERTIFICATE_CALL = 'POST_LEARNING_PATHS_CERTIFICATE_CALL';
export const POST_LEARNING_PATHS_CERTIFICATE_DONE = 'POST_LEARNING_PATHS_CERTIFICATE_DONE';
export const POST_LEARNING_PATHS_CERTIFICATE_FAIL = 'POST_LEARNING_PATHS_CERTIFICATE_FAIL';
