/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import useTranslation from '@src/hooks/useTranslation';

import { IEvent } from '@src/types/event';
import { formatToTimestamp } from '@src/utils/time';
import * as selectors from '@src/store/selectors';
import useMediaQuery from '@src/hooks/useMediaQuery';
import EmptyStateEventImagePath from '@src/assets/images/empty_state_event.svg';
import { SmallCard, HorizontalSmallCard } from '@src/components/card/offlineEvent';
import {
	StyledHorizontalSmallCard,
	SmallCardsWrapper,
} from '@src/components/home/components/cardStyle';

const PaddingWrapper = styled.div`
  @media (max-width: 576px) {
    padding: 16px 0;
  }
  @media (min-width: 576px) {
    padding: 16px 0;
  }
  @media (min-width: 768px) {
    padding: 32px 0;
  }
  @media (min-width: 992px) {
    padding: 50px 0;
  }
  @media (min-width: 1200px) {
    padding: 50px 0;
  }
`;

const EmptyEventImageWrapper = styled.div`
  padding: 35px 0 0;
  text-align: center;
  position: relative;
`;

const Title = styled.div`
	font-size: 22px;
	font-weight: 500;
	padding-left: 10px;
	margin-bottom: 15px;
	border-left: 4px solid ${props => props.theme.colors.secondary};
	color: ${props => props.theme.colors.secondary};
`;

const EmptyImage = styled.img`
  width: 100%;
  max-width: 580px;
`;

const Divider = styled.div`
  border-bottom: 1px solid #cfcfcf;
`;

const selectCardComponents = ({ mediaMatch }) => {
	const { isMobile } = mediaMatch;

	if (isMobile) {
		return {
			Card: HorizontalSmallCard,
			CardsWrapper: StyledHorizontalSmallCard,
		};
	}

	return {
		Card: SmallCard,
		CardsWrapper: SmallCardsWrapper,
	};
};

interface OfflineEventsProps {
	item: {
		chosen: boolean;
		editableList: string[];
		isPreview: boolean;
		key: string;
		name: string;
		type: string;
		sectionTitle: string;
	};
}

const OfflineEvents = ({ item }: OfflineEventsProps): JSX.Element => {
	const { t } = useTranslation();
	const events = useSelector(selectors.getEvents) as IEvent[];
	const { mediaMatch } = useMediaQuery();
	const { sectionTitle } = item;
	const defaultTitle = t('event:event');
	const { Card, CardsWrapper } = selectCardComponents({ mediaMatch });

	const handleClickCard = shortLink => {
		window.open(`/event/${shortLink}`, '_blank');
	};

	const sortedEvents = [...events]
		.sort((a, b) => formatToTimestamp(b.publish_at) - formatToTimestamp(a.publish_at));

	return (
		<PaddingWrapper>
			<Title>{sectionTitle || defaultTitle}</Title>
			<Divider />
			{
				sortedEvents.length > 0 && (
					<CardsWrapper>
						{
							sortedEvents.map(event => (
								<Card
									key={event.id}
									event={event}
									onClick={() => handleClickCard(event.short_link)}
								/>
							))
						}
					</CardsWrapper>
				)
			}
			{events.length === 0 && (
				<EmptyEventImageWrapper>
					<EmptyImage
						src={EmptyStateEventImagePath}
						alt=""
					/>
					<div>{t('event:events_on_sale_countdown_and_please_wait')}</div>
				</EmptyEventImageWrapper>
			)}
		</PaddingWrapper>
	);
};

export default OfflineEvents;
