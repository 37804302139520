import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { isBoolean } from 'lodash';

import { getEventPriceRange, sortEventSlots, getShouldShowSoldOut } from '@src/utils/event';
import { IColors } from '@src/types/theme';
import { getColorTheme } from '@src/store/selectors/theme';
import useTranslation from '@src/hooks/useTranslation';
import {
	HostBy,
	PriceRange,
} from '@src/components/card/style';
import { CURRENCY_SYMBOL } from '@src/const/common';
import { HorizontalSmallCard as BasicHorizontalSmallCard } from '@src/components/card/basic';
import { getPrice } from '@src/components/card/utils';
import { getIsPriceVisible } from '@src/utils/hardcode';

import StatelessContent from './statelessContent';

const StyledHostBy = styled(HostBy)``;

const StyledPriceRange = styled(PriceRange)`
	display: flex;
	justify-content: flex-end;

	.price-range__currency {
		font-size: 16px;
	}

	.price-range__number {
		font-size: 20px;
	}
`;

const getCardContent = ({ t, isStateless, event, isPriceVisible }) => {
	if (isStateless) {
		return <StatelessContent event={event} />;
	} else {
		const {
			host_by: hostBy,
		} = event;
		let price = { minPrice: 0, maxPrice: 0 };
		if (event.slots.length) {
			price = getEventPriceRange(event.slots);
		}
		const { minPrice, maxPrice } = price;
		const isFree = (minPrice === maxPrice) && minPrice === 0;

		return (
			<>
				<StyledHostBy>{hostBy}</StyledHostBy>
				<StyledPriceRange>
					{
						isPriceVisible ? (
							<>
								{!isFree && <span className="price-range__currency">{CURRENCY_SYMBOL}</span>}
								<span className="price-range__number">
									{getPrice({ minPrice, maxPrice })}
								</span>
							</>
						) : (
							<>{t('common:consultation')}</>
						)
					}
				</StyledPriceRange>
			</>
		);
	}
};

interface IProps {
	onClick: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	event: any;
	isStateless?: boolean;
	isSoldOut?: boolean
}

const HorizontalSmallCard = ({
	onClick,
	event,
	isStateless = false,
	isSoldOut,
}: IProps): JSX.Element => {
	const { t } = useTranslation();
	const {
		name: title,
		photos,
		slots,
	} = event;
	const photoLinks = photos.map(photo => photo.link);
	const { colors: { primary } } = useSelector(getColorTheme) as { colors: IColors };
	const { id: eventId } = event || {};
	const isPriceVisible = getIsPriceVisible(eventId);
	const contentElem = getCardContent({ t, isStateless, event, isPriceVisible });
	const sortedSlots = sortEventSlots(slots);
	const isTicketSoldOut = isBoolean(isSoldOut) ? isSoldOut : getShouldShowSoldOut({ sortedSlots });

	return (
		<BasicHorizontalSmallCard
			onClick={onClick}
			title={title}
			tagText={t('event:event')}
			tagColor={primary}
			imageUrl={photoLinks[0]}
			imageAlt={photos[0]?.alt_title}
			contentElem={contentElem}
			isSoldOut={isTicketSoldOut}
		/>
	);
};

export default HorizontalSmallCard;
