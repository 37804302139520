/* eslint-disable react/prop-types */
import React from 'react';
import { handleThemeClick } from '@src/utils/theme';
import { MyProgressiveImage } from '@src/components';
import classStyle from './ImageComponent.module.less';

const ImageComponent = props => {
	const { item, pages } = props;
	const { image, thumbnailLink, action } = item;

	const handleClick = () => {
		if (action?.openType) {
			const { openType, link, paramId } = action;
			handleThemeClick({ openType, link, paramId, pages });
		}
	};

	return (
		<div
			role="presentation"
			className={classStyle.container}
			onClick={handleClick}
			style={{ cursor: action?.openType ? 'pointer' : 'default' }}
		>
			<MyProgressiveImage
				className={classStyle.image}
				src={image}
				placeholder={thumbnailLink}
			/>
		</div>
	);
};

export default ImageComponent;
