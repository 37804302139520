import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	html {
		display: ${props => (props.needLogin ? 'none' : 'block')};
	}
`;

const RedirectClosedLogin = ({ needLogin }: { needLogin: boolean }): JSX.Element => {
	const router = useRouter();
	useEffect(() => {
		if (needLogin) {
			console.log('跳轉企業封閉頁'); // 留下 log 方便未來如果出問題時追蹤
			router.push('/business-login');
		}
	}, [needLogin]);
	return <GlobalStyle needLogin={needLogin} />;
};

export default RedirectClosedLogin;
