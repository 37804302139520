import React from 'react';

import { Container } from '@src/components/grid';
import classes from './styles.module.less';
import maintenanceImg from './images/maintenance.svg';

function Maintenance() {
  const title = '喔喔不好意思，網站尚在建置中';

  return (
    <Container className={classes.box}>
      <img className={classes.image} src={maintenanceImg} alt={title} />
      <p className={classes.text}>{title}</p>
    </Container>
  );
}

export default Maintenance;
