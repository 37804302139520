export const ADD_EVENT_TO_CART_CALL = 'ADD_EVENT_TO_CART_CALL';
export const ADD_EVENT_TO_CART_DONE = 'ADD_EVENT_TO_CART_DONE';
export const ADD_EVENT_TO_CART_FAIL = 'ADD_EVENT_TO_CART_FAIL';

export const ADD_WEBINAR_TO_CART_CALL = 'ADD_WEBINAR_TO_CART_CALL';
export const ADD_WEBINAR_TO_CART_DONE = 'ADD_WEBINAR_TO_CART_DONE';
export const ADD_WEBINAR_TO_CART_FAIL = 'ADD_WEBINAR_TO_CART_FAIL';

export const GET_CART_PRODUCTS_CALL = 'GET_CART_PRODUCTS_CALL';
export const GET_CART_PRODUCTS_DONE = 'GET_CART_PRODUCTS_DONE';
export const GET_CART_PRODUCTS_FAIL = 'GET_CART_PRODUCTS_FAIL';

export const UPDATE_CART_PRODUCTS_CALL = 'UPDATE_CART_PRODUCTS_CALL';

export const REMOVE_COURSE_FROM_CART_CALL = 'REMOVE_COURSE_FROM_CART_CALL';
export const REMOVE_COURSE_FROM_CART_DONE = 'REMOVE_COURSE_FROM_CART_DONE';
export const REMOVE_COURSE_FROM_CART_FAIL = 'REMOVE_COURSE_FROM_CART_FAIL';

export const REMOVE_EVENT_FROM_CART_CALL = 'REMOVE_EVENT_FROM_CART_CALL';
export const REMOVE_EVENT_FROM_CART_DONE = 'REMOVE_EVENT_FROM_CART_DONE';
export const REMOVE_EVENT_FROM_CART_FAIL = 'REMOVE_EVENT_FROM_CART_FAIL';

export const REMOVE_WEBINAR_FROM_CART_CALL = 'REMOVE_WEBINAR_FROM_CART_CALL';
export const REMOVE_WEBINAR_FROM_CART_DONE = 'REMOVE_WEBINAR_FROM_CART_DONE';
export const REMOVE_WEBINAR_FROM_CART_FAIL = 'REMOVE_WEBINAR_FROM_CART_FAIL';

export const UPDATE_EVENT_QUANTITY_CALL = 'UPDATE_EVENT_QUANTITY_CALL';
export const UPDATE_EVENT_QUANTITY_DONE = 'UPDATE_EVENT_QUANTITY_DONE';
export const UPDATE_EVENT_QUANTITY_FAIL = 'UPDATE_EVENT_QUANTITY_FAIL';

export const RESERVE_CART_PRODUCTS_CALL = 'RESERVE_CART_PRODUCTS_CALL';
export const RESERVE_CART_PRODUCTS_DONE = 'RESERVE_CART_PRODUCTS_DONE';
export const RESERVE_CART_PRODUCTS_FAIL = 'RESERVE_CART_PRODUCTS_FAIL';

export const GET_PUBLIC_COURSE_COUPONS_CALL = 'GET_PUBLIC_COURSE_COUPONS_CALL';
export const GET_PUBLIC_COURSE_COUPONS_DONE = 'GET_PUBLIC_COURSE_COUPONS_DONE';
export const GET_PUBLIC_COURSE_COUPONS_FAIL = 'GET_PUBLIC_COURSE_COUPONS_FAIL';

export const APPLY_COURSE_COUPONS_CALL = 'APPLY_COURSE_COUPONS_CALL';
export const APPLY_COURSE_COUPONS_DONE = 'APPLY_COURSE_COUPONS_DONE';
export const APPLY_COURSE_COUPONS_FAIL = 'APPLY_COURSE_COUPONS_FAIL';

export const DELETE_COURSE_COUPONS_CALL = 'DELETE_COURSE_COUPONS_CALL';
export const DELETE_COURSE_COUPONS_DONE = 'DELETE_COURSE_COUPONS_DONE';
export const DELETE_COURSE_COUPONS_FAIL = 'DELETE_COURSE_COUPONS_FAIL';

export const GET_PUBLIC_EVENT_COUPONS_CALL = 'GET_PUBLIC_EVENT_COUPONS_CALL';
export const GET_PUBLIC_EVENT_COUPONS_DONE = 'GET_PUBLIC_EVENT_COUPONS_DONE';
export const GET_PUBLIC_EVENT_COUPONS_FAIL = 'GET_PUBLIC_EVENT_COUPONS_FAIL';

export const APPLY_EVENT_SLOT_COUPONS_CALL = 'APPLY_EVENT_SLOT_COUPONS_CALL';
export const APPLY_EVENT_SLOT_COUPONS_DONE = 'APPLY_EVENT_SLOT_COUPONS_DONE';
export const APPLY_EVENT_SLOT_COUPONS_FAIL = 'APPLY_EVENT_SLOT_COUPONS_FAIL';

export const DELETE_EVENT_SLOT_COUPONS_CALL = 'DELETE_EVENT_SLOT_COUPONS_CALL';
export const DELETE_EVENT_SLOT_COUPONS_DONE = 'DELETE_EVENT_SLOT_COUPONS_DONE';
export const DELETE_EVENT_SLOT_COUPONS_FAIL = 'DELETE_EVENT_SLOT_COUPONS_FAIL';

export const GET_PUBLIC_WEBINAR_COUPONS_CALL = 'GET_PUBLIC_WEBINAR_COUPONS_CALL';
export const GET_PUBLIC_WEBINAR_COUPONS_DONE = 'GET_PUBLIC_WEBINAR_COUPONS_DONE';
export const GET_PUBLIC_WEBINAR_COUPONS_FAIL = 'GET_PUBLIC_WEBINAR_COUPONS_FAIL';

export const APPLY_WEBINAR_SLOT_COUPONS_CALL = 'APPLY_WEBINAR_SLOT_COUPONS_CALL';
export const APPLY_WEBINAR_SLOT_COUPONS_DONE = 'APPLY_WEBINAR_SLOT_COUPONS_DONE';
export const APPLY_WEBINAR_SLOT_COUPONS_FAIL = 'APPLY_WEBINAR_SLOT_COUPONS_FAIL';

export const DELETE_WEBINAR_SLOT_COUPONS_CALL = 'DELETE_WEBINAR_SLOT_COUPONS_CALL';
export const DELETE_WEBINAR_SLOT_COUPONS_DONE = 'DELETE_WEBINAR_SLOT_COUPONS_DONE';
export const DELETE_WEBINAR_SLOT_COUPONS_FAIL = 'DELETE_WEBINAR_SLOT_COUPONS_FAIL';
