import { createAction } from 'redux-actions';
import * as types from '@src/store/types';

export const getCourseHomeworksCall = createAction(
  types.GET_COURSE_HOMEWORKS_CALL
);
export const getCourseHomeworksDone = createAction(
  types.GET_COURSE_HOMEWORKS_DONE
);
export const getCourseHomeworksFail = createAction(
  types.GET_COURSE_HOMEWORKS_FAIL
);

export const addCourseHomeworkCall = createAction(
  types.ADD_COURSE_HOMEWORK_CALL
);
export const addCourseHomeworkDone = createAction(
  types.ADD_COURSE_HOMEWORK_DONE
);
export const addCourseHomeworkFail = createAction(
  types.ADD_COURSE_HOMEWORK_FAIL
);

export const updateCourseHomeworkCall = createAction(
  types.UPDATE_COURSE_HOMEWORK_CALL
);
export const updateCourseHomeworkDone = createAction(
  types.UPDATE_COURSE_HOMEWORK_DONE
);
export const updateCourseHomeworkFail = createAction(
  types.UPDATE_COURSE_HOMEWORK_FAIL
);

export const getCourseHomeworkDetailCall = createAction(
  types.GET_COURSE_HOMEWORK_DETAIL_CALL
);
export const getCourseHomeworkDetailDone = createAction(
  types.GET_COURSE_HOMEWORK_DETAIL_DONE
);
export const getCourseHomeworkDetailFail = createAction(
  types.GET_COURSE_HOMEWORK_DETAIL_FAIL
);

export const getCourseHomeworkRepliesCall = createAction(
  types.GET_COURSE_HOMEWORK_REPLIES_CALL
);
export const getCourseHomeworkRepliesDone = createAction(
  types.GET_COURSE_HOMEWORK_REPLIES_DONE
);
export const getCourseHomeworkRepliesFail = createAction(
  types.GET_COURSE_HOMEWORK_REPLIES_FAIL
);

export const addCourseHomeworkReplyCall = createAction(
  types.ADD_COURSE_HOMEWORK_REPLY_CALL
);
export const addCourseHomeworkReplyDone = createAction(
  types.ADD_COURSE_HOMEWORK_REPLY_DONE
);
export const addCourseHomeworkReplyFail = createAction(
  types.ADD_COURSE_HOMEWORK_REPLY_FAIL
);
export const addCourseHomeworkReplyReset = createAction(
  types.ADD_COURSE_HOMEWORK_REPLY_RESET
);
