export const GET_SURVEYS_QUESTION_SHEET_CALL = 'GET_SURVEYS_QUESTION_SHEET_CALL';
export const GET_SURVEYS_QUESTION_SHEET_DONE = 'GET_SURVEYS_QUESTION_SHEET_DONE';
export const GET_SURVEYS_QUESTION_SHEET_FAIL = 'GET_SURVEYS_QUESTION_SHEET_FAIL';

export const POST_SURVEYS_ANSWER_SHEET_CALL = 'POST_SURVEYS_ANSWER_SHEET_CALL';
export const POST_SURVEYS_ANSWER_SHEET_DONE = 'POST_SURVEYS_ANSWER_SHEET_DONE';
export const POST_SURVEYS_ANSWER_SHEET_FAIL = 'POST_SURVEYS_ANSWER_SHEET_FAIL';

export const GET_SURVEYS_ANSWER_SHEET_CALL = 'GET_SURVEYS_ANSWER_SHEET_CALL';
export const GET_SURVEYS_ANSWER_SHEET_DONE = 'GET_SURVEYS_ANSWER_SHEET_DONE';
export const GET_SURVEYS_ANSWER_SHEET_FAIL = 'GET_SURVEYS_ANSWER_SHEET_FAIL';
