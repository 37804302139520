import React, { useContext, createContext, useState, useEffect } from 'react';
import { fromEvent } from 'rxjs';

export const BottomKeepSpaceContext = createContext();

function BottomKeepSpace(props) {
  const { children } = props;
  const [paddingBottom, setPaddingBottom] = useState(0);
  const [screenWidth, setScreenWidth] = useState(-1);

  useEffect(() => {
    const listener = fromEvent(window, 'resize').subscribe((x) => {
      setScreenWidth(window.innerWidth);
    });

    return () => {
      listener.unsubscribe();
    };
  }, [setScreenWidth]);

  const handlePaddingBottomChange = (dom) => {
    if (dom.current) {
      const rect = dom.current.getBoundingClientRect();
      setPaddingBottom(rect?.height || 0);
    }
  };
  const handlePaddingBottomClear = () => setPaddingBottom(0);

  const value = {
    screenWidth,
    handlePaddingBottomChange,
    handlePaddingBottomClear,
  };

  return (
    <BottomKeepSpaceContext.Provider value={value}>
      {children}
      <div style={{ paddingBottom }} />
    </BottomKeepSpaceContext.Provider>
  );
}

export default BottomKeepSpace;

export function useBottomKeepSpace(boxDOM) {
  const {
    screenWidth,
    handlePaddingBottomChange,
    handlePaddingBottomClear,
  } = useContext(BottomKeepSpaceContext);

  useEffect(() => {
    handlePaddingBottomChange(boxDOM);
    return () => handlePaddingBottomClear();
  }, [screenWidth]); // eslint-disable-line
}
