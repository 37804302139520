import React from 'react';
import styled from 'styled-components';
import { isBoolean, get } from 'lodash';

import { getEventPriceRange, getTicketStatus, sortEventSlots, getShouldShowSoldOut } from '@src/utils/event';
import { CURRENCY_SYMBOL } from '@src/const/common';
import {
	HostBy,
	PriceRange,
	InfoWrapper,
	InfoItem,
} from '@src/components/card/style';
import InfoByStatus from '@src/components/card/infoByStatus';
import { SmallCard as BasicSmallCard } from '@src/components/card/basic';
import { getPrice } from '@src/components/card/utils';
import { getIsCountdownVisible } from 'src/utils/hardcode';

import TagContent from './tagContent';
import StatelessContent from './statelessContent';

const SpaceBetweenWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1 1 auto;
`;

const ContentWrapper = styled.div`
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const getCardContent = ({ isStateless, webinar }) => {
	if (isStateless) {
		return <StatelessContent webinar={webinar} />;
	}

	const {
		host_by: hostBy,
		slots,
	} = webinar;
	let price = { minPrice: 0, maxPrice: 0 };
	if (slots.length) {
		price = getEventPriceRange(slots);
	}
	const { minPrice, maxPrice } = price;
	const isFree = (minPrice === maxPrice) && minPrice === 0;

	return (
		<ContentWrapper>
			<HostBy>{hostBy}</HostBy>
			<SpaceBetweenWrapper>
				<PriceRange>
					{!isFree && <span className="price-range__currency">{CURRENCY_SYMBOL}</span>}
					<span className="price-range__number">
						{getPrice({ minPrice, maxPrice })}
					</span>
				</PriceRange>
			</SpaceBetweenWrapper>
		</ContentWrapper>
	);
};

interface IProps {
	onClick?: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	webinar: any;
	isStateless?: boolean;
	isSoldOut?: boolean;
	className?: string;
}

const SmallCard = ({
	onClick,
	webinar,
	isStateless = false,
	isSoldOut,
	className,
}: IProps): JSX.Element => {
	const {
		name: title,
		photos,
		slots,
		short_link: shortLink,
	} = webinar;
	const photoLinks = photos.map(photo => photo.link);
	const contentElem = getCardContent({ isStateless, webinar });
	const { status, countDownTime } = getTicketStatus({ slots });
	const sortedSlots = sortEventSlots(slots);
	const isTicketSoldOut = isBoolean(isSoldOut) ? isSoldOut : getShouldShowSoldOut({ sortedSlots });
	const isCountdownVisible = getIsCountdownVisible(shortLink);

	return (
		<BasicSmallCard
			className={className}
			onClick={onClick}
			title={title}
			tagText={<TagContent />}
			tagColor="#FFFFFF"
			tagBorderColor="#DDDDDD"
			imageUrl={photoLinks[0]}
			imageAlt={get(photos, '0.alt_title', '')}
			contentElem={contentElem}
			infoElem={(
				isBoolean(isSoldOut) && !isSoldOut ? null : (
					<InfoWrapper>
						<InfoItem style={{ opacity: isCountdownVisible ? 1 : 0 }}>
							<InfoByStatus
								status={status}
								countDownTime={countDownTime}
								slots={slots}
							/>
						</InfoItem>
					</InfoWrapper>
				)
			)}
			isSoldOut={isTicketSoldOut}
		/>
	);
};

export default SmallCard;
