import React from 'react';
import classStyle from './ThemeButton.module.less';
import classNames from 'classnames';
import { handleThemeClick } from '@src/utils/theme';
import { useSelector } from 'react-redux';
import { getThemeSelector } from '@src/store/selectors';

export default function ThemeButton(props) {
  const { pages } = useSelector(getThemeSelector);
  const { button = {}, className } = props;
  const { text, openType, link, paramId } = button;
  const handleClick = () => {
    handleThemeClick({ openType, link, paramId, pages });
  };

  return (
    <div
      className={classNames([classStyle.themeButton, className])}
      onClick={handleClick}
    >
      {text}
    </div>
  );
}
