import React from 'react';
import { formatPrice } from '@src/utils/price';
import { Translation } from 'react-i18next';

export const getPrice = ({ minPrice, maxPrice }): string | JSX.Element => {
	const isFree = (minPrice === maxPrice) && parseInt(minPrice) === 0;
	if (isFree) {
		return (
			<Translation>
				{t => <>{t('common:free')}</>}
			</Translation>
		);
	}
	return (
		minPrice === maxPrice ?
			formatPrice(minPrice) : `${formatPrice(minPrice)} ~ ${formatPrice(maxPrice)}`
	);
};
