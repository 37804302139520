/**
 * textColor is used in Homepage.
 * It could be removed after it is no longer to be used in Homepage.
*/
export const colorThemes = [{
	id: 'default',
	colors: {
		primary: '#587CB0',
		primaryLight: 'rgba(88, 124, 176, 0.15)',
		primaryDark: '#3366B1',
		secondary: '#CE7778',
		secondaryLight: 'rgba(206, 119, 120, 0.15)',
		secondaryDark: '#C85B5C',
		block: '#F9F9F9',
		background: '#FFFFFF',
		auxiliary: '#354E72',
		textColor: '#101010',
		gradientColor: 'linear-gradient(289.31deg, #D97F80 21.08%, #F3DEDE 94.59%)',
	},
}, {
	id: 'colorTheme1',
	colors: {
		primary: '#458980',
		primaryLight: 'rgba(69, 137, 128, 0.15)',
		primaryDark: '#357C73',
		secondary: '#EC9B5B',
		secondaryLight: 'rgba(236,155,91, 0.15)',
		secondaryDark: '#D98949',
		block: '#F9F9F9',
		background: '#FFFFFF',
		auxiliary: '#395551',
		textColor: '#101010',
		gradientColor: 'linear-gradient(289.31deg, #F0AC76 21.08%, #FCD6B8 94.59%)',
	},
}, {
	id: 'colorTheme2',
	colors: {
		primary: '#3091FD',
		primaryLight: 'rgba(48, 145, 253, 0.15)',
		primaryDark: '#005ABE',
		secondary: '#FA8B00',
		secondaryLight: 'rgba(250, 139, 0, 0.15)',
		secondaryDark: '#FF6400',
		block: '#F9F9F9',
		background: '#FFFFFF',
		auxiliary: '#395677',
		textColor: '#101010',
		gradientColor: 'linear-gradient(289.31deg, #FFA83C 21.08%, #FCD4A3 94.59%)',
	},
}, {
	id: 'colorTheme3',
	colors: {
		primary: '#92B664',
		primaryLight: 'rgba(146, 182, 100, 0.15)',
		primaryDark: '#829F5A',
		secondary: '#688AB7',
		secondaryLight: 'rgba(104, 138, 183, 0.15)',
		secondaryDark: '#51719B',
		block: '#F9F9F9',
		background: '#FFFFFF',
		auxiliary: '#515C42',
		textColor: '#101010',
		gradientColor: 'linear-gradient(289.31deg, #7FA1CD 21.08%, #C7D4E4 94.59%)',
	},
}, {
	id: 'colorTheme4',
	colors: {
		primary: '#DFA608',
		primaryLight: 'rgba(223, 166, 8, 0.15)',
		primaryDark: '#CC9600',
		secondary: '#C24E26',
		secondaryLight: 'rgba(194, 78, 38, 0.15)',
		secondaryDark: '#923E21',
		block: '#F9F9F9',
		background: '#101010',
		auxiliary: '#101010',
		textColor: '#FFFFFF',
		gradientColor: 'linear-gradient(290.3deg, #E16945 21.38%, #ECCABF 94.37%)',
	},
}, {
	id: 'colorTheme5',
	colors: {
		primary: '#6755AC',
		primaryLight: 'rgba(103, 85, 172, 0.15)',
		primaryDark: '#4C3993',
		secondary: '#F4585B',
		secondaryLight: 'rgba(244, 88, 91, 0.15)',
		secondaryDark: '#D34C4E',
		block: '#F9F9F9',
		background: '#FFFFFF',
		auxiliary: '#575073',
		textColor: '#101010',
		gradientColor: 'linear-gradient(290.31deg, #FF7A7D 27.01%, #FFCBCC 100%)',
	},
}, {
	id: 'colorTheme6',
	colors: {
		primary: '#60BC8C',
		primaryLight: 'rgba(96, 188, 140, 0.15)',
		primaryDark: '#4C9B6E',
		secondary: '#EABB38',
		secondaryLight: 'rgba(234, 187, 56, 0.15)',
		secondaryDark: '#D8A928',
		block: '#F9F9F9',
		background: '#FFFFFF',
		auxiliary: '#2F4639',
		textColor: '#101010',
		gradientColor: 'linear-gradient(289.31deg, #F9CD55 21.08%, #F9E19D 94.59%)',
	},
}];
