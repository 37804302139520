import dayjs from 'dayjs';

export const isExpired = ({ startTime = new Date(), endTime = new Date() }: {
	startTime?: number | string | Date;
	endTime?: number | string | Date;
}): boolean => (
	dayjs(startTime).isAfter(dayjs(endTime))
);

export const isBetween = ({ startTime = new Date(), endTime = new Date() }: {
	startTime?: number | string | Date;
	endTime?: number | string | Date;
}): boolean => (
	isExpired({ endTime: startTime }) && isExpired({ startTime: endTime })
);

export const isSameDay = ({ startTime = new Date(), endTime = new Date() }: {
	startTime?: string | Date;
	endTime?: string | Date;
}): boolean => dayjs(startTime).isSame(dayjs(endTime), 'day');

export const getDiffDay = ({ startTime = new Date(), endTime }: {
	startTime?: number | Date,
	endTime: number
}): number => dayjs(endTime).diff(dayjs(startTime), 'day');

export const formatOnlyTime = (time: string): string => dayjs(time).format('HH:mm');

export const formatToDate = (time: number | string): string => dayjs(time).format('YYYY-MM-DD');

export const formatToMinutes = (time: number): string => dayjs(time).format('YYYY-MM-DD HH:mm');

export const formatToTimestamp = (time: string): number => dayjs(time).valueOf();

export const formatOnlyWeekDay = (time: string): number => dayjs(time).day();

export const formatTimeFloor = (timestamp: number): {
	seconds: number;
	minutes: number;
	hours: number;
	days: number;
} => {
	const seconds = Math.floor((timestamp / 1000) % 60);
	const minutes = Math.floor(((timestamp / 1000) / 60) % 60);
	const hours = Math.floor(((timestamp / 1000) / (60 * 60)) % 24);
	const days = Math.floor((timestamp / 1000) / (60 * 60 * 24));

	return {
		seconds,
		minutes,
		hours,
		days,
	};
};

/**
 * There are two display formats.
 * 1. When same day: 2021-05-04(二) 10:00 ~ 16:00
 * 2. When different day: 2021-05-04(二) 10:00 ~ 2021-05-12(三) 14:00
 */
export const getTimeRange = ({ startTime, endTime, t }: {
	startTime: string;
	endTime: string;
	t: (key: string)=> string;
}): string => {
	const eventStartTime = formatOnlyTime(startTime);
	const eventEndTime = formatOnlyTime(endTime);
	const startDate = formatToDate(startTime);
	const endDate = formatToDate(endTime);
	const startWeekNum = formatOnlyWeekDay(startTime);
	const startWeekText = t(`common:week:${startWeekNum}`);
	const endWeekNum = formatOnlyWeekDay(endTime);
	const endWeekText = t(`common:week:${endWeekNum}`);

	return isSameDay({ startTime, endTime }) ?
		`${startDate} (${startWeekText}) ${eventStartTime} ~ ${eventEndTime}` :
		`${startDate} (${startWeekText}) ${eventStartTime} ~ ${endDate} (${endWeekText}) ${eventEndTime}`;
};

export const formatTimeNumber = (timeNumber: number): string => (
	timeNumber > 9 ? timeNumber.toString() : `0${timeNumber}`
);

interface ITimeList {
	startTime: string;
	endTime: string;
}

export const getStartEndTime = (timeList: ITimeList[]): ITimeList => {
	let { startTime, endTime } = timeList[0];

	for (let i = 1; i < timeList.length; i += 1) {
		if (isExpired({ startTime, endTime: timeList[i].startTime })) {
			startTime = timeList[i].startTime;
		}

		if (isExpired({ startTime: timeList[i].endTime, endTime })) {
			endTime = timeList[i].endTime;
		}
	}

	return { startTime, endTime };
};

export const getSlotTimeRange = ({ sortedSlots, t }: {
	sortedSlots: any;
	t: (key: string) => string;
}): string => {
	const timeList = sortedSlots.map(slot => ({ startTime: slot.start_at, endTime: slot.end_at }));
	const { startTime, endTime } = getStartEndTime(timeList);

	return getTimeRange({ startTime, endTime, t });
};
