export const SLOT_STATUS_ON_SALE = 'on_sale';
export const SLOT_STATUS_EVENT_SOLD_OUT = 'event_sold_out';
export const SLOT_STATUS_WEBINAR_SOLD_OUT = 'webinar_sold_out';
export const SLOT_STATUS_SLOT_SOLD_OUT = 'slot_sold_out';
export const SLOT_STATUS_EVENT_NONSALE = 'event_nonsale';
export const SLOT_STATUS_WEBINAR_NONSALE = 'webinar_nonsale';
export const SLOT_STATUS_SLOT_NONSALE = 'slot_nonsale';
export const SLOT_STATUS_EVENT_DISABLED = 'event_disabled';
export const SLOT_STATUS_WEBINAR_DISABLED = 'webinar_disabled';

export const TICKET_STATUS_ON_SALE = 'on_sale';
export const TICKET_STATUS_NOT_ENOUGH = 'not_enough';
export const TICKET_STATUS_SOLD_OUT = 'sold_out';
export const TICKET_STATUS_NONSALE = 'nonsale';
export const TICKET_STATUS_DISABLED = 'disabled';
export const TICKET_STATUS_EXCEED_LIMIT = 'exceed_limit';

export const COUPON_STATUS_VALID = 'valid';
export const COUPON_STATUS_INVALID = 'invalid';
export const COUPON_STATUS_NOT_AVAILABLE = 'not_available';

export const COUPON_TYPE_PRODUCT = 'product';
export const COUPON_TYPE_EVENT_SLOT = 'event_slot';
export const COUPON_TYPE_WEBINAR_SLOT = 'webinar_slot';

export const DEMO = 'demo';
export const CREDIT_CARD_TEST = 'credit_card_test';
export const PLAN_BASIC = 'plan_basic';
export const PLAN_ADVANCED = 'plan_advanced';
export const PLAN_PRO = 'plan_pro';
export const PLAN_UPGRADE = 'plan_upgrade';
export const PLAN_LIMITED = 'plan_limited';
export const COURSE = 'course';
export const FUND_COURSE = 'fund_course';
