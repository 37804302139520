import React, { ReactNode } from 'react';
import styled from 'styled-components';
import {
	CardWrapper,
	MediaWrapper,
	Title,
} from '@src/components/card/style';
import TagWithTail from '@src/components/common/TagWithTail';

const StyledTag = styled(TagWithTail)`
	transform: translateY(-50%);
	position: absolute;
	top: 0px;
`;

const StyledCardWrapper = styled(CardWrapper)`
	padding: 24px 12px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
`;

const DetailContent = styled.div`
	padding: 4px 8px 8px 8px;
`;

const Image = styled.img`
	width: 100%;
`;

interface IProps {
	onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	title: string;
	contentElem: string | ReactNode | ReactNode[];
	imageUrl: string;
	imageAlt?: string;
	tagText: string | ReactNode;
	tagColor: string;
	tagBorderColor?: string;
}

const MediumCard = ({
	onClick,
	title,
	contentElem,
	imageUrl,
	imageAlt,
	tagText,
	tagColor,
	tagBorderColor,
}: IProps): JSX.Element => (
	<StyledCardWrapper onClick={onClick}>
		<MediaWrapper>
			<Image src={imageUrl} alt={imageAlt} />
		</MediaWrapper>
		<DetailContent>
			<StyledTag text={tagText} backgroundColor={tagColor} tagBorderColor={tagBorderColor} />
			<Title>{title}</Title>
			{contentElem}
		</DetailContent>
	</StyledCardWrapper>
);

export default MediumCard;
