import React from 'react';
import Image from 'react-image';
import { createGlobalStyle } from 'styled-components';

import { ThemeCarousel } from '@src/components/home';

import classStyle from './TeacherInfo.module.less';

const GlobalStyle = createGlobalStyle`
	.alice-carousel__stage-item {
		min-width: 211px;
	}
`;

export default class TeacherInfo extends React.Component {
	handleOnDragStart = (e) => {
		e.preventDefault();
	};

	renderItem = (item, index) => {
		const { showImage, image, title, subTitle, text } = item;

		return (
			<div className={classStyle.item} key={index}>
				{showImage && (
					<Image
						className={classStyle.image}
						src={image}
						onDragStart={this.handleOnDragStart}
					/>
				)}
				<div className={classStyle.title}>{title}</div>
				<div className={classStyle.subTitle}>{subTitle}</div>
				<div className={classStyle.line} />
				<div className={classStyle.text}>{text}</div>
			</div>
		);
	};

	render() {
		const { item, startIndex } = this.props;

		return (
			<>
				<GlobalStyle />
				<ThemeCarousel
					item={item}
					onRenderItem={this.renderItem}
					responsive={{
						0: { items: 1 },
						768: { items: 3 },
					}}
					startIndex={startIndex}
					infinite
				/>
			</>
		);
	}
}
