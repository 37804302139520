export const UPLOAD_CALL = 'UPLOAD_CALL';
export const UPLOAD_DONE = 'UPLOAD_DONE';
export const UPLOAD_FAIL = 'UPLOAD_FAIL';

export const UPLOAD_FILE_LINK_CALL = 'UPLOAD_FILE_LINK_CALL';
export const UPLOAD_FILE_LINK_DONE = 'UPLOAD_FILE_LINK_DONE';
export const UPLOAD_FILE_LINK_FAIL = 'UPLOAD_FILE_LINK_FAIL';

export const GET_FILE_VIDEO_LINK_CALL = 'GET_FILE_VIDEO_LINK_CALL';
export const GET_FILE_VIDEO_LINK_DONE = 'GET_FILE_VIDEO_LINK_DONE';
export const GET_FILE_VIDEO_LINK_FAIL = 'GET_FILE_VIDEO_LINK_FAIL';
