import React from 'react';
import styled from 'styled-components';

const TagWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const TagBody = styled.div`
	background: #FFFFFF;
	color: #101010;
	display: flex;
	align-items: center;
	font-size: 12px;
	line-height: 18px;
	white-space: nowrap;
`;

const Dot = styled.div`
	background-color: ${props => props.backgroundColor};
	margin-right: 4px;
	border-radius: 2px;
	width: 4px;
	height: 4px;
`;

interface CardTypeTag {
	text: string | React.ReactNode;
	backgroundColor?: string;
	textColor?: string;
	className?: string;
	tagBorderColor?: string;
}

const CardTypeTag = ({
	text,
	backgroundColor = '#587CB0',
	textColor = '#FFF',
	tagBorderColor = '#FFF',
	className,
}:CardTypeTag): JSX.Element => (
	<TagWrapper className={className}>
		<TagBody
			textColor={textColor}
			backgroundColor={backgroundColor}
			tagBorderColor={tagBorderColor}
		>
			<Dot backgroundColor={backgroundColor} />
			{text}
		</TagBody>
	</TagWrapper>
);

export default CardTypeTag;
