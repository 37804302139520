import React from 'react';
import { useSelector } from 'react-redux';
import useTranslation from '@src/hooks/useTranslation';

import { IColors } from '@src/types/theme';
import { getColorTheme } from '@src/store/selectors/theme';
import { MediumCard as BasicMediumCard } from '@src/components/card/basic';

import CommonContent from './commonContent';
import { getTagText } from './utils';

interface IProps {
	course: any;
	photos: string[];
	onClick: () => void;
}

const MediumCard = ({
	course,
	photos,
	onClick,
}: IProps): JSX.Element => {
	const {
		status,
		title,
	} = course;
	const { t } = useTranslation();
	const { colors: { primary, secondary } } = useSelector(getColorTheme) as { colors: IColors };
	const tagColor = status === 'published' ? primary : secondary;
	const tagText = getTagText({ t, status });

	return (
		<BasicMediumCard
			onClick={onClick}
			title={title}
			tagText={tagText.join(' / ')}
			tagColor={tagColor}
			imageUrl={photos[0]}
			contentElem={<CommonContent course={course} />}
		/>
	);
};

export default MediumCard;
