/* eslint-disable max-len */
import { createAction } from 'redux-actions';

import {
	GET_LEAVE_APPLICATIONS_CALL,
	GET_LEAVE_APPLICATIONS_DONE,
	GET_LEAVE_APPLICATIONS_FAIL,
	POST_LEAVE_APPLICATION_CALL,
	POST_LEAVE_APPLICATION_DONE,
	POST_LEAVE_APPLICATION_FAIL,
	GET_LEAVE_APPLICATION_INFO_CALL,
	GET_LEAVE_APPLICATION_INFO_DONE,
	GET_LEAVE_APPLICATION_INFO_FAIL,
	GET_LEAVE_APPLICATION_DETAIL_CALL,
	GET_LEAVE_APPLICATION_DETAIL_DONE,
	GET_LEAVE_APPLICATION_DETAIL_FAIL,
	REST_LEAVE_APPLICATION_DETAIL_CALL,
	POST_LEAVE_APPLICATION_APPROVE_CALL,
	POST_LEAVE_APPLICATION_APPROVE_DONE,
	POST_LEAVE_APPLICATION_APPROVE_FAIL,
	GET_LEAVE_APPLICATION_CONFIRM_CALL,
	GET_LEAVE_APPLICATION_CONFIRM_DONE,
	GET_LEAVE_APPLICATION_CONFIRM_FAIL,
	POST_LEAVE_APPLICATION_CONFIRM_CALL,
	POST_LEAVE_APPLICATION_CONFIRM_DONE,
	POST_LEAVE_APPLICATION_CONFIRM_FAIL,
	POST_LEAVE_APPLICATION_WITHDRAW_CALL,
	POST_LEAVE_APPLICATION_WITHDRAW_DONE,
	POST_LEAVE_APPLICATION_WITHDRAW_FAIL,
	GET_LEAVE_APPLICATIONS_APPROVER_VIEW_CALL,
	GET_LEAVE_APPLICATIONS_APPROVER_VIEW_DONE,
	GET_LEAVE_APPLICATIONS_APPROVER_VIEW_FAIL,
	POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_CALL,
	POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_DONE,
	POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_FAIL,
	DELETE_LEAVE_APPLICATION_ATTACHMENT_CALL,
	DELETE_LEAVE_APPLICATION_ATTACHMENT_DONE,
	DELETE_LEAVE_APPLICATION_ATTACHMENT_FAIL,
	GET_AVAILABLE_LEAVE_TYPES_CALL,
	GET_AVAILABLE_LEAVE_TYPES_DONE,
	GET_AVAILABLE_LEAVE_TYPES_FAIL,
	GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_CALL,
	GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_DONE,
	GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_FAIL,
	GET_LEAVE_ENTITLEMENT_CALL,
	GET_LEAVE_ENTITLEMENT_DONE,
	GET_LEAVE_ENTITLEMENT_FAIL,
	GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_CALL,
	GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_DONE,
	GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_FAIL,
	GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_CALL,
	GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_DONE,
	GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_FAIL,
	GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_CALL,
	GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_DONE,
	GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_FAIL,
	GET_CUSTOM_LEAVE_TYPE_RULES_CALL,
	GET_CUSTOM_LEAVE_TYPE_RULES_DONE,
	GET_CUSTOM_LEAVE_TYPE_RULES_FAIL,
	POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_CALL,
	POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_DONE,
	POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_FAIL,
} from '@src/store/types';

export const getLeaveApplicationsCall = createAction(GET_LEAVE_APPLICATIONS_CALL);
export const getLeaveApplicationsDone = createAction(GET_LEAVE_APPLICATIONS_DONE);
export const getLeaveApplicationsFail = createAction(GET_LEAVE_APPLICATIONS_FAIL);
export const postLeaveApplicationCall = createAction(POST_LEAVE_APPLICATION_CALL);
export const postLeaveApplicationDone = createAction(POST_LEAVE_APPLICATION_DONE);
export const postLeaveApplicationFail = createAction(POST_LEAVE_APPLICATION_FAIL);
export const getLeaveApplicationInfoCall = createAction(GET_LEAVE_APPLICATION_INFO_CALL);
export const getLeaveApplicationInfoDone = createAction(GET_LEAVE_APPLICATION_INFO_DONE);
export const getLeaveApplicationInfoFail = createAction(GET_LEAVE_APPLICATION_INFO_FAIL);
export const getLeaveApplicationDetailCall = createAction(GET_LEAVE_APPLICATION_DETAIL_CALL);
export const getLeaveApplicationDetailDone = createAction(GET_LEAVE_APPLICATION_DETAIL_DONE);
export const getLeaveApplicationDetailFail = createAction(GET_LEAVE_APPLICATION_DETAIL_FAIL);
export const restLeaveApplicationDetailCall = createAction(REST_LEAVE_APPLICATION_DETAIL_CALL);
export const postLeaveApplicationApproveCall = createAction(POST_LEAVE_APPLICATION_APPROVE_CALL);
export const postLeaveApplicationApproveDone = createAction(POST_LEAVE_APPLICATION_APPROVE_DONE);
export const postLeaveApplicationApproveFail = createAction(POST_LEAVE_APPLICATION_APPROVE_FAIL);
export const getLeaveApplicationConfirmCall = createAction(GET_LEAVE_APPLICATION_CONFIRM_CALL);
export const getLeaveApplicationConfirmDone = createAction(GET_LEAVE_APPLICATION_CONFIRM_DONE);
export const getLeaveApplicationConfirmFail = createAction(GET_LEAVE_APPLICATION_CONFIRM_FAIL);
export const postLeaveApplicationConfirmCall = createAction(POST_LEAVE_APPLICATION_CONFIRM_CALL);
export const postLeaveApplicationConfirmDone = createAction(POST_LEAVE_APPLICATION_CONFIRM_DONE);
export const postLeaveApplicationConfirmFail = createAction(POST_LEAVE_APPLICATION_CONFIRM_FAIL);
export const postLeaveApplicationWithdrawCall = createAction(POST_LEAVE_APPLICATION_WITHDRAW_CALL);
export const postLeaveApplicationWithdrawDone = createAction(POST_LEAVE_APPLICATION_WITHDRAW_DONE);
export const postLeaveApplicationWithdrawFail = createAction(POST_LEAVE_APPLICATION_WITHDRAW_FAIL);
export const getLeaveApplicationsApproverViewCall = createAction(GET_LEAVE_APPLICATIONS_APPROVER_VIEW_CALL);
export const getLeaveApplicationsApproverViewDone = createAction(GET_LEAVE_APPLICATIONS_APPROVER_VIEW_DONE);
export const getLeaveApplicationsApproverViewFail = createAction(GET_LEAVE_APPLICATIONS_APPROVER_VIEW_FAIL);
export const postLeaveApplicationWithdrawApprovalCall = createAction(POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_CALL);
export const postLeaveApplicationWithdrawApprovalDone = createAction(POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_DONE);
export const postLeaveApplicationWithdrawApprovalFail = createAction(POST_LEAVE_APPLICATION_WITHDRAW_APPROVAL_FAIL);
export const deleteLeaveApplicationAttachmentCall = createAction(DELETE_LEAVE_APPLICATION_ATTACHMENT_CALL);
export const deleteLeaveApplicationAttachmentDone = createAction(DELETE_LEAVE_APPLICATION_ATTACHMENT_DONE);
export const deleteLeaveApplicationAttachmentFail = createAction(DELETE_LEAVE_APPLICATION_ATTACHMENT_FAIL);
export const getAvailableLeaveTypesCall = createAction(GET_AVAILABLE_LEAVE_TYPES_CALL);
export const getAvailableLeaveTypesDone = createAction(GET_AVAILABLE_LEAVE_TYPES_DONE);
export const getAvailableLeaveTypesFail = createAction(GET_AVAILABLE_LEAVE_TYPES_FAIL);
export const getLeaveApplicationsTotalMinutesCall = createAction(GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_CALL);
export const getLeaveApplicationsTotalMinutesDone = createAction(GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_DONE);
export const getLeaveApplicationsTotalMinutesFail = createAction(GET_LEAVE_APPLICATIONS_TOTAL_MINUTES_FAIL);
export const getLeaveEntitlementCall = createAction(GET_LEAVE_ENTITLEMENT_CALL);
export const getLeaveEntitlementDone = createAction(GET_LEAVE_ENTITLEMENT_DONE);
export const getLeaveEntitlementFail = createAction(GET_LEAVE_ENTITLEMENT_FAIL);
export const getLeaveEntitlementByLeaveTypeCall = createAction(GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_CALL);
export const getLeaveEntitlementByLeaveTypeDone = createAction(GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_DONE);
export const getLeaveEntitlementByLeaveTypeFail = createAction(GET_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE_FAIL);
export const getLeaveApplicationAttachmentDownloadLinkCall = createAction(GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_CALL);
export const getLeaveApplicationAttachmentDownloadLinkDone = createAction(GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_DONE);
export const getLeaveApplicationAttachmentDownloadLinkFail = createAction(GET_LEAVE_APPLICATION_ATTACHMENT_DOWNLOAD_LINK_FAIL);
export const getLeaveApplicationsInfoByLeaveTypeCall = createAction(GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_CALL);
export const getLeaveApplicationsInfoByLeaveTypeDone = createAction(GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_DONE);
export const getLeaveApplicationsInfoByLeaveTypeFail = createAction(GET_LEAVE_APPLICATIONS_INFO_BY_LEAVE_TYPE_FAIL);
export const getCustomLeaveTypeRulesCall = createAction(GET_CUSTOM_LEAVE_TYPE_RULES_CALL);
export const getCustomLeaveTypeRulesDone = createAction(GET_CUSTOM_LEAVE_TYPE_RULES_DONE);
export const getCustomLeaveTypeRulesFail = createAction(GET_CUSTOM_LEAVE_TYPE_RULES_FAIL);
export const postLeaveApplicationGrantNewEntitlementCall = createAction(POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_CALL);
export const postLeaveApplicationGrantNewEntitlementDone = createAction(POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_DONE);
export const postLeaveApplicationGrantNewEntitlementFail = createAction(POST_LEAVE_APPLICATION_GRANT_NEW_ENTITLEMENT_FAIL);
