import { createAction } from 'redux-actions';
import * as types from '@src/store/types';

export const historyPushCall = createAction(types.HISTORY_PUSH_CALL);
export const historyPushDone = createAction(types.HISTORY_PUSH_DONE);
export const historyPushFail = createAction(types.HISTORY_PUSH_FAIL);

export const historyReplaceCall = createAction(types.HISTORY_REPLACE_CALL);
export const historyReplaceDone = createAction(types.HISTORY_REPLACE_DONE);
export const historyReplaceFail = createAction(types.HISTORY_REPLACE_FAIL);
