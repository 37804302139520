import React from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import useTranslation from '@src/hooks/useTranslation';
import { pipe } from 'lodash/fp';

import { getColorTheme } from '@src/store/selectors/theme';
import { getShoppingCartProducts } from '@src/store/selectors/cart';
import { IColors } from '@src/types/theme';
import { CURRENCY_SYMBOL } from '@src/const/common';
import Button from '@src/components/common/button';

import EmptyContent from './emptyContent';

const CartContentWrapper = styled.div`
	width: 376px;
`;

const ProductContent = styled.div`
	max-height: 328px;
	border-bottom: 1px solid #E9E9E9;
	overflow-y: auto;
`;

const ProductItem = styled.div`
	padding: 16px 16px 0 16px;

	&:hover {
		background: #F5F5F7;
	}
`;

const ItemContent = styled.div`
	display: flex;
	align-items: flex-start;
	padding-bottom: 16px;
	border-bottom: ${props => props.showBorder && '1px solid #E9E9E9'};
	cursor: pointer;
`;

const ItemImage = styled.img`
	width: 100px;
`;

const ItemInfo = styled.div`
	flex-grow: 1;
	margin-left: 8px;
`;

const ItemName = styled.div`
	display: -webkit-box;
	text-overflow: ellipsis;
	overflow: hidden;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	font-size: 16px;
	color: #101010;
`;

const ItemTypePrice = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 8px;
`;

const ItemType = styled.div`
	font-size: 14px;
	color: #676767;
`;

const Price = styled.div`
	font-size: 16px;
	font-weight: 700;
	color: ${props => props.color};
`;

const Summary = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 16px;
`;

const SummaryInfo = styled.div`
	flex-grow: 1;
`;

const TotalAmount = styled.div`
	margin-bottom: 4px;
	font-size: 14px;
	color: #101010;
`;

const CheckoutBtn = styled(Button)`
	width: 120px;
	padding: 8px;
	font-size: 18px;
	font-weight: 500;
`;

const CartContent = (): JSX.Element => {
	const { t } = useTranslation();
	const router = useRouter();
	const { colors } = useSelector(getColorTheme) as { colors: IColors };
	const cartProducts = useSelector(getShoppingCartProducts);
	const totalProductAmount =
		cartProducts.products_numbers + cartProducts.event_slots_numbers + cartProducts.webinar_slots_numbers;
	const productItems = pipe(
		result => result.products?.map(courseItem => ({
			id: courseItem.id,
			link: `/course/${courseItem.short_link}`,
			name: courseItem.description,
			price: courseItem.actual_price,
			image: courseItem.cover_link?.link,
			typeKey: 'course:course_products',
		})),
		result => result?.concat(cartProducts.webinar_slots.map(slotItem => ({
			id: slotItem.slot.id,
			link: `/webinar/${slotItem.short_link}`,
			name: slotItem.webinar_name,
			price: slotItem.subtotal_price,
			image: slotItem.cover_link?.link,
			typeKey: 'webinar:webinar',
		}))),
		result => result?.concat(cartProducts.event_slots.map(slotItem => ({
			id: slotItem.slot.id,
			link: `/event/${slotItem.short_link}`,
			name: slotItem.event_name,
			price: slotItem.subtotal_price,
			image: slotItem.cover_link?.link,
			typeKey: 'event:event',
		}))),
	)(cartProducts);

	const handleClickItem = link => () => { window.open(link, '_blank'); };
	const handleClickCheckout = () => { router.push('/cart'); };

	if (!productItems) {
		return <EmptyContent />;
	}

	return (
		<CartContentWrapper>
			<ProductContent>
				{productItems?.map((productItem, index) => (
					<ProductItem key={productItem.id} onClick={handleClickItem(productItem.link)}>
						<ItemContent showBorder={index !== (productItems.length - 1)}>
							<ItemImage src={productItem.image} />
							<ItemInfo>
								<ItemName>{productItem.name}</ItemName>
								<ItemTypePrice>
									<ItemType>{t(productItem.typeKey)}</ItemType>
									<Price color={colors.secondary}>
										{`${CURRENCY_SYMBOL} ${Number(productItem.price).toLocaleString()}`}
									</Price>
								</ItemTypePrice>
							</ItemInfo>
						</ItemContent>
					</ProductItem>
				))}
			</ProductContent>
			<Summary>
				<SummaryInfo>
					<TotalAmount>{t('common:total_amount', { amount: totalProductAmount })}</TotalAmount>
					<Price color={colors.secondary}>
						{`${CURRENCY_SYMBOL} ${Number(cartProducts.total_price).toLocaleString()}`}
					</Price>
				</SummaryInfo>
				<CheckoutBtn handleClick={handleClickCheckout}>{t('common:go_to_checkout_cart')}</CheckoutBtn>
			</Summary>
		</CartContentWrapper>
	);
};

export default CartContent;
