import React from 'react';
import styled from 'styled-components';
import useTranslation from '@src/hooks/useTranslation';

import { sortEventSlots, getShouldShowSoldOut } from '@src/utils/event';
import {
	INFO_STATUS_ACTIVITY_COUNTDOWN,
	INFO_STATUS_ON_SALE_COUNTDOWN,
	INFO_STATUS_SOLD_OUT_COUNTDOWN,
	INFO_STATUS_IS_OVER,
} from '@src/const/event';

import Timer from './timer';

const FlexWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 12px;
`;

const SeeYouText = styled.div`
	color: #797979;
`;

const Countdown = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 12px;
	color: #797979;
`;

const CountdownText = styled.span`
	padding-right: 2px;
`;

interface IInfoByStatus {
	className?: string;
	status: string;
	countDownTime?: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	slots: any;
}

const InfoByStatus = ({ className, status, countDownTime, slots }: IInfoByStatus): JSX.Element => {
	const { t } = useTranslation();
	const sortedSlots = sortEventSlots(slots);
	const isSoldOut = getShouldShowSoldOut({ sortedSlots });

	if (isSoldOut) {
		return (
			<FlexWrapper className={className}>
				<SeeYouText>{`${t('event:sold_out')}，${t('event:forward_to_see_you')}！`}</SeeYouText>
			</FlexWrapper>
		);
	}

	switch (status) {
		case INFO_STATUS_ACTIVITY_COUNTDOWN:
			return (
				<Countdown className={className}>
					<CountdownText>{`${t('event:events_countdown')}`}</CountdownText>
					<Timer countDownTime={countDownTime} />
				</Countdown>
			);
		case INFO_STATUS_ON_SALE_COUNTDOWN:
			return (
				<Countdown className={className}>
					<CountdownText>{`${t('event:countdown_for_sale')}`}</CountdownText>
					<Timer countDownTime={countDownTime} />
				</Countdown>
			);
		case INFO_STATUS_SOLD_OUT_COUNTDOWN:
			return (
				<FlexWrapper className={className}>
					<SeeYouText>{`${t('event:sold_out')}，${t('event:forward_to_see_you')}！`}</SeeYouText>
				</FlexWrapper>
			);
		case INFO_STATUS_IS_OVER:
			return (
				<FlexWrapper className={className}>
					<SeeYouText>{`${t('event:sold_out')}，${t('event:forward_to_see_you')}！`}</SeeYouText>
				</FlexWrapper>
			);
		default:
			return <></>;
	}
};

export default InfoByStatus;
