/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getLearningAreas = ({ learningArea }) => learningArea.learningAreas;

export const getLearningAreasByKey = key => ({ learningArea }) => learningArea.learningAreasByKey[key] || [];

export const getLearningAreaDetail = ({ learningArea }) => learningArea.learningAreaDetail;

export const getLearningAreasDetailSideEffect = ({ learningArea }) => learningArea.getLearningAreasDetailSideEffect;

export const getLearningAreasPaths = ({ learningArea }) => learningArea.learningAreasPaths;

export const getLearningAreasPathsSideEffect = ({ learningArea }) => (
	learningArea.getLearningAreasPathsSideEffect
);
