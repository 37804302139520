import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f5f5f7;
  width: 100%;
  min-height: 100vh;
  .img {
    height: 248px;
    object-fit: cover;
  }
  .img-box {
    width: 100%;
    text-align: center;
  }

  .text {
    text-align: center;
  }

  .title {
    font-size: 48px;
    color: #222222;
    font-weight: 500;
  }
  .sub-title {
    font-size: 22px;
    color: #333333;
    margin: 25px 0 43px;
  }

  @media (max-width: 768px) {
    .img {
      height: 200px;
    }
    .title {
      font-size: 28px;
    }
    .sub-title {
      font-size: 16px;
    }
  }
`;
