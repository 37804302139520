import React from 'react';
import styled from 'styled-components';

const TagWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const TagBody = styled.div`
	background: #FFFFFF;
	color: #101010;
	border: 1px solid #E9E9E9;
	height: 26px;
	padding: 0 8px;
	display: flex;
	align-items: center;
	font-size: 12px;
	white-space: nowrap;
	margin-bottom: 4px;
`;

interface ITagWithTail {
	text: string | React.ReactNode;
	backgroundColor?: string;
	textColor?: string;
	className?: string;
	tagBorderColor?: string;
}

const TagWithTail = ({
	text,
	backgroundColor = '#587CB0',
	textColor = '#FFF',
	tagBorderColor = '#FFF',
	className,
}:ITagWithTail): JSX.Element => (
	<TagWrapper className={className}>
		<TagBody
			textColor={textColor}
			backgroundColor={backgroundColor}
			tagBorderColor={tagBorderColor}
		>
			{text}
		</TagBody>
	</TagWrapper>
);

export default TagWithTail;
