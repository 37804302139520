import React from 'react';
import HtmlParser from '@src/components/common/htmlParser';
import classStyle from './TextView.module.less';

export default class TextView extends React.Component {
	render() {
		const { item } = this.props;
		const { text } = item;

		return (
			<div className={classStyle.container}>
				<HtmlParser html={text} />
			</div>
		);
	}
}
