import React from 'react';
import styled from 'styled-components';

import { getEventPriceRange, getTicketStatus } from '@src/utils/event';
import { CURRENCY_SYMBOL } from '@src/const/common';
import {
	HostBy,
	PriceRange,
} from '@src/components/card/style';
import InfoByStatus from '@src/components/card/infoByStatus';
import { MediumCard as BasicMediumCard } from '@src/components/card/basic';
import { getPrice } from '@src/components/card/utils';

import TagContent from './tagContent';

const StyledPriceRange = styled(PriceRange)`
	margin-top: 12px;
`;

interface IProps {
	onClick?: () => void;
	webinar?: any;
}

const MediumCard = ({
	onClick,
	webinar,
}: IProps): JSX.Element => {
	const {
		name: title,
		host_by: hostBy,
		photos,
		slots,
	} = webinar;
	const photoLinks = photos.map(photo => photo.link);
	const { status, countDownTime } = getTicketStatus({ slots });
	let price = { minPrice: 0, maxPrice: 0 };
	if (slots.length) {
		price = getEventPriceRange(slots);
	}
	const { minPrice, maxPrice } = price;
	const isFree = (minPrice === maxPrice) && minPrice === 0;

	return (
		<BasicMediumCard
			onClick={onClick}
			title={title}
			tagText={<TagContent />}
			tagColor="#FFFFFF"
			tagBorderColor="#DDDDDD"
			imageUrl={photoLinks[0]}
			imageAlt={photos[0]?.alt_title}
			contentElem={(
				<>
					<HostBy>{hostBy}</HostBy>
					<InfoByStatus status={status} countDownTime={countDownTime} slots={slots} />
					<StyledPriceRange>
						<span className="price-range__currency">{CURRENCY_SYMBOL}</span>
						<span className="price-range__number">
							{!isFree && <span className="price-range__currency">{CURRENCY_SYMBOL}</span>}
							<span className="price-range__number">
								{getPrice({ minPrice, maxPrice })}
							</span>
						</span>
					</StyledPriceRange>
				</>
			)}
		/>
	);
};

export default MediumCard;
