import { createAction } from 'redux-actions';
import {
	GET_LEARNING_AREAS_CALL,
	GET_LEARNING_AREAS_DONE,
	GET_LEARNING_AREAS_FAIL,
	GET_LEARNING_AREAS_BY_SHORT_LINK_CALL,
	GET_LEARNING_AREAS_BY_SHORT_LINK_DONE,
	GET_LEARNING_AREAS_BY_SHORT_LINK_FAIL,
	GET_LEARNING_AREAS_PATHS_CALL,
	GET_LEARNING_AREAS_PATHS_DONE,
	GET_LEARNING_AREAS_PATHS_FAIL,
} from '@src/store/types';

export const getLearningAreasCall = createAction(GET_LEARNING_AREAS_CALL);
export const getLearningAreasDone = createAction(GET_LEARNING_AREAS_DONE);
export const getLearningAreasFail = createAction(GET_LEARNING_AREAS_FAIL);

export const getLearningAreasByShortLinkCall = createAction(GET_LEARNING_AREAS_BY_SHORT_LINK_CALL);
export const getLearningAreasByShortLinkDone = createAction(GET_LEARNING_AREAS_BY_SHORT_LINK_DONE);
export const getLearningAreasByShortLinkFail = createAction(GET_LEARNING_AREAS_BY_SHORT_LINK_FAIL);

export const getLearningAreasPathsCall = createAction(GET_LEARNING_AREAS_PATHS_CALL);
export const getLearningAreasPathsDone = createAction(GET_LEARNING_AREAS_PATHS_DONE);
export const getLearningAreasPathsFail = createAction(GET_LEARNING_AREAS_PATHS_FAIL);
