export const GET_LEARNING_AREAS_CALL = 'GET_LEARNING_AREAS_CALL';
export const GET_LEARNING_AREAS_DONE = 'GET_LEARNING_AREAS_DONE';
export const GET_LEARNING_AREAS_FAIL = 'GET_LEARNING_AREAS_FAIL';

export const GET_LEARNING_AREAS_BY_SHORT_LINK_CALL = 'GET_LEARNING_AREAS_BY_SHORT_LINK_CALL';
export const GET_LEARNING_AREAS_BY_SHORT_LINK_DONE = 'GET_LEARNING_AREAS_BY_SHORT_LINK_DONE';
export const GET_LEARNING_AREAS_BY_SHORT_LINK_FAIL = 'GET_LEARNING_AREAS_BY_SHORT_LINK_FAIL';

export const GET_LEARNING_AREAS_PATHS_CALL = 'GET_LEARNING_AREAS_PATHS_CALL';
export const GET_LEARNING_AREAS_PATHS_DONE = 'GET_LEARNING_AREAS_PATHS_DONE';
export const GET_LEARNING_AREAS_PATHS_FAIL = 'GET_LEARNING_AREAS_PATHS_FAIL';
