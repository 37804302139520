import React from 'react';

import useMediaQuery from '@src/hooks/useMediaQuery';
import { SmallCardsWrapper } from '@src/components/home/components/cardStyle';
import {
	SmallCard as CourseSmallCard,
	HorizontalSmallCard as CourseHorizontalSmallCard,
} from '@src/components/card/courseProduct';
import {
	SmallCard as OfflineEventSmallCard,
	HorizontalSmallCard as OfflineEventHorizontalSmallCard,
} from '@src/components/card/offlineEvent';
import {
	SmallCard as WebinarSmallCard,
	HorizontalSmallCard as WebinarHorizontalSmallCard,
} from '@src/components/card/webinar';
import SkeletonCard from './skeletonCard';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Card = (
	{ className, data, isMobile }:
	{
		className?: string;
		data: any;
		isMobile: boolean;
	},
) => {
	if (data.type === 'course') {
		if (isMobile) {
			return (
				<CourseHorizontalSmallCard
					key={data.course.id}
					course={data.course}
					photos={[data.course.cover_link.link]}
					onClick={() => window.open(`/course/${data.course.short_link}`, '_blank')}
				/>
			);
		} else {
			return (
				<CourseSmallCard
					className={className}
					key={data.course.id}
					course={data.course}
					photos={[data.course.cover_link.link]}
					onClick={() => window.open(`/course/${data.course.short_link}`, '_blank')}
				/>
			);
		}
	} else if (data.type === 'offline_event') {
		if (isMobile) {
			return (
				<OfflineEventHorizontalSmallCard
					key={data.offline_event.id}
					event={data.offline_event}
					onClick={() => window.open(`/event/${data.offline_event.short_link}`, '_blank')}
				/>
			);
		} else {
			return (
				<OfflineEventSmallCard
					className={className}
					key={data.offline_event.id}
					event={data.offline_event}
					onClick={() => window.open(`/event/${data.offline_event.short_link}`, '_blank')}
				/>
			);
		}
	} else if (data.type === 'webinar') {
		if (isMobile) {
			return (
				<WebinarHorizontalSmallCard
					key={data.webinar.id}
					webinar={data.webinar}
					onClick={() => window.open(`/webinar/${data.webinar.short_link}`, '_blank')}
				/>
			);
		} else {
			return (
				<WebinarSmallCard
					className={className}
					key={data.webinar.id}
					webinar={data.webinar}
					onClick={() => window.open(`/webinar/${data.webinar.short_link}`, '_blank')}
				/>
			);
		}
	} else {
		return null;
	}
};

const List = (
	{ list = [], isLoading = false, itemNumber = 4 }:
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	{ list: any[], isLoading?: boolean, itemNumber?: number },
): JSX.Element => {
	const { mediaMatch } = useMediaQuery();
	const { isMobile } = mediaMatch;
	return (
		<SmallCardsWrapper itemNumber={itemNumber}>
			{
				isLoading ? (
					<>
						<SkeletonCard />
						<SkeletonCard />
						<SkeletonCard />
						<SkeletonCard />
						<SkeletonCard />
					</>
				) : list.map(data => Card({ data, isMobile }))
			}
		</SmallCardsWrapper>
	);
};

export default List;
