import React, { useRef } from 'react';
import styled from 'styled-components';
import { Carousel } from 'antd';
import useTranslation from '@src/hooks/useTranslation';

import { NextArrowIcon, PrevArrowIcon } from '@src/components/common/icon';

const Content = styled.div`
	background:#FFFFFF;
	padding: 12px 0 8px 0;
	border-bottom: 1px solid #E9E9E9;
	display: flex;
	flex-direction: row;
	word-break: break-all;
	:first-child {
		border-top: 1px solid #E9E9E9;
	}
`;

const Wrap = styled.div`
`;

const StyledCarousel = styled(Carousel)`
	width: 130px;
	height: 74px;
	& .slick-dots .slick-active {
		width: 6px !important;
		height: 6px !important;
	};
	& .slick-dots .slick-active button {
		border-radius: 100px !important;
		width: 6px !important;
		height: 6px !important;
	};
	& .slick-dots {
		width: 6px !important;
		height: 6px !important;
		margin: unset !important;
		left: 50% !important;
		bottom: 4px;
;
	};
	& .slick-dots button {
		border-radius: 100px !important;
		width: 6px !important;
		height: 6px !important;
	};
`;

const StyledImage = styled.img`
	width: 130px;
	height: 74px;
`;

const InfoContent = styled.div`
	padding: 0 8px;
	overflow : hidden;
	width: calc(100% - 138px);
`;

const Title = styled.div`
	font-weight: 500;
	font-size: 16px;
	color: #363636;
	margin-bottom: 4px;
	height: 48px;
	white-space: break-spaces;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`;

const Desc = styled.div`
	font-size: 12px;
	font-weight: lighter;
	color: #797979;
	white-space: break-spaces;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`;

const AttrInfoContent = styled.div`
	padding-top: 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const AttrInfoWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const Text = styled.div`
	margin-left: 8px;
	font-size: 12px;
	color: #797979;
`;

const ImageWrap = styled.div`
	position: relative;
`;

const NextBtn = styled.div`
	position: absolute;
	top: calc(50% - 12px);
	right: 0px;
	z-index: 1;
`;

const PrevBtn = styled.div`
	position: absolute;
	top: calc(50% - 12px);
	left: 0px;
	z-index: 1;
`;

const Divider = styled.div`
	margin: 0 12px;
	height: 12px;
	border-right: 1px solid #E9E9E9;
`;

interface ILearningPathCard {
	photos: {
		id: string;
		link: string;
		alt?: string;
	}[],
	title: string;
	desc: string;
	onClick: () => void;
	courseNumber: number;
	hasCertificate: number;
}

const LearningPathCard = ({
	photos,
	title,
	desc,
	onClick,
	courseNumber,
	hasCertificate,
}: ILearningPathCard): JSX.Element => {
	const { t } = useTranslation();
	const slider = useRef(null);
	const nextButton = e => {
		e.stopPropagation();
		slider.current.next();
	};
	const prevButton = e => {
		e.stopPropagation();
		slider.current.prev();
	};
	return (
		<Content onClick={onClick}>
			<Wrap>
				<ImageWrap>
					{
						photos.length > 1 && (
							<PrevBtn onClick={prevButton}>
								<PrevArrowIcon />
							</PrevBtn>
						)
					}
					<StyledCarousel ref={slider} swipeToSlide draggable>
						{
							photos.map(photo => (
								<StyledImage key={photo.id} src={photo.link} alt={photo.alt} />
							))
						}
					</StyledCarousel>
					{
						photos.length > 1 && (
							<NextBtn onClick={nextButton}>
								<NextArrowIcon />
							</NextBtn>
						)
					}
				</ImageWrap>
				<AttrInfoContent>
					<AttrInfoWrap>
						<Text>{t('learning_path:card_course_number', { courseNumber })}</Text>
					</AttrInfoWrap>
					{
						hasCertificate && (
							<>
								<Divider />
								<AttrInfoWrap>
									<Text>{t('learning_path:certificate')}</Text>
								</AttrInfoWrap>
							</>
						)
					}
				</AttrInfoContent>
			</Wrap>
			<InfoContent>
				<Title>{title}</Title>
				<Desc>{desc}</Desc>
			</InfoContent>
		</Content>
	);
};

export default LearningPathCard;
