import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { isBoolean, get } from 'lodash';

import { getEventPriceRange, getTicketStatus, sortEventSlots, getShouldShowSoldOut } from '@src/utils/event';
import { IColors } from '@src/types/theme';
import { getColorTheme } from '@src/store/selectors/theme';
import useTranslation from '@src/hooks/useTranslation';
import { CURRENCY_SYMBOL } from '@src/const/common';
import {
	HostBy,
	PriceRange,
	InfoWrapper,
	InfoItem,
} from '@src/components/card/style';
import InfoByStatus from '@src/components/card/infoByStatus';
import { SmallCard as BasicSmallCard } from '@src/components/card/basic';
import { getPrice } from '@src/components/card/utils';
import { getIsPriceVisible } from '@src/utils/hardcode';

import StatelessContent from './statelessContent';

const SpaceBetweenWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1 1 auto;
`;

const ContentWrapper = styled.div`
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const getCardContent = ({ t, isStateless, event, isPriceVisible }) => {
	if (isStateless) {
		return <StatelessContent event={event} />;
	} else {
		const {
			host_by: hostBy,
		} = event;
		let price = { minPrice: 0, maxPrice: 0 };
		if (event.slots.length) {
			price = getEventPriceRange(event.slots);
		}
		const { minPrice, maxPrice } = price;
		const isFree = (minPrice === maxPrice) && minPrice === 0;

		return (
			<ContentWrapper>
				<HostBy>{hostBy}</HostBy>
				<SpaceBetweenWrapper>
					<PriceRange>
						{
							isPriceVisible ? (
								<>
									{!isFree && <span className="price-range__currency">{CURRENCY_SYMBOL}</span>}
									<span className="price-range__number">
										{getPrice({ minPrice, maxPrice })}
									</span>
								</>
							) : (
								<>{t('common:consultation')}</>
							)
						}
					</PriceRange>
				</SpaceBetweenWrapper>
			</ContentWrapper>
		);
	}
};

interface IProps {
	onClick: () => void;
	isStateless?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	event: any;
	isSoldOut?: boolean
	className?: string
}

const SmallCard = ({
	onClick,
	event,
	isStateless = false,
	isSoldOut,
	className,
}: IProps): JSX.Element => {
	const { t } = useTranslation();
	const {
		name: title,
		photos,
		slots,
	} = event;
	const photoLinks = photos.map(photo => photo.link);
	const { colors: { primary } } = useSelector(getColorTheme) as { colors: IColors };
	const { id: eventId } = event || {};
	const isPriceVisible = getIsPriceVisible(eventId);

	const contentElem = getCardContent({ t, isStateless, event, isPriceVisible });
	const { status, countDownTime } = getTicketStatus({ slots });
	const sortedSlots = sortEventSlots(slots);
	const isTicketSoldOut = isBoolean(isSoldOut) ? isSoldOut : getShouldShowSoldOut({ sortedSlots });

	return (
		<BasicSmallCard
			className={className}
			onClick={onClick}
			title={title}
			tagText={t('event:event')}
			tagColor={primary}
			imageUrl={photoLinks[0]}
			imageAlt={get(photos, '0.alt_title', '')}
			contentElem={contentElem}
			infoElem={(
				isBoolean(isSoldOut) && !isSoldOut ? null : (
					<InfoWrapper>
						<InfoItem>
							<InfoByStatus status={status} countDownTime={countDownTime} slots={slots} />
						</InfoItem>
					</InfoWrapper>
				)
			)}
			isSoldOut={isTicketSoldOut}
		/>
	);
};

export default SmallCard;
