import React from 'react';
import styled from 'styled-components';
import useTranslation from '@src/hooks/useTranslation';

import { EmptyBoxIcon } from '@src/components/common/icon';

const EmptyWrapper = styled.div`
	width: 376px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 32px 16px;
`;

const Text = styled.div`
	margin-top: 12px;
`;

const EmptyContent = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<EmptyWrapper>
			<EmptyBoxIcon />
			<Text>{t('checkout:go_to_explore_knowledge')}</Text>
		</EmptyWrapper>
	);
};

export default EmptyContent;
