import React, { useState } from 'react';
import { ThemeButton } from '@src/components/home';
import HtmlParser from '@src/components/common/htmlParser';
import { Row, Col } from 'antd';
import { MyProgressiveImage, Video } from '@src/components';
import { IconComponent } from '@src/icons';
import classStyle from './VideoText.module.less';

export default function VideoText(props) {
	const { item } = props;
	const { text, button, image, thumbnailLink, link } = item;
	const [isPlay, setPlay] = useState(false);
	return (
		<Row
			className={classStyle.container}
			align="center"
			justify="center"
			gutter={[30, { xs: 30, sm: 15, md: 0 }]}
		>
			<Col xs={24} md={12}>
				<div className={classStyle.video}>
					<div className={classStyle.backVideo}>
						{image && !isPlay ? (
							<MyProgressiveImage
								className={classStyle.image}
								src={image}
								placeholder={thumbnailLink}
							/>
						) : null}
						<Video
							url={link}
							playing={isPlay}
							loop
							isRwdType
							controls
						/>
					</div>
					{
						image && !isPlay && (
							<div className={classStyle.iconDiv}>
								<div className={classStyle.icon} onClick={() => setPlay(isPlay => !isPlay)}>
									<IconComponent
										icon={isPlay ? 'icon_play_stop_06' : 'icon_play_05'}
									/>
								</div>
							</div>

						)
					}
				</div>
			</Col>
			<Col xs={24} md={12}>
				<div className={classStyle.textBox}>
					<HtmlParser html={text} />
					<ThemeButton className={classStyle.button} button={button} />
				</div>
			</Col>
		</Row>
	);
}
