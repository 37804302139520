import { createAction } from 'redux-actions';
import {
	GET_SCHOOL_ANNOUNCEMENTS_CALL,
	GET_SCHOOL_ANNOUNCEMENTS_DONE,
	GET_SCHOOL_ANNOUNCEMENTS_FAIL,
	GET_SCHOOL_ANNOUNCEMENT_DETAIL_CALL,
	GET_SCHOOL_ANNOUNCEMENT_DETAIL_DONE,
	GET_SCHOOL_ANNOUNCEMENT_DETAIL_FAIL,
	GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_CALL,
	GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_DONE,
	GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_FAIL,
	POST_SCHOOL_ANNOUNCEMENT_READ_CALL,
	POST_SCHOOL_ANNOUNCEMENT_READ_DONE,
	POST_SCHOOL_ANNOUNCEMENT_READ_FAIL,
	POST_SCHOOL_ANNOUNCEMENT_READ_ALL_CALL,
	POST_SCHOOL_ANNOUNCEMENT_READ_ALL_DONE,
	POST_SCHOOL_ANNOUNCEMENT_READ_ALL_FAIL,
} from '@src/store/types';

export const getSchoolAnnouncementsCall = createAction(GET_SCHOOL_ANNOUNCEMENTS_CALL);
export const getSchoolAnnouncementsDone = createAction(GET_SCHOOL_ANNOUNCEMENTS_DONE);
export const getSchoolAnnouncementsFail = createAction(GET_SCHOOL_ANNOUNCEMENTS_FAIL);
export const getSchoolAnnouncementDetailCall = createAction(GET_SCHOOL_ANNOUNCEMENT_DETAIL_CALL);
export const getSchoolAnnouncementDetailDone = createAction(GET_SCHOOL_ANNOUNCEMENT_DETAIL_DONE);
export const getSchoolAnnouncementDetailFail = createAction(GET_SCHOOL_ANNOUNCEMENT_DETAIL_FAIL);
export const getSchoolAnnouncementByIndexCall = createAction(GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_CALL);
export const getSchoolAnnouncementByIndexDone = createAction(GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_DONE);
export const getSchoolAnnouncementByIndexFail = createAction(GET_SCHOOL_ANNOUNCEMENT_BY_INDEX_FAIL);
export const postSchoolAnnouncementReadCall = createAction(POST_SCHOOL_ANNOUNCEMENT_READ_CALL);
export const postSchoolAnnouncementReadDone = createAction(POST_SCHOOL_ANNOUNCEMENT_READ_DONE);
export const postSchoolAnnouncementReadFail = createAction(POST_SCHOOL_ANNOUNCEMENT_READ_FAIL);
export const postSchoolAnnouncementReadAllCall = createAction(POST_SCHOOL_ANNOUNCEMENT_READ_ALL_CALL);
export const postSchoolAnnouncementReadAllDone = createAction(POST_SCHOOL_ANNOUNCEMENT_READ_ALL_DONE);
export const postSchoolAnnouncementReadAllFail = createAction(POST_SCHOOL_ANNOUNCEMENT_READ_ALL_FAIL);
