import React from 'react';
import styled from 'styled-components';
import useTranslation from '@src/hooks/useTranslation';

import { formatTimeNumber } from '@src/utils/time';
import useCountdown from '@src/hooks/useCountdown';

const TimerWrapper = styled.div`
	display: flex;
`;

const TimeValue = styled.div`
	color: ${props => props.theme.colors.primary};
	margin: 0px 4px;
	font-size: 12px;
`;

const TimeUnit = styled.div`
	font-size: 12px;
	color: #797979;
	display: flex;
	align-items: center;
`;

interface ITimer {
	countDownTime: number;
}

const Timer = ({ countDownTime }: ITimer): JSX.Element => {
	const { t } = useTranslation();
	const { days, hours, minutes, seconds } = useCountdown({ endTime: countDownTime });

	return (
		<TimerWrapper>
			<TimeUnit>
				<TimeValue style={{ marginLeft: 0 }}>{formatTimeNumber(days)}</TimeValue>
				{t('time:day')}
			</TimeUnit>
			<TimeUnit>
				<TimeValue>{formatTimeNumber(hours)}</TimeValue>
				{t('time:hour')}
			</TimeUnit>
			<TimeUnit>
				<TimeValue>{formatTimeNumber(minutes)}</TimeValue>
				{t('time:minute')}
			</TimeUnit>
			<TimeUnit>
				<TimeValue>{formatTimeNumber(seconds)}</TimeValue>
				{t('time:second')}
			</TimeUnit>
		</TimerWrapper>
	);
};

export default Timer;
